import React from "react"
import { FiveCircleRate } from "../../../../common/components"
import { EmptyData } from "../../../../common/components/EmptyData"

interface Props {
  data?: {
    id: string
    name: string
    proficiency: number
  }[]
}

export const GreenSkills: React.FC<Props> = ({ data }) => {
  return (
    <div className="p-5 rounded-xl shadow-md bg-[#FAFAFA] mb-5">
      <h3 className="">Green Skills</h3>
      <div>
        {data && data.length > 0 ? (
          data.map((item, i) => (
            <div
              key={i}
              className="flex md:gap-5 gap-2 md:items-center  md:flex-row flex-col rounded px-3 py-1 my-2 bg-[#F0F2F5] md:h-14"
              style={{ border: "1px solid #D0DDE5" }}
            >
              <FiveCircleRate circleSize={16} defaultValue={item.proficiency} />
              <div className="md:text-left">{item.name}</div>
            </div>
          ))
        ) : (
          <EmptyData />
        )}
      </div>
    </div>
  )
}
