import { useCallback, useEffect, useRef, useState } from "react"
import { useAxiosWithMsal } from "../../../common/hooks"
import { type AxiosInstance } from "axios"
import { message } from "antd"
import { useQuery } from "react-query"
import { type AxiosError } from "../../../common/errors"
import { type ApiResponsePaginatedV2 } from "../../../common/types/ApiResponse"
import { type TalentDatabase } from "../components/type/database"
import { debounce } from "../../../utils/debounce"
import { useLocation } from "react-router-dom"

export const useTalentDatabases = () => {
  const { pathname } = useLocation()
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    search: "",
    search_name: "",
    favorite: 0,
    location_id: null,
    career_goal: null,
    green_skills_id: null,
    connected: pathname === "/talent/database" ? 0 : 1,
  })

  const [dataList, setDataList] = useState<TalentDatabase[]>([])
  const [total, setTotal] = useState<number>(0)

  const getAxiosInstance = useAxiosWithMsal()
  const getTalentFinderList = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<ApiResponsePaginatedV2<TalentDatabase>>(
        "/talent-finders/database",
        {
          params,
        },
      )
    } catch (error) {
      message.error("An error occurred while fetching  talent finder list")
    }
  }
  const { data, isLoading } = useQuery(
    ["talentfinder-list", params],
    getTalentFinderList,
    {
      retry: 3,
      onError: (error: AxiosError) => {
        message.error(
          "An error occurred while fetching talent finder list: " +
            error.message,
        )
      },
    },
  )

  const handleSearch = debounce((value: string | null) => {
    setParams((prev: any) => {
      if (pathname === "/talent/connected") {
        return { ...prev, search_name: value, page: 1 }
      } else {
        return { ...prev, search: value, page: 1 }
      }
    })
  }, 1000)

  const handleFavorite = (value: number) => {
    setParams((prev: any) => ({ ...prev, favorite: value, page: 1 }))
  }

  const handleChangeFilter = (key: string, value: string) => {
    setParams((prev: any) => ({ ...prev, [key]: value, page: 1 }))
  }

  const observer = useRef<IntersectionObserver | null>(null)
  const lastCardElementRef = useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          dataList.length &&
          dataList?.length < total &&
          !isLoading
        ) {
          setParams((prev: any) => ({ ...prev, page: prev.page + 1 }))
        }
      })
      if (node) observer.current.observe(node)
    },
    //
    [dataList?.length],
  )

  useEffect(() => {
    if (!isLoading) {
      if (params.page === 1) {
        setDataList(data?.data.data.list ?? [])
        setTotal(data?.data.data.total_data ?? 0)
      } else {
        setDataList((prev) => [...prev, ...(data?.data.data.list ?? [])])
        setTotal(data?.data.data.total_data ?? 0)
      }
    }
    //
  }, [data?.data.data.list])

  return {
    dataList,
    isLoading,
    handleSearch,
    params,
    handleFavorite,
    handleChangeFilter,
    lastCardElementRef,
    isConnectedPage: pathname === "/talent/connected",
    total,
  }
}
