import { Skeleton } from "antd"
import React from "react"

interface PrivacyPolicyContentProps {
  data: any
}

const PrivacyPolicyContent: React.FC<PrivacyPolicyContentProps> = ({
  data,
}) => {
  console.log("data", data)
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-4">
        <div className="bg-white-custom py-3 px-4 shadow-custom rounded-xl !h-auto">
          {data?.isLoading ? (
            <Skeleton.Input
              active={true}
              className="!w-full !rounded-lg my-1"
              size={"small"}
            />
          ) : (
            <p className="tracking-widest uppercase text-xs text-yellow-600">
              Contents
            </p>
          )}

          {data?.isLoading ? (
            <Skeleton className="mt-3" active paragraph={{ rows: 1 }} />
          ) : (
            <ol className="-ml-5">
              {data?.data?.contents?.map((section: any, index: number) => {
                return (
                  <li className="rounded-full my-2 !text-[16px]" key={index}>
                    {section?.title}
                  </li>
                )
              })}
            </ol>
          )}
        </div>
      </div>

      <div className="col-span-8 rounded-xl shadow-custom p-4 bg-white space-y-5">
        {data?.isLoading
          ? Array.from({ length: 3 }, (_: any, index: number) => (
              <div
                key={index}
                className="bg-white-custom h-64 rounded-xl shadow-custom p-5 space-y-3 w-full"
              >
                <Skeleton.Input
                  active={true}
                  className="!w-full !rounded-lg"
                  size={"large"}
                />

                <Skeleton.Input
                  active={true}
                  className="!w-full !rounded-lg !h-40"
                  size={"large"}
                />
              </div>
            ))
          : data?.data?.contents?.map((section: any, index: number) => {
              return (
                <div
                  key={index}
                  className="bg-white-custom p-5 rounded-xl shadow-custom"
                >
                  <h3 className="text-md font-bold">
                    {section?.order + 1}. {section?.title}
                  </h3>
                  <div
                    className="mt-2 text-[16px] custom-container"
                    dangerouslySetInnerHTML={{
                      __html: `
                    <style>
                      .custom-container a {
                        color: #86C769 !important;
                        background-color: "red";
                        transition: all .3s ease;
                      }

                      .custom-container a:hover {
                        font-weight : bold;
                      }
			              </style>
                      
                    ${section?.body}`,
                    }}
                  ></div>
                </div>
              )
            })}
      </div>
    </div>
  )
}

export default PrivacyPolicyContent
