import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
} from "react-query"
import { message } from "antd"
import { AxiosInstance } from "axios"
import { useAxiosWithMsal } from "../../../common/hooks"
import { AxiosError } from "../../../common/errors"
import { ApiResponse } from "../../../common/types/ApiResponse"

export type GetStageType = {
  id: string
  tag: string
  name: string
}

export type Stage = {
  name: string
}

export interface GetStagesApiResponse extends ApiResponse {
  data: { data: GetStageType[] }
}

export const useGetStages = (): UseQueryResult<
  GetStagesApiResponse,
  AxiosError
> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getStages = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<GetStagesApiResponse>("/stages")
    } catch (error) {
      message.error("An error occurred while fetching stages.")
    }
  }
  return useQuery("stages", getStages, {
    onError: (error: AxiosError) => {
      message.error("An error occurred while fetching stages." + error.message)
    },
  })
}
export const useCreateStage = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()
  const createStage = async (data: Stage) => {
    try {
      const api = await getAxiosInstance()
      return await api.post("/stages", data)
    } catch (error) {
      console.log(error)
      message.error(
        "Failed to edit Stage, there was a problem with your request. Please try again by giving a different value.",
      )
    }
  }
  return useMutation(createStage, {
    onSuccess: () => {
      queryClient.invalidateQueries(["stages"])
      message.success("Stage has successfully been created.")
    },
    onError: (error: AxiosError) => {
      message.error(
        "An error occurred while creating the Stage: " + error.message,
      )
    },
  })
}

export const useEditStage = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const editStage = async ({ data, id }: { data: Stage; id: string }) => {
    try {
      const api = await getAxiosInstance()
      return await api.patch(`/stages/${id}`, data)
    } catch (error) {
      console.log(error)
      message.error(
        "Failed to edit Stage, there was a problem with your request. Please try again by giving a different value.",
      )
    }
  }
  return useMutation(editStage, {
    onSuccess: () => {
      queryClient.invalidateQueries(["stages"])
      message.success("Stage has successfully been edited.")
    },
    onError: (error: AxiosError) => {
      message.error(
        "An error occurred while editing the Stage: " + error.message,
      )
    },
  })
}

export const useDeleteStage = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const deleteStage = async (id: string) => {
    try {
      const api = await getAxiosInstance()
      return await api.delete(`/stages/${id}`)
    } catch (error) {
      console.log(error)
      message.error(
        "Failed to edit Stage, there was a problem with your request. Please try again by giving a different value.",
      )
    }
  }
  return useMutation(deleteStage, {
    onSuccess: () => {
      queryClient.invalidateQueries(["stages"])
      message.success("Stage successfully deleted.")
    },
    onError: (error: AxiosError) => {
      message.error(
        "An error occurred while deleting the stage: " + error.message,
      )
    },
  })
}
