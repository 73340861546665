import { Tag } from "antd"
import React, { useState } from "react"

export interface ToggleProps {
  options: string[]
  defaultSelected?: string
  from?: string
  onToggle?: (selected: string) => void
}

export const Toggle: React.FC<ToggleProps> = ({
  options,
  defaultSelected,
  onToggle,
  from,
}) => {
  const [selected, setSelected] = useState(defaultSelected || options[0])

  const handleToggle = (option: string) => {
    setSelected(option)
    if (onToggle) {
      onToggle(option)
    }
  }

  return (
    <div className="flex border-2 border-white z-20">
      {options.map((option, index) => (
        <button
          key={option}
          style={
            index === 0
              ? { border: "0.5px solid #D0DDE5", borderRight: "none" }
              : { border: "0.5px solid #D0DDE5", borderLeft: "none" }
          }
          className={`flex w-48 justify-center items-center duration-200 transition-all gap-2 px-4 py-3 cursor-pointer ${
            index === 0 ? "rounded-l-full" : ""
          } ${index === options.length - 1 ? "rounded-r-full" : ""} ${
            selected === option ? "bg-secondaryYellow" : "bg-white"
          }`}
          onClick={() => handleToggle(option)}
        >
          {option}
          {from && index === 1 && (
            <Tag color="#D4F5C5" className=" rounded-full !text-backdrop">
              -17%
            </Tag>
          )}
        </button>
      ))}
    </div>
  )
}
