import { type ApiResponseV2 } from "../../../common/types/ApiResponse"
import { type AxiosInstance } from "axios"
import { useAxiosWithMsal } from "../../../common/hooks"
import { message } from "antd"
import { type AxiosError } from "../../../common/errors"
import { useMutation, useQuery } from "react-query"

export const useSelectedStage = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const getSelectedStage = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<ApiResponseV2>("/stages/finders/with-selected")
    } catch (error) {
      message.error("An error occurred while fetching stage selected ")
    }
  }
  const { data, isLoading } = useQuery(["stage-selected"], getSelectedStage, {
    onError: (error: AxiosError) => {
      message.error(
        "An error occurred while fetching stage selected: " + error.message,
      )
    },
  })

  const updateSelected = async (id: string) => {
    try {
      const api = await getAxiosInstance()
      return await api.patch("/stages/finders/with-selected/update", {
        stage_id: id,
      })
    } catch (error) {
      message.error("An error occurred while update selected stage")
    }
  }
  const { mutate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: async (val: string) => {
      return updateSelected(val)
    },
    mutationKey: "update-stage-selected",
  })

  const handleUpdateSelected = (val: string) => {
    mutate(val, {
      onError: (err: any) => {
        message.error(err?.response?.data?.data)
      },
    })
  }

  return {
    data: data?.data?.data,
    isLoading,
    handleUpdateSelected,
    isLoadingUpdate,
  }
}
