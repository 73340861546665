import { useFetchPrivacyPolicy } from "../apis/rest"

export const usePrivacyPolicy = () => {
  const restPrivacyPolicy = window.location.href.includes("privacy-policy")
    ? useFetchPrivacyPolicy({ query: "privacy" })
    : useFetchPrivacyPolicy({ query: "term" })

  console.log("restPrivacyPolicy", restPrivacyPolicy?.data?.data)
  return {
    privacyPolicyResponse: {
      data: restPrivacyPolicy?.data?.data,
      isLoading: restPrivacyPolicy.isLoading,
    },
  }
}
