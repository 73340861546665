import { Button, Input, Modal } from "antd"
import React from "react"
import { Controller, useForm } from "react-hook-form"

interface ModalManagePasswordProps {
  isOpenFormPassword: boolean
  isConfirmLoading: boolean
  handleChangePassword: (val: any) => void
  hideModalPassword: () => void
}

const ModalManagePassword: React.FC<ModalManagePasswordProps> = ({
  isOpenFormPassword,
  isConfirmLoading,
  handleChangePassword,
  hideModalPassword,
}) => {
  const {
    handleSubmit,
    control,
    // reset,
    // setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    },
  })
  return (
    <Modal title="Reset Password" open={isOpenFormPassword} footer={null}>
      <div>
        <form className="mt-10">
          <div className="">
            <div>
              <label className="text-xs" htmlFor="old_password">
                Old Password
              </label>
              <Controller
                name="old_password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="old_password"
                    status={errors?.old_password ? "error" : ""}
                    className="my-1 font-default rounded-none -mt-0"
                    placeholder="Old Password"
                  />
                )}
              />
            </div>
            <div className="my-3">
              <label className="text-xs" htmlFor="new_password">
                New Password
              </label>
              <Controller
                name="new_password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="new_password"
                    status={errors?.new_password ? "error" : ""}
                    className="my-1 font-default rounded-none -mt-0"
                    placeholder="New Password"
                  />
                )}
              />
            </div>
            <div className="my-3">
              <label className="text-xs" htmlFor="confirm_new_password">
                Confirm New Password
              </label>
              <Controller
                name="confirm_new_password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="confirm_new_password"
                    status={errors?.confirm_new_password ? "error" : ""}
                    className="my-1 font-default rounded-none -mt-0"
                    placeholder="Confirm New Password"
                  />
                )}
              />
            </div>
          </div>
          <div className="mt-5 flex items-center gap-2 justify-end">
            <Button
              type="default"
              className="bg-white"
              onClick={hideModalPassword}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="bg-primary"
              onClick={handleSubmit(handleChangePassword)}
              loading={isConfirmLoading}
            >
              Save changes
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ModalManagePassword
