import { useContext, useEffect, useState } from "react"
import { useSelectedStage } from "./useSelectedStage"
import { message } from "antd"
import { useAxiosWithMsal } from "../../../common/hooks"
import { useMutation } from "react-query"
import { TenantContext } from "../../../context/user"

const useInviteApplicant = () => {
  const tenant = useContext(TenantContext)
  const {
    data: dataStages,
    isLoading: isLoadingStages,
    handleUpdateSelected,
  } = useSelectedStage()

  const [valueStage, setValueStage] = useState<any>(undefined)
  useEffect(() => {
    if (dataStages?.length > 0) {
      const temp = dataStages?.find((item: any) => item.is_selected)
      setValueStage(temp?.id)
    }
  }, [dataStages])

  const handleChangeStage = (val: string) => {
    handleUpdateSelected(val)
    setValueStage(val)
  }

  const getAxiosInstance = useAxiosWithMsal()
  const inviteApplicant = async (val: any) => {
    try {
      const api = await getAxiosInstance()
      return await api.post("/invited-applicants/jobs", {
        stage_id: valueStage,
        job_id: val.id,
        job_name: val.name,
        talent_id: val.talent_id,
        tenant_name: tenant?.name,
      })
    } catch (error) {
      message.error("An error occurred while invite applicant")
    }
  }
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async (val: any) => {
      return inviteApplicant(val)
    },
    mutationKey: "invited-applicant-store",
  })

  const handleInvite = async (val: any) => {
    try {
      const result = await mutateAsync(val)
      return result
    } catch (error: any) {
      message.error(error?.response?.data?.data)
    }
  }

  return {
    dataStages,
    isLoadingStages,
    valueStage,
    handleChangeStage,
    handleInvite,
    isLoading,
  }
}

export default useInviteApplicant
