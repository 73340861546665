import React from "react"
import { EditOutlined } from "@ant-design/icons"
import { Controller, useFormContext } from "react-hook-form"
import { CreateJobSchema } from "../types"
import { Button, Input, InputNumber, Select } from "antd"
export const EditJobPostDetails: React.FC<Props> = () => {
  const {
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useFormContext<CreateJobSchema>()
  const { edit, setEdit } = useJobPostDetailStore()
  const jobTypes = useJobTypes()
  const updateDraftMutate = useUpdateJobDetail()
  const { id } = useParams()
  const currencyFrequencies = useCurrenciesFrequencies()

  React.useEffect(() => {
    const jobDetailKeys = [
      "title",
      "job_type",
      "location",
      "team",
      "salary_currency",
      "salary_from",
      "salary_up_to",
      "salary_frequency",
    ]
    if (
      errors &&
      Object.keys(errors).some((key) => jobDetailKeys.includes(key))
    ) {
      setEdit(true)
    }
  }, [])

  const saveAsDraft = async () => {
    const unfilteredData = {
      title: getValues("title"),
      job_type: getValues("job_type"),
      location: getValues("location"),
      team: getValues("team"),
      salary_currency: getValues("salary_currency"),
      salary_from: Number(getValues("salary_from")) || 0,
      salary_up_to: Number(getValues("salary_up_to")) || 0,
      salary_frequency: getValues("salary_frequency"),
    }
    const isValid = await trigger(Object.keys(unfilteredData) as any)
    if (!isValid) return
    const data = Object.entries(unfilteredData).reduce<Record<string, any>>(
      (acc, [key, value]) => {
        if (value !== null && value !== undefined) {
          acc[key] = value
        }
        return acc
      },
      {},
    )
    console.log(data)
    updateDraftMutate.mutate(
      { data, id: id ?? "" },
      {
        onSuccess: () => {
          setEdit(false)
        },
      },
    )
  }

  return (
    <div className="basic-card bg-custom-white flex flex-col gap-4">
      {edit ? (
        <>
          <div className="flex gap-4  items-center">
            <div className="w-[90px] text-darkGrey whitespace-nowrap">
              Job Name
            </div>
            <div className="flex flex-col gap-2 w-full">
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder="Job Title" className="" />
                )}
              />
              {errors.title && (
                <span className="text-danger text-xs">
                  {errors?.title?.message}
                </span>
              )}
            </div>
          </div>
          <div className="flex gap-4  items-center">
            <div className="w-[90px] text-darkGrey whitespace-nowrap">
              Job Type
            </div>
            <div className="flex flex-col gap-2 w-full">
              <Controller
                name="job_type"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    loading={jobTypes?.isLoading}
                    onChange={(option, value) => field.onChange(value)}
                    value={field?.value?.id}
                    className="w-full rounded-md"
                    fieldNames={{ label: "name", value: "id" }}
                    options={
                      Array.isArray(jobTypes?.data?.data?.data)
                        ? jobTypes?.data?.data?.data
                        : []
                    }
                    placeholder="Job Type"
                  ></Select>
                )}
              />
              {errors?.job_type && (
                <div className="text-danger text-xs">Job Type is required</div>
              )}
            </div>
          </div>
          <div className="flex gap-4  items-center">
            <div className="w-[90px] text-darkGrey">Location</div>
            <div className="flex flex-col gap-2 w-full">
              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <InfiniteAutoComplete
                    {...field}
                    className="!w-full"
                    placeholder="Location"
                    useDataHook={useCountriesInfinity}
                    onClearValue={null}
                    // @ts-ignore
                    selectedValue={field.value}
                    onSelect={(value, option) => {
                      field.onChange(option)
                    }}
                  />
                )}
              />
              {errors?.location && (
                <div className="text-danger text-xs">Location is required</div>
              )}
            </div>
          </div>
          <div className="flex gap-4  items-center ">
            <div className="w-[90px] text-darkGrey">Team</div>
            <div className="flex flex-col gap-2 w-full">
              <Controller
                name="team"
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder="Job Team/Function" />
                )}
              />
              {errors?.team && (
                <div className="text-danger text-xs">
                  {errors?.team?.message}
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-4  items-center ">
            <div className="w-[90px] text-darkGrey">Salary</div>
            <div className="flex flex-col w-full gap-4">
              <Controller
                name="salary_currency"
                control={control}
                render={({ field }) => {
                  return (
                    <InfiniteAutoComplete
                      {...field}
                      fieldNames={{ label: "code", value: "id" }}
                      useDataHook={useCurrenciesInfinity}
                      className="w-full"
                      onClearValue={null}
                      placeholder="Search currencies"
                      selectedValue={field?.value ?? undefined}
                      onSelect={(value, option) => {
                        console.log("option", option)
                        field.onChange(option)
                      }}
                    />
                  )
                }}
              />
              <div className="grid grid-cols-12 gap-3">
                <Controller
                  name="salary_from"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      className="col-span-6 w-full"
                      placeholder="0"
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      onFocus={(e) => e.target.select()}
                      controls={false}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="salary_up_to"
                  control={control}
                  // should be higher than salary from
                  rules={{
                    validate: (value) => {
                      const salaryFrom = getValues("salary_from")
                      if (
                        salaryFrom !== undefined &&
                        value !== undefined &&
                        Number(value) <= Number(salaryFrom)
                      ) {
                        return "must be higher than salary from"
                      }
                      return true
                    },
                    required: !!getValues("salary_from"),
                  }}
                  render={({ field }) => (
                    <InputNumber
                      className="col-span-6 w-full"
                      placeholder="0"
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      onFocus={(e) => e.target.select()}
                      controls={false}
                      {...field}
                    />
                  )}
                />
                {errors?.salary_up_to && (
                  <div className="text-danger">
                    {errors?.salary_up_to?.message?.toString() ||
                      "Salary Up Too is required"}
                  </div>
                )}
              </div>
              <Controller
                name="salary_frequency"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(option, value) => field.onChange(value)}
                    value={field?.value?.id}
                    loading={currencyFrequencies?.isLoading}
                    className="w-full"
                    fieldNames={{ label: "name", value: "id" }}
                    options={
                      Array.isArray(currencyFrequencies?.data?.data?.data)
                        ? currencyFrequencies?.data?.data?.data
                            .filter((item) => item.name !== "custom")
                            .map((item) => {
                              let name = item.name.replace(/-/g, " ")
                              name = name
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() +
                                    word.slice(1),
                                )
                                .join(" ")

                              return { ...item, name }
                            })
                        : []
                    }
                    placeholder="Salary Frequency"
                  ></Select>
                )}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex gap-4  items-center">
            <div className="w-[90px] text-darkGrey">Job Name</div>
            <div className="flex-1">{getValues("title")}</div>
          </div>
          <div className="flex gap-4  items-center">
            <div className="w-[90px] text-darkGrey">Job Type</div>
            <div className="flex-1">{getValues("job_type.name")}</div>
          </div>
          <div className="flex gap-4  items-center">
            <div className="w-[90px] text-darkGrey">Location</div>
            <div className="flex-1">{getValues("location.name")}</div>
          </div>
          <div className="flex gap-4  items-center">
            <div className="w-[90px] text-darkGrey">Team</div>
            <div className="flex-1">{getValues("team")}</div>
          </div>
          <div className="flex gap-4  items-center">
            <div className="w-[90px] text-darkGrey">Salary</div>
            <div className="flex gap-2 items-center">
              <div className="flex-1">{getValues("salary_currency.code")}</div>
              <div className="flex gap-1">
                <div className="flex-1">
                  {formatNumber(Number(getValues("salary_from"))) || 0}
                </div>{" "}
                -{" "}
                <div className="flex-1">
                  {formatNumber(Number(getValues("salary_up_to"))) || 0}
                </div>
              </div>
              <div className="text-xs lowercase">
                {getValues("salary_frequency.name") &&
                  getValues("salary_frequency.name")}
              </div>
            </div>
          </div>
        </>
      )}
      <div className="flex items-center gap-4 justify-end">
        {edit ? (
          <>
            <Button
              type="primary"
              size="small"
              loading={updateDraftMutate.isLoading}
              className="bg-primary text-white"
              onClick={saveAsDraft}
            >
              Save
            </Button>
          </>
        ) : (
          <EditOutlined
            onClick={() => setEdit(!edit)}
            className="text-secondary hover:text-backdrop cursor-pointer hover:scale-105 transition-all duration-200 text-[18px]"
          />
        )}
      </div>
    </div>
  )
}
import { useCountriesInfinity } from "../../../../../common/hooks"
import { InfiniteAutoComplete } from "../../../../../common/components"
import {
  useCurrenciesFrequencies,
  useCurrenciesInfinity,
  useJobTypes,
} from "../../../../../common/hooks/useReferences"
import { useUpdateJobDetail } from "../../../hooks/useJobPosts"
import { useParams } from "react-router-dom"
import { useJobPostDetailStore } from "../store/jobPostDetailStore"

import { formatNumber } from "../../../../../helper/formatNumber"

interface Props {
  isLoading: boolean
}
