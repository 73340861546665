import React from "react"
import { useFormContext } from "react-hook-form"
import { CreateJobSchema } from "../types"
import { CheckCircleOutlined, CheckCircleTwoTone } from "@ant-design/icons"

export const JobDetailsChecklist = () => {
  const { getValues } = useFormContext<CreateJobSchema>()

  const greenSkillsFields = getValues("green_skills") ?? []
  const technicalSkillFields = getValues("technical_skills") ?? []
  const coreSkillsFields = getValues("critical_skills") ?? []

  return (
    <div className="basic-card bg-custom-white">
      <h3 className="font-semibold">Job Details Checklist</h3>
      <div className="">
        <ul className="list-none p-0 flex flex-col gap-4">
          {[
            {
              name: "include Green Skills",
              checked: greenSkillsFields?.length > 0,
            },
            {
              name: "Fill in the proficiency of Green Skills",
              checked:
                greenSkillsFields?.length > 0 &&
                greenSkillsFields.every((skill) => skill.proficiency !== 0),
            },
            {
              name: "Specify required Technical Skills",
              checked: technicalSkillFields?.length > 0,
            },
            {
              name: "List 3 or more Soft Skills",
              checked: coreSkillsFields?.length >= 3,
            },
            {
              name: "Select proficiency level for Soft Skills",
              checked:
                coreSkillsFields?.length &&
                coreSkillsFields?.every((skill) => skill.proficiency !== null),
            },
          ].map((item, index) => (
            <li key={index} className="flex items-center">
              {item.checked ? (
                <CheckCircleTwoTone
                  twoToneColor="#86C769"
                  color={"#D4F5C5"}
                  className="mr-2"
                />
              ) : (
                <CheckCircleOutlined color={"#36505E"} className="mr-2" />
              )}
              {item.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
