import React from "react"
import { Rate, RateProps } from "antd"
export interface FiveCircleRateProps extends RateProps {
  circleSize?: number
  defaultValue?: number
  disabled?: boolean
  count?: number
  color?: string
}
export function FiveCircleRate({
  circleSize = 20,
  defaultValue = 0,
  disabled,
  count,
  color,
  ...inputProps
}: FiveCircleRateProps) {
  return (
    <Rate
      {...inputProps}
      className="!inline-flex"
      character={(obj) => renderCharacter(obj, circleSize, defaultValue, color)}
      disabled={disabled}
      defaultValue={defaultValue}
      count={count === 0 || !count ? 5 : count}
    />
  )
}

export const renderCharacter = (
  input: { index?: number },
  size: number,
  defaultValue: number,
  color?: string,
) => {
  const fill =
    input.index === undefined || defaultValue <= input.index
      ? "none"
      : color
        ? color
        : "#D4F5C5"
  const stroke = "#36505E"
  const strokeWidth = 1
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={size / 2 - strokeWidth}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
