// formSchema.js
import * as yup from "yup"

const proficiencySchema = yup.object().shape({
  id: yup.string(),
  name: yup.string(),
})

const skillSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string(),
  proficiency: proficiencySchema.required("Proficiency is required."),
})

const technicalSkillSchema = yup
  .object()
  .shape({ id: yup.string(), name: yup.string() })

const greenSkillSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string(),
  proficiency: yup.number(),
})

const languageSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string(),
  proficiency: proficiencySchema.required("Proficiency is required."),
})

const locationSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
})

const currencySchema = yup.object().shape({
  id: yup.string(),
  name: yup.string(),
  code: yup.string(),
})

const frequencySchema = yup.object().shape({
  id: yup.string(),
  name: yup.string(),
})

const choiceSchema = yup.object().shape({
  name: yup.string(),
  disqualify: yup.boolean(),
})

const questionSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string(),
  question: yup.string(),
  choices: yup.array().of(choiceSchema).min(1),
})

const jobTypeSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
})

const schema = yup.object().shape({
  description: yup
    .string()
    .notOneOf(["<p><br></p>"], "Can't have an empty description.")
    .min(1, "Please enter a description")
    .required("Description is required"),
  critical_skills: yup
    .array()
    .of(skillSchema)
    .min(3, "Please add at least 5 critical skill")
    .required("Critical skills are required"),
  green_skills: yup
    .array()
    .of(greenSkillSchema)
    .min(1, "Please add at least one green skill")
    .max(25, "You can add at most 25 green skills")
    .required("Green skills are required"),
  technical_skills: yup
    .array()
    .of(technicalSkillSchema)
    .min(1, "Please add at least one technical skill")
    .max(10, "You can add at most 10 technical skills")
    .required("Technical skills are required"),
  languages: yup
    .array()
    .of(languageSchema)
    .min(1, "Please add at least one language")
    .max(10, "You can add at most 10 languages")
    .nullable(true),
  location: locationSchema
    .required("Location is required")
    .nullable(false)
    .unknown(false),
  remote: yup.boolean().default(false).nullable(false),
  salary_currency: currencySchema.nullable(true),
  salary_frequency: frequencySchema.nullable(true),
  salary_from: yup
    .number()
    .default(0)
    .positive("Salary from must be a positive number")
    .integer("Salary from must be an integer")
    .min(0, "Salary from must be at least 0")
    .max(
      yup.ref("salary_up_to"),
      "Salary from must be less than or equal to salary up to",
    )
    .nullable(true),
  salary_up_to: yup
    .number()
    .default(0)
    .positive("Salary up to must be a positive number")
    .integer("Salary up to must be an integer")
    .min(
      yup.ref("salary_from"),
      "Salary up to must be greater than or equal to salary from",
    )
    .nullable(true),
  screening_questions: yup
    .array()
    .of(questionSchema)
    .max(10, "You can add at most 10 screening questions")
    .nullable(true),
  team: yup
    .string()
    .min(1, "Please enter a team name")
    .max(1000, "Team name must be at most 1000 characters")
    .required("Team is required"),
  title: yup
    .string()
    .min(1, "Please enter a Job name")
    .max(1000, "Job name must be at most 1000 characters")
    .required("Name is required"),
  job_type: jobTypeSchema.nullable(false).required("Job type is required"),
})

export default schema
