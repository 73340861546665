import React from "react"
import { Navigate, useLocation } from "react-router-dom"
import { useMsal } from "@azure/msal-react"
import { useGetTenants } from "../../common/hooks/useTenant"
import { PageLoader } from "../../common/components"

interface ProtectRoutesProps {
  children: React.ReactElement
}
export const ProtectRoutes: React.FC<ProtectRoutesProps> = ({ children }) => {
  const instance = useMsal()
  const tenants = useGetTenants()
  const location = useLocation()

  if (tenants?.isLoading) {
    return <PageLoader />
  }

  const isTenantError =
    tenants?.isError &&
    !tenants?.isLoading &&
    tenants?.error?.response?.data?.code === 400 &&
    tenants?.error?.response?.data?.data?.redirect_affected === "frontend" &&
    tenants?.error?.response?.data?.data?.type === "redirect"
  const activeAccount = instance?.accounts[0]

  if (activeAccount && location.pathname === "/") {
    return <Navigate to="/home/dashboard" replace />
  }

  return isTenantError ? (
    <Navigate
      to={tenants?.error?.response?.data?.data?.redirect_path}
      replace
    />
  ) : activeAccount ? (
    children
  ) : (
    <Navigate to="/login" />
  )
}
