import React from "react"
import dayjs from "dayjs"
import { Tag } from "antd"
import { Link } from "react-router-dom"
import { Invoice } from "../../hooks/biliingPlan/type"
import { formatNumber } from "../../../../helper/formatNumber"

interface Props {
  data?: Invoice[]
  isLoading: boolean
}
export const Invoices: React.FC<Props> = ({ data }) => {
  return (
    <div className="rounded-xl shadow-md bg-[#FAFAFA] p-5">
      <h3>Invoices</h3>

      <div className="mt-2">
        {data && data.length > 0
          ? data?.map((item, index) => {
              return item.status !== "draft" ? (
                <Link
                  to={item.hosted_invoice_url}
                  target="_blank"
                  className="flex gap-5 mb-2 w-fit"
                  key={index}
                >
                  <div className="min-w-[80px]">
                    {dayjs.unix(item.created).format("MMM DD, YYYY")}
                  </div>
                  <div className="uppercase min-w-[100px]">
                    {`${item.currency} ${formatNumber(item.amount_paid / 100)}`}
                  </div>
                  <div className="text-center">
                    <Tag color="green" className=" capitalize">
                      {item.status}
                    </Tag>
                  </div>
                  <div>
                    {item.lines.data.map((item) => item.description).join(", ")}
                  </div>
                </Link>
              ) : (
                <div className="flex gap-5 mb-2" key={index}>
                  <div className="min-w-[80px]">
                    {dayjs.unix(item.created).format("MMM DD, YYYY")}
                  </div>
                  <div className="uppercase min-w-[100px]">
                    {`${item.currency} ${(item.total / 100).toFixed(2)}`}
                  </div>
                  <div className="text-center">
                    <Tag color="lightgray" className=" capitalize">
                      {item.status}
                    </Tag>
                  </div>
                  <div>
                    {item.lines.data.map((item) => item.description).join(", ")}
                  </div>
                </div>
              )
            })
          : "No invoice history"}
      </div>
    </div>
  )
}
