import React from "react"
import { Button, Drawer, Input, Radio, type DrawerProps } from "antd"
import { Controller } from "react-hook-form"
import { useNewUserDrawer } from "../hooks/useNewUserDrawer"
import { InfiniteAutoComplete } from "../../../common/components"
import { useCountriesInfinity } from "../../../common/hooks"

interface NewUserDrawerProps extends DrawerProps {
  open: boolean
  onClose: () => void
}

export const NewUserDrawer: React.FC<NewUserDrawerProps> = ({
  open,
  onClose,
  ...inputProps
}) => {
  const { handleSubmit, control, errors, onSaveSubmit } =
    useNewUserDrawer(onClose)

  return (
    <Drawer
      {...inputProps}
      closable={false}
      placement="right"
      size="large"
      onClose={onClose}
      open={open}
    >
      {/* @ts-ignore */}
      <form onSubmit={handleSubmit(onSaveSubmit)}>
        <p className="font-bold text-xl text-backdrop mb-5">New User</p>
        <div className="mb-5 space-y-6">
          <div className="flex items-center gap-4">
            <div className="flex flex-col items-center-justify-start gap-2 w-1/2">
              <div className="">First Name</div>
              <Controller
                control={control}
                name="first_name"
                render={({ field }) => (
                  <Input placeholder="First Name" {...field} />
                )}
              />
              {errors.first_name && (
                <p className="text-red-500">{errors.first_name.message}</p>
              )}
            </div>
            <div className="flex flex-col items-center-justify-start gap-2 w-1/2">
              <div className="">Last Name</div>
              <Controller
                control={control}
                name="last_name"
                render={({ field }) => (
                  <Input placeholder="Last Name" {...field} />
                )}
              />
              {errors.last_name && (
                <p className="text-red-500">{errors.last_name.message}</p>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center-justify-start gap-2">
            <div className="">Work Email</div>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <Input placeholder="Work Email" {...field} />
              )}
            />
            {errors.email && (
              <p className="text-red-500">{errors.email.message}</p>
            )}
          </div>
          {/* <div className="flex flex-col items-center-justify-start gap-2">
            <div className="">Display Name</div>
            <Controller
              control={control}
              name="display_name"
              render={({ field }) => (
                <Input placeholder="Display Name" {...field} />
              )}
            />
            {errors.display_name && (
              <p className="text-red-500">{errors.display_name.message}</p>
            )}
          </div> */}
          <div className="flex flex-col items-center-justify-start gap-2">
            <div className="">Country</div>
            <Controller
              name="country"
              rules={{ required: true }}
              control={control}
              render={({ field }) => {
                return (
                  <InfiniteAutoComplete
                    {...field}
                    getPopupContainer={(triggerNode: any) =>
                      triggerNode.parentNode
                    }
                    className="!w-full"
                    placeholder="Search Country"
                    useDataHook={useCountriesInfinity}
                    // @ts-ignore
                    selectedValue={field.value}
                    onSelect={(value, option) => {
                      field.onChange(option)
                    }}
                  />
                )
              }}
            />
            {errors?.country && (
              <p className="text-red-500">{errors?.country.message}</p>
            )}
          </div>
          <div className="flex flex-col  justify-start gap-2">
            <div>Role</div>
            <Controller
              control={control}
              name="role"
              defaultValue="Recruiter"
              render={({ field }) => (
                <Radio.Group
                  onChange={(e) => field.onChange(e.target.value)}
                  onBlur={field.onBlur}
                  className="flex flex-col gap-3"
                  defaultValue={"Recruiter"}
                  value={field.value}
                >
                  <Radio value="Admin">Admin</Radio>
                  <Radio value="Recruiter">Recruiter</Radio>
                </Radio.Group>
              )}
            />

            {errors?.role && (
              <p className="text-red-500">{errors?.role.message}</p>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end gap-3">
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" className="bg-primary" htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    </Drawer>
  )
}
