import React from "react"
import { useForm, Controller } from "react-hook-form"
import { Input, Button } from "antd"
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons"

interface FormData {
  name: string
}
interface InputWithSaveButtonProps {
  showInput: boolean
  isLoading: boolean
  onSave: (data: FormData) => void
  onClose: () => void
}
const InputWithSaveButton: React.FC<InputWithSaveButtonProps> = ({
  showInput,
  onSave,
  isLoading,
  onClose,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>()

  const onSubmit = (data: FormData) => {
    onSave(data)
    reset()
  }

  return showInput ? (
    <>
      <div className="flex items-center w-full mb-4">
        <div className="rounded-full bg-secondaryYellow h-4 w-4 mr-2"></div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-grow items-center "
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                placeholder="Type New Pipeline Stage Here"
                {...field}
                className="h-6 text-sm"
                prefix={isLoading && <LoadingOutlined />}
                suffix={
                  <>
                    <Button
                      htmlType="submit"
                      type="text"
                      className="!m-0 hover:!bg-transparent group !p-0"
                      icon={
                        <CheckOutlined className="!text-sm group-hover:scale-105 duration-300 transition-transform" />
                      }
                    />
                    <CloseOutlined
                      onClick={() => onClose()}
                      className="!text-sm hover:scale-105 duration-300 transition-transform"
                    />
                  </>
                }
              />
            )}
          />
        </form>
      </div>
      {errors?.name && (
        <span className="text-red-500 ml-6 -mt-7">Name is required</span>
      )}
    </>
  ) : (
    <></>
  )
}

export default InputWithSaveButton
