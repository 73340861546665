import { ApiError, ApiResponseV2 } from "./../../../common/types/ApiResponse"
import {
  useQuery,
  useMutation,
  useQueryClient,
  type UseQueryResult,
} from "react-query"
import { AxiosResponse, type AxiosInstance } from "axios"
import { useAxiosWithMsal } from "../../../common/hooks"
import { message } from "antd"
import { type AxiosError } from "../../../common/errors"
import {
  type ApiResponse,
  type ApiResponsePaginated,
} from "../../../common/types/ApiResponse"
import {
  type Item,
  type GreenSkill,
  type CoreSkill,
  type Question,
  Currency,
} from "../../../common/hooks/useReferences"
import React from "react"

interface SingleJobPostResponse extends ApiResponse {
  data: { data: JobPost }
}
export interface JobPost {
  team: string
  id: string
  name: string
  job_type: null | Item
  location: Item | null
  remote: boolean
  salary_currency: null | Item
  salary_from: null | number
  salary_up_to: null | number
  salary_frequency: null | Item
  green_skills: null | GreenSkill[]
  technical_skills: null | Item[]
  critical_skills: null | CoreSkill[]
  languages: null | Item[]
  description?: string
  screening_questions: null | Question[]
  status: Status
  expired_at: null | string
  closed_at: null | string
  total_candidates: number
}
interface JobPostListResponse extends ApiResponse {}

export type Status = "ACTIVE" | "CLOSE" | "DRAFT" | "JUST_PUBLISH"
export interface JobListQueryType {
  name: string | null
  location: string[] | string | null
  team: string[] | null
  status: Status
  by_recruiter?: 1 | 0
  limit?: number
  page?: number
}

export interface ActiveJob {
  id: string
  name: string
  team: string
  location: string
  status: Status
  remote: boolean
  expired_at: string | null
  closed_at: string | null
  total_candidates: number
}

export type ClosedJob = {
  outcome: string
} & ActiveJob

interface CloseJobForm {
  candidate_source?: string
  candidates_ids?: string[]
  closing_status_id: string
  other_reason?: string
}

export const useGetSingleJobPost = (
  id: string,
): [
  UseQueryResult<SingleJobPostResponse, AxiosError>,
  () => Promise<any | undefined>,
] => {
  const getAxiosInstance = useAxiosWithMsal()
  const getSingleJobPost = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<SingleJobPostResponse>(`jobs/${id}`)
    } catch (error) {
      message.error("An error occurred while fetching Job Post.")
    }
  }
  return [
    useQuery(["job-post", id], getSingleJobPost, {
      onError: () => {
        message.error("An error occurred while fetching Job Post.")
      },
    }),
    getSingleJobPost,
  ]
}

export const useGetJobList = (
  queryParam: JobListQueryType,
): UseQueryResult<ApiResponsePaginated<ActiveJob | ClosedJob>, AxiosError> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getJobList = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      // Process queryParam
      const processedParam = Object.fromEntries(
        Object.entries(queryParam).map(([key, value]) => [
          key,
          Array.isArray(value) ? value.join(",") : value,
        ]),
      )

      return await api.get<JobPostListResponse>("/jobs", {
        params: {
          ...processedParam,
        },
      })
    } catch (error) {
      message.error("An error occurred while fetching job posts: ")
    }
  }
  return useQuery(["job-list", queryParam], getJobList, {
    onError: (error: AxiosError) => {
      message.error(
        "An error occurred while fetching job posts: " + error.message,
      )
    },
  })
}

export const useGetJobPostLocations = (name?: string) => {
  const getAxiosInstance = useAxiosWithMsal()
  const getJobPostLocations = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<ApiResponseV2<Item[]>>("/jobs/locations", {
        params: {
          name,
        },
      })
    } catch (error) {
      message.error("An error occurred while fetching job post locations.")
    }
  }
  return useQuery("job-post-locations", getJobPostLocations, {
    onError: () => {
      message.error("An error occurred while fetching job post locations.")
    },
  })
}

export const useGetJobExpiringSoon = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const getJobList = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()

      return await api.get<AxiosResponse<ActiveJob[]>>("/jobs/expiring-soon", {
        params: {
          by_recruiter: 1,
        },
      })
    } catch (error: any) {
      message.error(error?.response?.data?.data || "Internal server error")
    }
  }
  const { data, isLoading } = useQuery(["job-list-expiring-soon"], getJobList, {
    onError: (error: AxiosError) => {
      message.error(error?.response?.data?.data || "Internal server error")
    },
  })
  return {
    data: data?.data?.data,
    isLoading,
  }
}

export interface AddJobPostAsDraftType {
  job_role?: Item
  title: string
  job_type?: Item
  location?: Item
  remote?: boolean
  salary_currency?: Item
  salary_from?: any
  salary_up_to?: any
  salary_frequency?: Item
  team?: string
}

export const usePostJobAsDraft = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const addJobPost = React.useCallback(
    async (data: AddJobPostAsDraftType) => {
      const api = await getAxiosInstance()
      return api.post("/jobs", {
        ...data,
        job_role_id: data?.job_role?.id,
        job_type_id: data?.job_type?.id,
        location_id: data?.location?.id,
        salary_currency_id: data?.salary_currency?.id,
        salary_frequency_id: data?.salary_frequency?.id,
      })
    },
    [getAxiosInstance],
  )

  return useMutation(async (data: AddJobPostAsDraftType) => addJobPost(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["job-list"])
      message.success(
        "A draft job has been created. Please edit it as you please, and don't forget to save as draft each time you have made changes.",
      )
    },
    onError: (error: AxiosError) => {
      message.error(
        "An error occurred while creating your job: " +
          error.response?.data?.data +
          " Please try again.",
        10,
      )
    },
  })
}
export const useUpdateDraft = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const addJobPost = async ({ data, id }: { data: any; id: string }) => {
    const api = await getAxiosInstance()
    return api.patch(`/jobs/${id}`, data)
  }
  return useMutation(addJobPost, {
    onSuccess: () => {
      queryClient.invalidateQueries(["job-list"])
      message.success("Draft successfully edited and saved.")
    },
    onError: (error: ApiError) => {
      message.error(
        "An error occurred while saving your job post: " +
          error?.response?.data?.data +
          "Please try again.",
      )
    },
  })
}
export const usePublishJob = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const publishJob = async (id: string) => {
    const api = await getAxiosInstance()
    return api.patch(`/jobs/${id}/publish`)
  }
  return useMutation(publishJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(["job-list", "job-post"])
      message.success("Job Post has been published successfully")
    },
    onError: (error: ApiError) => {
      message.error(
        "An error occurred while publishing your job post: " +
          error?.response?.data?.data,
      )
    },
  })
}

export const useCloseJobPost = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()
  const closeJob = async ({
    reason,
    id,
  }: {
    reason: CloseJobForm
    id: string
  }) => {
    try {
      const api = await getAxiosInstance()
      return await api.patch(`/jobs/${id}/close`, reason)
    } catch (error) {
      throw new Error("An error occurred while closing the job post.")
    }
  }
  return useMutation(closeJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(["job-list"])
      message.success("Job Post has been closed successfully")
    },
    onError: (error: AxiosError) => {
      message.error(
        "An error occurred while closing your job post: " +
          error.message +
          "Please try again.",
      )
    },
  })
}

export const useDeleteJobPost = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()
  const deleteJob = async (id: string) => {
    try {
      const api = await getAxiosInstance()
      return await api.delete(`/jobs/${id}`)
    } catch (error) {
      message.error("An error occurred while deleting the job post.")
    }
  }
  return useMutation(deleteJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(["job-list"])
      message.success("Job Post has been deleted successfully")
    },
    onError: (error: ApiError) => {
      message.error(
        "An error occurred while deleting your job post: " +
          error.response?.data?.data +
          "Please try again.",
      )
    },
  })
}

export const useSaveJobPostAsTemplate = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()
  const saveJobAsTemplate = async (id: string) => {
    message.open({
      content: "Saving job post as template...",
      key: "save-job-post-as-template",
    })
    const api = await getAxiosInstance()
    return api.post(`/jobs/${id}/save-as-template`)
  }
  return useMutation(saveJobAsTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries(["job-list"])
      message.open({
        content: "Job Post has been saved as template successfully",
        key: "save-job-post-as-template",
        duration: 1,
        type: "success",
      })
    },
    onError: (error: ApiError) => {
      message.open({
        content:
          "An error occurred while saving your job post as template: " +
          error.response?.data?.data,
        key: "save-job-post-as-template",
        duration: 5,
        type: "error",
      })
    },
  })
}

type JobPostDetail = {
  job_type?: Item
  location?: Item
  name?: string
  remote?: boolean
  salary_currency?: Currency
  salary_from?: number
  salary_up_to?: number
  salary_frequency?: Item
  team?: string
}

export const useUpdateJobPost = <T>(
  endpoint: string,
  successMessage: string,
) => {
  const getAxiosInstance = useAxiosWithMsal()
  // const queryClient = useQueryClient()
  const updateJobPost = async (id: string, data: T) => {
    try {
      const api = await getAxiosInstance()
      return await api.patch(`/jobs/${id}/${endpoint}`, { [endpoint]: data })
    } catch (error) {
      message.error("An error occurred while updating the job post.")
    }
  }
  return useMutation(
    ({ id, data }: { id: string; data: T }) => updateJobPost(id, data),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["job-list"])
        message.success(successMessage)
      },
      onError: (error: AxiosError) => {
        message.error(
          "An error occurred while updating your job post: " +
            error.message +
            "Please try again.",
        )
      },
    },
  )
}

export const useUpdateJobDetail = () =>
  useUpdateJobPost<JobPostDetail>(
    "detail",
    "Job Post has been updated successfully",
  )
export const useUpdateJobPostDescription = () =>
  useUpdateJobPost<string>("description", "Description updated successfully.")
export const useUpdateJobPostGreenSkills = () =>
  useUpdateJobPost<GreenSkill[]>(
    "green-skills",
    "Green Skills has been updated successfully",
  )
export const useUpdateJobPostTechnicalSkills = () =>
  useUpdateJobPost<Item[]>(
    "technical-skills",
    "Technical Skills updated successfully",
  )
export const useUpdateJobPostCriticalSkills = () =>
  useUpdateJobPost<CoreSkill[]>(
    "soft-skills",
    "Critical Skills updated successfully",
  )
export const useUpdateJobPostLanguages = () =>
  useUpdateJobPost<Item[]>("languages", "Languages updated successfully")
export const useUpdateJobPostScreeningQuestions = () =>
  useUpdateJobPost<Question[]>(
    "screening-questions",
    "Screening Questions updated successfully",
  )
