import { useMutation, useQueryClient } from "react-query"
import { useAxiosWithMsal } from "../../../common/hooks"
import { message } from "antd"

interface MakeTenantSubscriptionType {
  stripe_coupon_id?: string
  stripe_price_id: string
  stripe_product_id: string
  is_trial?: boolean
  currency?: string
}

export const useSubscriptions = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const makeTenantSubscription = async (data: MakeTenantSubscriptionType) => {
    message.open({
      key: "create-sub",
      content: "creating subscriprion...",
      type: "loading",
    })
    try {
      const api = await getAxiosInstance()
      return await api.post("/subscriptions/make", data)
    } catch (error) {
      throw error
    }
  }

  return {
    makeTenantSubscription: useMutation(makeTenantSubscription, {
      onSuccess: () => {
        message.open({
          key: "create-sub",
          content: "subscription created",
          type: "success",
        })
        queryClient.invalidateQueries(["tenants"])
      },
      onError: (err: any) => {
        const errfix = JSON.parse(err?.response?.data?.data || "{}")
        message.open({
          key: "create-sub",
          content: errfix?.message || "Internal server error",
          type: "success",
        })
      },
    }),
  }
}
