import React from "react"
import { PageContent, PageHeader } from "../../../common/layout"
import { HomeOutlined } from "@ant-design/icons"
import { useGetDashboard } from "../hooks"
import { useGetUserList } from "../../settings/hooks/useAdministrateUsers"
import { useGetTenants } from "../../../common/hooks/useTenant"
import { ResponseDashboardAPIType } from "../types/responseDashboard"
import { TeamAndCompany } from "../components/dashboard/TeamAndCompany"
import { JobActive } from "../components/dashboard/JobActive"
import { TalentConnect } from "../components/dashboard/TalentConnect"
import { CandidateByStage } from "../components/dashboard/CandidateByStage"
import { JobExpiringSoon } from "../components/dashboard/JobExpiringSoon"

export function Dashboard() {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Dashboard",
    },
  ]

  const dashboardQuery = useGetDashboard()
  const teamList = useGetUserList({ userStatusSelected: "active" })
  const tenant = useGetTenants()

  const dashboardData: ResponseDashboardAPIType | undefined =
    dashboardQuery?.data?.data?.data

  const content = React.useMemo(() => {
    return (
      <div className=" p-5 flex gap-5 bg-white">
        <div className=" w-[325px]">
          <TeamAndCompany
            tenant={{
              data: tenant?.data?.data?.data,
              isLoading: tenant?.isLoading,
            }}
            teamList={{
              data: teamList?.data?.data?.data,
              isLoading: teamList?.isLoading,
            }}
          />
        </div>
        <div className=" w-[calc(100%-325px-1.25rem)]">
          <div className="grid grid-cols-2 gap-3">
            <div className="mb-5 ">
              <div className=" mb-3">
                <JobActive />
              </div>
              <div>
                <TalentConnect />
              </div>
            </div>
            <CandidateByStage dashboardData={dashboardData} />
          </div>
          <JobExpiringSoon />
        </div>
      </div>
    )
  }, [teamList, tenant, dashboardQuery])

  return (
    <PageContent
      header={
        <PageHeader title={"Dashboard"} breadcrumbItems={items}></PageHeader>
      }
      content={content}
    />
  )
}
