import { useContext, useState } from "react"
import { useAxiosWithMsal } from "../../../common/hooks"
import { useMutation, useQueryClient } from "react-query"
import { message } from "antd"
import { TenantContext, UserContext } from "../../../context/user"

export const useConnect = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()
  const user = useContext(UserContext)
  const tenant = useContext(TenantContext)

  const [current, setCurrent] = useState<string>("")
  const requestConnect = async (talentId: string) => {
    try {
      const api = await getAxiosInstance()
      return await api.post("/talent-finders/request-connect", {
        recruiter_id: user?.id,
        tenant_id: tenant?.id,
        recruiter_name: user?.display_name,
        tenant_name: tenant?.name,
        talent_id: talentId,
      })
    } catch (error) {
      message.error("An error occurred while fetching talent")
    }
  }
  const { mutate, isLoading } = useMutation({
    mutationFn: async (val: string) => {
      return requestConnect(val)
    },
    mutationKey: "connect-talent",
  })

  const handleRequest = (val: string) => {
    setCurrent(val)
    mutate(val, {
      onSuccess: async () =>
        queryClient.invalidateQueries(["raw-fav-connected-list"]),
      onError: (err: any) => {
        message.error(err?.response?.data?.data)
      },
    })
  }
  return {
    handleRequest,
    isLoading,
    current,
  }
}
