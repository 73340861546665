import { Avatar, Divider, Spin, Tag } from "antd"
import React, { useContext } from "react"
import { UserCard } from "../../../../common/components"
import { EmptyData } from "../../../../common/components/EmptyData"
import otherCompany from "../../../../assets/images/other_company.png"
import { LoadingOutlined } from "@ant-design/icons"
import { Tenant } from "../../../../common/hooks/useTenant"
import { GetUser } from "../../../settings/hooks/useAdministrateUsers"
import {
  ITourContext,
  TourContext,
} from "../../../../common/components/tour/TourProvider"

interface Props {
  tenant?: {
    data: Tenant | null | undefined
    isLoading: boolean
  }
  teamList?: {
    data?: GetUser[]
    isLoading: boolean
  }
}

export const TeamAndCompany: React.FC<Props> = ({ tenant, teamList }) => {
  const {
    refs: { refOurTeam },
  } = useContext(TourContext) as ITourContext

  return (
    <div ref={refOurTeam} className="bg-secondaryBlue p-5 rounded-md">
      <Spin
        spinning={tenant?.isLoading || teamList?.isLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <div className=" flex flex-col items-center">
          <Avatar
            src={tenant?.data?.logo || otherCompany}
            className="h-20 w-20 object-contain"
          />
          <h4 className="font-semibold text-center capitalize">
            {tenant?.data?.name || "Company Name"}
          </h4>
          <Divider className=" m-0 border-1 mb-2 border-[#22A5D1]" />
          <div className="w-full">
            {teamList?.data && teamList?.data?.length > 0 ? (
              teamList?.data?.map((user: any, i: number) => (
                <div className="flex justify-between items-center my-3" key={i}>
                  <UserCard
                    key={user?.id}
                    avatarUrl={user?.avatar}
                    location={user?.country}
                    name={user?.display_name}
                  />
                  <Tag
                    // className="rounded border-none"
                    className="rounded"
                    color={user?.role === "Admin" ? "green" : "orange"}
                  >
                    {user?.role}
                  </Tag>
                </div>
              ))
            ) : (
              <EmptyData />
            )}
          </div>
        </div>
      </Spin>
    </div>
  )
}
