import React from "react"
import { Screening } from "../../../inteface/detailApplicant"
import {
  CloseCircleOutlined,
  CheckSquareOutlined,
  WarningOutlined,
} from "@ant-design/icons"
// import { Radio } from "antd"

interface ScreeningProps {
  data: Screening[] | null | undefined
}

export const ScreeningQuestions: React.FC<ScreeningProps> = ({ data }) => {
  return (
    <div className="py-5">
      {data?.map((v: any, i: number) => (
        <div key={i} className=" rounded-xl shadow-md p-5 bg-[#FAFAFA]">
          <div className=" text-primaryYellow tracking-widest uppercase text-[10px]">
            {v?.name}
          </div>
          <h3 className=" first-letter:capitalize">{v?.question}</h3>
          <div>{helperScreeningAnswer(v?.choices)}</div>
        </div>
        // <div key={i} className="bg-[#FFF8F5] my-3 text-left">
        //   <div className=" bg-[#36505E] text-white px-5 py-3 first-letter:capitalize font-semibold">
        //     {v.name}
        //   </div>
        //   <div className=" p-5">
        //     <div className=" first-letter:capitalize font-semibold mb-3">
        //       {v.question}
        //     </div>
        //     <div className="">
        //       {v.choices?.map((vc: any) => (
        //         <div
        //           key={vc.answer}
        //           className={`py-1 px-2 my-2 ${
        //             vc.answer ? "bg-[#D4F5C5]" : "bg-[#F5F5F5]"
        //           }`}
        //         >
        //           <Radio checked={vc.answer}>
        //             <div className=" first-letter:capitalize">{vc.name}</div>
        //           </Radio>
        //         </div>
        //       ))}
        //     </div>
        //   </div>
        // </div>
      ))}
    </div>
  )
}

const helperScreeningAnswer = (data: any) => {
  const answer = data?.find((item: any) => item.answer)
  return (
    <div>
      {answer ? (
        answer.disqualify ? (
          <div className=" flex items-center gap-3">
            <CloseCircleOutlined className=" text-danger" />
            <div className=" first-letter:capitalize">{answer?.name}</div>
          </div>
        ) : (
          <div className=" flex items-center gap-3">
            <CheckSquareOutlined className=" text-primary" />
            <div className=" first-letter:capitalize">{answer?.name}</div>
          </div>
        )
      ) : (
        <div className=" flex items-center gap-3">
          <WarningOutlined className=" text-primaryYellow" />
          <div className=" first-letter:capitalize">No answer</div>
        </div>
      )}
    </div>
  )
}
