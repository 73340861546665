import React, { useContext, useState } from "react"
import { Avatar, Tag, Image, Dropdown, Drawer, Button, Select } from "antd"
import {
  BellOutlined,
  SearchOutlined,
  CloseOutlined,
  DollarOutlined,
} from "@ant-design/icons"
import { useMatch, useNavigate } from "react-router-dom"
import { GreenSkillsLogo } from "../../assets/images"
import { avatarImgs } from "../../assets/avatar_images"
import { useActionUser } from "../hooks"
import ViewProfile from "./ViewProfile"
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react"
import { useAuth } from "../../modules/auth/hooks/useAuth"
import { CurrenciesContext, TenantContext } from "../../context/user"
import { useQueryClient } from "react-query"

interface Props {
  isOpenSidebar?: boolean
  isAlertShown?: boolean
  isSidebar?: boolean
}

export const CustomHeader: React.FC<Props> = ({
  isOpenSidebar,
  isAlertShown,
  isSidebar,
}) => {
  const action = useActionUser()
  const { accounts } = useMsal()
  const isAuthenticated = accounts.length > 0
  const { handleLogoutRedirect } = useAuth()
  const tenant = useContext(TenantContext)
  const isHowItWorks = useMatch("/")
  const isPricing = useMatch("/price")
  const isGreenTalent = useMatch("/green-talent")
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [currency, setCurrency] = useState(
    localStorage.getItem("currency_code"),
  )

  const currencies = useContext(CurrenciesContext)

  const items = [
    {
      key: "1",
      label: <div onClick={() => action.setIsOpen(true)}>My Profile</div>,
    },
    {
      key: "2",
      label: <div onClick={handleLogoutRedirect}>Logout</div>,
    },
  ]

  return (
    <>
      <div
        style={{
          width: isAuthenticated
            ? !isSidebar
              ? "100%"
              : isOpenSidebar
                ? "calc(100% - 15.7rem)"
                : "calc(100% - 4.2rem)"
            : "100%",
        }}
        className={`z-50 h-14 md:h-16 px-5 md:px-10 py-4 md-py-6 flex items-center justify-between bg-custom-white border-b shadow transition-all ease-in-out absolute ${
          isAlertShown ? "top-10" : "top-0"
        } ${
          isAuthenticated
            ? !isSidebar
              ? "ml-0"
              : isOpenSidebar
                ? "ml-[15.7rem]"
                : "ml-[4.2rem]"
            : ""
        }`}
      >
        <AuthenticatedTemplate>
          <div className="flex items-center justify-end w-full">
            <div className="flex items-center justify-end gap-x-5">
              <SearchOutlined className="hidden text-base" />
              <BellOutlined className="hidden text-base" />
              {currencies && (
                <div className="hide-select">
                  <Select
                    onSelect={(e) => {
                      localStorage.setItem("currency_code", e)
                      setCurrency(e)
                      queryClient.invalidateQueries(["plans"])
                      queryClient.invalidateQueries(["planlist"])
                    }}
                    className=" w-[6rem] rounded-md bg-none "
                    // style={{ border: "none" }}
                    popupClassName="m-0 p-0"
                    defaultValue={currency || "sgd"}
                    options={currencies?.map((item) => ({
                      label: (
                        <div className="flex gap-2 items-center">
                          {item?.logo ? (
                            <img
                              className=" w-4 h-4 rounded-full object-cover"
                              style={{ border: "0.5px solid #36505E" }}
                              src={item?.logo}
                            />
                          ) : (
                            <DollarOutlined className=" text-[1.5rem]" />
                          )}
                          <div className=" uppercase">{item.currency_code}</div>
                        </div>
                      ),
                      value: item.currency_code,
                    }))}
                  />
                </div>
              )}
              <p>{tenant?.name}</p>
              <Dropdown menu={{ items }} placement="bottomRight" arrow>
                {action.user?.avatar ? (
                  <img
                    src={`${action.user?.avatar}?${Date.now()}`}
                    alt="user"
                    className="h-[35px] w-[35px] rounded-full object-cover border border-primary cursor-pointer"
                  />
                ) : (
                  <Avatar
                    size={35}
                    src={avatarImgs[0]}
                    className="cursor-pointer "
                    style={{ border: "1px solid #D4F5C5" }}
                    // onClick={() => navigate("/account?account-setting")}
                  />
                )}
              </Dropdown>
            </div>

            <Drawer
              title="Edit my profile"
              placement="right"
              closable={false}
              onClose={() => action.setIsOpen(false)}
              open={action.isOpen}
              width={"736px"}
              extra={
                <CloseOutlined
                  className="cursor-pointer "
                  onClick={() => action.setIsOpen(false)}
                />
              }
            >
              <ViewProfile
                handlePassword={action.handlePassword}
                user={action.user}
                imageUrl={action.imageUrl}
                handleImageChange={action.handleImageChange}
                handleUpdate={action.update.handle}
                loading={action.update.loading}
                isOpenFormPassword={action.isOpenFormPassword}
                showModalPassword={action.showModalPassword}
                isConfirmLoading={action.isConfirmLoading}
                handleChangePassword={action.handleChangePassword}
                hideModalPassword={action.hideModalPassword}
              />
            </Drawer>
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div className="flex items-center justify-between w-full mt-3 md:mt-0 ">
            <div
              className="flex items-center gap-3 cursor-pointer "
              onClick={() => navigate("/")}
            >
              <Image
                src={GreenSkillsLogo}
                preview={false}
                height={39}
                width={112}
                className="object-contain"
              />

              <Tag className="bg-secondaryYellow text-brown border-brown border-[0.5px] mt-5">
                beta 0.1.0
              </Tag>
            </div>

            <div className="items-center hidden gap-10 md:flex">
              <div
                onClick={() => navigate("/")}
                className={
                  !!isHowItWorks
                    ? "hover:border-b cursor-pointer font-semibold"
                    : "hover:border-b cursor-pointer"
                }
              >
                How it works
              </div>
              <div
                onClick={() => navigate("/green-talent")}
                className={
                  !!isGreenTalent
                    ? "hover:border-b cursor-pointer font-semibold"
                    : "hover:border-b cursor-pointer"
                }
              >
                Green Talent
              </div>
              <div
                onClick={() => navigate("/price")}
                className={
                  !!isPricing
                    ? "hover:border-b cursor-pointer font-semibold"
                    : "hover:border-b cursor-pointer"
                }
              >
                Pricing
              </div>
            </div>

            <div className="flex items-end justify-end h-full mt-1 gap-x-5 md:mt-0">
              <div className="flex items-center gap-10">
                <div className="items-center hidden gap-2 md:flex">
                  <Tag
                    className={
                      "rounded-full font-semibold bg-backdrop text-white"
                    }
                  >
                    Employer
                  </Tag>
                  <a
                    href={process.env.REACT_APP_TALENT_SPA_URL}
                    onClick={() =>
                      (window.location.href =
                        process.env.REACT_APP_TALENT_SPA_URL ?? "")
                    }
                  >
                    <Tag
                      className={
                        "cursor-pointer rounded-full font-semibold bg-white"
                      }
                      onClick={() =>
                        (window.location.href =
                          process.env.REACT_APP_TALENT_SPA_URL ?? "")
                      }
                    >
                      Talent
                    </Tag>
                  </a>
                </div>
                {currencies && (
                  <div className="hide-select">
                    <Select
                      onSelect={(e) => {
                        localStorage.setItem("currency_code", e)
                        setCurrency(e)
                        queryClient.invalidateQueries(["plans"])
                        queryClient.invalidateQueries(["planlist"])
                      }}
                      className=" w-[6rem] rounded-md bg-none "
                      // style={{ border: "none" }}
                      popupClassName="m-0 p-0"
                      defaultValue={currency || "sgd"}
                      options={currencies?.map((item) => ({
                        label: (
                          <div className="flex gap-2 items-center">
                            {item?.logo ? (
                              <img
                                className=" w-4 h-4 rounded-full object-cover"
                                style={{ border: "0.5px solid #36505E" }}
                                src={item?.logo}
                              />
                            ) : (
                              <DollarOutlined className=" text-[1.5rem]" />
                            )}
                            <div className=" uppercase">
                              {item.currency_code}
                            </div>
                          </div>
                        ),
                        value: item.currency_code,
                      }))}
                    />
                  </div>
                )}
                <Button type="primary" onClick={() => navigate("/login")}>
                  Login
                </Button>
              </div>
            </div>
            <Drawer
              title="Edit my profile"
              placement="right"
              closable={false}
              onClose={() => action.setIsOpen(false)}
              open={action.isOpen}
              width={"736px"}
              extra={
                <CloseOutlined
                  className="cursor-pointer "
                  onClick={() => action.setIsOpen(false)}
                />
              }
            >
              <ViewProfile
                handlePassword={action.handlePassword}
                user={action.user}
                imageUrl={action.imageUrl}
                handleImageChange={action.handleImageChange}
                handleUpdate={action.update.handle}
                loading={action.update.loading}
                isOpenFormPassword={action.isOpenFormPassword}
                showModalPassword={action.showModalPassword}
                isConfirmLoading={action.isConfirmLoading}
                handleChangePassword={action.handleChangePassword}
                hideModalPassword={action.hideModalPassword}
              />
            </Drawer>
          </div>
        </UnauthenticatedTemplate>
      </div>
    </>
  )
}
