import React from "react"

interface TLDetailResponsibleProps {
  templateDetailResponse: any
}

const TLDetailResponsible: React.FC<TLDetailResponsibleProps> = ({
  templateDetailResponse,
}) => {
  return (
    <div className="px-6 py-2 bg-[#FAFAFA] shadow-custom rounded-xl">
      <h3>Responsibilities</h3>

      <div
        dangerouslySetInnerHTML={{
          __html: templateDetailResponse?.data?.job_detail,
        }}
      ></div>
    </div>
  )
}

export default TLDetailResponsible
