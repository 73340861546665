import React from "react"
import { Result, Spin } from "antd"
import { HomeOutlined, LoadingOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../common/layout"
import { Description } from "../components/jobdetail/Description"
import { GreenSkills } from "../components/jobdetail/GreenSkills"
import { TechnicalSkills } from "../components/jobdetail/TechnicalSkills"
import { Languages } from "../components/jobdetail/Languages"
import { SoftSkills } from "../components/jobdetail/SoftSkills"
import { InfoJob } from "../components/jobdetail/InfoJob"
import { ViewApplicant } from "../components/jobdetail/ViewApplicant"
import { Company } from "../components/jobdetail/Company"
import { InfoJobDraft } from "../components/jobdetail/InfoJobDraft"
import { useDetail } from "../hooks/jobdetail/useDetail"

interface Props {
  status?: string[]
}

export const JobDetail: React.FC<Props> = ({ status }) => {
  const jobDetail = useDetail()

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/jobs/my-job-posts",
      title: "My Job Posts",
    },
    {
      title: (
        <div>
          {jobDetail.isLoading ? <LoadingOutlined /> : jobDetail.data?.name}
        </div>
      ),
    },
  ]

  return (
    <PageContent
      header={
        <PageHeader
          title={
            jobDetail.isLoading ? (
              <LoadingOutlined />
            ) : (
              jobDetail.data?.name || "-"
            )
          }
          breadcrumbItems={items}
        ></PageHeader>
      }
      content={
        jobDetail.isLoading ? (
          <div className="absolute left-[50%] top-1/2 ">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : !jobDetail.data ? (
          <Result status="500" subTitle="Sorry, data job not found." />
        ) : // prevent user to access job detail that not belong to on their status bas on path we used!
        !status?.includes(jobDetail?.data?.status) ? (
          <Result
            status="500"
            subTitle={`Sorry, this job on status ${jobDetail?.data?.status}`}
          />
        ) : (
          <div className=" flex gap-5">
            <div className="w-[calc(100%-20rem-1.25rem)]">
              <Description data={jobDetail?.data?.description} />
              <GreenSkills data={jobDetail?.data?.green_skills} />
              <div className="mb-5 grid grid-cols-2 gap-5">
                <TechnicalSkills data={jobDetail?.data?.technical_skills} />
                <Languages data={jobDetail?.data?.languages} />
              </div>
              <SoftSkills data={jobDetail?.data?.critical_skills} />
            </div>
            <div className="w-80">
              {status?.includes("DRAFT") ? (
                <>
                  <InfoJobDraft data={jobDetail?.data} />
                  <Company />
                </>
              ) : (
                <>
                  <ViewApplicant
                    update={jobDetail?.update}
                    id={jobDetail?.data?.id}
                    publisedAt={jobDetail?.data?.published_at}
                    isTemplate={jobDetail?.data?.is_template}
                    isClosed={jobDetail?.data?.status === "CLOSED"}
                  />
                  <InfoJob data={jobDetail?.data} />
                </>
              )}
            </div>
          </div>
        )
      }
    />
  )
}
