import React, { useContext } from "react"
import {
  EnvironmentOutlined,
  UserOutlined,
  LinkOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  FacebookOutlined,
  InstagramOutlined,
  GithubOutlined,
} from "@ant-design/icons"
import { Link } from "react-router-dom"
import { TenantContext } from "../../../../context/user"
import { ensureHttps } from "../../../../helper/ensureHtpps"
import { message } from "antd"

export const Company = () => {
  const tenant = useContext(TenantContext)
  return (
    <div className=" p-5 rounded-xl shadow-md bg-[#fafafa] w-full">
      <img
        className="h-[52px] object-contain"
        src={tenant?.logo}
        alt="company"
      />

      <div className="flex gap-2 items-baseline my-3 text-darkGrey">
        <EnvironmentOutlined />
        <div>{tenant?.headquarter?.name || "unknown"}</div>
      </div>
      <div className="flex gap-2 items-baseline text-darkGrey">
        <UserOutlined />
        <div>{helperSize(tenant?.size?.name || "")}</div>
      </div>
      <div className="my-3">{tenant?.description}</div>
      <div className="flex gap-3">
        {tenant?.website_url ? (
          <Link to={ensureHttps(tenant?.website_url)} target="_blank">
            <LinkOutlined />
          </Link>
        ) : (
          <LinkOutlined
            onClick={() => message.error("Website URL not available")}
          />
        )}

        {tenant?.linkedin_url ? (
          <Link to={ensureHttps(tenant?.linkedin_url)} target="_blank">
            <LinkedinOutlined />
          </Link>
        ) : (
          <LinkedinOutlined
            onClick={() => message.error("LinkedIn URL not available")}
          />
        )}

        {tenant?.twitter_url ? (
          <Link to={ensureHttps(tenant?.twitter_url)} target="_blank">
            <TwitterOutlined />
          </Link>
        ) : (
          <TwitterOutlined
            onClick={() => message.error("Twitter URL not available")}
          />
        )}

        {tenant?.instagram_url ? (
          <Link to={ensureHttps(tenant?.instagram_url)} target="_blank">
            <InstagramOutlined />
          </Link>
        ) : (
          <InstagramOutlined
            onClick={() => message.error("Instagram URL not available")}
          />
        )}

        {tenant?.facebook_url ? (
          <Link to={ensureHttps(tenant?.facebook_url)} target="_blank">
            <FacebookOutlined />
          </Link>
        ) : (
          <FacebookOutlined
            onClick={() => message.error("Facebook URL not available")}
          />
        )}

        {tenant?.github_url ? (
          <Link to={ensureHttps(tenant?.github_url)} target="_blank">
            <GithubOutlined />
          </Link>
        ) : (
          <GithubOutlined
            onClick={() => message.error("GitHub URL not available")}
          />
        )}
      </div>
    </div>
  )
}

const helperSize = (size: string): string => {
  if (size.includes("employees")) {
    return size.replace(" employees", "")
  } else {
    return "1"
  }
}
