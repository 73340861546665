import {
  Modal,
  Input,
  type ModalProps,
  Select,
  Switch,
  InputNumber,
} from "antd"
import { useForm, Controller } from "react-hook-form"
import {
  usePostJobAsDraft,
  type AddJobPostAsDraftType,
  ActiveJob,
} from "../hooks/useJobPosts"
import {
  useCurrenciesFrequencies,
  useCurrenciesInfinity,
  useJobTypes,
} from "../../../common/hooks/useReferences"
import { InfiniteAutoComplete } from "../../../common/components"
import { useCountriesInfinity } from "../../../common/hooks"
import { useMyJobTemplatesInfinite } from "../hooks/useTemplates"
import { useNavigate } from "react-router-dom"
import { ApiResponseV2 } from "../../../common/types/ApiResponse"
import { AxiosResponse } from "axios"
import { useQueryClient } from "react-query"
interface NewJobPostModalProps extends ModalProps {
  type?: "new" | "template"
  from?: string
  templateData?: any
}

export function NewJobPostModal({
  type = "new",
  from,
  templateData,
  ...inputProps
}: NewJobPostModalProps) {
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<AddJobPostAsDraftType>({
    defaultValues: {
      title: from === "template-page" ? templateData?.name : "",
      job_role:
        from === "template-page"
          ? { id: templateData?.id ?? "", name: templateData?.name ?? "" }
          : undefined,
    },
  })

  const navigate = useNavigate()
  const createJobDraftMutation = usePostJobAsDraft()
  const jobTypes = useJobTypes()
  const currencyFrequencies = useCurrenciesFrequencies()
  const queryClient = useQueryClient()

  const onSubmit = async (data: AddJobPostAsDraftType) => {
    try {
      const errors = await trigger()
      if (Object.keys(errors).length > 0) {
        return
      }
      const record: AxiosResponse<ApiResponseV2<ActiveJob>> =
        await createJobDraftMutation.mutateAsync({
          ...data,
          salary_from: Number(data.salary_from || 0),
          salary_up_to: Number(data.salary_up_to || 0),
        })
      const dummyEvent = {} as any
      navigate(`/jobs/my-job-posts/create/${record?.data?.data?.id}`)
      inputProps.onCancel?.(dummyEvent)
      queryClient.invalidateQueries(["job-list"])
    } catch (error: any) {
      console.error("An error occurred:", error?.response?.data?.data)
    }
  }

  return (
    <Modal
      {...inputProps}
      title={
        <h2 className="text-backdrop font-semibold -mt-1">
          New Job Post {type === "template" && "from Template"}
        </h2>
      }
      closable={false}
      className="rounded-modal"
      onOk={handleSubmit(onSubmit)}
      okButtonProps={{
        className: "bg-primary text-white",
        loading: createJobDraftMutation.isLoading,
      }}
      centered
    >
      <div className="flex flex-col gap-4 w-full">
        {type === "template" && (
          <div className="flex flex-col gap-2">
            <Controller
              name="job_role"
              control={control}
              render={({ field }) => {
                return (
                  <InfiniteAutoComplete
                    {...field}
                    className="!w-full"
                    placeholder={"Job Template"}
                    useDataHook={useMyJobTemplatesInfinite}
                    selectedValue={field.value}
                    disabled={from === "template-page"}
                    onSelect={(value, option) => {
                      field.onChange(option)
                      setValue("title", option?.name ?? "")
                    }}
                  />
                )
              }}
            />
          </div>
        )}
        <div className="flex flex-col gap-2">
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Job Title"
                className=""
                value={field.value}
              />
            )}
          />
          {errors.title && (
            <span className="text-red-500">Job Display Name is required</span>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <Controller
            name="job_type"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                loading={jobTypes?.isLoading}
                onChange={(option: any, value: any) => field.onChange(value)}
                value={field?.value?.id}
                className="w-full rounded-md"
                fieldNames={{ label: "name", value: "id" }}
                options={
                  Array.isArray(jobTypes?.data?.data?.data)
                    ? jobTypes?.data?.data?.data
                    : []
                }
                placeholder="Job Type"
              ></Select>
            )}
          />
          {errors?.job_type && (
            <div className="text-danger">Job Type is required</div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <Controller
            name="location"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <InfiniteAutoComplete
                {...field}
                className="!w-full"
                placeholder="Location"
                useDataHook={useCountriesInfinity}
                selectedValue={field.value}
                onSelect={(value, option) => {
                  field.onChange(option)
                }}
              />
            )}
          />
          {errors?.location && (
            <div className="text-danger">Location is required</div>
          )}
        </div>
        <div className="flex gap-x-4 items-center">
          <div className="text-sm">Remote</div>
          <Controller
            name="remote"
            control={control}
            render={({ field }) => <Switch checked={field.value} {...field} />}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Controller
            name="team"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Job Team/Function" />
            )}
          />
          {errors?.team && (
            <span className="text-red-500">Job Team is required</span>
          )}
        </div>

        <p className="m-0 text-base ">Gross Salary (optional)</p>

        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-6 flex flex-col gap-1">
            <p className="m-0">Salary from</p>
            <Controller
              name="salary_from"
              control={control}
              // rules={{
              //   pattern: {
              //     value: /^[0-9]*$/,
              //     message: "Salary must be a number",
              //   },
              // }}
              render={({ field }) => (
                <InputNumber
                  className="w-full"
                  placeholder="0"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                  onFocus={(e) => e.target.select()}
                  controls={false}
                  {...field}
                />
              )}
            />
          </div>
          <div className="col-span-6 flex flex-col gap-1">
            <p className="m-0">Salary Up to</p>
            <Controller
              name="salary_up_to"
              control={control}
              // should be higher than salary from
              rules={{
                validate: (value) => {
                  const salaryFrom = getValues("salary_from")
                  if (
                    salaryFrom !== undefined &&
                    value !== undefined &&
                    Number(value) <= Number(salaryFrom)
                  ) {
                    return "Salary Up to must be higher than Salary from"
                  }
                  return true
                },
                required: !!getValues("salary_from"),

                // pattern: {
                //   value: /^[0-9]*$/,
                //   message: "Salary must be a number",
                // },
              }}
              render={({ field }) => (
                <InputNumber
                  className="w-full"
                  placeholder="0"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                  onFocus={(e) => e.target.select()}
                  controls={false}
                  {...field}
                />
              )}
            />
            {errors?.salary_up_to && (
              <div className="text-danger">
                {errors?.salary_up_to?.message?.toString() ||
                  "Salary Up Too is required"}
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-6 flex flex-col ">
            <p className="m-0">Salary Currency</p>
            <Controller
              name="salary_currency"
              control={control}
              render={({ field }) => (
                <InfiniteAutoComplete
                  {...field}
                  fieldNames={{ label: "code", value: "id" }}
                  useDataHook={useCurrenciesInfinity}
                  placeholder="Search currencies"
                  className="!w-full"
                  selectedValue={field?.value}
                  onSelect={(value, option) => {
                    field.onChange(option)
                  }}
                />
              )}
            />
          </div>
          <div className="col-span-6 flex flex-col ">
            <p className="m-0">Frequency</p>
            <Controller
              name="salary_frequency"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  onChange={(option: any, value: any) => field.onChange(value)}
                  value={field?.value?.id}
                  loading={currencyFrequencies?.isLoading}
                  className="w-full "
                  fieldNames={{ label: "name", value: "id" }}
                  options={
                    Array.isArray(currencyFrequencies?.data?.data?.data)
                      ? currencyFrequencies?.data?.data?.data
                          .filter((item) => item.name !== "custom")
                          .map((item) => {
                            let name = item.name.replace(/-/g, " ")
                            name = name
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1),
                              )
                              .join(" ")

                            return { ...item, name }
                          })
                      : []
                  }
                  placeholder="Salary Frequency"
                ></Select>
              )}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
