import React from "react"
import Cookie from "../../assets/images/month.svg"

interface EmptyDataProps {
  description?: string
}

export const EmptyData: React.FC<EmptyDataProps> = ({ description }) => {
  return (
    <div className="flex gap-3 items-center">
      <img src={Cookie} alt="cookies" />
      <div>{description || "Nothing to display"}</div>
    </div>
  )
}
