import { type AxiosInstance } from "axios"
import { type ApiResponseV2 } from "../../../common/types/ApiResponse"
import { useAxiosWithMsal } from "../../../common/hooks"
import { message } from "antd"
import { useQuery } from "react-query"
import { type AxiosError } from "../../../common/errors"
import { useLocation } from "react-router-dom"

export const useConnectAvailable = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const { pathname } = useLocation()

  const getConnectAvailable = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<ApiResponseV2>("/talent-finders/connect-available")
    } catch (error) {
      message.error("An error occurred while fetching plan connect available ")
    }
  }
  const { data, isLoading } = useQuery(["plan-connect"], getConnectAvailable, {
    onError: (error: AxiosError) => {
      message.error(
        "An error occurred while fetching plan connect available: " +
          error.message,
      )
    },
    enabled: pathname === "/talent/database" || pathname === "/home/dashboard",
  })
  return {
    data: data?.data?.data,
    isLoading,
  }
}
