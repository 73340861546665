import { useState } from "react"
import { AxiosInstance } from "axios"
import { useMsal } from "@azure/msal-react"
import { SilentRequest } from "@azure/msal-browser"
import { scopes as defaultScopes } from "../../authConfig"
import { axiosInstance } from "../../config/axios"
import { msalInstance } from "../../msal-instance"

const defaultMsalRequest: SilentRequest = { redirectUri: "", scopes: [] }

export const useAxiosWithMsal = (
  msalRequest: SilentRequest = defaultMsalRequest,
): (() => Promise<AxiosInstance>) => {
  const { instance } = useMsal()
  const account = instance.getAllAccounts()?.[0] ?? undefined
  const [tokenData, setTokenData] = useState<{
    token?: string
    expiresOn: number
  } | null>({
    token: account?.idToken,
    expiresOn: account?.idTokenClaims?.exp || 0,
  })

  const getAxiosInstance = async () => {
    let token = tokenData?.token
    if (
      !tokenData?.token ||
      Math.floor(new Date().getTime() / 1000) > tokenData?.expiresOn
    ) {
      try {
        const result: any = await instance.acquireTokenSilent({
          ...msalRequest,
          scopes: [...defaultScopes.read, ...defaultScopes.write],
          account,
        })
        token = result.idToken as string
        setTokenData({
          token,
          expiresOn: result?.idTokenClaims?.exp,
        })
      } catch (error) {
        console.error(error)
        msalInstance.logoutRedirect()
      }
    }

    // const api = axios.create({
    //   baseURL: process.env.REACT_APP_API + "/api/v1",
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });

    // return api;
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
    return axiosInstance
  }

  return getAxiosInstance
}
