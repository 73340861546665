import { useEffect, useState } from "react"
import { useAxiosWithMsal } from "../../../common/hooks"
import { useMutation } from "react-query"
import { message } from "antd"
import { DetailApplicant } from "../inteface/detailApplicant"
import { useUpdateApplicantData } from "./useCandidates"

export const useDetailTalent = (): DetailApplicant => {
  const getAxiosInstance = useAxiosWithMsal()
  // <--- start of state --->
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [ishandleDisqualify, setIsHandleDisqualify] = useState<boolean>(false)
  const handleClose = () => {
    setIsOpen(false)
  }

  const getUserDetail = async (id: string) => {
    const api = await getAxiosInstance()
    return api.get("/applicants/" + id)
  }

  const {
    mutate: actionGetDetail,
    isLoading: loadingDetail,
    data: detail,
  } = useMutation({
    mutationFn: (id: string) => {
      return getUserDetail(id)
    },
    mutationKey: "applicant-detail",
  })
  const handleGetDetail = (id: any) => {
    actionGetDetail(id, {
      onError: (err: any) => {
        message.error(err?.response?.data?.data)
      },
    })
  }
  const handleOpen = (id: string) => {
    handleGetDetail(id)
    setIsOpen(true)
    setIsHandleDisqualify(false)
  }
  // <--- end of state --->

  // <--- start of fetch detail --->

  // <--- end of fetch detail --->

  // <--- start disqualify candidate --->
  const { mutateAsync: updateApplicant } = useUpdateApplicantData()
  const handleStage = (
    userId: string,
    stageId: string,
    callback?: () => void,
  ) =>
    updateApplicant({ id: userId, data: { stage_id: stageId } }).then(
      () => callback && callback(),
    )

  useEffect(() => {
    if (detail?.data?.data) {
      if (detail?.data?.data?.stage?.name === "disqualified") {
        setIsHandleDisqualify(true)
      }
    }
  }, [detail?.data?.data])

  return {
    state: {
      open: isOpen,
      closeAction: handleClose,
      openAction: handleOpen,
      changeStage: handleStage,
      ishandleDisqualify,
      setIsHandleDisqualify,
    },
    detail: {
      user: detail?.data?.data,
      loading: loadingDetail,
    },
    getDetail: handleGetDetail,
  }
}
