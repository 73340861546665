import React from "react"
import TLTechnicalSkillProficiency from "./TLTechnicalSkillProficiency"
import { Skeleton } from "antd"

interface TLTechnicalSkillsProps {
  otherSkills: any
}

const TLTechnicalSkills: React.FC<TLTechnicalSkillsProps> = ({
  otherSkills,
}) => {
  return (
    <div className="px-6 py-2 bg-[#FAFAFA] shadow-custom rounded-xl">
      <h3>Technical Skills</h3>

      <div className="flex items-center flex-wrap gap-3">
        {otherSkills?.isLoading ? (
          <Skeleton active />
        ) : (
          otherSkills?.data?.other_skills?.map((item: any, index: number) => {
            return <TLTechnicalSkillProficiency key={index} item={item} />
          })
        )}
      </div>
    </div>
  )
}

export default TLTechnicalSkills
