import React from "react"
import {
  FiveCircleRate,
  InfiniteAutoComplete,
} from "../../../../../common/components"
import {
  Item,
  useGreenSkillsInfinity,
} from "../../../../../common/hooks/useReferences"
import { Table, Tooltip } from "antd"
import { useFieldArray, useFormContext } from "react-hook-form"
import { CreateJobSchema } from "../types"
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons"

export const GreenSkill = () => {
  const {
    control,
    // formState: { errors },
  } = useFormContext<CreateJobSchema>()

  const {
    fields: greenSkillsFields,
    append: appendGreenSkill,
    remove: removeGreenSkill,
    update: updateGreenSkill,
  } = useFieldArray({
    control,
    name: "green_skills",
    keyName: "id_",
  })

  const handleUpdateProficiency = (
    id: string,
    proficiency: number,
    index: number,
  ) => {
    const greenSkillItem = greenSkillsFields[index]
    updateGreenSkill(index, { ...greenSkillItem, proficiency })
  }
  const handleSelectGreenSkills = (
    value: string,
    option: Item | undefined | null,
  ) => {
    if (option && option.id) {
      // ensure that 'option' and 'option.id' are defined
      const isAlreadySelected = greenSkillsFields.some((item) => {
        return item.id === option.id
      })
      if (!isAlreadySelected) {
        appendGreenSkill({ ...option, proficiency: 3 })
      }
    }
  }
  const skillsTableColumns = [
    {
      title: "Skill Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: (
        <div className="">
          Proficiency{" "}
          <Tooltip title="Update proficiency to required level by selecting a level">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: "proficiency",
      key: "proficiency",
      render: (proficiency: number, record: Item, index: number) => (
        <FiveCircleRate
          disabled={false}
          onChange={(value: number) =>
            handleUpdateProficiency(record?.id, value, index)
          }
          defaultValue={proficiency}
          count={5}
          circleSize={15}
        />
      ),
    },
    {
      title: "",
      key: "action",
      render: (_: any, record: Item, index: number) => (
        <>
          <DeleteOutlined
            onClick={() => {
              removeGreenSkill(index)
            }}
          />
          {/* <DeleteOutlined onClick={() => removeGreenSkill(record?.id)} /> */}
        </>
      ),
      width: 100,
    },
  ]
  return (
    <div className="basic-card bg-custom-white shadow-custom-lg">
      <h3 className="font-semibold text-lg">Green Skills</h3>

      {/* {errors?.green_skills?.message && (
        <div className="text-danger -mt-3">{errors?.green_skills?.message}</div>
      )} */}

      <InfiniteAutoComplete
        useDataHook={useGreenSkillsInfinity}
        onSelect={handleSelectGreenSkills}
        className="w-full"
        placeholder="Search Green Skills"
      />
      <div className="">
        <Table
          columns={skillsTableColumns}
          className="mt-6"
          pagination={false}
          //  @ts-ignore
          dataSource={greenSkillsFields}
        />
      </div>
    </div>
  )
}
