import React from "react"
import {
  SolutionOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
} from "@ant-design/icons"
import dayjs from "dayjs"
import { EmptyData } from "../../../../common/components/EmptyData"
import otherCompany from "../../../../assets/images/other_company.png"

interface Props {
  data?: {
    id: string
    company: {
      name: string
      id: string
      image: string
    }
    employment_type: {
      name: string
      id: string
    }
    country: {
      name: string
      id: string
    }
    current_role: boolean
    start_at: string
    end_at: string
    remote: boolean
    job_title: string
    description: string
  }[]
}

export const WorkExperience: React.FC<Props> = ({ data }) => {
  return (
    <div>
      {data ? (
        data.map((item, i) => (
          <div
            key={i}
            className="shadow-md bg-[#FAFAFA] rounded-xl mb-5 relative p-5"
          >
            <div className="flex gap-5 ">
              <img
                src={item?.company?.image || otherCompany}
                alt="company"
                className="w-20 h-20 p-2 rounded-full shadow object-contain"
              />
              <div>
                <h3 className=" capitalize">{item.company.name}</h3>
                <div className="flex gap-4 items-center my-1">
                  <div className="">
                    <SolutionOutlined />
                    <span className="ml-2">{item.employment_type.name}</span>
                  </div>
                  <div className="">
                    <EnvironmentOutlined />
                    <span className="ml-2">{item.country.name}</span>
                  </div>
                  <small className=" text-slate-400">
                    {item.remote ? "remote" : "onsite"}
                  </small>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="">
                    <CalendarOutlined />
                    <span className="ml-2">{`${dayjs(item.start_at).format(
                      "MMM YYYY",
                    )} - ${
                      item.end_at
                        ? dayjs(item.end_at).format("MMM YYYY")
                        : "Present"
                    }`}</span>
                  </div>
                  <small className=" text-slate-400">
                    {" "}
                    {getPeriode(
                      dayjs(item?.end_at ? item.end_at : Date.now())
                        .startOf("month")
                        .diff(dayjs(item.start_at).startOf("month"), "month"),
                    )}
                  </small>
                </div>
              </div>
            </div>
            <h3 className=" capitalize my-3">{item.job_title}</h3>
            {item.description && item.description !== "<p><br></p>" && (
              <div
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              ></div>
            )}
          </div>
        ))
      ) : (
        <div className="shadow-md bg-[#FAFAFA] rounded-xl mb-5 relative p-5">
          <EmptyData />
        </div>
      )}
    </div>
  )
}

const getPeriode = (val: any) => {
  if (val === 0) return "1 month"
  const year = val !== 0 ? Math.floor(val / 12) : 0

  const month = val % 12

  return `${year > 0 ? year + ` ${year > 1 ? "years" : "year"}` : ""} ${
    month > 0 ? month + ` ${month > 1 ? "months" : "month"}` : ""
  }`
}
