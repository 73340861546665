import { useAxiosWithMsal } from "../../../common/hooks"
import { useMutation } from "react-query"

export const usePromotionCode = () => {
  const getAxiosInstance = useAxiosWithMsal()

  const getPromotionCode = async (data: string) => {
    try {
      const api = await getAxiosInstance()
      return await api.get("/subscriptions/coupons/" + data)
    } catch (error: any) {
      throw error
    }
  }

  return {
    getPromotionCode: useMutation(getPromotionCode),
  }
}
