import React from "react"
import { Radar } from "@ant-design/plots"

interface ChartProps {
  data: any // Replace 'any' with the actual type of your data
}

export const RadarSoftSkill: React.FC<ChartProps> = ({ data }) => {
  const config = {
    data: data || [],
    xField: "name",
    yField: "proficiency",
    xAxis: {
      tickLine: null,
    },
    yAxis: {
      label: false,
      grid: {
        alternateColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    meta: {
      proficiency: {
        min: 0,
      },
    },
    radius: 0.6,
    point: {
      size: 1,
    },
  }

  // @ts-ignore
  return <Radar {...config} />
}
