import React, { useContext } from "react"
import {
  FacebookOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  GithubOutlined,
  InstagramOutlined,
  LinkOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import { Controller } from "react-hook-form"
import { Input, Select, Upload, Button, Image } from "antd"
import { useCompanyProfileEdit } from "../hooks/useCompanyProfileEdit"
import {
  // CustomInputDropdownNew,
  InfiniteAutoComplete,
} from "../../../common/components"
import { BasicContainerWithTitle } from "../../../common/components/BasicBoxContainers/BasicContainerWithTitle"
import {
  useCountriesInfinity,
  useListCompanySectorsInfinity,
} from "../../../common/hooks"
import { DeleteOutlined, EyeOutlined, UploadOutlined } from "@ant-design/icons"
import { TenantContext } from "../../../context/user"

export function CompanyProfileEdit() {
  const {
    imageUrl,
    handleSubmit,
    control,
    errors,
    handleUpload,
    onSubmit,
    handleChange,
    beforeUpload,
    companySizes,
    isDirty,
    deleteImage,
  } = useCompanyProfileEdit("edit")
  const [showImageHover, setShowImageHover] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const tenants = useContext(TenantContext)

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BasicContainerWithTitle
          titleSize={"h3"}
          type="small"
          className=" bg-custom-white"
          title="Branding"
        >
          <div className="flex gap-x-8 mt-4">
            <div className="w-[134px] h-[134px] ">
              <Upload
                name="avatar"
                listType="picture-card"
                className=" custom-picture-upload"
                showUploadList={false}
                customRequest={handleUpload}
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {tenants?.logo || imageUrl ? (
                  <div
                    className="relative h-full w-full"
                    onMouseEnter={() => setShowImageHover(true)}
                    onMouseLeave={() => setShowImageHover(false)}
                  >
                    <img
                      src={tenants?.logo ?? imageUrl}
                      alt="avatar"
                      className="object-contain absolute top-0 left-0 w-full h-full"
                    />
                    {showImageHover && (
                      <div className="absolute top-0 left-0 w-full h-full z-10 bg-black opacity-50 inset-0 flex items-center justify-center gap-2">
                        <UploadOutlined className="text-white text-2xl z-50" />
                        <EyeOutlined
                          className="text-white text-2xl z-50"
                          onClick={(e) => {
                            e.stopPropagation()
                            setVisible(true)
                          }}
                        />
                        <DeleteOutlined
                          className="text-white text-2xl z-50"
                          onClick={async (e) => {
                            e.stopPropagation()
                            await deleteImage()
                          }}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  uploadButton
                )}
              </Upload>
            </div>
            <div className="flex-grow h-full">
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input.TextArea
                    rows={6}
                    {...field}
                    placeholder="Write a short company description, to build a stronger Employer Branding"
                    className=" w-full h-full !resize-none rounded-md"
                  />
                )}
              />
              {errors.description && (
                <span className="text-red-500 text-sm">
                  Company Description is required
                </span>
              )}
            </div>
          </div>
        </BasicContainerWithTitle>
        <div className="w-full flex gap-5 mt-10 flex-wrap sm:flex-nowrap items-start">
          <BasicContainerWithTitle
            titleSize={"h3"}
            type="small"
            title="Company Information"
            className="bg-custom-white md:w-[60%] sm:w-[100%]  md:min-w-[400px]"
          >
            <div className="flex flex-col mt-4">
              <div className="">
                <div className="text-backdrop">Company Name</div>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Company Name"
                      className="mt-1"
                    />
                  )}
                />
                {errors.name && (
                  <span className="text-red-500 text-sm">
                    Company Name is required
                  </span>
                )}
              </div>
              <div className="mt-5">
                <div className="text-backdrop">Headquarters</div>
                <Controller
                  name="headquarter"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <InfiniteAutoComplete
                        {...field}
                        className="!w-full"
                        placeholder="Search Country"
                        useDataHook={useCountriesInfinity}
                        // @ts-ignore
                        selectedValue={field.value}
                        onSelect={(value, option) => {
                          field.onChange(option)
                        }}
                      />
                    )
                  }}
                />
                {errors.headquarter && (
                  <span className="text-red-500 text-sm">
                    Company HQ Country is required
                  </span>
                )}
              </div>
              <div className=" mt-5">
                <div className="text-backdrop">Sector</div>
                <Controller
                  name="sector"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <InfiniteAutoComplete
                      {...field}
                      className="!w-full"
                      placeholder="Search Sector"
                      useDataHook={useListCompanySectorsInfinity}
                      // @ts-ignore
                      selectedValue={field.value}
                      onSelect={(value, option) => {
                        field.onChange(option)
                      }}
                    />
                  )}
                />
                {errors.sector && (
                  <span className="text-red-500 text-sm">
                    Sector is required
                  </span>
                )}
              </div>
              <div className=" mt-5">
                <div className="text-backdrop">Company Size (Employees)</div>
                <Controller
                  name="size"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      loading={companySizes.isLoading}
                      className="w-full mt-1"
                      fieldNames={{ label: "name", value: "id" }}
                      options={
                        Array.isArray(companySizes?.data?.data?.data)
                          ? companySizes?.data?.data?.data
                          : []
                      }
                      placeholder="Company Size"
                    ></Select>
                  )}
                />
                {errors.size && (
                  <span className="text-red-500 text-sm">
                    Company Size is required (1-100)
                  </span>
                )}
              </div>
            </div>
          </BasicContainerWithTitle>

          <BasicContainerWithTitle
            titleSize={"h3"}
            type="small"
            title="Social Media (optional)"
            className="bg-custom-white md:w-[40%] sm:w-[100%]  md:min-w-[400px]"
          >
            <div className=" flex flex-col justify-start mt-4">
              <Controller
                name="website_url"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    prefix={<LinkOutlined className="mr-2" />}
                    className=" font-default rounded-none"
                    placeholder="company website"
                  />
                )}
              />
              <Controller
                name="linkedin_url"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    prefix={<LinkedinOutlined className="mr-2" />}
                    className="mt-5 font-default rounded-none"
                    placeholder="LinkedIn"
                  />
                )}
              />
              <Controller
                name="twitter_url"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    prefix={<TwitterOutlined className="mr-2" />}
                    className="mt-5 font-default rounded-none"
                    placeholder="Twitter"
                  />
                )}
              />
              <Controller
                name="facebook_url"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    status={errors.facebook_url ? "error" : ""}
                    prefix={<FacebookOutlined className="mr-2" />}
                    className="mt-5 font-default rounded-none"
                    placeholder="Facebook"
                  />
                )}
              />
              <Controller
                name="instagram_url"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    status={errors.instagram_url ? "error" : ""}
                    prefix={<InstagramOutlined className="mr-2" />}
                    className="mt-5 font-default rounded-none"
                    placeholder="Instagram"
                  />
                )}
              />
              <Controller
                name="github_url"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    status={errors.github_url ? "error" : ""}
                    prefix={<GithubOutlined className="mr-2" />}
                    className="mt-5 font-default rounded-none"
                    placeholder="GitHub"
                  />
                )}
              />
            </div>
          </BasicContainerWithTitle>
        </div>
        <div className="mt-14 flex w-full justify-end">
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty}
            className="bg-primary text-white "
          >
            Save
          </Button>
        </div>
      </form>
      <Image
        width={200}
        style={{ display: "none" }}
        src={tenants?.logo || imageUrl}
        preview={{
          visible,
          scaleStep: 0.5,
          src: tenants?.logo || imageUrl,
          onVisibleChange: (value) => {
            setVisible(value)
          },
        }}
      />
    </>
  )
}

const uploadButton = (
  <div>
    {false ? <LoadingOutlined /> : <PlusOutlined />}
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
)
