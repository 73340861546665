import React, { useMemo } from "react"
import { WordCloud } from "@ant-design/plots"

interface ChartProps {
  data: any // Replace 'any' with the actual type of your data
}

export const WordHardkill: React.FC<ChartProps> = ({ data }) => {
  const config = useMemo(() => {
    return {
      width: 350,
      height: 250,
      data: data || [],
      wordField: "name",
      weightField: "value",
      colorField: "name",
      wordStyle: {
        fontFamily: "Sen",
        fontSize: [4, 24],
        rotation: 0,
      },
      random: () => 0.5,
    }
  }, [])

  // @ts-ignore
  return <WordCloud {...config} />
}
