import { useAxiosWithMsal } from "./useAxiosWithMsal"
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from "react-query"
import {
  type ApiResponsePaginated,
  type ApiResponse,
  type ApiResponsePaginatedInfinite,
  type QueryParams,
  ApiResponseV2,
  ApiResponse2Generic,
} from "../types/ApiResponse"
import { type AxiosError } from "../errors"
import { message } from "antd"

export interface Item {
  id: string
  name: string
}
export interface ExtendedItem extends Item {
  [key: string]: any
}

export type CoreSkill = {
  proficiency: Item | null
} & Item

export type GreenSkill = {
  proficiency: number
} & Item

export type Language = {
  proficiency: Item | null
} & Item

export type Currency = {
  code: string
} & Item

export interface Choice {
  name: string
  disqualify: boolean
}

export type Question = {
  question: string
  choices: Choice[]
} & Item
export interface GetItems extends ApiResponse {
  data: { data: Item[] }
}

export const useReferences = (): {
  companySizes: UseQueryResult<GetItems, AxiosError>
  companySectors: UseQueryResult<ApiResponsePaginated<Item>, AxiosError>
  countries: UseQueryResult<ApiResponsePaginated<Item>, AxiosError>
} => {
  const getAxiosInstance = useAxiosWithMsal()
  const getCompanySizes = async () => {
    const api = await getAxiosInstance()
    return api.get<GetItems>("/references/company-sizes")
  }
  const getCompanySectors = async () => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginated<Item>>("/references/company-sectors", {
      params: { limit: 150 },
    })
  }
  const getCountries = async () => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginated<Item>>("/references/countries", {
      params: { limit: 242 },
    })
  }

  return {
    companySizes: useQuery("company-sizes", getCompanySizes),
    companySectors: useQuery("company-sectors", getCompanySectors),
    countries: useQuery("countries", getCountries),
  }
}

export const useCompanySizes = (): UseQueryResult<GetItems, AxiosError> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getCompanySizes = async () => {
    const api = await getAxiosInstance()
    return api.get<GetItems>("/references/company-sizes")
  }
  return useQuery("company-sizes", getCompanySizes)
}

export const useCompanySectors = (): UseQueryResult<
  ApiResponsePaginated<Item>,
  AxiosError
> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getCompanySectors = async () => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginated<Item>>("/references/company-sectors", {
      params: { limit: 150 },
    })
  }
  return useQuery("company-sectors", getCompanySectors)
}

export const useTechnicalSkills = (
  queryParams?: QueryParams,
): UseQueryResult<ApiResponsePaginated<Item>, AxiosError> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getCompanySectors = async () => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginated<Item>>("/references/technical-skills", {
      params: {
        ...queryParams,
      },
    })
  }
  return useQuery(["-", queryParams], getCompanySectors)
}

export const useTechnicalSkillsInfinity = (searchParam: string) => {
  const handleFetchError = (error: any) => {
    message.error(
      `An error occurred while fetching technical skills: ${error?.data}`,
    )
  }
  const getAxiosInstance = useAxiosWithMsal()

  const fetchTechnicalSkillsInfinity = async ({
    pageParam = 1,
    search,
  }: {
    pageParam: number
    search: string
  }) => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginatedInfinite<Item>>(
      "/references/technical-skills",
      {
        params: {
          page: pageParam,
          limit: 10,
          name: search,
        },
      },
    )
  }
  return useInfiniteQuery(
    ["--infinity", searchParam],
    async ({ pageParam }) =>
      fetchTechnicalSkillsInfinity({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    },
  )
}

export const useCreateTechnicalSkill = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const addTechSkill = async (data: { name: string }) => {
    message.open({
      key: "create-tech",
      type: "loading",
      content: "Creating...",
      duration: 0,
    })
    const api = await getAxiosInstance()
    return api.post<ApiResponseV2<Item[]>>("/references/technical-skills", data)
  }
  return useMutation(addTechSkill, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["tech-skills-infinity"])
      message.open({
        key: "create-tech",
        type: "success",
        content: "Successfully created technical skill",
      })
    },
    onError: async () => {
      message.open({
        key: "create-tech",
        type: "error",
        content: "Error creating technical skill. Please try again.",
      })
    },
  })
}

export const useCountries = (): UseQueryResult<
  ApiResponsePaginated<Item>,
  AxiosError
> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getCountries = async () => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginated<Item>>("/references/countries", {
      params: { limit: 242 },
    })
  }
  return useQuery("countries", getCountries)
}

export const useCriticalSkills = (
  queryParams?: QueryParams,
): UseQueryResult<ApiResponsePaginated<CoreSkill>, AxiosError> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getCriticalSkills = async () => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginated<CoreSkill>>(
      "/references/critical-skills",
      {
        params: {
          ...queryParams,
        },
      },
    )
  }
  return useQuery(["critical-skills", queryParams], getCriticalSkills)
}

export const useCriticalSkillsInfinity = (searchParam: string) => {
  const handleFetchError = (error: any) => {
    message.error(
      `An error occurred while fetching critical skills: ${error?.data}`,
    )
  }
  const getAxiosInstance = useAxiosWithMsal()

  const fetchCriticalSkillsInfinity = async ({
    pageParam = 1,
    search,
  }: {
    pageParam: number
    search: string
  }) => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginatedInfinite<CoreSkill>>(
      "/references/critical-skills",
      {
        params: {
          page: pageParam,
          limit: 10,
          name: search,
        },
      },
    )
  }
  return useInfiniteQuery(
    ["critical-skills-infinity", searchParam],
    async ({ pageParam }) =>
      fetchCriticalSkillsInfinity({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    },
  )
}

export const useCurrencies = (
  queryParams?: QueryParams,
): UseQueryResult<ApiResponsePaginated<Currency>, AxiosError> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getCurrencies = async () => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginated<Currency>>("/references/currencies", {
      params: {
        search: queryParams?.search,
        limit: queryParams?.limit ?? 10,
        page: queryParams?.page ?? 1,
      },
    })
  }
  return useQuery(["currencies", queryParams], getCurrencies)
}

export const useCurrenciesInfinity = (searchParam: string) => {
  const handleFetchError = (error: any) => {
    message.error(`An error occurred while fetching currencies: ${error?.data}`)
  }
  const getAxiosInstance = useAxiosWithMsal()

  const fetchCurrenciesInfinity = async ({
    pageParam = 1,
    search,
  }: {
    pageParam: number
    search: string
  }) => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginatedInfinite<Currency>>(
      "/references/currencies",
      {
        params: {
          page: pageParam,
          limit: 10,
          search,
        },
      },
    )
  }
  return useInfiniteQuery(
    ["currencies-infinity", searchParam],
    async ({ pageParam }) =>
      fetchCurrenciesInfinity({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    },
  )
}

export const useCurrenciesFrequencies = (): UseQueryResult<
  GetItems,
  AxiosError
> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getCurrenciesFrequencies = async () => {
    const api = await getAxiosInstance()
    return api.get<GetItems>("/references/currencies/frequencies")
  }
  return useQuery("currencies-frequencies", getCurrenciesFrequencies)
}

export const useGreenSkills = (
  queryParams?: QueryParams,
): UseQueryResult<ApiResponsePaginated<Item>, AxiosError> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getGreenSkills = async () => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginated<Item>>("/references/green-skills", {
      params: {
        ...queryParams,
      },
    })
  }
  return useQuery(["green-skills", queryParams], getGreenSkills)
}

export const useGreenSkillsInfinity = (searchParam: string) => {
  const handleFetchError = (error: any) => {
    message.error(
      `An error occurred while fetching green skills: ${error?.data}`,
    )
  }
  const getAxiosInstance = useAxiosWithMsal()

  const fetchGreenSkillsInfinity = async ({
    pageParam = 1,
    search,
  }: {
    pageParam: number
    search: string
  }) => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginatedInfinite<GreenSkill>>(
      "/references/green-skills",
      {
        params: {
          page: pageParam,
          limit: 10,
          search,
        },
      },
    )
  }
  return useInfiniteQuery(
    ["green-skills-infinity", searchParam],
    async ({ pageParam }) =>
      fetchGreenSkillsInfinity({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    },
  )
}

type GetJobClosingReasons = unknown
export const useJobClosingReasons = (): UseQueryResult<
  GetJobClosingReasons,
  AxiosError
> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getJobClosingReasons = async () => {
    const api = await getAxiosInstance()
    return api.get<GetJobClosingReasons>("/references/job-closing-reasons")
  }
  return useQuery("job-closing-reasons", getJobClosingReasons)
}

export const useJobTypes = (): UseQueryResult<GetItems, AxiosError> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getJobTypes = async () => {
    const api = await getAxiosInstance()
    return api.get<GetItems>("/references/job-types")
  }
  return useQuery("job-types", getJobTypes)
}

export const useLanguages = (
  queryParams?: QueryParams,
): UseQueryResult<ApiResponsePaginated<Item>, AxiosError> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getLanguages = async () => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginated<Item>>("/references/languages", {
      params: {
        ...queryParams,
      },
    })
  }
  return useQuery(["languages", queryParams], getLanguages)
}

export const useLanguagesInfinity = (searchParam: string) => {
  const handleFetchError = (error: any) => {
    message.error(`An error occurred while fetching languages: ${error?.data}`)
  }
  const getAxiosInstance = useAxiosWithMsal()

  const fetchLanguagesInfinity = async ({
    pageParam = 1,
    search,
  }: {
    pageParam: number
    search: string
  }) => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginatedInfinite<Language>>(
      "/references/languages",
      {
        params: {
          page: pageParam,
          limit: 10,
          name: search,
        },
      },
    )
  }
  return useInfiniteQuery(
    ["languages-infinity", searchParam],
    async ({ pageParam }) =>
      fetchLanguagesInfinity({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    },
  )
}

export function useProficiencies(
  type: "language" | "skill",
): UseQueryResult<GetItems, AxiosError> {
  const getAxiosInstance = useAxiosWithMsal()
  const getProficienciesTypeParam = async () => {
    const api = await getAxiosInstance()
    return api.get<GetItems>(`/references/proficiencies/${type}`)
  }
  return useQuery(["proficiencies", type], async () =>
    getProficienciesTypeParam(),
  )
}

interface GetScreeningQuestions extends ApiResponse {
  data: { data: Question[] }
}
export function useScreeningQuestions(): UseQueryResult<
  GetScreeningQuestions,
  AxiosError
> {
  const getAxiosInstance = useAxiosWithMsal()

  const getScreeningQuestions = async () => {
    const api = await getAxiosInstance()

    return api.get<GetScreeningQuestions>("/references/screening-questions")
  }
  return useQuery("screening-questions", getScreeningQuestions)
}

export const useApplicantLocations = (
  id: string | undefined,
): UseQueryResult<ApiResponse2Generic<{ data: string[] }>, AxiosError> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getApplicantLocations = async () => {
    const api = await getAxiosInstance()
    return api.get<GetItems>(`/references/applicant-locations?id=${id}`)
  }
  return useQuery("applicant-locations", getApplicantLocations)
}

export const useJobRoles = (): UseQueryResult<GetItems, AxiosError> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getJobRoles = async () => {
    const api = await getAxiosInstance()
    return api.get<GetItems>("/references/job-roles")
  }
  return useQuery("job-roles", getJobRoles)
}

export const useJobRolesInfinity = (searchParam: string) => {
  const handleFetchError = (error: any) => {
    message.error(`An error occurred while fetching job roles: ${error?.data}`)
  }
  const getAxiosInstance = useAxiosWithMsal()

  const fetchJobRolesInfinity = async ({
    pageParam = 1,
    search,
  }: {
    pageParam: number
    search: string
  }) => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginatedInfinite<Item>>(
      "/references/job-roles",
      {
        params: {
          page: pageParam,
          limit: 10,
          name: search,
        },
      },
    )
  }
  return useInfiniteQuery(
    ["job-roles-infinity", searchParam],
    async ({ pageParam }) =>
      fetchJobRolesInfinity({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    },
  )
}

export const useTeamFunctions = (tenantId: string | undefined) => {
  const getAxiosInstance = useAxiosWithMsal()
  const getApplicantLocations = async () => {
    const api = await getAxiosInstance()
    return api.get<ApiResponseV2<string[]>>(
      `/references/teams-functions?id=${tenantId}`,
    )
  }
  return useQuery("teams-functions", getApplicantLocations, {
    enabled: !!tenantId,
  })
}

export const useJobSectorsInfinity = (searchParam: string) => {
  const handleFetchError = (error: any) => {
    message.error(`An error occurred while fetching job sector: ${error?.data}`)
  }
  const getAxiosInstance = useAxiosWithMsal()

  const fetchJobRolesInfinity = async ({
    pageParam = 1,
    search,
  }: {
    pageParam: number
    search: string
  }) => {
    const api = await getAxiosInstance()
    return api.get<ApiResponsePaginatedInfinite<Item>>(
      "/references/job-sectors",
      {
        params: {
          page: pageParam,
          limit: 10,
          name: search,
        },
      },
    )
  }
  return useInfiniteQuery(
    ["job-sector-infinity", searchParam],
    async ({ pageParam }) =>
      fetchJobRolesInfinity({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    },
  )
}
