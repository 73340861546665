import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import {
  CustomFooter,
  CustomHeader,
  PageContent,
  PageHeader,
} from "../../../common/layout"
import Lottie from "lottie-react"
import PrivacyPolicyContent from "../components/PrivacyPolicyContent"
import { usePrivacyPolicy } from "../hooks/usePrivacyPlocy"
import success_payment from "../../../assets/json/success_payment.json"
import dayjs from "dayjs"
import { Layout } from "antd"

interface TermOfUseProps {}

const TermOfUse: React.FC<TermOfUseProps> = () => {
  const { privacyPolicyResponse } = usePrivacyPolicy()

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Term of Use",
    },
  ]

  console.log("privacyPolicyResponse", privacyPolicyResponse)

  return (
    <div className="h-screen">
      <Layout>
        <CustomHeader />

        <div className="mt-16 h-screen">
          <PageContent
            header={
              <PageHeader
                breadcrumbItems={items}
                title={"Term of Use"}
                // eslint-disable-next-line
                children={`Last Updated on ${dayjs(
                  privacyPolicyResponse?.data?.created_at,
                ).format("DD MMM YYYY")}`}
                needRightComponent={true}
                // eslint-disable-next-line
                rightComponent={
                  <div className=" right-10">
                    <Lottie
                      animationData={success_payment}
                      style={{ width: 100, height: 100 }}
                    />
                  </div>
                }
              />
            }
            content={<PrivacyPolicyContent data={privacyPolicyResponse} />}
          />
        </div>
        <div className="relative w-full">
          <div className="absolute bottom-0 w-full">
            <CustomFooter />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default TermOfUse
