import React from "react"
import { Breadcrumbs } from "../../../../common/components"
import { HomeOutlined, ArrowLeftOutlined } from "@ant-design/icons"
import { Typography } from "antd"
import { useGetSingleJobPost } from "../../hooks/useJobPosts"
import { useNavigate } from "react-router"
interface HeaderProps {
  id: string | undefined
}

export const Header = ({ id }: HeaderProps) => {
  const [singleJobPostQuery] = useGetSingleJobPost(id as unknown as string)
  const queryData = singleJobPostQuery.data?.data?.data

  const navigate = useNavigate()

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "My Job Posts",
      path: "/jobs/my-job-posts",
    },
    { title: queryData?.name ?? "N/A" },
    { title: "Candidates" },
  ]
  return (
    <div className=" bg-white px-10 py-5 flex justify-between">
      <div>
        <Breadcrumbs items={items} />
        <div className="flex gap-3 items-center my-3 ">
          <ArrowLeftOutlined
            className="text-base cursor-pointer"
            onClick={() => navigate("/jobs/my-job-posts")}
          />
          <Typography.Title level={3} style={{ margin: "0px" }}>
            {queryData?.name}
          </Typography.Title>
        </div>
      </div>
    </div>
  )
}
