/* eslint-disable    */
import React, { useState, useRef, useEffect } from "react"
import { avatarImgs } from "../../../../assets/avatar_images"
import pin_map from "../../../../assets/images/pin_map.svg"
import { Card, Divider } from "antd"
import { Candidate, useUpdateApplicantData } from "../../hooks/useCandidates"
import { GetStageType } from "../../hooks/useManageJobStages"
interface CardCandidateProps {
  data: Candidate
  observ?: (node: Element | null) => void
  stages: GetStageType[] | undefined
  handleOpen: (id: string) => void
  isDisqualified: boolean
}

export const CardCandidate: React.FC<CardCandidateProps> = ({
  data,
  observ,
  stages,
  handleOpen,
  isDisqualified,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    data?.stage?.id ?? "",
  )
  const selectRef = useRef<HTMLSelectElement>(null)
  const { mutateAsync: updateApplicant } = useUpdateApplicantData()

  const getColorForStatus = (statusId: string): string => {
    const status = stages?.find((item) => item.id === statusId)?.name
    switch (status) {
      case "hired":
        return "#D4F5C5"
      case "new":
        return "#b9babd"
      case "disqualified":
        return "#EF7574"
      default:
        return "#FFEFA0"
    }
  }

  useEffect(() => {
    updateSelectWidth()
  }, [selectedOption, data])
  useEffect(() => {
    if (data) {
      setSelectedOption(data?.stage?.id)
    }
  }, [data])

  const updateSelectWidth = () => {
    if (selectRef.current) {
      const selectedOptionLabel =
        selectRef.current.options[selectRef.current.selectedIndex].text
      const width = getTextWidth(selectedOptionLabel)
      selectRef.current.style.width = `${width}px`
    }
  }

  const getTextWidth = (text: string) => {
    const div = document.createElement("button")
    div.textContent = text
    document.body.appendChild(div)
    const width = div.getBoundingClientRect().width
    document.body.removeChild(div)
    return width + (1 / 5) * width
  }

  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    updateApplicant({ id: data.id, data: { stage_id: event.target.value } })
    setSelectedOption(event.target.value)
  }

  return (
    <Card
      ref={observ}
      style={{ width: 300 }}
      hoverable
      className="p-1"
      onClick={() => {
        handleOpen(data.id)
      }}
    >
      <div className="flex gap-5">
        <img
          src={data?.avatar ?? avatarImgs[0]}
          className="w-14 h-14 rounded-full"
          alt="profile"
          style={{ border: "1px solid #D4F5C5" }}
        />
        <div className="w-full">
          <div className="mb-1">
            {data.stage.name === "new" || data.stage.name === "disqualified"
              ? data.username
              : data?.full_name}
          </div>
          <select
            onClick={(e) => e.stopPropagation()}
            ref={selectRef}
            onChange={handleChange}
            value={selectedOption}
            // className={`text-[#36505E] rounded outline-none !w-1/2 text-xs p-0.5`}
            style={{
              color: "#36505E",
              border: "0.5px solid #36505E",
              fontFamily: "Sen",
              borderRadius: "4px",
              width: "40px",
              backgroundColor: getColorForStatus(selectedOption),
            }}
          >
            {isDisqualified
              ? stages?.map((stage) => {
                  return (
                    <option key={stage?.id} value={stage?.id}>
                      {stage?.name}
                    </option>
                  )
                })
              : stages
                  ?.filter((item) => item.name !== "disqualified")
                  ?.map((stage) => {
                    return (
                      <option key={stage?.id} value={stage?.id}>
                        {stage?.name}
                      </option>
                    )
                  })}
          </select>
        </div>
      </div>
      <Divider className="my-2 p-0" />
      <div className="flex gap-5">
        <div className="px-1 rounded-lg bg-[#D4F5C5]">
          {Math.floor(data?.fit_percentage || 0)}%
        </div>
        <div className="flex gap-2 justify-center">
          <img src={pin_map} alt="map" />
          <div>{data?.location}</div>
        </div>
      </div>
    </Card>
  )
}
