import { Button, Modal, ModalProps } from "antd"
import React from "react"
import { Perk } from "../hooks/usePerks"
import { openInNewTab } from "../../../utils/util_funcs"

interface PerkDetailModalProps extends ModalProps {
  perk: Perk | null
}

export const PerkDetailModal: React.FC<PerkDetailModalProps> = ({
  perk,
  ...props
}) => {
  return (
    <Modal
      closable
      {...props}
      className="rounded-xl"
      rootClassName="rounded-xl"
      footer={null}
    >
      <div className="flex flex-col gap-4 pt-4">
        <img
          src={perk?.company_logo}
          alt={perk?.company_name}
          className="w-[200px] mx-auto h-[200px] object-contain"
        />
        <div className="text-center font-bold text-xl">{perk?.offer_title}</div>
        <div
          className=""
          dangerouslySetInnerHTML={{ __html: perk?.offer_desc ?? "" }}
        ></div>
        <Button
          onClick={() => perk?.offer_link && openInNewTab(perk?.offer_link)}
          className="bg-primary text-white mx-auto"
        >
          Redeem Deal
        </Button>
      </div>
    </Modal>
  )
}
