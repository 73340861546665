import { create } from "zustand"

interface IJobPostDetailStore {
  edit: boolean
  setEdit: (edit: boolean) => void
}

export const useJobPostDetailStore = create<IJobPostDetailStore>((set) => ({
  edit: false,
  setEdit: (edit) => set({ edit }),
}))
