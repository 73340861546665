import React from "react"
import { type DrawerProps, Drawer } from "antd"
import {
  useGetStages,
  useCreateStage,
  useDeleteStage,
  useEditStage,
} from "../hooks/useManageJobStages"
import { InputWithEditAndDelete } from "./InputWithEditAndDelete"
import { AddNewWithInput } from "./AddNewWithInput"
import { CloseOutlined } from "@ant-design/icons"
interface ManageStagesDrawerProps extends DrawerProps {
  open: boolean
  onClose: () => void
}

export function ManageStagesDrawer({
  open,
  onClose,
  ...inputProps
}: ManageStagesDrawerProps) {
  const stagesQuery = useGetStages()
  const createStageMutation = useCreateStage()
  const editStageMutation = useEditStage()
  const deleteStageMutation = useDeleteStage()
  const totalCurrentCustomStages = stagesQuery.data?.data?.data?.filter(
    (stage) => stage?.tag === "pipeline" && stage?.name !== "shortlisted",
  )?.length

  return (
    <Drawer
      {...inputProps}
      closable={false}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <div className="flex w-full justify-between -mt-4 items-center">
        <p className="font-bold text-xl text-backdrop ">Manage Stages</p>
        <CloseOutlined onClick={onClose} />
      </div>
      <div className="mt-8">
        <h4 className="font-bold text-backdrop mb-5">New Candidates</h4>
        <div className="flex gap-x-2 items-center mb-5">
          <div className="rounded-full bg-darkGrey h-4 w-4"></div>
          <div className="h-5 text-sm">New</div>
        </div>
      </div>
      <div className="">
        <h4 className="font-bold text-backdrop mb-5">Pipeline</h4>
        <div className="flex gap-x-2 items-center mb-5">
          <div className="rounded-full bg-secondaryYellow h-4 w-4"></div>
          <div className="h-5 text-sm">Shortlisted</div>
        </div>
        {stagesQuery.data?.data?.data
          ?.filter((stage) => stage.tag === "pipeline")
          .map((stage) => (
            <>
              {stage?.name !== "shortlisted" && (
                <div key={stage?.id} className="flex gap-x-2 items-center mb-5">
                  <div className="rounded-full bg-secondaryYellow h-4 w-4"></div>

                  <InputWithEditAndDelete
                    key={stage?.id}
                    isLoading={editStageMutation.isLoading}
                    onSave={async (name) =>
                      editStageMutation.mutateAsync({
                        id: stage?.id,
                        data: { name },
                      })
                    }
                    onDelete={async () =>
                      deleteStageMutation.mutateAsync(stage?.id)
                    }
                    value={stage?.name}
                  />
                </div>
              )}
            </>
          ))}
        {totalCurrentCustomStages !== undefined &&
          totalCurrentCustomStages < 5 && (
            <div className="">
              <AddNewWithInput
                isLoading={createStageMutation.isLoading}
                createStage={async (stage) => createStageMutation.mutate(stage)}
              />
            </div>
          )}
      </div>
      <div className="">
        <h4 className="font-bold text-backdrop mb-5">Decision</h4>
        <div className="flex gap-x-2 items-center mb-5">
          <div className="rounded-full bg-primary-bg shadow-md h-4 w-4"></div>
          <div className="h-5 text-sm">Hired</div>
        </div>
        <div className="flex gap-x-2 items-center mb-5">
          <div className="rounded-full bg-secondaryYellow h-4 w-4"></div>
          <div className="h-5 text-sm">Disqualified</div>
        </div>
      </div>
    </Drawer>
  )
}
