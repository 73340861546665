// eslint-disable-next-line no-unused-vars
import { UseQueryResult, useQuery } from "react-query"
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import { AxiosError, AxiosInstance, AxiosResponse } from "axios"
import { message } from "antd"
import { ApiResponse2Generic } from "../../../common/types/ApiResponse"

import { PrivacyPolicyParams, PrivacyResponse } from "../types/response"
import { useAxiosWithoutMsal } from "../../../common/hooks/useAxiosWithoutMsal"

export const QUERYPRIVACYPOLICYNAME = "privacy-policy"

export const useFetchPrivacyPolicy = (
  queryParam: PrivacyPolicyParams,
): UseQueryResult<ApiResponse2Generic<PrivacyResponse>, AxiosError> => {
  const getAxiosInstance = useAxiosWithoutMsal()
  const fetchData = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      const response: AxiosResponse<ApiResponse2Generic<PrivacyResponse>> =
        await api.get(`/references/policies/recruiter/${queryParam.query}`)
      return response?.data
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYPRIVACYPOLICYNAME}: + ${error.message}`,
      )
      throw error
    }
  }

  const queryResult = useQuery([QUERYPRIVACYPOLICYNAME], fetchData, {
    onError: (error: AxiosError) => {
      message.error(
        `An error occurred while fetching ${QUERYPRIVACYPOLICYNAME}: + ${error.message}`,
      )
    },
  })

  return queryResult
}
