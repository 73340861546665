import React, { useContext } from "react"
import { Image, Button, Skeleton, ButtonProps } from "antd"
import { PlanCardList, ListProps } from "./PlanCardList"
import { CurrenciesContext } from "../../../context/user"
import { formatNumber } from "../../../helper/formatNumber"

type Props = {
  name: string
  price: number
  originalPrice?: number | undefined
  image?: string
  currency: string
  features: ListProps["items"]
  buttonTitle: string
  footer?: string | React.ReactNode
  isLoading?: boolean
  stripe_price_id: string
  stripe_product_id: string
  onActionClick?: () => void
  buttonProps?: ButtonProps
}

export const PlanCardLoading: React.FC<{ features: Props["features"] }> = ({
  features,
}) => {
  return (
    <div className="py-4 px-9 flex flex-col justify-center items-center bg-white flex-grow rounded">
      <Skeleton.Button active style={{ width: "80%", height: "1.5rem" }} />
      <Skeleton.Avatar
        className="mt-5"
        active
        style={{ width: "88px", height: "88px" }}
      />
      <div className="mt-4">
        <Skeleton.Button active style={{ width: "50%", height: "1.5rem" }} />
      </div>
      <hr className="border-none h-[1px] bg-backdrop my-4" />
      <Skeleton active paragraph={{ rows: features.length }} />
      <Skeleton active paragraph={{ rows: features.length }} />
    </div>
  )
}

export function PlanCard({
  name,
  price,
  originalPrice,
  currency,
  image = "",
  features,
  buttonTitle,
  footer,
  isLoading = false,
  buttonProps,
  // stripe_price_id,
  // stripe_product_id,
  onActionClick,
}: Props) {
  const currencies = useContext(CurrenciesContext)
  // const { makeTenantSubscription } = useSubscriptions();
  if (isLoading) {
    return <PlanCardLoading features={features} />
  }
  return (
    <div className="basic-card flex flex-col justify-center items-center bg-white flex-grow">
      <h3 className="capitalize font-semibold text-center text-lg">{name}</h3>
      {image && (
        <div className="mt-5">
          <Image
            src={image}
            loading="lazy"
            preview={false}
            style={{ width: 88, height: 88 }}
            placeholder={
              <Skeleton.Avatar active style={{ width: 88, height: 88 }} />
            }
          />
        </div>
      )}
      <div className="mt-4">
        <span className="text-xl flex items-center gap-3 ">
          {currencies?.find((item) => item.currency_code === currency) && (
            <img
              className=" rounded-full w-6 h-6 object-cover"
              style={{ border: "0.5px solid #36505E" }}
              src={
                currencies?.find((item) => item.currency_code === currency)
                  ?.logo
              }
            />
          )}
          <span className="uppercase">{currency}</span>
          <div className=" flex flex-col">
            {originalPrice && (
              <span className="text-secondaryRed text-xs line-through">
                {formatNumber(originalPrice)}
              </span>
            )}
            <span>{formatNumber(price)}</span>
          </div>
          <span className="font-light text-xs text-darkGrey">/mo</span>
        </span>
      </div>
      <hr className="border-none  w-full h-[1px] bg-backdrop my-4" />
      <div className="flex-grow flex w-full">
        <PlanCardList items={features} />
      </div>
      <Button
        {...buttonProps}
        className="bg-primary text-white w-full"
        onClick={onActionClick}
        // onClick={handleCreateTenantSubscription}
      >
        {buttonTitle}
      </Button>
      {footer && <div className="text-center mt-4">{footer}</div>}
    </div>
  )
}
