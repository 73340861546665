import React from "react"
import { Button, Divider, Input, Popover, Spin } from "antd"
import { HeartOutlined, HeartFilled, LoadingOutlined } from "@ant-design/icons"
import { ReactComponent as PinMap } from "../../../../assets/images/pin_map.svg"
import { type TalentDatabase } from "../type/database"
import { type AxiosResponse } from "axios"
import { EmptyData } from "../../../../common/components/EmptyData"

interface CardTalentProps {
  data: TalentDatabase
  raw: any
  isLoading: boolean
  currentUpdate?: string
  handleUpdate: (id: string) => void
  isLoadingUpdate: boolean
  isLoadingRequest: boolean
  handleRequest: (id: string) => void
  currentRequest: string
  // observ?: (node: Element | null) => void | undefined;
  observ?: ((node: Element | null) => void) | undefined
  handleSelect: (val: TalentDatabase, connect: boolean) => void
  isConnectedPage: boolean
  dataJob?: any
  observJob: ((node: Element | null) => void) | undefined
  handleGet: (val: string) => void
  paramsJob: any
  handleSearchJob: (...args: any[]) => void
  dataJobUsed: any
  isLoadingJobUsed: boolean
  handleInvite: (val: any) => Promise<AxiosResponse<any, any> | undefined>
  isLoadingInvite: boolean
}

export const CardTalent: React.FC<CardTalentProps> = ({
  data,
  raw,
  isLoading,
  currentUpdate,
  handleUpdate,
  isLoadingUpdate,
  isLoadingRequest,
  handleRequest,
  currentRequest,
  observ,
  handleSelect,
  isConnectedPage,
  dataJob,
  observJob,
  handleGet,
  paramsJob,
  handleSearchJob,
  dataJobUsed,
  isLoadingJobUsed,
  handleInvite,
  isLoadingInvite,
}) => {
  return (
    <div
      className="my-5 hover:shadow-md p-5 rounded-xl bg-[#FAFAFA] border-none shadow-md"
      ref={observ}
      onClick={() => handleSelect(data, helperConnected(raw, data?.id))}
    >
      <div className="grid grid-cols-12 gap-3">
        <div className=" col-span-9 flex gap-3">
          <div>
            <img
              src={
                data?.profile_picture ||
                "https://stgreenskills001.blob.core.windows.net/default-avatars/9.png"
              }
              alt="talent"
              className="w-12 h-12 bg-slate-400 rounded-full "
            ></img>
          </div>
          <div>
            <div className="flex gap-5">
              {data?.first_name && data.last_name && (
                <div className=" capitalize font-semibold">{`${data?.first_name} ${data?.last_name}`}</div>
              )}

              <div className="">{`@${data?.nick_name}`}</div>
              {data?.location && (
                <div>
                  <PinMap />
                  <span className="ml-2">{data?.location}</span>
                </div>
              )}
            </div>
            <div className="mt-1">{data?.profile_headline}</div>
          </div>
        </div>
        <div className=" col-span-3 justify-self-end self-start flex gap-3 items-center">
          {isConnectedPage ? (
            <Popover
              trigger={"click"}
              placement="rightTop"
              arrow={false}
              content={
                <div
                  className="w-56 "
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <div className=" capitalize">invite talent apply</div>
                  <Input.Search
                    placeholder="search"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleSearchJob(e.target.value)
                    }}
                    defaultValue={paramsJob?.name}
                    className="w-full my-2"
                  />
                  <div className="min-h-[35px] h-full max-h-32 overflow-auto">
                    <Spin
                      spinning={isLoadingJobUsed || isLoadingInvite}
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    >
                      {dataJob && dataJob.length > 0 ? (
                        dataJob?.map((item: any, index: number) => (
                          <div
                            ref={
                              index === dataJob?.length + 1
                                ? observJob
                                : undefined
                            }
                            className={`my-1 py-1 px-2 hover:bg-slate-200 ${
                              dataJobUsed?.find((val: any) => val === item?.id)
                                ? "cursor-not-allowed text-slate-500"
                                : "cursor-pointer"
                            } `}
                            key={index}
                          >
                            <div
                              className="truncate"
                              onClick={() => {
                                if (
                                  !dataJobUsed?.find(
                                    (val: any) => val === item?.id,
                                  )
                                ) {
                                  handleInvite({
                                    id: item?.id,
                                    name: item?.name,
                                    talent_id: data?.id,
                                  }).then(() => handleGet(data?.id))
                                }
                              }}
                            >
                              {item?.name}
                            </div>
                            {dataJobUsed?.find(
                              (val: any) => val === item?.id,
                            ) && (
                              <div className="ml-3 text-xs">
                                already applied or invited
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <EmptyData />
                      )}
                    </Spin>
                  </div>
                </div>
              }
            >
              <Button
                type="primary"
                ghost
                onClick={(
                  e:
                    | React.MouseEvent<HTMLAnchorElement, MouseEvent>
                    | React.MouseEvent<HTMLButtonElement, MouseEvent>,
                ) => {
                  handleGet(data?.id)
                  e.stopPropagation()
                }}
              >
                Invite to Apply
              </Button>
            </Popover>
          ) : (
            <>
              {isLoading || (isLoadingUpdate && currentUpdate === data.id) ? (
                <LoadingOutlined />
              ) : helperFavorit(raw, data?.id) ? (
                <HeartFilled
                  className=" text-primary text-xl"
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    handleUpdate(data?.id)
                    e.stopPropagation()
                  }}
                />
              ) : (
                <HeartOutlined
                  className=" text-xl"
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    handleUpdate(data?.id)
                    e.stopPropagation()
                  }}
                />
              )}
              <Button
                type="primary"
                disabled={
                  helperStatusButton(raw, data?.id) ||
                  (isLoadingRequest && currentRequest === data?.id)
                }
                className=" w-28"
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  if (!isLoadingRequest && currentRequest !== data?.id) {
                    handleRequest(data?.id)
                  }
                  e.stopPropagation()
                }}
              >
                {helperStatus(raw, data?.id)}
              </Button>
            </>
          )}
        </div>
      </div>
      <Divider className="my-3 mx-0 p-0" />
      {data?.career_aspirations.length > 0 && (
        <div className="flex gap-3">
          <div className=" capitalize font-semibold w-36">
            career aspirations
          </div>
          <div className=" w-[calc(100%-10.75rem)]">
            {data?.career_aspirations?.map((item: any, i: any) => (
              <span key={i}>
                <span className="">{item?.name}</span>
                {data?.career_aspirations?.length - 1 !== i && (
                  <span
                    className=" relative text-3xl -top-0.5"
                    style={{ lineHeight: 0 }}
                  >
                    {" "}
                    .{" "}
                  </span>
                )}
              </span>
            ))}
          </div>
        </div>
      )}
      {helperGs(data?.roadmap)?.length > 0 && (
        <div className="flex gap-3 mt-3">
          <div className=" capitalize font-semibold w-36">green skill</div>
          <div className=" w-[calc(100%-10.75rem)]">
            {helperGs(data?.roadmap)?.map((item: any, i: any) => (
              <span key={i}>
                <span>{item?.name}</span>
                {helperGs(data?.roadmap)?.length - 1 !== i && (
                  <span
                    className=" relative text-3xl -top-0.5"
                    style={{ lineHeight: 0 }}
                  >
                    {" "}
                    .{" "}
                  </span>
                )}
              </span>
            ))}
          </div>
        </div>
      )}

      {helperLp(data?.roadmap)?.length > 0 && (
        <div className="flex gap-3 mt-3">
          <div className=" capitalize font-semibold w-36">learning plan</div>
          <div className=" w-[calc(100%-10.75rem)]">
            {helperLp(data?.roadmap)?.map((item: any, i: any) => (
              <span key={i}>
                <span>{item?.name}</span>
                {helperLp(data?.roadmap)?.length - 1 !== i && (
                  <span
                    className=" relative text-3xl -top-0.5"
                    style={{ lineHeight: 0 }}
                  >
                    {" "}
                    .{" "}
                  </span>
                )}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const helperGs = (roadmaps: any): any => {
  const a = []
  for (let i = 0; i < roadmaps?.length; i++) {
    const roadmap = roadmaps[i]
    for (let j = 0; j < roadmap?.levels?.length; j++) {
      const level = roadmap.levels[j]
      if (level.user_track.is_assessment_passed) {
        a.push(roadmap)
        break
      }
    }
  }
  return a
}

const helperLp = (roadmaps: any): any => {
  const a = []
  for (let i = 0; i < roadmaps?.length; i++) {
    const roadmap = roadmaps[i]
    const x = roadmap?.levels?.filter(
      (item: any) => item.user_track.is_assessment_passed,
    )
    if (x.length === 0) a.push(roadmap)
  }
  return a
}

const helperStatus = (raw: any, id: string): any => {
  const temp = raw?.find((item: any) => item.talent_id === id)
  switch (temp?.request_status) {
    case "accepted":
      return "Connected"
    case "pending":
      return "Pending"
    case "rejected":
      return "Rejected"
    default:
      return "Connect"
  }
}

const helperStatusButton = (raw: any, id: string): boolean => {
  const temp = raw?.find((item: any) => item.talent_id === id)
  switch (temp?.request_status) {
    case "accepted":
      return true
    case "pending":
      return true
    case "rejected":
      return false
    default:
      return false
  }
}

const helperConnected = (raw: any, id: string): any => {
  const temp = raw?.find((item: any) => item.talent_id === id)
  switch (temp?.request_status) {
    case "accepted":
      return { disabled: true, status: "connected" }
    case "pending":
      return { disabled: true, status: "pending" }
    default:
      return { disabled: false, status: "connect" }
  }
}

const helperFavorit = (raw: any, id: string): boolean => {
  const temp = raw?.find((item: any) => item.talent_id === id)
  return temp ? temp?.is_favorite : false
}
