import React from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { CreateJobSchema } from "../types"
import {
  Item,
  useTechnicalSkillsInfinity,
  useCreateTechnicalSkill,
} from "../../../../../common/hooks/useReferences"
import { InfiniteAutoComplete } from "../../../../../common/components"
import { Button, Tag } from "antd"
import { CloseOutlined } from "@ant-design/icons"

export const TechnicalSkills = () => {
  const {
    control,
    // formState: { errors },
  } = useFormContext<CreateJobSchema>()
  const addTechnicalSkillMutation = useCreateTechnicalSkill()
  const {
    fields: technicalSkillFields,
    append: appendTechnicalSkill,
    remove: removeTechnicalSkill,
  } = useFieldArray({
    control,
    name: "technical_skills",
    keyName: "id_",
  })

  const handleRemoveTechSkill = (index: number) => {
    removeTechnicalSkill(index)
  }

  const handleSelectTechSkills = (
    value: string,
    option: Item | undefined | null,
  ) => {
    if (option && option.id) {
      const isAlreadySelected = technicalSkillFields.some((item) => {
        return item.id === option?.id
      })
      if (!isAlreadySelected) {
        appendTechnicalSkill({ ...option })
      }
    }
  }
  return (
    <div className="basic-card bg-custom-white shadow-custom-lg">
      <h3 className="font-semibold text-lg">Technical Skills</h3>

      {/* {errors?.technical_skills?.message && (
        <div className="text-danger  -mt-3">
          {errors?.technical_skills?.message}
        </div>
      )} */}
      <InfiniteAutoComplete
        useDataHook={useTechnicalSkillsInfinity}
        onSelect={handleSelectTechSkills}
        className="w-full"
        placeholder="Add a Technical Skill"
        notFoundContent={(search: string) => (
          <Button
            type="text"
            onClick={async () => {
              const newTechnicalSkill =
                await addTechnicalSkillMutation.mutateAsync({ name: search })
              console.log("newTechnicalSkill", newTechnicalSkill)
              if (newTechnicalSkill) {
                appendTechnicalSkill(
                  (newTechnicalSkill?.data?.data as Item[])?.[0],
                )
              }
            }}
          >
            Create and Add New
          </Button>
        )}
      />
      <div className="mt-4 flex items-center gap-3 flex-wrap">
        {technicalSkillFields?.map((techSkill: any, index: number) => (
          <Tag
            className={"bg-secondaryYellow text-brown border-brown"}
            icon={
              <CloseOutlined onClick={() => handleRemoveTechSkill(index)} />
            }
            key={index}
          >
            {techSkill.name}
          </Tag>
        ))}
      </div>
    </div>
  )
}
