import { Controller, useFormContext } from "react-hook-form"
import { RichText } from "../../../../../common/components"
import { CreateJobSchema } from "../types"
import { TabCompLayout } from "./TabCompLayout"

export function JobDescriptionTab({ isLoading }: { isLoading: boolean }) {
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateJobSchema>()
  return (
    <TabCompLayout
      isLoading={isLoading}
      className="flex flex-col min-h-[528px]"
    >
      {errors?.description && (
        <div className="text-danger mb-2">{errors?.description?.message}</div>
      )}
      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <RichText
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            className={"  flex-grow font-default bg-white font-normal  pb-10"}
            placeholder={"Type Here"}
          />
        )}
      />
    </TabCompLayout>
  )
}
