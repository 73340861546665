import React, { useContext } from "react"
import { Divider, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { EmptyData } from "../../../../common/components/EmptyData"
import dayjs from "dayjs"
import { useGetJobExpiringSoon } from "../../../job-posts/hooks/useJobPosts"
import { useNavigate } from "react-router-dom"
import {
  ITourContext,
  TourContext,
} from "../../../../common/components/tour/TourProvider"

export const JobExpiringSoon = () => {
  const { data, isLoading } = useGetJobExpiringSoon()
  const navigate = useNavigate()

  const {
    refs: { refJobsExpiringSoon },
  } = useContext(TourContext) as ITourContext

  return (
    <div
      ref={refJobsExpiringSoon}
      className="  rounded-md shadow-custom p-5 bg-custom-white"
    >
      <Spin
        spinning={isLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <h3>Jobs Expiring Soon</h3>
        <div className="mt-5">
          {/* header */}
          <div className=" grid grid-cols-4 text-darkGrey mx-5">
            <div className="col-span-2">Job Title</div>
            <div className="col-span-1">Expires on</div>
            <div className="col-span-1">Applicants</div>
          </div>
          <Divider className=" mt-5 border-darkGrey" />
          {data && data.length > 0 ? (
            data.map((job, i) => (
              <div key={i} className=" grid grid-cols-4 mx-5 my-5">
                <div className="col-span-2 capitalize">
                  <span
                    onClick={() => navigate(`/jobs/detail/${job.id}/preview`)}
                    className=" cursor-pointer hover:text-darkGrey"
                  >
                    {job.name}
                  </span>
                </div>
                <div className="col-span-1">
                  {dayjs(job.expired_at).format("DD MMM YYYY")}
                </div>
                <div className="col-span-1">{job.total_candidates}</div>
              </div>
            ))
          ) : (
            <div className=" flex justify-center p-5">
              <EmptyData />
            </div>
          )}
        </div>
      </Spin>
    </div>
  )
}
