import React from "react"
import { Routes, Route } from "react-router-dom"
import Candidates from "./pages/Candidates/Candidates"
// import Candidates from "./pages/Candidates";
import { SingleJobPage } from "./pages"
import { NotFound } from "../../common/components"
import { JobDetail } from "./pages/JobDetail"
// import { ProtectRoutes } from "../../routes/components/ProtectRoutes";
// import { SingleJobPage, CreateJob, loader as createJobLoader } from "./pages";
// import { useQueryClient } from "react-query";
// import { PageLayout } from "../../common/layout";

const Index = () => {
  // const queryClient = useQueryClient();
  return (
    <Routes>
      <Route path="/candidates/:id" element={<Candidates />} />
      <Route path="/closed/detail/:id" element={<SingleJobPage />} />

      {/* note! each route must be contain this route error to handle error after parent route*/}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Index

export const JobRouteWithSidebar = () => {
  return (
    <Routes>
      <Route
        path="/:id/preview"
        element={<JobDetail status={["PUBLISH", "CLOSED"]} />}
      />
      <Route path="/:id/draft" element={<JobDetail status={["DRAFT"]} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
