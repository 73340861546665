import React from "react"
import { Skeleton } from "antd"
// import { getTitleAccordingToSize, TitleSize } from "../../utils/utils.tsx"
import { BasicContainer } from "./BasicContainer"
import { getTitleAccordingToSize, TitleSize } from "../../utils/utils"
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  titleRef?: React.RefObject<HTMLDivElement | HTMLHeadingElement>
  isLoading?: boolean
  titleSize?: TitleSize
  children?: React.ReactNode | React.ReactElement
  className?: string
  extraTitle?: React.ReactNode | React.ReactElement
  type?: "small" | "default" | "large"
}

export const BasicContainerWithTitle = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      isLoading,
      children,
      titleSize = "h2",
      className,
      extraTitle = undefined,
      type = "default",
      titleRef,
      ...rest
    },
    ref,
  ) => (
    <BasicContainer {...rest} className={className} ref={ref} type={type}>
      {isLoading ? (
        <Skeleton
          active
          loading
          title={false}
          paragraph={{ rows: 1, width: "100%" }}
        />
      ) : extraTitle ? (
        <div className="flex flex-wrap items-center justify-between md:flex-nowrap">
          {getTitleAccordingToSize(titleSize, title, titleRef)}
          {extraTitle}
        </div>
      ) : (
        getTitleAccordingToSize(titleSize, title)
      )}
      {children}
    </BasicContainer>
  ),
)
