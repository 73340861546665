import React from "react"
import { useParams } from "react-router-dom"
import { useDetailMyTemplateLibrary } from "../hooks/useMyTemplateLibrary"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../common/layout"
import TLDetailContent from "../../templates-library/components/TLDetailContent"
import JTDetailRightContent from "../components/JTDetailRightContent"

interface DetailJobTemplateProps {}

const DetailJobTemplate: React.FC<DetailJobTemplateProps> = ({}) => {
  const { id } = useParams()
  const { templateDetailResponse } = useDetailMyTemplateLibrary(id ?? "")

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      path: "/jobs/templates-library",
      title: "Template Library",
    },
    {
      title: templateDetailResponse?.data?.name ?? "Unknown",
    },
  ]

  return (
    <PageContent
      header={
        <PageHeader
          title={templateDetailResponse?.data?.name ?? "Unavailable"}
          sector={templateDetailResponse?.data?.sector ?? "Unavailable"}
          breadcrumbItems={items}
          showBack={true}
          backLink="/jobs/templates-library"
        />
      }
      content={
        <TLDetailContent templateDetailResponse={templateDetailResponse} />
      }
      contentRightSidebar={
        <JTDetailRightContent templateDetailResponse={templateDetailResponse} />
      }
    />
  )
}

export default DetailJobTemplate
