import { Button } from "antd"
import React from "react"
import {
  EnvironmentOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  MailOutlined,
  LinkOutlined,
} from "@ant-design/icons"
import {
  Avatar1,
  // LpMeetGreenCV
} from "../../../../assets/images"
import { SkillCompetencies } from "../../../talent-finder/components/commons/SkillCompetencies"
import {
  applicantSampleData,
  talentData,
} from "../../constants/landing-page-contants"
import { withAuthentication } from "../../../../common/components/hocs/withAuthentication"

const AuthenticatedButton = withAuthentication(Button)

const MeetGreenCV = () => {
  return (
    <section className=" bg-secondaryBlue text-backdrop py-[70px]  space-y-6 md:px-60  px-3">
      <div className="text-center">
        <p className="uppercase text-center tracking-widest text-xs leading text-secondary">
          Standardized Green CV
        </p>
        <h2 className="md:text-4xl text-3xl font-semibold mt-2">
          Forget about PDFs. Meet Green CV!
        </h2>
        <p className="text-xl md:px-36 mt-4">
          Compare like-to-like when screening standardised format CVs, reduce
          bias, and save time by knowing where to look for specific information
          on candidates.
        </p>
      </div>
      <div className="bg-backdrop px-6 space-y-[30px] py-4 text-start text-white">
        <div className="grid grid-cols-5 gap-5">
          <div className="col-span-full md:col-span-3 flex flex-wrap md:flex-nowrap md:justify-start justify-center  py-[30px] px-[17px] gap-4">
            <img
              src={Avatar1}
              className="object-contain w-[114px] h-[114px] rounded-full"
            />
            <div className="space-y-2">
              <p className="text-2xl font-semibold leading-7">wartortle</p>
              <div className="flex space-x-2">
                <EnvironmentOutlined />
                <p className="font-normal text-[14px]">Singapore</p>
              </div>
              <p>
                Aspiring sustainability architect, currently upskilling to
                transitoin to Green Economy roles
              </p>
              <div className="flex gap-x-6 items-center">
                <MailOutlined className="text-xl" />
                <LinkedinOutlined className="text-xl" />
                <TwitterOutlined className="text-xl" />
                <LinkOutlined className="text-xl" />
              </div>
            </div>
          </div>
          <div className="col-span-full md:col-span-2 basic-card text-backdrop bg-custom-white">
            <p className="font-semibold text-lg leading-7">
              Green Jobs I am interested in
            </p>
            <div className="space-y-4 mt-4">
              <p>Environment and Social Governance </p>
              <p>Sustainability Manager</p>
              <p>Sustainability Consultant</p>
            </div>
          </div>
        </div>
        <p className="leading-5">
          As fairly experienced in Sustainability professional, including
          managerial position that allowed me to created singlehandedly and
          entire new department, I believe that my strengths will bring
          considerable value in my new challenge .{" "}
        </p>
        <div className="flex gap-x-4 ">
          <AuthenticatedButton className="bg-primary text-white border-none hover:!text-white hover:scale-105 transition-all duration-300">
            Shortlist
          </AuthenticatedButton>
          <AuthenticatedButton className="bg-white text-danger border-none hover:!text-danger hover:scale-105 transition-all duration-300 ">
            Disqualify
          </AuthenticatedButton>
        </div>
      </div>

      <SkillCompetencies
        isAnonymised
        data={talentData}
        showCert={false}
        candidate={applicantSampleData}
      />
      {/* <div className="w-full flex justify-center">
        <Button className="bg-primary text-white ">Get Started Now</Button>
      </div> */}
    </section>
  )
}

export default MeetGreenCV
