import React from "react"
import { Tag } from "antd"

export interface TLTechnicalSkillProficiencyProps {
  item: any
}

const TLTechnicalSkillProficiency: React.FC<
  TLTechnicalSkillProficiencyProps
> = ({ item }) => {
  return (
    <div className="flex items-center -gap-2">
      <Tag color="" className="border border-backdrop !bg-white">
        {" "}
        {item?.level}
      </Tag>
      <p className="m-0 p-0 leading-6"> {item?.name}</p>
    </div>
  )
}

export default TLTechnicalSkillProficiency
