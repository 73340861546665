import React, { useMemo } from "react"
import { Image, Layout, Menu, Tag } from "antd"
import { GiftOutlined, RightCircleOutlined } from "@ant-design/icons"
import { isMobile } from "react-device-detect"
import { AuthenticatedTemplate } from "@azure/msal-react"
import { GreenSkillsLogoLoggedIn } from "../../assets/images"
import { useNavigate } from "react-router-dom"
import { returnSidebarAuthenticatedMenuItems } from "./menuItem"

const { Sider } = Layout

interface CustomSiderProps {
  openSidebar?: boolean
  handleOpenSidebar: (isOpenSidebar: boolean) => void
}

export const CustomSider: React.FC<CustomSiderProps> = ({
  openSidebar,
  handleOpenSidebar,
}) => {
  const navigate = useNavigate()
  const { menuItems, activeLink } = useMemo(() => {
    return returnSidebarAuthenticatedMenuItems(location.pathname, false)
  }, [location.pathname])

  return (
    <>
      <div className="h-full bg-backdrop">
        <Sider
          width={openSidebar ? 251.2 : 70}
          style={{
            width: openSidebar ? 251.2 : 70,
            backgroundColor: "#36505E",
          }}
          collapsed={!openSidebar || isMobile}
          collapsible
          onCollapse={
            isMobile ? undefined : () => handleOpenSidebar(!openSidebar)
          }
          trigger={null}
          className={`shadow h-screen overflow-y-auto overflow-x-hidden transition-all duration-100 ease-in-out absolute ${
            openSidebar ? "" : "!w-[70px] !max-w-[70px] !min-w-[70px]"
          }`}
        >
          {!isMobile && (
            <div
              onClick={
                isMobile ? undefined : () => handleOpenSidebar(!openSidebar)
              }
              className={` flex text-white mt-4 w-full transition-transform duration-500 ease-in-out ${
                openSidebar ? "justify-end" : "justify-center"
              } `}
            >
              <RightCircleOutlined
                className={`text-xl cursor-pointer ${
                  openSidebar ? "mr-4 rotate-180" : ""
                }`}
              />
            </div>
          )}
          <div className={""}>
            <div
              className={`flex items-center text-primary  mt-3 ${
                openSidebar ? "rounded-xl flex-col" : ""
              }  border-primary border-[0.5px]   ${
                openSidebar ? "" : "justify-center"
              }`}
            >
              <AuthenticatedTemplate>
                <Image
                  className=""
                  src={GreenSkillsLogoLoggedIn}
                  preview={false}
                  width={openSidebar ? 60 : 50}
                />
                {openSidebar && (
                  <Tag className="hidden md:flex !mr-0 rounded-xl bg-secondaryYellow text-brown border-brown border-[0.5px]">
                    <div className="">beta 0.3.1</div>
                  </Tag>
                )}
              </AuthenticatedTemplate>
            </div>
          </div>

          <Menu
            mode="inline"
            className=" !bg-backdrop  h-full !text-white mt-6"
            selectedKeys={activeLink ? [activeLink] : []}
            style={{
              height: "70%",
              borderRight: 0,
              maxWidth: "100%",
            }}
            items={menuItems}
          />
        </Sider>
      </div>
      <div
        className={`absolute bottom-0 w-full flex items-center group  left-0 px-4 py-2 cusror-pointer z-[100] bg-secondaryYellow text-brown ${
          openSidebar ? "" : "justify-center"
        }`}
        onClick={() => navigate("/rewards-and-perks")}
      >
        <GiftOutlined className="mr-2 text-xl transition-all duration-100 group-hover:scale-105" />
        {openSidebar && (
          <div className="transition-all duration-100 whitespace-nowrap group-hover:font-bold">
            {" "}
            Rewards and Perks{" "}
          </div>
        )}
      </div>
    </>
  )
}
