import { useNavigate } from "react-router-dom"
import { useMsal } from "@azure/msal-react"
import {
  loginRequest,
  registerRequest,
  resetPasswordRequest,
} from "../../../authConfig"
import { StringDict } from "../../../common/types/common"

export const useAuth = () => {
  const { instance } = useMsal()
  const navigate = useNavigate()
  // const activeAccount = instance.getActiveAccount();
  const handleLoginRedirect = () => {
    instance
      .loginRedirect(loginRequest)
      .then(() => navigate("/"))

      .catch((error: any) => console.log(error))
  }

  const handleResetPasswordRedirect = () => {
    instance
      .loginPopup(resetPasswordRequest)
      .then(() => navigate("/"))

      .catch((error: any) => console.log(error))
  }

  const handleRegisterRedirect = (extraQueryParameters?: StringDict) => {
    instance
      .loginRedirect(
        extraQueryParameters
          ? {
              ...registerRequest,
              extraQueryParameters,
              redirectUri:
                (process.env.REACT_APP_API ?? "") + "/tenant-detail-form",
            }
          : registerRequest,
      )
      .then(() => navigate("/tenant-detail-form"))

      .catch((error: any) => console.log(error))
  }

  const handleLogoutRedirect = () => {
    instance.logoutRedirect()
  }

  // const handleLoginPopup = () => {
  //   instance
  //     .loginPopup({
  //       ...loginRequest,
  //       redirectUri: 'http://localhost:3000',
  //     })
  //     .catch((error) => console.log(error))
  // }

  // const handleLogoutPopup = () => {
  //   instance.logoutPopup({
  //     mainWindowRedirectUri: '/', // redirects the top level app after logout
  //   })
  // }

  return {
    handleLogoutRedirect,
    handleLoginRedirect,
    handleRegisterRedirect,
    handleResetPasswordRedirect,
  }
}
