import React from "react"

interface SquareRatesProps {
  fill: string
}

const SquareRates: React.FC<SquareRatesProps> = ({ fill }) => {
  return (
    <svg
      width="22"
      height="13"
      viewBox="0 0 22 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.24512"
        y="0.5"
        width="20"
        height="12"
        fill={fill}
        stroke="#36505E"
      />
    </svg>
  )
}

interface SquareRateProps {
  value: number
}

const SquareRate: React.FC<SquareRateProps> = ({ value }) => {
  return (
    <div>
      {[...Array(5)].map((_, i) => (
        <SquareRates key={i} fill={i + 1 <= value ? "#E2EBFA" : "white"} />
      ))}
    </div>
  )
}

export default SquareRate
