import React from "react"
import { PageContent, PageHeader } from "../../../common/layout"
import { Spin } from "antd"
import { HomeOutlined, LoadingOutlined } from "@ant-design/icons"
import { Invoices } from "../components/planBilling/Invoices"
import { Plan } from "../components/planBilling/Plan"
import { PaymentMethod } from "../components/planBilling/PaymentMethod"
import { BillingInformation } from "../components/planBilling/BillingInformation"
import { useInvoices } from "../hooks/biliingPlan/useInvoices"
import { useCustomer } from "../hooks/biliingPlan/useCustomer"
import { usePaymentMethods } from "../hooks/biliingPlan/usePaymentMethods"
import { useSubscription } from "../hooks/biliingPlan/useSubscription"
import { usePlan } from "../hooks/biliingPlan/usePlan"

export const PlanBilling = () => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Settings",
    },
    {
      title: "Company Profile",
    },
  ]

  const invoices = useInvoices()
  const customer = useCustomer()
  const paymentMethods = usePaymentMethods()
  const subscription = useSubscription()
  const plans = usePlan()

  return (
    <PageContent
      header={
        <PageHeader
          title={"Plan and Billing"}
          breadcrumbItems={items}
        ></PageHeader>
      }
      content={
        customer.isLoading || invoices.isLoading || subscription.isLoading ? (
          <div className=" flex mt-10 justify-center">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : (
          <div className="">
            <div className=" flex gap-5 mb-5">
              <div className=" w-72 h-auto">
                <Plan
                  data={subscription.data}
                  hasDefaultPayment={
                    !!customer.data?.invoice_settings?.default_payment_method
                      ?.id
                  }
                  plans={plans}
                  cancel={subscription.cancel}
                  update={subscription.update}
                />
              </div>
              <div className="w-[calc(100%-1.25rem-18rem)] grid grid-cols-2 gap-5">
                <PaymentMethod
                  data={paymentMethods?.data?.data}
                  add={paymentMethods?.add}
                  remove={paymentMethods.remove}
                  defaultPayment={
                    customer.data?.invoice_settings?.default_payment_method?.id
                  }
                  makeDefault={paymentMethods.makeDefault}
                />
                <BillingInformation
                  data={customer.data}
                  update={customer.updateCustomer}
                />
              </div>
            </div>
            <Invoices
              data={invoices.data?.data}
              isLoading={invoices.isLoading}
            />
          </div>
        )
      }
    />
  )
}
