import { useInfiniteQuery } from "react-query"
import { message } from "antd"
import { useAxiosWithMsal } from "./useAxiosWithMsal"
import { ApiResponsePaginatedInfinite } from "../types/ApiResponse"
import { Item } from "./useReferences"
import { useCallback } from "react"

interface CountriesInfinity {
  pageParam: number
  search: string
}

export const useCountriesInfinity = (searchParam: string) => {
  const handleFetchError = (error: any) => {
    message.error(`An error occurred while fetching countries: ${error?.data}`)
  }
  const getAxiosInstance = useAxiosWithMsal()

  const fetchCountriesInfinity = useCallback(
    async ({ pageParam = 1, search }: CountriesInfinity) => {
      const api = await getAxiosInstance()
      return api.get<ApiResponsePaginatedInfinite<Item>>(
        "/references/countries",
        {
          params: {
            page: pageParam,
            limit: 10,
            name: search,
          },
        },
      )
    },
    [getAxiosInstance],
  )
  return useInfiniteQuery(
    ["countries-infinity", searchParam],
    ({ pageParam }) =>
      fetchCountriesInfinity({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    },
  )
}
