import { useCallback, useEffect, useRef, useState } from "react"
import {
  useFetchDetailTemplateLibrary,
  useFetchTemplateLibraryList,
  usePostSaveTemplateLibrary,
  useUnSaveTemplateLibrary,
} from "../apis/rest"
import { debounce } from "../../../utils/debounce"
import { TemplateLibraryResponseType } from "../types/response"

export const useListTemplateLibrary = () => {
  const [dataList, setDataList] = useState<TemplateLibraryResponseType[]>([])
  const [name, setName] = useState<string | undefined>(undefined)
  const [limit, setLimit] = useState<number | undefined>(21)
  const [page, setPage] = useState<number | undefined>(1)
  const [myTmpl, setMyTmpl] = useState<number | undefined>(0)
  const [selectedSector, setSelectedSector] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [noMoreData, setNoMoreData] = useState<string | undefined>(undefined)

  const templateLibraryListResponse = useFetchTemplateLibraryList({
    name,
    sectors_id: selectedSector?.map((item) => item.id).join(","),
    limit,
    my_tmpl: myTmpl,
    page,
  })

  useEffect(() => {
    if (!templateLibraryListResponse.isLoading) {
      if (page === 1) {
        setDataList(templateLibraryListResponse.data?.data?.data?.list ?? [])
        setTotal(templateLibraryListResponse.data?.data?.data?.total_data ?? 0)
      } else {
        setDataList((prev) => [
          ...prev,
          ...(templateLibraryListResponse.data?.data?.data?.list ?? []),
        ])
        setTotal(templateLibraryListResponse.data?.data?.data?.total_data ?? 0)
      }
    }
    //
  }, [templateLibraryListResponse.data?.data?.data?.list])

  const handleSearch = debounce((e: any) => {
    setName(e.target.value)
    setPage(1)
  }, 1000)

  const handleFilterMyTemplate = (e: any) => {
    setMyTmpl(e ? 1 : 0)
    setPage(1)
  }

  const handleSelectSector = (item: string) => {
    if (selectedSector.includes(item)) {
      setSelectedSector(
        selectedSector.filter((selectedItem) => selectedItem !== item),
      )
    } else {
      setSelectedSector([...selectedSector, item])
    }
  }

  const handleRemoveSelectedSector = (id: string) => {
    const updatedSelectedSector = selectedSector.filter(
      (selectedSector) => selectedSector?.id !== id,
    )
    setSelectedSector(updatedSelectedSector)
  }

  const saveTemplateLibraryMutation = usePostSaveTemplateLibrary()
  const unSaveTemplateLibraryMutation = useUnSaveTemplateLibrary()

  const handleSaveJob = async (e: any, id: string) => {
    e.stopPropagation()
    try {
      await saveTemplateLibraryMutation.mutateAsync({ job_id: id })
    } catch (error: any) {
      console.error("An error occurred:", error?.response?.data?.data)
    }
  }
  const handleUnSaveJob = async (e: any, id: string) => {
    e.stopPropagation()
    try {
      await unSaveTemplateLibraryMutation.mutateAsync({ job_id: id })
    } catch (error: any) {
      console.error("An error occurred:", error?.response?.data?.data)
    }
  }

  const observer = useRef<IntersectionObserver | null>(null)
  const lastCardElementRef = useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          dataList.length &&
          dataList?.length < total &&
          !templateLibraryListResponse.isLoading
        ) {
          setPage((prev) => prev! + 1)
        } else {
          setNoMoreData("No more data to load.")
        }
      })
      if (node) observer.current.observe(node)
    },
    //
    [dataList?.length],
  )

  useEffect(() => {
    if (!templateLibraryListResponse.isLoading) {
      if (page === 1) {
        setTotal(templateLibraryListResponse?.data?.data?.data?.total_data ?? 0)
      }
    }
    //
  }, [templateLibraryListResponse])

  return {
    templateResponse: {
      data: dataList,
      isLoading: templateLibraryListResponse.isLoading,
      noMoreData,
    },
    queryTemplateResponse: {
      handleSearch,
      setLimit,
      limit,
      page,
      setPage,
      myTmpl,
      handleFilterMyTemplate,
      handleSelectSector,
      selectedSector,
      handleRemoveSelectedSector,
      handleSaveJob,
      handleUnSaveJob,
      lastCardElementRef,
    },
  }
}
export const useDetailTemplateLibrary = (templateID: string) => {
  const templateLibraryDetailResponse =
    useFetchDetailTemplateLibrary(templateID)

  const saveTemplateLibraryMutation = usePostSaveTemplateLibrary()
  const unSaveTemplateLibraryMutation = useUnSaveTemplateLibrary()

  const handleSaveJob = async (e: any, id: string) => {
    e.stopPropagation()
    try {
      await saveTemplateLibraryMutation.mutateAsync({ job_id: id })
    } catch (error: any) {
      console.error("An error occurred:", error?.response?.data?.data)
    }
  }
  const handleUnSaveJob = async (e: any, id: string) => {
    e.stopPropagation()
    try {
      await unSaveTemplateLibraryMutation.mutateAsync({ job_id: id })
    } catch (error: any) {
      console.error("An error occurred:", error?.response?.data?.data)
    }
  }

  return {
    templateDetailResponse: {
      data: templateLibraryDetailResponse?.data?.data,
      isLoading: templateLibraryDetailResponse.isLoading,
      handleSaveJob,
      handleUnSaveJob,
    },
  }
}
