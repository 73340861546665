import { useQuery, UseQueryResult } from "react-query"
import { useAxiosWithMsal } from "../../../common/hooks"
import { message } from "antd"
import { AxiosError } from "../../../common/errors"
import { AxiosInstance } from "axios"
import { ApiResponse } from "../../../common/types/ApiResponse"
import { ResponseDashboardAPIType } from "../types/responseDashboard"

interface GetDashboardApiResponse extends ApiResponse {
  data: { data: ResponseDashboardAPIType }
}

export const useGetDashboard = (): UseQueryResult<
  GetDashboardApiResponse,
  AxiosError
> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getDashboard = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<GetDashboardApiResponse>("/dashboard")
    } catch (error) {
      message.error("An error occurred while fetching dashboard.")
    }
  }
  return useQuery("dashboard", getDashboard, {
    onError: (error: any) => {
      message.error(
        "An error occurred while fetching dashboard: " + error.message,
      )
    },
  })
}
