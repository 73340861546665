import React from "react"
import { useMsalAuthentication } from "@azure/msal-react"
import { loginRequest } from "../../authConfig"
import { InteractionType } from "@azure/msal-browser"
import { LoadingOutlined } from "@ant-design/icons"

export const Login: React.FC = () => {
  useMsalAuthentication(InteractionType.Redirect, loginRequest)

  return (
    <div className="h-screen bg-secondaryBlue flex flex-col gap-4 justify-center w-full items-center">
      <LoadingOutlined className="text-primary text-5xl" />
      Taking you to Sign in...
    </div>
  )
}
