import { createContext } from "react"

export interface Currency {
  logo: string
  country: string
  currency_code: string
  id: string
}

// Buat context dengan menggunakan tipe data yang sesuai dengan interface yang Anda definisikan
export const CurrenciesContext = createContext<Currency[] | undefined | null>(
  null,
)
