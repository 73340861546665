import React, { ReactNode } from "react"
import { Breadcrumbs } from "../components"
import { Skeleton, Typography, Avatar } from "antd"
import { avatarImgs } from "../../assets/avatar_images"
import { useNavigate } from "react-router-dom"

interface BreadcrumbItem {
  path?: string
  title?: string | React.ReactElement
}
interface PageHeaderProps {
  breadcrumbItems?: BreadcrumbItem[]
  title?: string | ReactNode
  sector?: string | ReactNode
  isLoading?: boolean
  isError?: boolean
  subtitle?: string
  isHeaderForAccountInfo?: boolean
  children?: ReactNode
  showBack?: boolean
  backLink?: string
  rightComponent?: React.ReactNode | React.ReactElement
  needRightComponent?: boolean
}
interface PageHeaderWrapperProps {
  children?: ReactNode
}
export const PageHeaderWrapper: React.FC<PageHeaderWrapperProps> = ({
  children,
}) => <div className="bg-white pt-4 pb-2 px-6 w-full">{children}</div>
export const PageHeader: React.FC<PageHeaderProps> = ({
  breadcrumbItems,
  title,
  sector,
  isLoading,
  isHeaderForAccountInfo,
  children,
  showBack,
  backLink,
  needRightComponent,
  rightComponent,
}) => {
  if (isLoading || isHeaderForAccountInfo) {
    return (
      <PageHeaderWrapper>
        <Skeleton active avatar />
      </PageHeaderWrapper>
    )
  }
  if (isHeaderForAccountInfo) {
    return (
      <PageHeaderWrapper>
        <Breadcrumbs items={breadcrumbItems} />
        <div className="flex items-center justify-start mt-4 gap-3">
          {
            <Avatar
              src={avatarImgs[0]}
              size={58}
              className=""
              style={{ border: "1px solid #D4F5C5" }}
            />
          }
          <div className="flex flex-col">
            {
              <Typography.Title>
                {title ?? "Hello, Recruiter!"}
              </Typography.Title>
            }
          </div>
        </div>
        {children}
      </PageHeaderWrapper>
    )
  }

  const navigate = useNavigate()

  return (
    <PageHeaderWrapper>
      <Breadcrumbs items={breadcrumbItems} />

      {showBack && (
        <div className="mt-3">
          <p
            onClick={() => (backLink ? navigate(backLink) : navigate(-1))}
            className="text-secondary w- cursor-pointer transition-transform transform hover:scale-[101%]"
          >
            {"<<"} back
          </p>
        </div>
      )}

      <div
        className={`mt-3 ${
          needRightComponent ? "flex items-center justify-between" : ""
        }`}
      >
        <div>
          {sector && <p className="golden-subtitle">{sector}</p>}
          {title && <Typography.Title>{title}</Typography.Title>}
        </div>
        <div>{needRightComponent && rightComponent}</div>
      </div>
      {children}
    </PageHeaderWrapper>
  )
}
