import React, { useEffect, useRef, useState } from "react"
import { useMsal } from "@azure/msal-react"
import { Layout, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { CustomFooter, CustomHeader, CustomSider } from "./index"
import { useUser } from "../hooks"
import {
  TenantContext,
  UserContext,
  CurrenciesContext,
} from "../../context/user"
import { useGetTenants } from "../hooks/useTenant"
import { isMobile } from "react-device-detect"
import { useLocation } from "react-router-dom"
import { useSubscription } from "../../modules/settings/hooks/biliingPlan/useSubscription"
import TourProvider from "../components/tour/TourProvider"
import FloatingButtons from "../components/FloatButton"
import { useCurrencies } from "../hooks/useCurrencies"

const { Content } = Layout

export const PageLayout: React.FC<{
  children: React.ReactElement
  isSidebar?: boolean
}> = ({ isSidebar, children }) => {
  const [openSidebar, setOpenSidebar] = useState(true)

  const { inProgress } = useMsal()
  const { user } = useUser()
  const { data } = useGetTenants()
  const currencies = useCurrencies()
  const contentDivRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentDivRef?.current?.scrollTop) {
      contentDivRef.current.scrollTop = 0
    }
  }, [location])

  const { pathname } = useLocation()
  const subscription = useSubscription()

  if (inProgress !== "none" && inProgress !== "acquireToken") {
    return (
      <div className="h-screen w-screen bg-slate-200 flex justify-center items-center absolute z-50">
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 24,
              }}
              spin
            />
          }
        />
      </div>
    )
  }

  return (
    <UserContext.Provider value={user?.data?.data?.data}>
      <TenantContext.Provider value={data?.data?.data}>
        <CurrenciesContext.Provider value={currencies?.data?.data?.data}>
          <TourProvider>
            <Layout>
              <FloatingButtons />
              <CustomHeader isOpenSidebar={openSidebar} isSidebar={isSidebar} />
              <Layout className="h-screen overflow-hidden">
                {isSidebar && (
                  <div className="fixed">
                    <CustomSider
                      openSidebar={openSidebar}
                      handleOpenSidebar={() => setOpenSidebar(!openSidebar)}
                    />
                  </div>
                )}
                <Layout
                  className={`h-full relative !bg-[#F0F2F5]  ${
                    !isSidebar
                      ? "ml-0"
                      : openSidebar
                        ? `${isMobile ? "ml-[80px]" : "ml-[15.7rem]"} `
                        : "ml-[4.2rem]"
                  } `}
                >
                  <Content
                    ref={contentDivRef}
                    className="h-full overflow-auto flex flex-col justify-between !bg-[#F0F2F5]"
                    style={{
                      margin: 0,
                      marginTop: "65px",
                    }}
                  >
                    <div className="relative">
                      <div>{children}</div>
                      {subscription.data?.subscription_status === "canceled" &&
                        !pathname.startsWith("/settings") && (
                          <div className=" absolute h-full w-full top-0 left-0 bg-slate-200 bg-opacity-50">
                            <div
                              className={`fixed top-1/2 ${
                                openSidebar ? "left-[60%]" : "left-1/2"
                              } transform -translate-x-1/2 -translate-y-1/2`}
                            >
                              <div className=" w-96 p-3 shadow-md rounded-md bg-primary text-white font-semibold">
                                You have no active plan. Contact your Admin to
                                select a plan to continue using Skilledin
                                Recruiter
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="mt-5">
                      <CustomFooter />
                    </div>
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          </TourProvider>
        </CurrenciesContext.Provider>
      </TenantContext.Provider>
    </UserContext.Provider>
  )
}

PageLayout.defaultProps = {
  isSidebar: true,
}
