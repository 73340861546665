import React, { useEffect } from "react"
import { NumberedSteps } from "../../../common/components"
import { Button, Dropdown, Input, Modal, Result, message } from "antd"
import { useMakeCustomer } from "../hooks/useMakeCustomer"
import { Controller, useForm } from "react-hook-form"
import {
  PlusOutlined,
  CreditCardOutlined,
  LoadingOutlined,
} from "@ant-design/icons"
import { CardElement } from "@stripe/react-stripe-js"
import { usePaymentMethods } from "../../settings/hooks/biliingPlan/usePaymentMethods"
import { useElements, useStripe } from "@stripe/react-stripe-js"
import { useSubscriptions } from "../hooks/useSubscriptions"
import { LayoutForStep4 } from "../../auth/LayoutForStep4"
import { usePromotionCode } from "../hooks/usePromotionCode"
import { formatNumber } from "../../../helper/formatNumber"

export const PayForSubscription = () => {
  const elements = useElements()
  const stripe = useStripe()
  const [isOpen, setIsOpen] = React.useState(false)
  const [isLoadingAdd, setIsLoadingAdd] = React.useState(false)
  // get selectedPlan from localstorage
  const selectedPlan = JSON.parse(localStorage.getItem("selectedPlan") ?? "{}")
  const customer = useMakeCustomer()
  const paymentMethod = usePaymentMethods()
  const subscription = useSubscriptions()

  const addAction = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    message.open({
      key: "adding",
      content: "adding new card...",
      type: "loading",
    })
    setIsLoadingAdd(true)

    const result = await stripe.createPaymentMethod({
      //`Elements` instance that was used to create the Payment Element
      elements,
    })
    if (result.error) {
      setIsLoadingAdd(false)
      message.open({
        key: "add",
        content: result.error.message,
        type: "error",
      })
      // Show error to your customer (for example, payment details incomplete)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      paymentMethod.add.handleAdd(
        result.paymentMethod?.id,
        function () {
          paymentMethod.makeDefault
            .handleMakeDefault(result.paymentMethod?.id)
            .then(() => {
              setIsOpen(false)
              elements.getElement("card")?.clear()
              setIsLoadingAdd(false)
              message.destroy("adding")
            })
        },
        function (error: any) {
          setIsOpen(false)
          elements.getElement("card")?.clear()
          setIsLoadingAdd(false)
          message.open({ key: "add", content: error, type: "error" })
        },
      )
    }
  }

  const {
    handleSubmit,
    control,
    // reset,
    setValue,
  } = useForm()
  useEffect(() => {
    if (customer.data) {
      // reset({
      //   name: customer.data.name,
      //   email: customer.data.email,
      // })
      setValue("name", customer.data.name)
      setValue("email", customer.data.email)
    }
  }, [customer.data])

  const makeSubAndUpdateCust = async (val: any) => {
    await customer.updateCustomer.handleUpdate(val)
    await subscription.makeTenantSubscription.mutateAsync({
      stripe_coupon_id: state?.error ? "" : val.coupon,
      stripe_price_id: selectedPlan.stripe_price_id,
      stripe_product_id: selectedPlan.stripe_product_id,
      is_trial: true,
      currency: selectedPlan.currency,
    })
  }

  const { getPromotionCode } = usePromotionCode()
  const [state, setState] = React.useState<
    { error: boolean; data: any; message: string; code: number } | undefined
  >()

  return (
    <LayoutForStep4
      topBarChildren={
        <div className="px-20">
          <NumberedSteps
            currentStepIndex={3}
            steps={[
              "Select Plan",
              "Details",
              "Company Settings",
              "Payment Details",
            ]}
            className="mt-[40px]"
          />
        </div>
      }
    >
      <div className="px-20">
        {customer.isLoading ? (
          <div className="h-80 flex justify-center items-center">
            <LoadingOutlined className=" text-4xl" />
          </div>
        ) : customer.data ? (
          <div className=" w-[85%] my-20 rounded-xl shadow-md p-5 bg-[#FAFAFA]">
            <div className="rounded-xl shadow-md p-5 bg-secondaryYellow">
              <h3>Selected Plan</h3>
              <div className="flex gap-5 mb-5">
                <img
                  src={selectedPlan?.image}
                  className="w-[44px] h-[48px] object-cover"
                  alt="plan"
                />
                <div className="">
                  <div className=" first-letter:capitalize mb-1.5 font-semibold">
                    {`${selectedPlan?.name} ${
                      selectedPlan?.prices?.find(
                        (item: any) =>
                          item.stripe_price_id === selectedPlan.stripe_price_id,
                      ).recurring_interval === "month"
                        ? "(monthly)"
                        : "(yearly)"
                    }`}
                  </div>
                  <div className="">{`${selectedPlan?.prices
                    ?.find(
                      (item: any) =>
                        item.stripe_price_id === selectedPlan.stripe_price_id,
                    )
                    .currency.toUpperCase()} ${formatNumber(
                    selectedPlan?.prices?.find(
                      (item: any) =>
                        item.stripe_price_id === selectedPlan.stripe_price_id,
                    ).price,
                  )} / ${selectedPlan?.prices?.find(
                    (item: any) =>
                      item.stripe_price_id === selectedPlan.stripe_price_id,
                  ).recurring_interval}`}</div>
                </div>
              </div>
              <div className="flex gap-4 items-center py-1">
                <label htmlFor="coupon" className="w-32">
                  Coupon Code
                </label>
                <Controller
                  name="coupon"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onBlur={(e) => {
                        if (e.target.value) {
                          getPromotionCode
                            .mutateAsync(e.target.value)
                            .then((res) => setState(res.data))
                            .catch((err) => {
                              setState(err?.response?.data)
                            })
                        } else {
                          setState(undefined)
                        }
                      }}
                      id="coupon"
                      className="my-1 font-default rounded"
                      placeholder="enter coupon code"
                    />
                  )}
                />
              </div>
              {state ? (
                state?.error ? (
                  <div className=" first-letter:capitalize">{state.data}</div>
                ) : (
                  <div>{`${state?.data?.percent_off}% off for ${
                    state?.data?.duration_in_months
                      ? state?.data?.duration_in_months + " months"
                      : state?.data?.duration
                  }  applied`}</div>
                )
              ) : null}
            </div>

            <div className="rounded-xl shadow-md bg-white p-5 mt-5">
              <h3>Payment Method</h3>
              <div>
                {paymentMethod.data && paymentMethod.data.total_count === 0 ? (
                  <div
                    className=" cursor-pointer "
                    onClick={() => setIsOpen(true)}
                  >
                    {" "}
                    <PlusOutlined /> Add payment method
                  </div>
                ) : (
                  paymentMethod.data?.data.map((item, index) => (
                    <div
                      key={index}
                      className=" flex justify-between items-center"
                    >
                      <div className=" flex gap-5">
                        <CreditCardOutlined />
                        <div className=" first-letter:capitalize">{`${item.card.brand} ending in ${item.card.last4}`}</div>
                      </div>
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: "1",
                              label: (
                                <div
                                  onClick={() => {
                                    paymentMethod.remove.handleRemove(item.id)
                                  }}
                                >
                                  Delete
                                </div>
                              ),
                            },
                          ],
                        }}
                        arrow={{ pointAtCenter: true }}
                        placement="bottomRight"
                      >
                        <strong className=" cursor-pointer">...</strong>
                      </Dropdown>
                    </div>
                  ))
                )}
                <Modal
                  title="Add payment method"
                  centered
                  open={isOpen}
                  onCancel={() => setIsOpen(false)}
                  onOk={addAction}
                  okButtonProps={{ disabled: isLoadingAdd }}
                >
                  <div
                    className="p-2 rounded-md"
                    style={{ border: "0.5px solid #d9d9d9" }}
                  >
                    <CardElement options={{ hidePostalCode: true }} />
                  </div>
                </Modal>
              </div>
            </div>
            <div className="rounded-xl shadow-md bg-white p-5 mt-5">
              <h3>Billing Information</h3>
              <div>
                <div className="flex gap-4 items-center py-1">
                  <label htmlFor="name" className="w-16">
                    Name
                  </label>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        id="name"
                        className="my-1 font-default rounded"
                        placeholder="john doe"
                      />
                    )}
                  />
                </div>
                <div className="flex gap-4 items-center py-1">
                  <label htmlFor="email" className="w-16">
                    Email
                  </label>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        id="email"
                        className="my-1 font-default rounded"
                        placeholder="johndoe@example.com"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className=" text-end mt-5">
              <Button
                type="primary"
                onClick={handleSubmit(makeSubAndUpdateCust)}
                disabled={
                  paymentMethod.data && paymentMethod.data.total_count === 0
                }
              >
                Finish Setup
              </Button>
            </div>
          </div>
        ) : (
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
          />
        )}
      </div>
    </LayoutForStep4>
  )
}
