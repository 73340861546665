import React from "react"
import { Route, Routes } from "react-router-dom"
import { NotFound } from "../../common/components"

const ListTemplatesLibrary = React.lazy(() =>
  import("./pages/ListTemplatesLibrary").then((module) => ({
    default: module.default,
  })),
)
const DetailTemplateLibrary = React.lazy(() =>
  import("./pages/DetailTemplateLibrary").then((module) => ({
    default: module.default,
  })),
)

interface IndexProps {}

const Index: React.FC<IndexProps> = ({}) => {
  return (
    <Routes>
      <Route path="/" element={<ListTemplatesLibrary />} />
      <Route path="/detail/:id" element={<DetailTemplateLibrary />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Index
