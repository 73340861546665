import React, { useRef, useCallback } from "react"
import { CardCandidate } from "./Card"
import { Candidate } from "../../hooks/useCandidates"
import { Empty } from "antd"
import { useGetStages } from "../../hooks/useManageJobStages"
import { tagOrder } from "../../utils"
import { Moon } from "../../../../assets/images"

interface CardListProps {
  data: Candidate[] | undefined
  handleNext: () => void
  handleOpen: (id: string) => void

  isDisqualified: boolean
}

export const CardList: React.FC<CardListProps> = ({
  data,
  handleNext,
  handleOpen,
  isDisqualified,
}) => {
  const stagesQuery = useGetStages()
  const stagesData = stagesQuery?.data?.data?.data
  const observer = useRef<IntersectionObserver | null>(null)
  const lastCardElementRef = useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && data?.length && data?.length < 50) {
          handleNext()
        }
      })
      if (node) observer.current.observe(node)
    },
    [data?.length],
  )
  return (
    <div
      className={`py-5 flex ${
        (data?.length ?? 0) % 3 ? "justify-normal gap-10" : "justify-between"
      } flex-wrap`}
    >
      {data ? (
        data?.map((v, i) => {
          if (data?.length === i + 1) {
            return (
              <CardCandidate
                key={i}
                isDisqualified={isDisqualified}
                handleOpen={handleOpen}
                data={v}
                observ={lastCardElementRef}
                stages={stagesData?.sort(
                  (a, b) => tagOrder.indexOf(a.tag) - tagOrder.indexOf(b.tag),
                )}
              />
            )
          }
          return (
            <CardCandidate
              key={i}
              isDisqualified={isDisqualified}
              handleOpen={handleOpen}
              data={v}
              stages={stagesData?.sort(
                (a, b) => tagOrder.indexOf(a.tag) - tagOrder.indexOf(b.tag),
              )}
            />
          )
        })
      ) : (
        <Empty
          className="mx-auto flex gap-4 flex-col-reverse"
          image={Moon}
          description="You have no current candidates for this job."
        />
      )}
    </div>
  )
}
