import React from "react"
import { Button, Drawer, Input, Radio } from "antd"
import { Controller } from "react-hook-form"
import { DrawerProps } from "antd"
import { useEditUserDrawer } from "../hooks/useEditUserDrawer"
import { GetUser } from "../hooks/useAdministrateUsers"
import { InfiniteAutoComplete } from "../../../common/components"
import { useCountriesInfinity } from "../../../common/hooks"

interface EditUserDrawerProps extends DrawerProps {
  open: boolean
  onClose: () => void
  user: GetUser
  onlyOneAdmin: boolean
}

export const EditUserDrawer: React.FC<EditUserDrawerProps> = ({
  open,
  onClose,
  user,
  onlyOneAdmin,
  ...inputProps
}) => {
  const { handleSubmit, control, errors, onSaveSubmit } = useEditUserDrawer({
    onClose,
    user,
  })

  return (
    <Drawer
      {...inputProps}
      closable={false}
      placement="right"
      size="large"
      onClose={onClose}
      open={open}
    >
      <form onSubmit={handleSubmit(onSaveSubmit)}>
        <p className="font-bold text-xl text-backdrop mb-5">User User</p>
        <div className="mb-5 space-y-6">
          <div className="flex items-center gap-4">
            <div className="flex flex-col items-center-justify-start gap-2 w-1/2">
              <div className="">First Name</div>
              <Controller
                control={control}
                name="first_name"
                render={({ field }) => (
                  <Input placeholder="First Name" {...field} />
                )}
              />
              {errors.first_name && (
                <p className="text-red-500">{errors.first_name.message}</p>
              )}
            </div>
            <div className="flex flex-col items-center-justify-start gap-2 w-1/2">
              <div className="">Last Name</div>
              <Controller
                control={control}
                name="last_name"
                render={({ field }) => (
                  <Input placeholder="Last Name" {...field} />
                )}
              />
              {errors.last_name && (
                <p className="text-red-500">{errors.last_name.message}</p>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center-justify-start gap-2">
            <div className="">Work Email</div>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <Input placeholder="Work Email" {...field} />
              )}
            />
            {errors.email && (
              <p className="text-red-500">{errors.email.message}</p>
            )}
          </div>
          <div className="flex flex-col items-center-justify-start gap-2">
            <div className="">Display Name</div>
            <Controller
              control={control}
              name="display_name"
              render={({ field }) => (
                <Input placeholder="Display Name" {...field} />
              )}
            />
            {errors.display_name && (
              <p className="text-red-500">{errors.display_name.message}</p>
            )}
          </div>
          <div className="flex flex-col items-center-justify-start gap-2">
            <div className="">Country</div>
            <Controller
              name="country"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InfiniteAutoComplete
                  {...field}
                  className="!w-full"
                  placeholder="Location"
                  useDataHook={useCountriesInfinity}
                  selectedValue={field.value}
                  onSelect={(value, option) => {
                    field.onChange(option)
                  }}
                />
              )}
            />
            {/* {errors?.country && (
              <p className="text-red-500">{errors?.country.message}</p>
            )} */}
          </div>
          <div className="flex flex-col  justify-start gap-2">
            <div>Role</div>
            <Controller
              control={control}
              name="role"
              defaultValue="Recruiter"
              render={({ field }) => (
                <Radio.Group
                  onChange={(e) => field.onChange(e.target.value)}
                  onBlur={field.onBlur}
                  className="flex flex-col gap-3"
                  defaultValue={"Recruiter"}
                  value={field.value}
                >
                  <Radio value="Admin">
                    Admin{" "}
                    {onlyOneAdmin && (
                      <span className="text-danger ml-4">
                        at least 1 admin user is required.
                      </span>
                    )}
                  </Radio>
                  <Radio disabled={onlyOneAdmin} value="Recruiter">
                    Recruiter
                  </Radio>
                </Radio.Group>
              )}
            />

            {errors?.role && (
              <p className="text-red-500">{errors?.role.message}</p>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end gap-3">
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" className="bg-primary" htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    </Drawer>
  )
}
