import {
  useScreeningQuestions,
  type Question,
} from "../../../../../common/hooks/useReferences"
import { SelectedQuestion } from "./SelectedQuestion"
import { useFieldArray, useFormContext } from "react-hook-form"
import { CreateJobSchema } from "../types"
import { Moon } from "../../../../../assets/images"
import { Image } from "antd"
import { QuestionCheckList } from "./QuestionCheckList"
import { TabCompLayout } from "./TabCompLayout"

export function ScreeningQuestionsTab({}: { isLoading?: boolean }) {
  const {
    control,
    formState: {},
    getValues,
  } = useFormContext<CreateJobSchema>()

  const {
    fields: screeningQuestionFields,
    append: appendScreeningQuestions,
    remove: removeScreeningQuestions,
    update: updateScreeningQuestions,
  } = useFieldArray({
    control,
    name: "screening_questions",
    keyName: "id_",
  })

  const handleAddQuestion = (question: Question) => {
    appendScreeningQuestions({ ...question, id: question?.id })
  }

  const handleUpdateQuestion = (
    id: string,
    choice: Question["choices"][0] | Question["choices"],
    index: number,
  ) => {
    if (Array.isArray(choice)) {
      updateScreeningQuestions(index, {
        ...screeningQuestionFields[index],
        choices: [...choice],
      })
    } else {
      const question = screeningQuestionFields[index]
      updateScreeningQuestions(index, {
        ...question,
        choices: question?.choices?.map((c) =>
          c.name === choice.name ? choice : { ...c, disqualify: false },
        ),
      })
    }
  }

  const screeningQuestions = useScreeningQuestions()
  console.log("screeningQuestionFields", screeningQuestionFields)
  return (
    <TabCompLayout
      className=" flex flex-col gap-y-9"
      rightSideClassName="flex flex-col gap-4"
      rightSidebarChildren={
        <div className="flex justify-start gap-4 flex-col items-start basic-card bg-custom-white">
          {screeningQuestions?.data?.data?.data?.map((question) => {
            const isSelected = screeningQuestionFields?.some(
              (q) => q.id === question?.id,
            )
            return (
              <QuestionCheckList
                key={question?.id}
                question={question}
                isSelected={isSelected}
                onClick={() => !isSelected && handleAddQuestion(question)}
              />
            )
          })}
        </div>
      }
    >
      {screeningQuestionFields?.length === 0 && (
        <div className="w-full flex flex-col justify-center basic-card bg-white items-center py-[30px] px-[17px] gap-[27px]">
          <div className="text-center">
            Select (optional) screening questions from the list on the right
          </div>
          <Image
            src={Moon}
            preview={false}
            className="!h-[116px] object-contain mx-auto rounded-full shadow-md"
          />
        </div>
      )}
      {screeningQuestionFields?.map((question, index) => (
        <SelectedQuestion
          key={question?.id}
          question={question}
          country={getValues("location")?.name}
          removeQuestion={() => removeScreeningQuestions(index)}
          updateQuestion={handleUpdateQuestion}
          index={index}
        />
      ))}
    </TabCompLayout>
  )
}
