import React from "react"
import { openInNewTab } from "../../utils/util_funcs"
import dayjs from "dayjs"
import { useMsal } from "@azure/msal-react"
import { useNavigate } from "react-router-dom"

export function CustomFooter() {
  const { accounts } = useMsal()
  const isLogged = accounts.length > 0
  const footerBgColor = isLogged ? "bg-[#F0F2F5] py-6" : "bg-backdrop"
  const footerTextColor = isLogged ? "text-backdrop" : "text-[#F0F2F5]"

  const navigate = useNavigate()

  return (
    <div
      className={` w-full ${
        footerBgColor + " " + footerTextColor
      } px-6 md:px-8 py-5  md:!h-[80px] flex items-center justify-between flex-wrap`}
    >
      <div className={`capitalize text-center leading-6 ${footerTextColor}`}>
        {`COPYRIGHT © ${dayjs().format(
          "YYYY",
        )} SKILLEDIN PTE. LTD. ALL RIGHTS RESERVED.`}
      </div>
      <div className="md:ml-6 flex items-center gap-2 lg:gap-5 lg:flex-nowrap flex-wrap lg:justify-start justify-center">
        <>
          <p
            className={`${footerTextColor} cursor-pointer hover:text-blue-400 whitespace-nowrap `}
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </p>
          <small className={`${footerTextColor}`}>|</small>

          <p
            className={`${footerTextColor} cursor-pointer hover:text-blue-400 whitespace-nowrap `}
            onClick={() => navigate("/term-of-use")}
          >
            Terms of use
          </p>
          <small className={`${footerTextColor}`}>|</small>

          <p
            className={`${footerTextColor} cursor-pointer hover:text-blue-400 whitespace-nowrap  z-[10]`}
            onClick={() =>
              openInNewTab("https://skilledin.io/cookie-policy-eu/")
            }
          >
            Cookie Policy (EU)
          </p>
        </>
      </div>
    </div>
  )
}
