import React from "react"

import { Talent } from "../../../../talent-finder/components/type/database"
import { Applicant, Items } from "../../../inteface/detailApplicant"
import { SkillCompetencies } from "../../../../talent-finder/components/commons/SkillCompetencies"
import { WorkExperience } from "../../../../talent-finder/components/commons/WorkExperience"
import { Educations } from "../../../../talent-finder/components/commons/Educations"

interface CvDrawerProps {
  profile: Talent | undefined
  location: string | undefined
  stage: Items | undefined
  candidate: Applicant | undefined
}

export const TalentInfo: React.FC<CvDrawerProps> = ({
  profile,
  stage,
  candidate,
}) => {
  const menu = [
    {
      key: 1,
      label: "Skills & Competencies",
      active: true,
    },
    {
      key: 2,
      label: "Work Experience",
      active: false,
    },
    {
      key: 3,
      label: "Education",
      active: false,
    },
  ]
  const [stateMenu, setStateMenu] = React.useState(menu)
  React.useEffect(() => {
    setStateMenu(menu)
  }, [profile])
  // const getPeriode = (val: number) => {
  //   const year =
  //     val !== 0
  //       ? Math.floor(val / 12)
  //         ? `${Math.floor(val / 12)} year`
  //         : ""
  //       : ""
  //   const month = val % 12 ? `${Math.floor(val % 12)} month` : "0 month"
  //   return `${year} ${month}`
  // }

  return (
    <div>
      {/* switch */}
      <div className="mt-5 flex gap-5 mb-5">
        {stateMenu.map((item) => (
          <div
            className={` cursor-pointer px-2 py-1 rounded-lg ${
              item.active ? "bg-secondaryYellow" : "bg-white"
            }`}
            style={{ border: "1px solid gray" }}
            key={item.key}
            onClick={() =>
              setStateMenu((prev) =>
                prev.map((val) => {
                  if (val.key === item.key) {
                    return { ...val, active: true }
                  } else {
                    return { ...val, active: false }
                  }
                }),
              )
            }
          >
            {item.label}
          </div>
        ))}
      </div>
      <div>
        {getComponentActive(
          stateMenu.find((item) => item.active)?.key || 1,
          profile,
          stage?.name === "new",
          candidate,
        )}
      </div>
    </div>
  )
}

const getComponentActive = (
  key: number,
  data?: Talent,
  isAnonymised?: boolean,
  candidate?: Applicant,
): React.ReactNode => {
  switch (key) {
    case 1:
      return (
        <SkillCompetencies
          data={data}
          isAnonymised={isAnonymised}
          candidate={candidate}
        />
      )
    case 2:
      return <WorkExperience data={data?.job_histories} />
    case 3:
      return <Educations data={data?.educations} />
    default:
      return <SkillCompetencies data={data} />
  }
}
