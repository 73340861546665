import React from "react"
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons"
import { useFormContext } from "react-hook-form"
import { CreateJobSchema } from "../types"

type TabKey = (typeof tabs)[number]["key"]

interface TabComponentProps {
  activeKey: string
  setActiveKey: React.Dispatch<React.SetStateAction<TabKey>>
}

const tabs = [
  {
    title: "Job Description",
    key: "1",
    items: ["description"],
  },
  {
    title: "Skills",
    key: "2",
    items: ["green_skills", "technical_skills", "critical_skills"],
  },
  {
    title: "Screening Questions",
    key: "3",
    items: ["screening_questions"],
  },
] as const

export const TabsJobPost: React.FC<TabComponentProps> = ({
  activeKey,
  setActiveKey,
}) => {
  const methods = useFormContext<CreateJobSchema>()
  // const [hasTriggered, setHasTriggered] = React.useState(false)
  const [triggeredFor, setTriggeredFor] = React.useState<number[]>([])
  const handleTabChange = async (key: TabKey) => {
    // setHasTriggered(true)
    if (activeKey === "1") {
      methods.trigger(["description"])
      setTriggeredFor((prev) => [...prev, 0])
    } else if (activeKey === "2") {
      methods.trigger(["green_skills", "technical_skills", "critical_skills"])
      setTriggeredFor((prev) => [...prev, 1])
    } else if (activeKey === "3") {
      methods.trigger(["screening_questions"])
      setTriggeredFor((prev) => [...prev, 2])
    }
    setActiveKey(key)
  }

  return (
    <div className="w-full grid grid-cols-12 basic-card bg-custom-white py-[13px] px-[17px]">
      {tabs.map((tab, idx) => {
        const isError = tabs[idx].items.some((item) =>
          methods.formState.errors.hasOwnProperty(item),
        )
        return (
          <div
            key={tab.key}
            style={{
              borderBottom:
                activeKey === tab.key ? "2px solid #36505E" : "none",
            }}
            className={`${
              activeKey === tab.key
                ? "text-backdrop font-semibold"
                : "text-gray-400"
            } col-span-4 flex items-center justify-center cursor-pointer gap-x-2 pb-2 transition-colors duration-150`}
            onClick={() => handleTabChange(tab.key)}
          >
            {triggeredFor.includes(idx) ? (
              isError ? (
                <CloseCircleOutlined className="text-danger text-[32px]" />
              ) : (
                <CheckCircleOutlined className="text-primary text-[32px]" />
              )
            ) : (
              <div
                className={`h-8 w-8 flex items-center justify-center rounded-full transition-colors duration-150 ${
                  activeKey === tab.key
                    ? "bg-backdrop text-white"
                    : "text-lightGrey "
                } `}
                style={{
                  border: activeKey === tab.key ? "none" : "1px solid #D9D9D9",
                }}
              >
                {tab.key}
              </div>
            )}
            <div
              className={`text-base ${
                triggeredFor.includes(idx)
                  ? isError
                    ? "!text-danger"
                    : "!text-primary"
                  : ""
              }`}
            >
              {tab.title}
            </div>
          </div>
        )
      })}
    </div>
  )
}
