import {
  useQuery,
  useMutation,
  useQueryClient,
  type UseQueryResult,
} from "react-query"
import { type AxiosInstance } from "axios"
import { useAxiosWithMsal } from "./useAxiosWithMsal"
import { message } from "antd"
import { type AxiosError } from "../errors"
import { type ApiResponse } from "../types/ApiResponse"
import { useNavigate, useLocation } from "react-router-dom"
import { type Item } from "./useReferences"
export interface TenantData {
  headquarter: Item
  name: string
  description: string
  sector: Item
  size: string
  facebook_url: string
  linkedin_url: string
  github_url: string
  instagram_url: string
  twitter_url: string
  website_url: string
}

export interface Tenant {
  id: string
  name: string
  description: string
  sector?: Item
  logo: string
  headquarter?: Item
  size?: Item
  website_url: string
  linkedin_url: string
  twitter_url: string
  facebook_url: string
  instagram_url: string
  github_url: string
}

interface TenantApiResponse extends ApiResponse {
  data: { data: Tenant | null }
}

export const useGetTenants = (): UseQueryResult<
  TenantApiResponse,
  AxiosError
> => {
  const getAxiosInstance = useAxiosWithMsal()
  const navigate = useNavigate()
  const location = useLocation()
  const getTenants = async () => {
    const api: AxiosInstance = await getAxiosInstance()
    return api.get<TenantApiResponse>("/tenants")
  }

  return useQuery("tenants", getTenants, {
    retry: 1,
    onError: (error: any) => {
      if (
        error?.response?.data?.code === 400 &&
        error?.response?.data?.data?.redirect_affected === "frontend" &&
        error?.response?.data?.data?.type === "redirect"
      ) {
        // Handle redirect here
        if (location.pathname !== error?.response?.data?.data?.redirect_path) {
          navigate(error?.response?.data?.data?.redirect_path)
        }
      }
    },
  })
}

export const useCreateTenant = (type: "create" | "edit" = "create") => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const createTenant = async (data: TenantData) => {
    const api = await getAxiosInstance()
    return api.post("/tenants", {
      ...data,
      headquarter: (data?.headquarter as any)?.id,
      sector: (data?.sector as any)?.id,
    })
  }

  return useMutation(createTenant, {
    onSuccess: async () => {
      queryClient.invalidateQueries(["tenants"])
      if (type === "create") {
        message.success("Tenant created successfully")
      } else {
        message.success("Changes saved successfully")
      }
    },
    onError: async () => {
      if (type === "create") {
        message.error("An error occurred while creating the tenant")
      } else {
        message.error("An error occurred while updating the tenant")
      }
    },
  })
}

export const useUpdateTenantLogo = () => {
  const getAxiosInstance = useAxiosWithMsal()

  const updateTenantLogo = async (data: any) => {
    try {
      const api = await getAxiosInstance()
      return await api.post("/tenants/logo", data)
    } catch (error) {}
  }

  return useMutation(updateTenantLogo, {
    onSuccess: async () => {
      message.success("Logo updated successfully")
    },
    onError: async () => {
      message.error("An error occurred while updating the logo")
    },
  })
}

export const useDeleteTenantLogo = () => {
  const getAxiosInstance = useAxiosWithMsal()

  const deleteTenantLogo = async () => {
    try {
      const api = await getAxiosInstance()
      return await api.delete("/tenants/logo")
    } catch (error) {}
  }

  return useMutation(deleteTenantLogo, {
    onSuccess: async () => {
      message.success("Logo deleted successfully")
    },
    onError: async () => {
      message.error("An error occurred while deleting the logo")
    },
  })
}
