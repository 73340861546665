import React from "react"
import { Input } from "antd"
import { SearchOutlined } from "@ant-design/icons"

interface SearchProps {
  className?: string
  handleSearch?: (value: string) => void
  search?: string
  placeholder?: string
}

export const Search: React.FC<SearchProps> = ({
  className,
  handleSearch,
  search,
  placeholder = "search",
}) => {
  return (
    <Input
      prefix={<SearchOutlined className="mr-1" />}
      className={className}
      placeholder={placeholder}
      // allowClear
      style={{ borderBottom: "1px solid #D0DDE5" }}
      defaultValue={search}
      bordered={false}
      onChange={(e) => handleSearch && handleSearch(e.target.value)}

      //   size="large"
      //   onSearch={onSearch}
    />
  )
}
