import { useEffect, useState } from "react"
import type React from "react"
import { useAxiosWithMsal } from "../../../common/hooks"
import { type AxiosInstance } from "axios"
import { type ApiResponsePaginatedV2 } from "../../../common/types/ApiResponse"
import { type Common } from "../components/type/common"
import { message } from "antd"
import { type AxiosError } from "../../../common/errors"
import { useQuery } from "react-query"
import { debounce } from "../../../utils/debounce"
import { useLocation } from "react-router-dom"

export const useCareerGoalDiscover = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const { pathname } = useLocation()

  const [params, setParams] = useState<any>({
    limit: 20,
    page: 1,
    search: null,
    connected: pathname === "/talent/connected" ? 1 : 0,
  })

  const [total, setTotal] = useState<number>(0)
  const [dataList, setDataList] = useState<Common[]>([])

  const [careerUsed, setCareerUsed] = useState<any>([])
  const [finalOption, setFinalOption] = useState<any[]>([])

  const getCareerGoalDiscover = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<ApiResponsePaginatedV2<Common>>(
        "/talent-finders/career-goals",
        {
          params,
        },
      )
    } catch (error) {
      message.error(
        "An error occurred while fetching raw favorite and connected: ",
      )
    }
  }
  const { data, isLoading } = useQuery(
    ["career-goals-discover", params],
    getCareerGoalDiscover,
    {
      onError: (error: AxiosError) => {
        message.error(
          "An error occurred while fetching career goal discover: " +
            error.message,
        )
      },
    },
  )

  const handleSearch = debounce((e: string) => {
    setParams((prevParams: any) => ({ ...prevParams, search: e, page: 1 }))
  }, 1000)

  const handleClear = () => {
    if (params?.page !== 1 || params?.search !== "") {
      setParams((prevParams: any) => ({ ...prevParams, search: "", page: 1 }))
    }
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target as HTMLButtonElement
    const maxScrollPosition =
      target.scrollHeight - target.clientHeight - target.clientHeight * 0.1

    if (
      target.scrollTop >= maxScrollPosition &&
      !isLoading &&
      total !== dataList.length
    ) {
      setParams((prev: any) => ({ ...prev, page: prev.page + 1 }))
    }
  }

  const handleSelectCareer = (value: any) => {
    setCareerUsed((prevSelectedItems: any) => [...prevSelectedItems, value])
    if (params.search) {
      handleClear()
    }
  }

  const handleDeleteCareer = (id: any) => {
    const updatedData = careerUsed.filter((item: any) => item.value !== id)
    setCareerUsed(updatedData)
  }

  useEffect(() => {
    setFinalOption(
      dataList?.map((item: any) => ({
        ...item,
        disabled: careerUsed?.find((gs: any) => gs?.value === item?.id),
      })),
    )
    // }
    //
  }, [dataList, careerUsed])

  useEffect(() => {
    if (!isLoading) {
      if (params?.page === 1) {
        setDataList(data?.data?.data?.list ?? [])
        setTotal(data?.data?.data?.total_data ?? 0)
      } else {
        setDataList((prev) => [...prev, ...(data?.data?.data?.list ?? [])])
        setTotal(data?.data?.data?.total_data ?? 0)
      }
    }
    //
  }, [data?.data?.data?.list])

  useEffect(() => {
    if (total > dataList.length) {
      setParams((prev: any) => ({ ...prev, page: prev.page + 1 }))
    }
    //
  }, [total])

  return {
    data: finalOption,
    isLoading,
    handleSearch,
    handleClear,
    handleScroll,
    params,
    handleSelectCareer,
    handleDeleteCareer,
    careerUsed,
  }
}
