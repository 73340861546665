import React, { useContext } from "react"
import { PageContent, PageHeader } from "../../../common/layout"
import { HomeOutlined } from "@ant-design/icons"
import { CustomSearch } from "../../../common/components"
import { Select, Button } from "antd"
import {
  TeamManagementTable,
  NewUserDrawer,
  EditUserDrawer,
} from "../components"
import { GetUser, UserStatusType } from "../hooks/useAdministrateUsers"
import {
  ITourContext,
  TourContext,
} from "../../../common/components/tour/TourProvider"

const Content = () => {
  const [search, setSearch] = React.useState<string | null>(null)
  const [userStatusSelected, setUserStatusSelected] =
    React.useState<UserStatusType>("All")
  const [open, setOpen] = React.useState<boolean>(false)
  const [editDrawerOpen, setEditDrawerOpen] = React.useState<boolean>(false)
  const [toEditUser, setToEditUser] = React.useState<GetUser | null>(null)
  const [onlyOneAdmin, setOnlyOneAdmin] = React.useState(true)

  const {
    refs: { refNewUser },
  } = useContext(TourContext) as ITourContext

  const handleEditDrawer = (user: GetUser, onlyOneAdmin: boolean) => {
    setToEditUser(user)
    setOnlyOneAdmin(onlyOneAdmin)
    setEditDrawerOpen(true)
  }

  const onCloseEditDrawer = () => {
    setToEditUser(null)
    setEditDrawerOpen(false)
  }
  return (
    <div className="px-5 py-6 bg-white h-full w-full">
      <div className="flex w-full gap-x-4">
        <CustomSearch setSearch={setSearch} className="w-1/2" />
        <Select
          placeholder="All Statuses"
          className="w-24"
          value={userStatusSelected}
          options={[
            { label: "All", value: "All" },
            { label: "Pending", value: "pending" },
            { label: "Active", value: "active" },
          ]}
          onChange={setUserStatusSelected}
        />
        <Button
          ref={refNewUser}
          className="bg-primary text-white ml-auto"
          onClick={() => setOpen(true)}
        >
          New User
        </Button>
      </div>
      <div className="mt-6">
        <TeamManagementTable
          search={search}
          userStatusSelected={userStatusSelected}
          handleEditDrawer={handleEditDrawer}
        />
      </div>
      <NewUserDrawer open={open} onClose={() => setOpen(false)} />
      {toEditUser && (
        <EditUserDrawer
          onClose={onCloseEditDrawer}
          open={editDrawerOpen}
          user={toEditUser}
          onlyOneAdmin={onlyOneAdmin}
        />
      )}
    </div>
  )
}

export const TeamManagement = () => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Settings",
    },
    {
      title: "Team Management",
    },
  ]
  return (
    <PageContent
      header={
        <PageHeader
          title={"Team Management"}
          breadcrumbItems={items}
        ></PageHeader>
      }
      content={<Content />}
    />
  )
}
