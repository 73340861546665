import { useAxiosWithMsal } from "../../../../common/hooks"
import { useQuery } from "react-query"
import { AxiosInstance } from "axios"
import { PlanDetail } from "./type"
import { ApiResponse } from "../../../../common/types/ApiResponseV2"
import { message } from "antd"

export const usePlan = () => {
  const getAxiosInstance = useAxiosWithMsal()

  const { data, isLoading } = useQuery(
    "planlist",
    async () => {
      try {
        const api: AxiosInstance = await getAxiosInstance()
        return await api.get<ApiResponse<PlanDetail[]>>(
          `/plans?currency_code=${
            localStorage.getItem("currency_code") || "sgd"
          }`,
        )
      } catch (error) {
        throw error
      }
    },
    {
      retry: 0,
      onError: (error: any) => {
        message.error(error?.response?.data?.message)
      },
    },
  )
  return {
    data: data?.data?.data,
    isLoading,
  }
}
