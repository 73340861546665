import React from "react"
import { useForm, Controller } from "react-hook-form"
import { Radio, Select, Input, ModalProps, Modal, Form } from "antd"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
// import { useJobClosingReasons } from "../../../../common/hooks/useReferences";
import { useCloseJobPost } from "../../hooks/useJobPosts"
import { useGetJobApplicantsById } from "../../hooks/useCandidates"

type FormValues = {
  candidate_source?: string
  candidates_ids?: string[]
  closing_status_id: string
  other_reason?: string
}

const FormSchema = yup.object({
  closing_status_id: yup.string().required(),
  candidate_source: yup
    .string()
    .when(
      "closing_status_id",
      (closing_status_id: any, schema: yup.StringSchema) => {
        console.log("Looking for", closing_status_id)
        return closing_status_id[0] === "e54310f8-a8bf-44b0-981e-93096d414c29"
          ? schema.required("Source is required")
          : schema
      },
    ),
  candidates_ids: yup
    .array()
    .when(
      "closing_status_id",
      (closing_status_id: any, schema: yup.ArraySchema<any, any>) =>
        closing_status_id[0] === "11d87398-bff8-4348-b902-cd95f62493f7"
          ? schema.of(yup.string()).required("Candidates are required")
          : schema,
    ),
  other_reason: yup
    .string()
    .when(
      "closing_status_id",
      (closing_status_id: any, schema: yup.StringSchema) =>
        closing_status_id[0] === "3cee1d6e-862d-4fec-8a1e-fd34a7599e67"
          ? schema.required("Reason is required")
          : schema,
    ),
})

export function CloseJobModal({
  id,
  close,
  ...inputProps
}: { id: string; close: () => void } & ModalProps) {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(FormSchema),
  })
  //   const reasonsQuery = useJobClosingReasons();
  const closeJobMutate = useCloseJobPost()
  const getApplicantsQuery = useGetJobApplicantsById({
    job_id: id,
    status: "CANDIDATES",
  })

  const onSubmit = async (data: FormValues) => {
    await closeJobMutate.mutateAsync({ reason: data, id: id })
    close()
  }
  const closingStatusId = watch("closing_status_id")

  return (
    <Modal
      {...inputProps}
      onCancel={close}
      title={
        <h2 className="text-backdrop font-semibold text-xl mb-5">
          Please select a reason for closing the job
        </h2>
      }
      onOk={handleSubmit(onSubmit)}
      okButtonProps={{
        className: "bg-primary text-white",
        loading: closeJobMutate.isLoading,
      }}
      centered
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="closing_status_id"
          control={control}
          render={({ field }) => (
            <Radio.Group
              {...field}
              onChange={(e) => reset({ closing_status_id: e.target.value })}
              className="!flex flex-col gap-y-4"
            >
              <Radio value="11d87398-bff8-4348-b902-cd95f62493f7">
                Hired through Skilledin
              </Radio>
              <Radio value="e54310f8-a8bf-44b0-981e-93096d414c29">
                Hired outside Skilledin
              </Radio>
              <Radio value="3cee1d6e-862d-4fec-8a1e-fd34a7599e67">Other</Radio>
            </Radio.Group>
          )}
        />

        {closingStatusId === "11d87398-bff8-4348-b902-cd95f62493f7" && (
          <div className="mt-4">
            <div className="">Please select hired candidate</div>
            <Controller
              name="candidates_ids"
              control={control}
              render={({ field }) => (
                <Form.Item
                  validateStatus={errors.candidates_ids && "error"}
                  help={errors.candidates_ids?.message as React.ReactNode}
                >
                  <Select
                    placeholder="Select"
                    options={getApplicantsQuery?.data?.data?.data?.list?.map(
                      (candidate) => ({
                        label: candidate?.full_name,
                        value: candidate?.id,
                      }),
                    )}
                    mode="multiple"
                    className="w-full"
                    {...field}
                  ></Select>
                </Form.Item>
              )}
            />
          </div>
        )}
        {closingStatusId === "e54310f8-a8bf-44b0-981e-93096d414c29" && (
          <div className="mt-4">
            <div className="">Please state the candidate source </div>
            <Controller
              name="candidate_source"
              control={control}
              render={({ field }) => (
                <Form.Item
                  validateStatus={errors.candidate_source && "error"}
                  help={errors.candidate_source?.message as React.ReactNode}
                >
                  <Input placeholder="e.g. headhunter" {...field} />
                </Form.Item>
              )}
            />
          </div>
        )}
        {closingStatusId === "3cee1d6e-862d-4fec-8a1e-fd34a7599e67" && (
          <div className="mt-4">
            <div className="">Please state the reason</div>
            <Controller
              name="other_reason"
              control={control}
              render={({ field }) => (
                <Form.Item
                  validateStatus={errors.other_reason && "error"}
                  help={errors.other_reason?.message as React.ReactNode}
                >
                  <Input
                    placeholder="e.g. no longer hiring for this role"
                    {...field}
                  />
                </Form.Item>
              )}
            />
          </div>
        )}
      </form>
    </Modal>
  )
}
