import React from "react"
import { Pagination } from "antd"

interface CustomPaginationProps {
  total_data: number | undefined
  selectedPage: number
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>
  itemsPerPage: number
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>
}

export const CustomPagination: React.FC<CustomPaginationProps> = ({
  total_data,
  selectedPage,
  setSelectedPage,
  itemsPerPage,
  setItemsPerPage,
}) => {
  const minPerPage = 20

  const handlePageChange = (page: number) => {
    setSelectedPage(page)
  }

  const handlePageSizeChange = (current: number, size: number) => {
    setSelectedPage(1)
    setItemsPerPage(size)
  }
  if (total_data && total_data < minPerPage) {
    return null
  }

  return (
    <Pagination
      className="my-5 !text-backdrop ml-auto"
      current={selectedPage}
      showQuickJumper
      onChange={handlePageChange}
      onShowSizeChange={handlePageSizeChange}
      total={total_data}
      pageSize={itemsPerPage}
      showSizeChanger
      defaultPageSize={20}
      pageSizeOptions={[20, 50, 100]}
    />
  )
}
