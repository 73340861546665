import { useAxiosWithMsal } from "../../../../common/hooks"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { AxiosInstance } from "axios"
import { ApiResponse } from "../../../../common/types/ApiResponseV2"
import { PaymentMethod } from "./type"
import { message } from "antd"

interface ResponsePaymentMethods {
  data: PaymentMethod[]
  has_more: false
  total_count: number
}

export const usePaymentMethods = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(
    "paymentmethods",
    async () => {
      try {
        const api: AxiosInstance = await getAxiosInstance()
        return await api.get<ApiResponse<ResponsePaymentMethods>>(
          "/billings/payment-methods",
        )
      } catch (error) {
        throw error
      }
    },
    {
      retry: 3,
      onSuccess: () => {
        queryClient.invalidateQueries(["customer"])
      },
    },
  )

  const { mutateAsync: addPayment } = useMutation({
    mutationFn: async (val: any) => {
      try {
        const api: AxiosInstance = await getAxiosInstance()
        return await api.post(`/billings/payment-methods/id/${val}`)
      } catch (error) {
        throw error
      }
    },
    mutationKey: "add-payment",
  })

  const handleAdd = async (
    val: any,
    callbackSuccess: () => void,
    callbackError: (error: any) => void,
  ) => {
    try {
      const response = await addPayment(val)
      if (response) {
        queryClient
          .invalidateQueries(["paymentmethods"])
          .then(() => callbackSuccess())
      }
      return response
    } catch (error: any) {
      callbackError(error?.response?.data?.data || "Internal server error")
    }
  }

  const {
    mutateAsync: deletePaymentMethod,
    isLoading: isLoadingDeletePaymentMethod,
  } = useMutation({
    mutationFn: async (val: any) => {
      try {
        const api: AxiosInstance = await getAxiosInstance()
        return await api.delete(`/billings/payment-methods/id/${val}`)
      } catch (error) {
        throw error
      }
    },
    mutationKey: "delete-payment",
  })

  const handleRemove = async (val: any) => {
    message.open({
      key: "delete-payment-method",
      content: "updating...",
      type: "loading",
    })
    try {
      const response = await deletePaymentMethod(val)
      if (response) {
        queryClient.invalidateQueries(["paymentmethods"]).then(() =>
          message.open({
            key: "delete-payment-method",
            content: "deleted",
            type: "success",
          }),
        )
      }
      return response
    } catch (error: any) {
      message.open({
        key: "delete-payment-method",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  const {
    mutateAsync: makeDefaultPaymentMethod,
    isLoading: isLoadingMakeDefaultPaymentMethod,
  } = useMutation({
    mutationFn: async (val: any) => {
      try {
        const api: AxiosInstance = await getAxiosInstance()
        return await api.patch(`/billings/payment-methods/default/${val}`)
      } catch (error) {
        throw error
      }
    },
    mutationKey: "default-payment",
  })

  const handleMakeDefault = async (val: any) => {
    message.open({
      key: "make-default-payment-method",
      content: "making as default payment...",
      type: "loading",
    })
    try {
      const response = await makeDefaultPaymentMethod(val)
      if (response) {
        queryClient.invalidateQueries(["subscription"])
        queryClient.invalidateQueries(["customer"]).then(() =>
          message.open({
            key: "make-default-payment-method",
            content: "updated",
            type: "success",
          }),
        )
      }
      return response
    } catch (error: any) {
      message.open({
        key: "make-default-payment-method",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }
  return {
    data: data?.data?.data,
    isLoading,
    add: { handleAdd },
    remove: {
      handleRemove,
      isLoading: isLoadingDeletePaymentMethod,
    },
    makeDefault: {
      handleMakeDefault,
      isLoading: isLoadingMakeDefaultPaymentMethod,
    },
  }
}
