import { useState } from "react"
import { message } from "antd"
import { useAxiosWithMsal } from "../../../../common/hooks"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { AxiosInstance } from "axios"
import { ApiResponse } from "../../../../common/types/ApiResponseV2"
import { Plan } from "./type"

export const useSubscription = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)

  const { data, isLoading } = useQuery("subscription", async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<ApiResponse<Plan>>("/billings/subscription")
    } catch (error) {
      throw error
    }
  })

  const { mutateAsync: cancelSub, isLoading: isLoadingCancelSub } = useMutation(
    {
      mutationFn: async () => {
        try {
          const api: AxiosInstance = await getAxiosInstance()
          return await api.delete("/billings/subscription")
        } catch (error) {
          throw error
        }
      },
      mutationKey: "cancel-sub",
    },
  )

  const handleCancel = async () => {
    message.open({
      key: "cancel-sub",
      content: "canceling...",
      type: "loading",
    })
    try {
      const response = await cancelSub()
      if (response) {
        queryClient.invalidateQueries(["subscription"]).then(() => {
          message.open({
            key: "cancel-sub",
            content: "subscription updated",
            type: "success",
          })
        })
      }
    } catch (error: any) {
      message.open({
        key: "cancel-sub",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  const { mutateAsync: updateSub, isLoading: isLoadingUpdateSub } = useMutation(
    {
      mutationFn: async (val: any) => {
        try {
          const api: AxiosInstance = await getAxiosInstance()
          return await api.patch("/billings/subscription", val)
        } catch (error) {
          throw error
        }
      },
      mutationKey: "update-sub",
    },
  )

  const handleUpdate = async (val: any) => {
    message.open({
      key: "update-sub",
      content: "updating...",
      type: "loading",
      duration: 0,
    })
    try {
      const response = await updateSub(val)
      if (response) {
        if (response?.data?.data?.redirect_url) {
          window.location.href = response?.data?.data?.redirect_url as string
        }
        queryClient.invalidateQueries(["invoices"]).then(() => {
          queryClient.invalidateQueries(["subscription"])
          setOpen(false)
          message.open({
            key: "update-sub",
            content: "subscription updated",
            type: "success",
          })
        })
      }
    } catch (error: any) {
      message.open({
        key: "update-sub",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  return {
    data: data?.data?.data,
    isLoading,
    cancel: { handleCancel, isLoading: isLoadingCancelSub },
    update: { open, setOpen, handleUpdate, isLoading: isLoadingUpdateSub },
  }
}
