import { useEffect, useState } from "react"
import type React from "react"
import { useAxiosWithMsal } from "../../../common/hooks"
import { type AxiosInstance } from "axios"
import { type ApiResponsePaginatedV2 } from "../../../common/types/ApiResponse"
import { type Common } from "../components/type/common"
import { message } from "antd"
import { type AxiosError } from "../../../common/errors"
import { useQuery } from "react-query"
import { debounce } from "../../../utils/debounce"

export const useGreenSkills = () => {
  const getAxiosInstance = useAxiosWithMsal()

  const [params, setParams] = useState<any>({
    limit: 20,
    page: 1,
    search: "",
  })

  const [total, setTotal] = useState<number>(0)
  const [dataList, setDataList] = useState<Common[]>([])
  const [gsUsed, setGsUsed] = useState<any>([])
  const [finalOption, setFinalOption] = useState<any[]>([])

  const getGreenSkill = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<ApiResponsePaginatedV2<Common>>(
        "/references/green-skills",
        {
          params,
        },
      )
    } catch (error) {
      message.error("An error occurred while fetchinggreen-skills ")
    }
  }
  const { data, isLoading } = useQuery(["gs-discover", params], getGreenSkill, {
    onError: (error: AxiosError) => {
      message.error(
        "An error occurred while fetching green-skills: " + error.message,
      )
    },
  })

  const handleSearch = debounce((e: string) => {
    setParams((prevParams: any) => ({ ...prevParams, search: e, page: 1 }))
  }, 1000)

  const handleClear = () => {
    if (params?.page !== 1 || params?.search !== "") {
      setParams((prevParams: any) => ({ ...prevParams, search: "", page: 1 }))
    }
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target as HTMLButtonElement
    const maxScrollPosition =
      target.scrollHeight - target.clientHeight - target.clientHeight * 0.1

    if (
      target.scrollTop >= maxScrollPosition &&
      !isLoading &&
      total !== dataList.length
    ) {
      setParams((prev: any) => ({ ...prev, page: prev.page + 1 }))
    }
  }

  const handleSelectGS = (value: any) => {
    setGsUsed((prevSelectedItems: any) => [...prevSelectedItems, value])
    if (params.search) {
      handleClear()
    }
  }

  const handleDeleteGS = (id: any) => {
    const updatedData = gsUsed.filter((item: any) => item.value !== id)
    setGsUsed(updatedData)
  }

  useEffect(() => {
    setFinalOption(
      dataList?.map((item: any) => ({
        ...item,
        disabled: gsUsed?.find((gs: any) => gs?.value === item?.id),
      })),
    )
    // }
    //
  }, [dataList, gsUsed])

  useEffect(() => {
    if (!isLoading) {
      if (params?.page === 1) {
        setDataList(data?.data?.data?.list ?? [])
        setTotal(data?.data?.data?.total_data ?? 0)
      } else {
        setDataList((prev) => [...prev, ...(data?.data?.data?.list ?? [])])
        setTotal(data?.data?.data?.total_data ?? 0)
      }
    }
    //
  }, [data?.data?.data?.list])

  useEffect(() => {
    if (total > dataList.length) {
      setParams((prev: any) => ({ ...prev, page: prev.page + 1 }))
    }
    //
  }, [total])

  return {
    data: finalOption,
    gsUsed,
    handleSelectGS,
    handleDeleteGS,
    isLoading,
    handleSearch,
    handleClear,
    handleScroll,
    params,
  }
}
