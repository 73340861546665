import React from "react"
import { RadarSoftSkill } from "./RadarSoftSkill"
import { EmptyData } from "../../../../common/components/EmptyData"

interface Props {
  data?: {
    id: string
    name: string
    proficiency: {
      id: string
      name: string
    }
  }[]
}

export const SoftSkills: React.FC<Props> = ({ data }) => {
  return (
    <div className="p-5 rounded-xl shadow-md bg-[#FAFAFA] mb-5">
      <h3 className="">Soft Skills</h3>
      {data && data.length > 0 ? (
        <div className=" h-60">
          <RadarSoftSkill
            data={data.map((item) => ({
              name: item.name,
              proficiency: translateProficiency(item.proficiency.name),
            }))}
          />
        </div>
      ) : (
        <EmptyData />
      )}
    </div>
  )
}

const translateProficiency = (proficiency: string): number => {
  switch (proficiency) {
    case "Basic":
      return 1
    case "Intermediate":
      return 2
    case "Advanced":
      return 3
    default:
      return 0
  }
}
