import React from "react"

interface PageContentProps {
  header: React.ReactElement
  content: React.ReactElement
  contentRightSidebar?: React.ReactElement
  contentLeftSidebar?: React.ReactElement
}

export const PageContent: React.FC<PageContentProps> = ({
  header,
  content,
  contentRightSidebar,
  contentLeftSidebar,
}) => {
  return (
    <div className="">
      {header}
      <div
        className={`p-6 grid ${
          contentRightSidebar || contentLeftSidebar
            ? "grid-cols-3"
            : "grid-cols-1"
        } gap-5 h-full`}
      >
        {contentLeftSidebar && (
          <div className={"col-span-1"}>{contentLeftSidebar}</div>
        )}
        <div
          className={`col-span-${
            contentRightSidebar || contentLeftSidebar ? "2" : "3"
          } flex flex-col gap-4 relative min-h-[calc(100vh-20rem)]`}
        >
          {content}
        </div>
        {contentRightSidebar && (
          <div className={"col-span-1"}>{contentRightSidebar}</div>
        )}
      </div>
    </div>
  )
}
