import React, { ReactNode, useContext } from "react"
import { useForm } from "react-hook-form"
import { message } from "antd"
import {
  useCreateTenant,
  useUpdateTenantLogo,
  TenantData,
  useDeleteTenantLogo,
} from "../../../common/hooks/useTenant"
import { useReferences } from "../../../common/hooks"
import { TenantContext } from "../../../context/user"
export interface Option {
  value: string | ReactNode
  key?: string
}

export function useCompanyProfileEdit(type: "create" | "edit" = "create") {
  const createTenant = useCreateTenant(type)
  const updateTenantLogo = useUpdateTenantLogo()
  const [imageUrl, setImageUrl] = React.useState("")
  const tenants = useContext(TenantContext)
  const deleteTenantLogo = useDeleteTenantLogo()

  const { companySizes } = useReferences()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<TenantData>({
    defaultValues: {
      headquarter: undefined,
      name: "",
      description: "",
      sector: undefined,
      size: "",
      facebook_url: "",
      linkedin_url: "",
      github_url: "",
      instagram_url: "",
      twitter_url: "",
      website_url: "",
    },
  })

  React.useEffect(() => {
    if (tenants) {
      reset({
        headquarter: tenants?.headquarter || undefined,
        name: tenants?.name || "",
        description: tenants?.description || "",
        sector: tenants?.sector || undefined,
        size: tenants?.size?.id || undefined,
        facebook_url: tenants?.facebook_url || "",
        linkedin_url: tenants?.linkedin_url || "",
        github_url: tenants?.github_url || "",
        instagram_url: tenants?.instagram_url || "",
        twitter_url: tenants?.twitter_url || "",
        website_url: tenants?.website_url || "",
      })
      setImageUrl(tenants?.logo || "")
    }
  }, [tenants, reset])

  const handleUpload = async ({ file }: { file: unknown }) => {
    const formData = new FormData()
    formData.append("file", file as File)

    try {
      await updateTenantLogo.mutateAsync(formData)
      setImageUrl(URL.createObjectURL(file as File))
    } catch (error) {
      message.error("An error occurred while updating the logo")
    }
  }

  const deleteImage = async () => {
    await deleteTenantLogo.mutateAsync()
    setImageUrl("")
  }

  const onSubmit = async (data: TenantData) => {
    try {
      await createTenant.mutateAsync(data)
    } catch (error) {
      message.error("Failed to update company profile")
    }
  }
  const handleChange = () => {}
  function dataURLtoFile(dataURL: string, fileName: string): File {
    const arr = dataURL.split(",")
    const mime = arr[0].match(/:(.*?);/)?.[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], fileName, { type: mime })
  }
  function beforeUpload(file: File) {
    const allowedTypes = ["image/jpeg", "image/png"]
    const maxSize = 2 * 1024 * 1024 // 2MB
    return new Promise<File>((resolve, reject) => {
      // Check file type
      if (!allowedTypes.includes(file.type)) {
        message.error("Only JPEG and PNG files are allowed")
        reject(new Error("Invalid file type"))
        return
      }

      // Check file size
      if (file.size > maxSize) {
        message.error("File size exceeds the limit (2MB)")
        reject(new Error("File size exceeded"))
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const img = new Image()
        img.src = event.target?.result as string
        img.onload = () => {
          const canvas = document.createElement("canvas")
          const ctx = canvas.getContext("2d")

          const MAX_WIDTH = 800
          const MAX_HEIGHT = 800

          let width = img.width
          let height = img.height

          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }

          canvas.width = width
          canvas.height = height

          ctx?.drawImage(img, 0, 0, width, height)

          const compressedDataURL = canvas.toDataURL(file.type) // Use original image format
          const compressedFile = dataURLtoFile(compressedDataURL, file.name)

          resolve(compressedFile)
        }
      }

      reader.onerror = (error) => reject(error)
    })
  }
  return {
    imageUrl,
    handleSubmit,
    control,
    errors,
    handleUpload,
    onSubmit,
    handleChange,
    companySizes,
    beforeUpload,
    isDirty,
    deleteImage,
  }
}
