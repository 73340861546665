import { Button, Modal, ModalProps } from "antd"
import { useNavigate } from "react-router-dom"
import { useModalStore } from "../../stores/useModalStore"

export function SignInToAccessModal({ ...rest }: ModalProps) {
  const navigate = useNavigate()
  const closeModal = useModalStore((state) => state.closeModal)
  const isOpen = useModalStore((state) => state.isOpen)

  return (
    <Modal
      closable={false}
      {...rest}
      onCancel={closeModal}
      width={380}
      footer={null}
      open={isOpen}
      centered
    >
      <p className="text-center mt-4">
        Register and Login to access this feature.
      </p>
      <Button
        onClick={() => {
          closeModal()
          navigate("/register")
        }}
        className="w-full text-center mt-4 bg-primary text-white"
      >
        Register
      </Button>
    </Modal>
  )
}
