import React from "react"
import { Switch } from "antd"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
interface SwitchFilterProps {
  checked: number
  handleChecked: (value: number) => void
}

export const SwitchFilter: React.FC<SwitchFilterProps> = ({
  checked,
  handleChecked,
}) => {
  return (
    <div className=" justify-end w-36 flex items-center gap-3">
      <Switch
        size="small"
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={!!checked}
        onChange={() => (checked ? handleChecked(0) : handleChecked(1))}
      />
      <div>Saved only</div>
    </div>
  )
}
