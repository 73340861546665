import React, { useEffect } from "react"
// import { CloseCircleFilled } from "@ant-design/icons";
import {
  Select,
  Tag,
  message,
  // Tag
} from "antd"
import { useLocationDiscover } from "../../hooks/useLocationDiscover"
import { useCareerGoalDiscover } from "../../hooks/useCareerGoalDiscover"
import { useGreenSkills } from "../../hooks/useGreenSkill"
import { truncateText } from "../../../../utils/util_funcs"

interface FilterRightProps {
  handleChangeFilter: (key: string, value: string) => void
  paramGS: any
  paramCareer: any
  paramLocation: any
}
export const FilterRight: React.FC<FilterRightProps> = ({
  handleChangeFilter,
  paramGS,
  paramCareer,
  paramLocation,
}) => {
  const {
    data: dataListLocation,
    isLoading,
    handleScroll,
    handleSearch,
    locationUsed,
    handleDeleteLocation,
    handleSelectLocation,
  } = useLocationDiscover()
  const {
    data: dataListCG,
    isLoading: isLoadingCG,
    handleScroll: handleScrollCG,
    handleSearch: handleSearchCG,
    careerUsed,
    handleSelectCareer,
    handleDeleteCareer,
  } = useCareerGoalDiscover()

  const {
    data: dataGS,
    isLoading: isLoadingGS,
    handleScroll: handleScrollGS,
    handleSelectGS,
    handleDeleteGS,
    gsUsed,
    handleSearch: handleSearchGS,
  } = useGreenSkills()

  useEffect(() => {
    if (locationUsed?.length !== 0 || paramLocation !== null) {
      handleChangeFilter(
        "location_id",
        locationUsed?.map((item: any) => item.value).join(","),
      )
    }
    //
  }, [dataListLocation])

  useEffect(() => {
    if (careerUsed?.length !== 0 || paramCareer !== null) {
      handleChangeFilter(
        "career_goal",
        careerUsed?.map((item: any) => item.value).join(","),
      )
    }
    //
  }, [dataListCG])

  useEffect(() => {
    if (gsUsed?.length !== 0 || paramGS !== null) {
      handleChangeFilter(
        "green_skills_id",
        gsUsed?.map((item: any) => item.value).join(","),
      )
    }
    //
  }, [dataGS])

  return (
    <div className=" p-5 rounded-xl shadow-md bg-custom-white ">
      <div className=" mb-5 p-4 rounded-xl shadow-md bg-white ">
        <div className="grid grid-cols-3 justify-between items-center gap-3 ">
          <div className=" font-medium col-span-3">
            <div className=" font-semibold">Location</div>
          </div>
          <div className="bg-grey-select col-span-3">
            <Select
              showSearch
              onSearch={handleSearch}
              loading={isLoading}
              className="w-full"
              value={null}
              filterOption={false}
              placeholder="Select Location"
              options={dataListLocation?.map((item: any) => ({
                label: item.name,
                value: item.id,
                disabled: item?.disabled,
              }))}
              onPopupScroll={handleScroll}
              onSelect={(_, self) => {
                if (locationUsed?.length < 5) {
                  handleSelectLocation(self)
                } else {
                  message.info("You can only select up to 5 location")
                }
              }}
            />
          </div>
        </div>

        {locationUsed && locationUsed?.length > 0 && (
          <div className=" mt-3">
            {locationUsed?.map((item: any) => (
              <Tag
                key={item?.value}
                closable
                className="my-1 rounded-md shadow-md py-1 px-2 text-backdrop"
                onClose={() => handleDeleteLocation(item.value)}
              >
                {truncateText(item.label, 40)}
              </Tag>
            ))}
          </div>
        )}
      </div>

      <div className=" mb-5 p-4 rounded-xl shadow-md bg-white ">
        <div className="grid grid-cols-3 justify-between items-center gap-3 ">
          <div className=" font-medium col-span-3">
            <div className=" font-semibold">Career Aspirations</div>
          </div>
          <div className="bg-grey-select col-span-3">
            <Select
              showSearch
              onSearch={handleSearchCG}
              loading={isLoadingCG}
              className="w-full"
              value={null}
              filterOption={false}
              placeholder="Select Career Aspirations"
              options={dataListCG?.map((item: any) => ({
                label: item.name,
                value: item.id,
                disabled: item?.disabled,
              }))}
              onPopupScroll={handleScrollCG}
              onSelect={(_, self) => {
                if (gsUsed?.length < 5) {
                  handleSelectCareer(self)
                } else {
                  message.info("You can only select up to 5 career aspiration")
                }
              }}
            />
          </div>
        </div>

        {careerUsed && careerUsed?.length > 0 && (
          <div className=" mt-3">
            {careerUsed?.map((item: any) => (
              <Tag
                key={item?.value}
                closable
                className="my-1 rounded-md shadow-md py-1 px-2 text-backdrop"
                onClose={() => handleDeleteCareer(item.value)}
              >
                {truncateText(item.label, 40)}
              </Tag>
            ))}
          </div>
        )}
      </div>

      <div className="p-4 rounded-xl shadow-md bg-white ">
        <div className="grid grid-cols-3 justify-between items-center gap-3 ">
          <div className=" font-medium col-span-3">
            <div className=" font-semibold mb-1">Green Skill</div>
          </div>
          <div className="bg-grey-select col-span-3">
            <Select
              showSearch
              onSearch={handleSearchGS}
              loading={isLoadingGS}
              className="w-full"
              value={null}
              filterOption={false}
              placeholder="Select Green Skill"
              options={dataGS?.map((item: any) => ({
                label: item.name,
                value: item.id,
                disabled: item?.disabled,
              }))}
              onPopupScroll={handleScrollGS}
              onSelect={(_, self) => {
                if (gsUsed?.length < 5) {
                  handleSelectGS(self)
                } else {
                  message.info("You can only select up to 5 skills")
                }
              }}
            />
          </div>
        </div>
        {gsUsed && gsUsed?.length > 0 && (
          <div className=" mt-3">
            {gsUsed?.map((item: any) => (
              <Tag
                key={item?.value}
                closable
                className="my-1 rounded-md shadow-md py-1 px-2 text-backdrop"
                onClose={() => handleDeleteGS(item.value)}
              >
                {truncateText(item.label, 40)}
              </Tag>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
