import React from "react"
import { PageContent, PageHeader } from "../../../common/layout"
import {
  ArrowLeftOutlined,
  UserOutlined,
  EnvironmentOutlined,
  LinkOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  FacebookOutlined,
  InstagramOutlined,
  GithubOutlined,
} from "@ant-design/icons"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { useGetSingleJobPost, usePublishJob } from "../hooks/useJobPosts"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FiveCircleRate } from "../../../common/components"
import { Button, Tag, message } from "antd"
import ReactQuill from "react-quill"
import { useGetTenants } from "../../../common/hooks/useTenant"
import schema from "./CreateJob/formSchema"
import { useJobClosed } from "../hooks/useJobClosed"

export function SingleJobPage() {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  if (id === undefined) navigate("/not-found")
  const [singleJobPostQuery] = useGetSingleJobPost(id as unknown as string)
  const updateDraftMutation = usePublishJob()
  const { repost } = useJobClosed()

  const { reset, trigger } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      job_type: null,
      location: null,
      remote: false,
      salary_currency: null,
      salary_from: null,
      salary_up_to: null,
      salary_frequency: null,
      description: "",
      team: "",
      green_skills: null,
      technical_skills: null,
      critical_skills: null,
      languages: null,
      screening_questions: null,
    },
  })

  React.useEffect(() => {
    if (singleJobPostQuery?.data?.data?.data) {
      reset({
        name: singleJobPostQuery?.data?.data?.data?.name ?? "",
        job_type: singleJobPostQuery?.data?.data?.data?.job_type ?? null,
        location: singleJobPostQuery?.data?.data?.data?.location ?? null,
        remote: singleJobPostQuery?.data?.data?.data?.remote ?? false,
        team: singleJobPostQuery?.data?.data?.data?.team ?? "",
        salary_currency:
          singleJobPostQuery?.data?.data?.data?.salary_currency ?? null,
        salary_from: singleJobPostQuery?.data?.data?.data?.salary_from ?? null,
        salary_up_to:
          singleJobPostQuery?.data?.data?.data?.salary_up_to ?? null,
        salary_frequency:
          singleJobPostQuery?.data?.data?.data?.salary_frequency ?? null,
        description: singleJobPostQuery?.data?.data?.data?.description ?? "",
        green_skills:
          singleJobPostQuery?.data?.data?.data?.green_skills ?? null,
        technical_skills:
          singleJobPostQuery?.data?.data?.data?.technical_skills ?? null,
        critical_skills:
          singleJobPostQuery?.data?.data?.data?.critical_skills ?? null,
        languages: singleJobPostQuery?.data?.data?.data?.languages ?? null,
        screening_questions:
          singleJobPostQuery?.data?.data?.data?.screening_questions ?? null,
      })
    }
  }, [reset, singleJobPostQuery?.data?.data?.data])
  const tenant = useGetTenants()
  const handlePublish = async () => {
    const result = await trigger()
    if (result && id) {
      await updateDraftMutation.mutateAsync(id)
      navigate("/jobs/my-job-posts")
    } else {
      navigate("/jobs/my-job-posts/create/" + id)
      message.error(
        "Your Job Post still requires some data before it can be published",
      )
    }
  }
  return (
    <PageContent
      contentRightSidebar={
        <div className="bg-white px-6 py-5">
          <div className="text-lg font-semibold">
            {tenant?.data?.data?.data?.name ?? ""}
          </div>
          <div className="flex justify-between w-full items-center">
            <div className="">
              <div className="">
                <EnvironmentOutlined className="text-gray-500" />
                <span className="text-xs text-gray-500 ml-2">
                  {tenant?.data?.data?.data?.headquarter?.name ?? ""}
                </span>
              </div>
              <div className="">
                <UserOutlined className="text-gray-500" />
                <span className="text-xs text-gray-500 ml-2">
                  {tenant?.data?.data?.data?.size?.name ?? ""}
                </span>
              </div>
            </div>
            <img
              src={tenant?.data?.data?.data?.logo}
              alt="avatar"
              className="w-[30%]"
            />
          </div>
          <div className="mt-4">{tenant?.data?.data?.data?.description}</div>
          <div className="mt-4 flex gap-x-5 items-center">
            <LinkOutlined className="" onClick={() => {}} />
            <LinkedinOutlined className="" onClick={() => {}} />
            <TwitterOutlined className="" onClick={() => {}} />
            <FacebookOutlined className="" onClick={() => {}} />
            <InstagramOutlined className="" onClick={() => {}} />
            <GithubOutlined className="" onClick={() => {}} />
          </div>
        </div>
      }
      header={
        <PageHeader
          title={
            <div className="flex items-center gap-x-4">
              <ArrowLeftOutlined
                className="text-base cursor-pointer"
                onClick={() => {
                  location.pathname.includes("/jobs/closed/detail")
                    ? navigate("/jobs/my-job-posts?status=CLOSE")
                    : navigate("/jobs/my-job-posts")
                }}
              />
              <div className="font-semibold text-2xl">
                {singleJobPostQuery?.data?.data?.data?.name}
              </div>
            </div>
          }
        >
          <div className="flex gap-x-[10px] items-center justify-between">
            <div className="flex items-center w-full">
              <div className="text-secondary">
                {tenant?.data?.data?.data?.name}{" "}
              </div>
              <div>
                <span className="font-semibold ml-4">Team/Function</span>
                <span className="ml-2">
                  {singleJobPostQuery?.data?.data?.data?.team}
                </span>
              </div>
              {location.pathname.includes("/jobs/closed/detail") ? (
                <div className="flex gap-x-2 ml-auto">
                  <Button
                    loading={repost.loading}
                    onClick={() => repost.action(id)}
                    className="bg-primary text-white"
                  >
                    Repost
                  </Button>
                </div>
              ) : singleJobPostQuery?.data?.data?.data?.status === "DRAFT" ? (
                <div className="flex gap-x-2 ml-auto">
                  <Button
                    className=""
                    onClick={() => navigate("/jobs/my-job-posts/create/" + id)}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={async () => handlePublish()}
                    className="bg-primary text-white"
                  >
                    Publish Job
                  </Button>
                </div>
              ) : (
                <div className="flex gap-x-2 ml-auto">
                  <Button onClick={() => {}} className="bg-primary text-white">
                    View Candidates
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-x-9 items-center mt-3">
            <div className="text-gray-500 text-xs ">
              <span>Job Location:</span>{" "}
              <span>
                {singleJobPostQuery?.data?.data?.data?.location?.name}
              </span>
            </div>
            <div className="text-gray-500 text-xs ">
              <span>Gross Salary:</span>{" "}
              {singleJobPostQuery?.data?.data?.data?.salary_currency === null &&
              singleJobPostQuery?.data?.data?.data?.salary_frequency ===
                null ? (
                "N/A"
              ) : (
                <>
                  <span>
                    {
                      singleJobPostQuery?.data?.data?.data?.salary_currency
                        ?.name
                    }{" "}
                  </span>
                  <span>
                    {singleJobPostQuery?.data?.data?.data?.salary_from}
                  </span>
                  <span>
                    {singleJobPostQuery?.data?.data?.data?.salary_from &&
                      singleJobPostQuery?.data?.data?.data?.salary_up_to &&
                      " - "}
                  </span>
                  <span>
                    {singleJobPostQuery?.data?.data?.data?.salary_up_to}{" "}
                  </span>
                  <span>
                    {
                      singleJobPostQuery?.data?.data?.data?.salary_frequency
                        ?.name
                    }
                  </span>
                </>
              )}
            </div>
            <div className="text-gray-500 text-xs ">
              <span>Type:</span>{" "}
              <span>
                {singleJobPostQuery?.data?.data?.data?.job_type?.name}
              </span>
            </div>
          </div>
        </PageHeader>
      }
      content={
        <div className="px-5 py-6 bg-white">
          <h2 className="font-bold text-xl">
            Job Description and Requirements
          </h2>
          <div className="mt-4">
            <ReactQuill
              readOnly
              className="text-backdrop text-base"
              value={singleJobPostQuery.data?.data?.data?.description ?? "-"}
              theme="bubble"
            />
          </div>
          <div className="mt-4 font-semibold text-lg">Green Skills</div>
          <div className="mt-4">
            {singleJobPostQuery?.data?.data?.data?.green_skills?.map((gs) => (
              <div
                key={gs?.id}
                className="flex mt-4 items-center justify-between"
              >
                <div className="text-secondary cursor-pointer">{gs?.name}</div>
                <div className="">
                  <FiveCircleRate
                    disabled={true}
                    defaultValue={gs?.proficiency}
                    count={5}
                    circleSize={15}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 font-semibold text-lg">Technical Skills</div>
          <div className="flex flex-wrap gap-x-2 mt-4">
            {singleJobPostQuery?.data?.data?.data?.technical_skills?.map(
              (ts) => <Tag key={ts?.id}>{ts?.name}</Tag>,
            )}
          </div>
          <div className="mt-4 font-semibold text-lg">Soft Skills</div>
          <div className="grid grid-cols-12 items-center ml-4 mt-4">
            <div className="col-span-5"></div>
            <div className="col-span-7 flex items-center gap-20 justify-center">
              <p className="font-bold">Basic</p>
              <p className="font-bold">Intermediate</p>
              <p className="font-bold">Advanced</p>
            </div>
          </div>
          {singleJobPostQuery?.data?.data?.data?.critical_skills?.map(
            (item, index) => (
              <div key={index}>
                <div className="grid grid-cols-12 items-center mt-5 ml-4">
                  <div className="col-span-5 flex items-center gap-9">
                    <div
                      className="border cursor-text py-1 px-2 text-backdrop rounded-full"
                      style={{
                        backgroundColor: "transparent",
                        color: "#36505E",
                        border: "#36505E 3px solid",
                      }}
                    >
                      {item?.name}
                    </div>
                  </div>
                  <div className="col-span-7 flex items-center gap-20 justify-center relative">
                    <div className="h-4 w-full bg-gray-300 rounded-lg relative"></div>

                    {item?.proficiency?.name && (
                      <div
                        className={`h-4 w-[32%] bg-[#FFEFA0] rounded-lg absolute ${
                          item?.proficiency?.name === "Beginner"
                            ? "left-[1%]"
                            : item?.proficiency?.name === "Intermediate"
                              ? "left-[30%]"
                              : item?.proficiency?.name === "Advanced"
                                ? "left-[67%]"
                                : ""
                        } `}
                        style={{ border: "4px solid #36505E" }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      }
    />
  )
}
