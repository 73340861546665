import { UseQueryResult, useQuery } from "react-query"
import {
  TemplateLibraryQueryType,
  TemplateLibraryResponse,
  TemplateLibraryResponseType,
} from "../../templates-library/types/response"
import {
  ApiResponsePaginated,
  ApiResponseSingle,
} from "../../../common/types/ApiResponse"
import { AxiosError } from "../../../common/errors"
import { useAxiosWithMsal } from "../../../common/hooks"
import { AxiosInstance, AxiosResponse } from "axios"
import { message } from "antd"

export const QUERYMYTEMPLATELIST: string = "my-template-library-list"
export const QUERYMYTEMPLATEDETAIL: string = "my-template-library-detail"

export const useFetchMyTemplateLibraryList = (
  queryParam: TemplateLibraryQueryType,
): UseQueryResult<
  ApiResponsePaginated<TemplateLibraryResponseType>,
  AxiosError
> => {
  const getAxiosInstance = useAxiosWithMsal()
  const fetchTemplateLibrary = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<TemplateLibraryResponse>("/jobs/my-templates", {
        params: {
          ...queryParam,
        },
      })
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYMYTEMPLATELIST}: + ${error.message}`,
      )
    }
  }
  return useQuery([QUERYMYTEMPLATELIST, queryParam], fetchTemplateLibrary, {
    onError: (error: AxiosError) => {
      message.error(
        `An error occurred while fetching ${QUERYMYTEMPLATELIST}: + ${error.message}`,
      )
    },
  })
}

export const useFetchDetailMyTemplateLibrary = (
  id: string,
): UseQueryResult<
  ApiResponseSingle<TemplateLibraryResponseType>,
  AxiosError
> => {
  const getAxiosInstance = useAxiosWithMsal()

  const fetchDetailTemplateLibrary = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      const response: AxiosResponse<
        ApiResponseSingle<TemplateLibraryResponseType>
      > = await api.get(`/jobs/my-templates/${id}`)
      return response.data
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYMYTEMPLATEDETAIL}: + ${error.message}`,
      )
      throw error
    }
  }

  const queryResult = useQuery(
    [QUERYMYTEMPLATEDETAIL, id],
    fetchDetailTemplateLibrary,
    {
      onError: (error: AxiosError) => {
        message.error(
          `An error occurred while fetching ${QUERYMYTEMPLATEDETAIL}: + ${error.message}`,
        )
      },
    },
  )

  return queryResult
}
