import { useState } from "react"
import {
  useFetchDetailMyTemplateLibrary,
  useFetchMyTemplateLibraryList,
} from "../apis/rest"
import { debounce } from "../../../utils/debounce"
import { useListTemplateLibrary } from "../../templates-library/hooks/useTemplateLibrary"

export const useListMyTemplateLibrary = () => {
  const [name, setName] = useState<string | undefined>(undefined)
  const [limit, setLimit] = useState<number | undefined>(21)
  const [page, setPage] = useState<number | undefined>(1)

  const myTemplateLibraryListResponse = useFetchMyTemplateLibraryList({
    name,
    limit,
    page,
  })

  const handleSearch = debounce((e: any) => {
    setName(e.target.value)
  }, 1000)

  // call from module template library
  const { queryTemplateResponse: libraryTemplateQuery } =
    useListTemplateLibrary()

  return {
    templateResponse: {
      data: myTemplateLibraryListResponse.data?.data?.data?.list,
      isLoading: myTemplateLibraryListResponse.isLoading,
    },
    queryTemplateResponse: {
      handleSearch,
      setLimit,
      limit,
      page,
      setPage,
      handleUnSaveJob: libraryTemplateQuery?.handleUnSaveJob,
    },
  }
}

export const useDetailMyTemplateLibrary = (templateID: string) => {
  const myTemplateLibrary = useFetchDetailMyTemplateLibrary(templateID)

  const { queryTemplateResponse: libraryTemplateQuery } =
    useListTemplateLibrary()

  return {
    templateDetailResponse: {
      data: myTemplateLibrary?.data?.data,
      isLoading: myTemplateLibrary.isLoading,
      handleUnSaveJob: libraryTemplateQuery?.handleUnSaveJob,
    },
  }
}
