import React from "react"
import { EmptyData } from "../../../../common/components/EmptyData"

interface Props {
  data?: string
}

export const Description: React.FC<Props> = ({ data }) => {
  return (
    <div className="p-5 rounded-xl shadow-md bg-[#FAFAFA] mb-5">
      <h3 className="">Description</h3>
      {data ? (
        <div
          className="leading-normal"
          dangerouslySetInnerHTML={{ __html: data }}
        />
      ) : (
        <EmptyData />
      )}
    </div>
  )
}
