import { InfiniteAutoComplete } from "../../../../../common/components"
import {
  Item,
  Language,
  useLanguagesInfinity,
  useProficiencies,
} from "../../../../../common/hooks/useReferences"
import { Select, Table } from "antd"
import { useFieldArray, useFormContext } from "react-hook-form"
import { CreateJobSchema } from "../types"
import { DeleteOutlined } from "@ant-design/icons"

export const Languages = () => {
  const {
    control,
    formState: { errors },
    // setValue,
  } = useFormContext<CreateJobSchema>()

  const {
    fields: languageFields,
    append: appendLanguage,
    remove: removeLanguage,
    update: updateLanguage,
  } = useFieldArray({
    control,
    name: "languages",
    keyName: "id_",
  })

  const languageProficencies = useProficiencies("language")

  const handleSelectLanguage = (
    value: string,
    option: Item | undefined | null,
  ) => {
    if (option && option.id) {
      const isAlreadySelected = languageFields.some((item) => {
        return item.id === option?.id
      })
      if (!isAlreadySelected) {
        appendLanguage({
          ...option,
          proficiency: languageProficencies?.data?.data?.data[0] ?? null,
        })
      }
    }
  }

  const handleSelectProficiencyLanguage = (
    proficiency: Item,
    id: string,
    index: number,
  ) => {
    console.log("Language Proficiency: ", proficiency)
    const languageItem = languageFields[index]
    updateLanguage(index, {
      ...languageItem,
      proficiency: { ...proficiency },
    })
  }

  const languageTableCols = [
    {
      title: "Language",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Proficiency",
      dataIndex: "proficiency",
      key: "proficiency",
      render: (proficiency: Item, record: Language, index: number) => (
        <Select
          className="w-full"
          placeholder="Select Proficiency for the language"
          options={languageProficencies?.data?.data?.data}
          fieldNames={{ value: "id", label: "name" }}
          loading={languageProficencies?.isLoading}
          value={proficiency?.id}
          onSelect={(id, proficiency: Item) =>
            handleSelectProficiencyLanguage(proficiency, record?.id, index)
          }
        />
      ),
    },
    {
      title: "",
      key: "action",
      render: (_: any, record: Item, index: number) => (
        <>
          <DeleteOutlined onClick={() => removeLanguage(index)} />
        </>
      ),
      width: 100,
    },
  ]
  return (
    <div className="basic-card bg-custom-white shadow-custom-lg">
      <h3 className="font-semibold text-lg">Languages</h3>
      {errors?.languages?.message && (
        <div className="text-danger -mt-3">{errors?.languages?.message}</div>
      )}
      <InfiniteAutoComplete
        useDataHook={useLanguagesInfinity}
        onSelect={handleSelectLanguage}
        className="w-full"
        placeholder="Search Languages"
      />
      <div className="">
        <Table
          columns={languageTableCols}
          className="mt-6"
          pagination={false}
          //  @ts-ignore
          dataSource={languageFields}
        />
      </div>
      {/* <div
      className='text-secondary text-xs font-semibold my-5 cursor-pointer '
      onClick={() => setShowAddNewLangModal(true)}>
      + Add New
    </div> */}
    </div>
  )
}
