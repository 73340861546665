import { useState } from "react"
import { AxiosInstance } from "axios"
import { axiosInstance } from "../../config/axios"

export const useAxiosWithoutMsal = (): (() => Promise<AxiosInstance>) => {
  const [initialized, setInitialized] = useState<boolean>(false)

  const initializeAxiosInstance = async () => {
    // Any initialization logic can go here
    // For example, setting up default headers or other configurations

    // Simulating asynchronous initialization process
    await new Promise((resolve) => setTimeout(resolve, 1000))

    setInitialized(true)
  }

  const getAxiosInstance = async () => {
    if (!initialized) {
      await initializeAxiosInstance()
    }

    // Any additional logic can go here
    // For example, setting headers or interceptors

    return axiosInstance
  }

  return getAxiosInstance
}
