import React from "react"
import TLSkillProficiency from "./TLSkillProficiency"
import { Skeleton } from "antd"

interface TLGreenSkillsProps {
  greenSkills: any
}

const TLGreenSkills: React.FC<TLGreenSkillsProps> = ({ greenSkills }) => {
  return (
    <div className="px-6 py-2 bg-[#FAFAFA] shadow-custom rounded-xl">
      <h3>Green Skills</h3>

      <div className="flex flex-col gap-3">
        {greenSkills?.isLoading ? (
          <div className="space-y-3 my-3">
            <Skeleton.Button
              active={true}
              size={"default"}
              shape={"default"}
              block={true}
            />
            <Skeleton.Button
              active={true}
              size={"default"}
              shape={"default"}
              block={true}
            />
            <Skeleton.Button
              active={true}
              size={"default"}
              shape={"default"}
              block={true}
            />
          </div>
        ) : (
          greenSkills?.data?.green_skills?.map((item: any, index: number) => {
            return <TLSkillProficiency key={index} item={item} />
          })
        )}
      </div>
    </div>
  )
}

export default TLGreenSkills
