/* eslint-disable */
import React from "react"
import { EditJobPostDetails } from "./EditJobPostDetails"

interface Props {
  isLoading?: boolean
  children: React.ReactNode
  className?: string
  rightSidebarChildren?: React.ReactNode
  rightSideClassName?: string
}

export const TabCompLayout: React.FC<Props> = ({
  isLoading,
  children,
  className = "",
  rightSideClassName = "",
  rightSidebarChildren,
}) => {
  return (
    <div className="grid grid-cols-12 gap-x-6">
      <div className={`col-span-8 ${className}`}>{children}</div>
      <div className={`col-span-4 relative `}>
        <div className={`${rightSideClassName} sticky top-5`}>
          {rightSidebarChildren}
          <EditJobPostDetails isLoading={isLoading ?? false} />
        </div>
      </div>
    </div>
  )
}
