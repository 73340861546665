import { Applicant } from "../../job-posts/inteface/detailApplicant"
import { Talent } from "../../talent-finder/components/type/database"

export const talentData: Talent = {
  first_name: "M******",
  last_name: "****g",
  profile_picture: "",
  avatar:
    "https://stgreenskills001.blob.core.windows.net/default-avatars/18.png",
  profile_headline:
    "Dedicated professional with expertise in Marketing, Recruitment, and Accounting, Seeking to Drive Sustainable Finance Initiatives.",
  about_me:
    "<p><strong>[This CV is for demonstration purposes. Data is fictitious]</strong></p><p>I am a dynamic and results-oriented professional with a diverse background in marketing, recruitment, and accounting. With a passion for sustainability and a keen interest in finance, I am eager to transition into a role in green finance. I am known for my strong analytical skills, strategic thinking, and ability to adapt to new challenges.</p>",
  country: "Singapore",
  profile_strenght: 91,
  languages: [
    {
      id: "709fa900-c7f1-4dd4-a11a-10693750cc1a",
      language: {
        id: "642a9fea17b91df92072bea8",
        name: "English",
      },
      proficiency: {
        id: "6429856487b7de79969d6d78",
        name: "Full working proficiency",
        level: 4,
      },
    },
    {
      id: "87ee7999-0e72-4aaf-9fbd-5b3d283bb9fd",
      language: {
        id: "642a9fea17b91df92072be9c",
        name: "Chinese",
      },
      proficiency: {
        id: "6429857e87b7de79969d6d79",
        name: "Native or Bilingual",
        level: 5,
      },
    },
    {
      id: "171af49b-67bc-4df8-a178-faf527907913",
      language: {
        id: "642a9fea17b91df92072bee5",
        name: "Malay",
      },
      proficiency: {
        id: "6429851087b7de79969d6d76",
        name: "Limited working proficiency",
        level: 2,
      },
    },
  ],
  educations: [
    {
      id: "ad0113cf-cafb-4c18-9acc-4361bbc73bb8",
      institution: {
        id: "9aba8334-8b3b-448c-84b4-932dc11fd1be",
        name: "singapore management university",
        image:
          "https://stgreenskills001.blob.core.windows.net/others/9aba8334-8b3b-448c-84b4-932dc11fd1be.jpg",
      },
      degree: "Bachelor ",
      field_of_study: "Business Administration",
      online: false,
      start_at: "2023-12-04T07:48:27Z",
      end_at: "2025-12-31T07:48:29Z",
    },
    {
      id: "5c923d89-4dbe-45b2-8fab-2cbd289fcb92",
      institution: {
        id: "3161a4b0-dd91-4ea0-bc41-0ce22b4ee935",
        name: "national university singapore",
        image:
          "https://stgreenskills001.blob.core.windows.net/others/3161a4b0-dd91-4ea0-bc41-0ce22b4ee935.jpg",
      },
      degree: "Diploma",
      field_of_study: "Accounting and Finance",
      online: true,
      start_at: "2020-12-01T07:46:28Z",
      end_at: "2023-01-31T07:46:32Z",
    },
  ],
  job_histories: [
    {
      id: "da90a868-539f-47c7-b1a3-557ab03fa054",
      company: {
        id: "fe2ffec7-128e-4a5b-9c43-3b28ddd85bc6",
        name: "g*****",
        image: "",
      },
      employment_type: {
        id: "6429865687b7de79969d6d80",
        name: "Part-time",
      },
      country: {
        id: "63e0a50f94326da9a83a462b",
        name: "Singapore",
      },
      current_role: true,
      start_at: "2022-12-01T07:40:24Z",
      end_at: "",
      remote: true,
      job_title: "Marketing Specialist",
      description:
        "<p>Developed and executed comprehensive marketing campaigns, resulting in a 20% increase in customer acquisition. Conducted market research and analysis to identify emerging trends and opportunities in the industry. Collaborated with cross-functional teams to optimize product launches and promotional activities.</p>",
    },
    {
      id: "727fe7ba-fe6f-4b5f-9ef4-f2ed94358296",
      company: {
        id: "5b6dc84a-7c86-4e30-8e3f-f5f287e6b3e0",
        name: "microsoft",
        image:
          "https://stgreenskills001.blob.core.windows.net/others/5b6dc84a-7c86-4e30-8e3f-f5f287e6b3e0.jpg",
      },
      employment_type: {
        id: "6429861487b7de79969d6d7e",
        name: "Full-time",
      },
      country: {
        id: "63e0a50f94326da9a83a462b",
        name: "Singapore",
      },
      current_role: false,
      start_at: "2021-12-01T07:42:48Z",
      end_at: "2023-06-01T07:42:54Z",
      remote: false,
      job_title: "Recruitment Consultant ",
      description:
        "<ul><li>Managed full-cycle recruitment process, successfully placing candidates in roles across various industries. </li><li>Built strong relationships with clients and candidates, ensuring high levels of client satisfaction and candidate retention. </li><li>Conducted interviews, assessed candidates' skills, and provided valuable career advice.</li></ul>",
    },
    {
      id: "60d9877a-927d-45ab-961e-70fedcad8df3",
      company: {
        id: "72fba1b6-c013-40bf-9f46-c1b387e003c6",
        name: "food panda",
        image:
          "https://stgreenskills001.blob.core.windows.net/others/72fba1b6-c013-40bf-9f46-c1b387e003c6.jpg",
      },
      employment_type: {
        id: "6429862587b7de79969d6d7f",
        name: "Internship",
      },
      country: {
        id: "63e0a50f94326da9a83a462b",
        name: "Singapore",
      },
      current_role: false,
      start_at: "2018-12-01T07:39:27Z",
      end_at: "2019-02-28T07:39:37Z",
      remote: false,
      job_title: "Accounting Assistant",
      description:
        "<p>Assisted in the preparation of financial statements, ensuring compliance with regulatory standards. Managed accounts payable and receivable, reconciled bank statements, and assisted in budgeting and forecasting. Conducted internal audits to identify process improvements and enhance financial controls.</p>",
    },
  ],
  certification: [
    {
      id: "7ec68b2d-70ae-4d6e-a4cc-79ca6c9ccb67",
      organisation: {
        id: "0710d822-2bb0-4381-b2b1-93622cd7bf86",
        name: "microsoft",
        image:
          "https://stgreenskills001.blob.core.windows.net/others/0710d822-2bb0-4381-b2b1-93622cd7bf86.jpg",
      },
      certification_name: "Microsoft 365 Certified: Administrator Expert",
      credentials_url: "",
      has_expiry_date: true,
      start_at: "2023-12-01T07:10:00Z",
      end_at: "",
    },
  ],
  career_aspiration: [
    {
      id: "9489b9f6-8e6f-438f-a71e-919278a20d28",
      name: "Landscape Supervisor ",
    },
    {
      id: "c6ba4515-bf39-449a-b6ab-b080cd2c5f98",
      name: "Financial Controller",
    },
    {
      id: "9244e1f6-4bcd-49a1-8d9f-289dacbc65ce",
      name: "Sustainable Finance Analyst",
    },
  ],
  hard_skill: [
    {
      name: "Valuation",
      value: 1,
    },
    {
      name: "Accounting and Tax Systems",
      value: 1,
    },
    {
      name: "Financial Reporting Quality",
      value: 1,
    },
    {
      name: "Professional and Business Ethics",
      value: 1,
    },
    {
      name: "Vegetation Planting",
      value: 1,
    },
    {
      name: "Internal Controls",
      value: 1,
    },
    {
      name: "Audit Compliance",
      value: 1,
    },
    {
      name: "Change Management",
      value: 1,
    },
    {
      name: "Vendor Management",
      value: 1,
    },
    {
      name: "Disruption Management",
      value: 1,
    },
    {
      name: "Workflow Digitalisation",
      value: 1,
    },
  ],
  soft_skill: [
    {
      id: "13769c82-493f-44d7-b768-c0c64b22a3f9",
      name: "Decision Making",
      proficiency: 0,
    },
    {
      id: "4b81042e-144f-4d22-8be6-8f0cc7e99ef7",
      name: "Transdisciplinary Thinking",
      proficiency: 2,
    },
    {
      id: "8f60ebcd-8ea4-4c60-8e75-4c8c7604bc44",
      name: "Creative Thinking",
      proficiency: 1,
    },
    {
      id: "95b62d20-96df-4f7a-8d9d-0d5184a72376",
      name: "Problem Solving",
      proficiency: 3,
    },
    {
      id: "fb4e0807-f4c5-4b81-92d5-26f963a6a8ce",
      name: "Sense Making",
      proficiency: 0,
    },
    {
      id: "746879e3-5102-4e16-828a-249c43a4eb1a",
      name: "Developing People",
      proficiency: 0,
    },
    {
      id: "7a8127f6-8ec2-43d7-a7e3-f39f97d6f43e",
      name: "Building Inclusivity",
      proficiency: 2,
    },
    {
      id: "91fe0b67-1d2c-4085-8f5d-39905edcf109",
      name: "Customer Orientation",
      proficiency: 2,
    },
    {
      id: "a217eb72-7a43-4324-8547-519ec2b5ae33",
      name: "Collaboration",
      proficiency: 1,
    },
    {
      id: "b4a7b37b-4d33-4bdf-9eb5-320a4f8c2f91",
      name: "Influence",
      proficiency: 0,
    },
    {
      id: "d9d4d6b5-ee7d-4916-bd0f-6b1ff2815080",
      name: "Communication",
      proficiency: 2,
    },
    {
      id: "1a4f5bfc-4dc4-40c7-8c94-0dfcb0b8e4b7",
      name: "Global Perspective",
      proficiency: 0,
    },
    {
      id: "525199f1-4227-4f7c-8645-f69b69d352af",
      name: "Adaptability",
      proficiency: 3,
    },
    {
      id: "5d2b8cc6-b3dd-41a3-ba63-6c877c7003fc",
      name: "Learning Agility",
      proficiency: 1,
    },
    {
      id: "6991ff1c-af6a-4766-8547-239b24b1b29c",
      name: "Self Management",
      proficiency: 1,
    },
    {
      id: "b00f84a9-9995-4fcd-9125-5cc120e74612",
      name: "Digital Fluency",
      proficiency: 1,
    },
  ],
  green_skill_proficient_in: [
    {
      id: "ab617903-76a8-4f98-a6b7-31fc638f2e1f",
      name: "Climate Change Management",
      image:
        "https://stgreenskills001.blob.core.windows.net/assets/ab617903-76a8-4f98-a6b7-31fc638f2e1f",
      total_level: 5,
      total_available_level: 2,
      total_purchased_level: 1,
      total_completed_level: 1,
      certificate_url: "",
      signature_url:
        "https://stgreenskills001.blob.core.windows.net/greenskills-trainer-imgs-prod/b3928a92-0acf-48bd-b9d7-e22a45069094",
      is_signature_exist: true,
      is_request_verified: false,
    },
    {
      id: "62f5c4e3-c3db-4e3a-b006-fe1898b33b3d",
      name: "Climate Change Basics & Career Outlook",
      image:
        "https://stgreenskills001.blob.core.windows.net/assets/62f5c4e3-c3db-4e3a-b006-fe1898b33b3d",
      total_level: 5,
      total_available_level: 5,
      total_purchased_level: 2,
      total_completed_level: 2,
      certificate_url: "",
      signature_url:
        "https://stgreenskills001.blob.core.windows.net/greenskills-trainer-imgs-prod/b3928a92-0acf-48bd-b9d7-e22a45069094",
      is_signature_exist: true,
      is_request_verified: false,
    },
  ],
  green_skill_interest_in: [
    {
      id: "346b5810-eaa4-4eae-83af-c6943fa78904",
      name: "Green Building Strategy Implementation",
      image:
        "https://stgreenskills001.blob.core.windows.net/assets/346b5810-eaa4-4eae-83af-c6943fa78904",
    },
    {
      id: "b0b07263-9a24-47ea-93c5-f251654434fa",
      name: "Urban Design and Placemaking & Programming of Spaces",
      image:
        "https://stgreenskills001.blob.core.windows.net/assets/b0b07263-9a24-47ea-93c5-f251654434fa",
    },
    {
      id: "a26eeae5-1423-4cf4-b788-f315b1255d35",
      name: "Biophilic Design",
      image:
        "https://stgreenskills001.blob.core.windows.net/assets/a26eeae5-1423-4cf4-b788-f315b1255d35",
    },
    {
      id: "d0c5a993-5f7f-4baa-b3d2-899c2f9d9a27",
      name: "Environment Management in Landscape Operations",
      image:
        "https://stgreenskills001.blob.core.windows.net/assets/d0c5a993-5f7f-4baa-b3d2-899c2f9d9a27",
    },
    {
      id: "789dd7c3-6c88-4d65-9b1f-39179b248676",
      name: "Automation of Landscape Operations",
      image:
        "https://stgreenskills001.blob.core.windows.net/assets/789dd7c3-6c88-4d65-9b1f-39179b248676",
    },
  ],
  id: "",
  nick_name: "",
  email: "",
  linkedin: "",
  twitter: "",
  link: "",
}

export const applicantSampleData: Applicant = {
  id: "123",
  avatar: "https://example.com/avatar.jpg",
  username: "johndoe",
  stage: {
    name: "",
    id: "",
  },
  location: "New York, USA",
  full_name: "John Doe",
  profile_detail: talentData,
  screening_questions_detail: [],
  user_id: "789",
  is_error: false,
}
