import React from "react"
import { Navigate } from "react-router-dom"
import { useGetTenants } from "../../common/hooks/useTenant"
import { PageLoader } from "../../common/components"
interface PrivateRouteProps {
  children: React.ReactElement
}

export const OnboardingRoutes = ({ children }: PrivateRouteProps) => {
  const tenants = useGetTenants()

  if (tenants?.isLoading) return <PageLoader />

  const isTenantError =
    tenants?.isError &&
    !tenants?.isLoading &&
    tenants?.error?.response &&
    tenants?.error?.response?.data?.code === 400 &&
    tenants?.error?.response?.data?.data?.redirect_affected === "frontend" &&
    tenants?.error?.response?.data?.data?.type === "redirect"
  console.log("isTenantError", isTenantError)
  return isTenantError ? <>{children}</> : <Navigate to="/" replace />
}
