import React from "react"
import { EmptyData } from "../../../../common/components/EmptyData"

interface Props {
  data?: {
    id: string
    name: string
  }[]
}

export const TechnicalSkills: React.FC<Props> = ({ data }) => {
  return (
    <div className="p-5 rounded-xl shadow-md bg-[#FAFAFA] mb-5">
      <h3 className="">Technical Skills</h3>
      <div className="leading-normal">
        {data && data.length > 0 ? (
          data.map((item) => item.name).join(", ")
        ) : (
          <EmptyData />
        )}
      </div>
    </div>
  )
}
