import { Avatar, Skeleton, Tag } from "antd"
import { EnvironmentOutlined, EyeOutlined } from "@ant-design/icons"
import { Item } from "../../../types/common"
import { Avatar1 } from "../../../assets/images"

export type TalentProfile = {
  career_aspirations: Item[]
  connected: null
  default_avatar: string
  first_name: string
  id: string
  last_name: string
  links: null
  location: string
  nick_name: string
  profile_headline: string
  profile_picture: string
  roadmap: null
}

const GreenTalentProfile = ({
  talent,
  observ,
}: {
  talent: TalentProfile
  observ?: (node: Element | null) => void
}) => {
  return (
    <div
      ref={observ}
      className="py-[30px] px-[17px] col-span-full md:col-span-1 bg-custom-white shadow-custom rounded-[10px] flex flex-col"
    >
      <div className="flex flex-col gap-2 justify-center items-center">
        <Avatar
          size={141}
          src={talent?.profile_picture ?? talent?.default_avatar ?? Avatar1}
        />
        <div className="text-lg font-semibold">{talent?.nick_name}</div>
        <div className="flex gap-2 items-center text-darkGrey">
          <EnvironmentOutlined />
          <div>{!!talent?.location ? talent?.location : "Not Set"}</div>
        </div>
      </div>
      <p className="mt-2 mb-5">
        {talent?.profile_headline ??
          "Not proficient in writing about me section"}
      </p>
      <div className="bg-primary-bg p-[10px] space-y-3 flex-grow">
        <Tag icon={<EyeOutlined />}>My Green CV</Tag>
        <div className="text-center">Career Aspirations</div>
        <div className="flex items-center justify-center flex-wrap gap-3">
          {talent?.career_aspirations?.length > 0 ? (
            talent?.career_aspirations?.map((ca) => (
              <div
                key={ca?.id}
                className="rounded-full px-2  bg-background-light text-xs whitespace-nowrap overflow-clip max-w-full text-backdrop border-backdrop"
              >
                {ca?.name}
              </div>
            ))
          ) : (
            <div className="rounded-full px-2  bg-background-light text-xs text-backdrop whitespace-nowrap overflow-clip max-w-full border-backdrop">
              No aspirations yet.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const GreenTalentSkeleton = () => {
  return (
    <div className="py-[30px] px-[17px] col-span-full md:col-span-1 bg-custom-white shadow-custom rounded-[10px] flex flex-col">
      <div className="flex flex-col gap-4 justify-center">
        <Skeleton.Avatar active size={141} shape="circle" />
        <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
        <div className="flex gap-2 items-baseline my-3 text-darkGrey">
          <EnvironmentOutlined />
          <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
        </div>
      </div>
      <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
      <div className="bg-primary-bg py-[10px] space-y-3">
        <Tag icon={<EyeOutlined />}>My Green CV</Tag>
        <div className="text-center">Career Aspirations</div>
        <div className="flex items-center justify-center flex-wrap gap-3">
          <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
        </div>
      </div>
    </div>
  )
}

export default GreenTalentProfile
