import React from "react"
import { HomeOutlined } from "@ant-design/icons"
import { PageContent, PageHeader } from "../../../common/layout"
import { TalentFinders } from "../components/TalentFinders"

export const Connected = () => {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Talent Finder",
    },
    {
      title: "Talent Connected",
    },
  ]

  return (
    <PageContent
      header={
        <PageHeader
          title={"Green Talent Database"}
          breadcrumbItems={items}
        ></PageHeader>
      }
      content={<TalentFinders />}
    />
  )
}
