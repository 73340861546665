import { message } from "antd"
import { AxiosError, AxiosInstance, AxiosResponse } from "axios"
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query"
import {
  ApiResponsePaginated,
  ApiResponseSingle,
} from "../../../common/types/ApiResponse"
import {
  SavedTemplateLibraryType,
  TemplateLibraryQueryType,
  TemplateLibraryResponse,
  TemplateLibraryResponseType,
} from "../types/response"
import { useAxiosWithMsal } from "../../../common/hooks"
import React from "react"
import {
  QUERYMYTEMPLATEDETAIL,
  QUERYMYTEMPLATELIST,
} from "../../job-templates/apis/rest"

const QUERYNAMELIST: string = "template-library-list"
const QUERYNAMEDETAIL: string = "template-library-detail"

export const useFetchTemplateLibraryList = (
  queryParam: TemplateLibraryQueryType,
): UseQueryResult<
  ApiResponsePaginated<TemplateLibraryResponseType>,
  AxiosError
> => {
  const getAxiosInstance = useAxiosWithMsal()
  const fetchTemplateLibrary = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<TemplateLibraryResponse>("/jobs/templates", {
        params: {
          ...queryParam,
        },
      })
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYNAMELIST}: + ${error.message}`,
      )
    }
  }
  return useQuery([QUERYNAMELIST, queryParam], fetchTemplateLibrary, {
    onError: (error: AxiosError) => {
      message.error(
        `An error occurred while fetching ${QUERYNAMELIST}: + ${error.message}`,
      )
    },
  })
}

export const useFetchDetailTemplateLibrary = (
  id: string,
): UseQueryResult<
  ApiResponseSingle<TemplateLibraryResponseType>,
  AxiosError
> => {
  const getAxiosInstance = useAxiosWithMsal()

  const fetchDetailTemplateLibrary = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      const response: AxiosResponse<
        ApiResponseSingle<TemplateLibraryResponseType>
      > = await api.get(`/jobs/templates/${id}`)
      return response.data
    } catch (error: any) {
      message.error(
        `An error occurred while fetching ${QUERYNAMEDETAIL}: + ${error.message}`,
      )
      throw error
    }
  }

  const queryResult = useQuery(
    [QUERYNAMEDETAIL, id],
    fetchDetailTemplateLibrary,
    {
      onError: (error: AxiosError) => {
        message.error(
          `An error occurred while fetching ${QUERYNAMEDETAIL}: + ${error.message}`,
        )
      },
    },
  )

  return queryResult
}

export const usePostSaveTemplateLibrary = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const saveTemplate = React.useCallback(
    async (data: SavedTemplateLibraryType) => {
      const api = await getAxiosInstance()
      return api.post("/jobs/templates", {
        job_id: data?.job_id,
      })
    },
    [getAxiosInstance],
  )

  return useMutation(
    async (data: SavedTemplateLibraryType) => saveTemplate(data),
    {
      onSuccess: (data: any) => {
        message.success(data?.data?.data)
        setTimeout(() => {
          queryClient.invalidateQueries([QUERYNAMELIST])
          queryClient.invalidateQueries([QUERYNAMEDETAIL])
        }, 1000)
      },
      onError: (error: any) => {
        message.error(
          "An error occurred while save your job template: " +
            error.response?.data?.data,
          10,
        )
      },
    },
  )
}

export const useUnSaveTemplateLibrary = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const unsaveTemplate = React.useCallback(
    async (data: SavedTemplateLibraryType) => {
      const api = await getAxiosInstance()
      return api.delete(`/jobs/my-templates/${data?.job_id}`)
    },
    [getAxiosInstance],
  )

  return useMutation(
    async (data: SavedTemplateLibraryType) => unsaveTemplate(data),
    {
      onSuccess: () => {
        message.success("Unsave job template successfully")
        setTimeout(() => {
          queryClient.invalidateQueries([QUERYNAMELIST])
          queryClient.invalidateQueries([QUERYNAMEDETAIL])
          queryClient.invalidateQueries(QUERYMYTEMPLATELIST)
          queryClient.invalidateQueries(QUERYMYTEMPLATEDETAIL)
        }, 1000)
      },
      onError: (error: any) => {
        message.error(
          "An error occurred while unsave your job template: " +
            error.response?.data?.data,
          10,
        )
      },
    },
  )
}
