import React from "react"
// import { UserContext } from "../../context/user";
import { UserOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Input, Tag, Typography } from "antd"
import { Controller, useForm } from "react-hook-form"
import { User } from "../../context/user/user.context"
import ModalManagePassword from "../components/ModalManagePassword"

interface ViewProfileProps {
  handlePassword: () => void
  user: User | undefined
  imageUrl: string | undefined
  handleImageChange: (files: FileList | null) => void
  handleUpdate: (val: any) => void
  loading: boolean
  isOpenFormPassword: boolean
  showModalPassword: () => void
  isConfirmLoading: boolean
  handleChangePassword: (val: any) => void
  hideModalPassword: () => void
}

const ViewProfile: React.FC<ViewProfileProps> = ({
  handlePassword,
  user,
  imageUrl,
  handleImageChange,
  handleUpdate,
  loading,
  isOpenFormPassword,
  // showModalPassword,
  isConfirmLoading,
  handleChangePassword,
  hideModalPassword,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
    },
  })

  return (
    <div className="relative min-h-full h-full p-5">
      <div className="flex justify-center relative">
        {imageUrl ? (
          <img
            src={imageUrl}
            className="border border-primary rounded-full h-28 w-28 object-cover bg-white p-3"
            alt="profile"
            style={{ border: "0.5px solid #D9D9D9" }}
          />
        ) : (
          <div
            className="w-28 h-28 border border-primary bg-primary-bg flex justify-center items-center rounded-full p-3"
            style={{ border: "0.5px solid #D9D9D9" }}
          >
            <UserOutlined className="text-[30px]" />
          </div>
        )}
        <label
          htmlFor="image-profile"
          className="absolute bottom-1 left-[55%] w-6 h-6 text-center rounded-full bg-white cursor-pointer"
        >
          <EditOutlined className="text-primary" />
        </label>
        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          id="image-profile"
          className=" hidden"
          onChange={(e) => handleImageChange(e.target.files)}
        />
      </div>
      <div className="text-center ml-2 mt-3">
        <Tag className="  capitalize" color="gold">
          {user?.role}
        </Tag>
      </div>
      <form
        className="mt-10"
        // onSubmit={handleSubmit(handleUpdate)}
      >
        <div className="">
          <div>
            <label className="text-xs" htmlFor="first_name">
              First Name
            </label>
            <Controller
              name="first_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  id="first_name"
                  status={errors?.first_name ? "error" : ""}
                  className="my-1 font-default rounded-none -mt-0"
                  placeholder="First Name"
                />
              )}
            />
          </div>
          <div className="my-3">
            <label className="text-xs" htmlFor="last_name">
              Last Name
            </label>
            <Controller
              name="last_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  id="last_name"
                  status={errors?.last_name ? "error" : ""}
                  className="my-1 font-default rounded-none -mt-0"
                  placeholder="Last Name"
                />
              )}
            />
          </div>
          <div className="mt-10">
            <Typography.Title level={4}>Password</Typography.Title>
            <Button className=" mt-3" onClick={handlePassword}>
              Manage Password
            </Button>
          </div>
        </div>
        <div className="text-end mt-5">
          <Button
            type="primary"
            className="bg-primary"
            onClick={handleSubmit(handleUpdate)}
            loading={loading}
          >
            Save changes
          </Button>
        </div>
      </form>

      <ModalManagePassword
        isOpenFormPassword={isOpenFormPassword}
        isConfirmLoading={isConfirmLoading}
        handleChangePassword={handleChangePassword}
        hideModalPassword={hideModalPassword}
      />
    </div>
  )
}

export default ViewProfile
