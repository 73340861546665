import React from "react"
import "./App.css"
import { QueryClient, QueryClientProvider } from "react-query"
import { MsalProvider } from "@azure/msal-react"
import { ConfigProvider } from "antd"
import GeneralRoutes from "./routes"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
})

// @ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const App: React.FC<{ instance: any }> = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#86C769",
              fontFamily: "Sen",
              borderRadius: 0,
              colorText: "#36505E",
              fontSizeHeading1: 24,
              fontSizeHeading2: 20,
              fontSizeHeading3: 18,
              fontSizeHeading4: 16,
            },
            components: {
              Menu: {
                colorText: "#FFFFFF",
              },
              Dropdown: {
                borderRadiusLG: 8,
                borderRadiusSM: 4,
                borderRadiusOuter: 4,
                borderRadiusXS: 2,
              },
              Popover: {
                borderRadiusOuter: 4,
                borderRadiusXS: 2,
              },
              Pagination: {
                colorPrimary: "#36505E",
              },
              Tag: {
                // colorBgContainer: "#86C769",
                // colorBgBase: "#86C769",
                // colorPrimaryBg: "#86C769",
                // borderRadiusSM: 3,
                // colorText: "#36505E",
                // colorBorder: "#36505E",
              },
              Tabs: {
                colorBgContainer: "#D4F5C5",
                colorBgTextActive: "#D4F5C5",
                colorTextBase: "#36505E",
                colorText: "#36505E",
                colorTextHeading: "#36505E",
                margin: 0,
                marginXL: 0,
                marginXS: 0,
                marginXXS: 0,
                marginXXL: 0,
                controlHeightLG: 60,
              },
              Empty: {
                colorTextDescription: "#36505E",
                colorTextLabel: "#36505E",
                colorTextBase: "#36505E",
              },
              Button: {
                colorText: "#36505E",
              },
              Input: {
                // colorPrimary: "#009ACE",
                // colorPrimaryActive: "#009ACE",
                // colorPrimaryHover: "#009ACE",
                controlOutlineWidth: 0,
                colorText: "#36505E",
              },
              Select: {
                // colorPrimary: "#009ACE",
                // colorPrimaryActive: "#009ACE",
                // colorPrimaryHover: "#009ACE",
                controlOutlineWidth: 0,
                colorText: "#36505E",
              },
              Tooltip: {
                borderRadius: 6,
                borderRadiusOuter: 4,
                borderRadiusXS: 2,
              },
              Modal: {
                colorText: "#36505E",
                borderRadiusLG: 10,
              },
              Slider: {
                railSize: 10,
                handleSize: 15,
                borderRadiusXS: 10,
              },
              Card: {
                paddingLG: 5,
              },
            },
          }}
        >
          <Elements stripe={stripePromise}>
            <GeneralRoutes />
          </Elements>
        </ConfigProvider>
      </QueryClientProvider>
    </MsalProvider>
  )
}

export default App
