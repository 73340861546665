import { Carousel, Empty } from "antd"
import useTailwindScreenSizes from "../../../../common/hooks/useTailwindScreenSizes"
import { useMemo } from "react"
import { PerkCard } from "./PerkCard"
import { PerkType } from "../../types"
import { usePerksUnAuth } from "../../../perks/hooks/usePerks"

function divideIntoGroups<T>(array: T[], groupSize: number): T[][] {
  let result: T[][] = []
  // Create a copy of the array to ensure immutability
  let arrayCopy = [...array]
  while (arrayCopy.length > 0) {
    result.push(arrayCopy.splice(0, groupSize))
  }
  return result
}

const Perks = () => {
  const { screenSize } = useTailwindScreenSizes()
  const perkGroupLength = screenSize === "md" || screenSize === "sm" ? 1 : 4

  const perksQuery = usePerksUnAuth()

  const filteredAndSortedProducts = useMemo(() => {
    return divideIntoGroups<PerkType>(
      perksQuery?.data?.data?.data ?? [],
      perkGroupLength,
    )
  }, [perksQuery?.data?.data?.data, screenSize])

  return (
    <div className="m-auto min-w-[87%] py-20  bg-white px-6 flex flex-col">
      <h3 className="text-center font-semibold text-[40px]">
        Do more - perks and benefits
      </h3>

      <div className="basic-card mt-10 bg-white-custom pb-5 flex-grow">
        <Carousel className="pb-6" dots={{ className: "" }} arrows autoplay>
          {filteredAndSortedProducts?.length > 0 ? (
            filteredAndSortedProducts?.map((perks, idx) => (
              <div
                key={idx}
                className="!grid !grid-cols-12 gap-4 p-3 py-6 pb-8"
              >
                {perks?.map((perk, idx) => (
                  <PerkCard
                    unauthenticated
                    className="col-span-full lg:col-span-3"
                    key={perk.id + idx}
                    perk={perk}
                  />
                ))}
              </div>
            ))
          ) : (
            <Empty />
          )}
        </Carousel>
      </div>
    </div>
  )
}

export default Perks
