import { createContext } from "react"

// Definisikan interface untuk tipe data yang ingin Anda bagikan melalui context
export interface User {
  avatar: string
  country: string
  display_name: string
  email: string
  first_name: string
  id: string
  last_name: string
  role: string
  status: string
  tenant_id: string
}

// Buat context dengan menggunakan tipe data yang sesuai dengan interface yang Anda definisikan
export const UserContext = createContext<User | undefined>(undefined)
