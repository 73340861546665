import React, { createContext, useRef, useState } from "react"
import { Tour, TourProps } from "antd"
import { isMobile } from "react-device-detect"
// import { Link } from "react-router-dom"

export interface ITourContext {
  refs: {
    refOurTeam: React.RefObject<HTMLDivElement>
    refActiveJobs: React.RefObject<HTMLDivElement>
    refTalentConnect: React.RefObject<HTMLDivElement>
    refCandidateStages: React.RefObject<HTMLDivElement>
    refJobsExpiringSoon: React.RefObject<HTMLDivElement>
    refJobsSwitch: React.RefObject<HTMLDivElement>
    refJobPostFilter: React.RefObject<HTMLDivElement>
    refNewJobPost: React.RefObject<HTMLButtonElement | HTMLAnchorElement>
    refNewJobPostFromTemplate: React.RefObject<
      HTMLButtonElement | HTMLAnchorElement
    >
    refJobsTable: React.RefObject<HTMLDivElement>
    refCompanyBranding: React.RefObject<HTMLDivElement>
    refCompanyInfo: React.RefObject<HTMLDivElement>
    refCompanyLinks: React.RefObject<HTMLDivElement>
    refNewUser: React.RefObject<HTMLButtonElement | HTMLAnchorElement>
    refEditUser: React.RefObject<HTMLDivElement>
    refDeleteUser: React.RefObject<HTMLDivElement>
  }
  startTour: (route: Route) => void
  stopTour: () => void
  open: boolean
  route: Route | null
}

export enum Route {
  HomeDashboard = "/home/dashboard",
  MyJobPosts = "/jobs/my-job-posts",
  MyJobTemplates = "/jobs/my-job-templates",
  TemplatesLibrary = "/jobs/templates-library",
  TalentConnected = "/talent/connected",
  TalentDatabase = "/talent/database",
  CompanySettings = "/settings/company",
  TeamManagement = "/settings/team-management",
  PlanAndBilling = "/settings/plan-and-billing",
}

export const TourContext = createContext<ITourContext | null>(null)

export const TourProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [steps, setSteps] = useState<TourProps["steps"]>([])
  const [route, setRoute] = useState<Route | null>(null)

  const refs = {
    refOurTeam: useRef(null),
    refActiveJobs: useRef(null),
    refTalentConnect: useRef(null),
    refCandidateStages: useRef(null),
    refJobsExpiringSoon: useRef(null),
    refJobsSwitch: useRef(null),
    refJobPostFilter: useRef(null),
    refNewJobPost: useRef(null),
    refNewJobPostFromTemplate: useRef(null),
    refJobsTable: useRef(null),
    refCompanyBranding: useRef(null),
    refCompanyInfo: useRef(null),
    refCompanyLinks: useRef(null),
    refNewUser: useRef(null),
    refEditUser: useRef(null),
    refDeleteUser: useRef(null),
  }

  function handleRoute(route: Route | null): TourProps["steps"] {
    switch (route) {
      case Route.HomeDashboard:
        return [
          {
            title: "Our Team",
            description:
              "Meet your team and learn more about your company. This section provides a snapshot of your company's profile and the diverse roles within your team.",
            target: isMobile ? undefined : () => refs.refOurTeam.current,
            placement: "top",
          },
          {
            title: "Active Jobs",
            description:
              "View your active job posts. This section provides a quick overview of the number of jobs you have posted that are currently active. Click on it to view all active jobs.",
            target: isMobile ? undefined : () => refs.refActiveJobs.current,
            placement: "top",
          },
          {
            title: "Connections with Talent",
            description:
              "See number of connections with Talent and the credits you have to connect with more.",
            target: isMobile ? undefined : () => refs.refTalentConnect.current,
            placement: "top",
          },
          {
            title: "Stages of recruitement talent are at",
            description:
              "View a breadkdown of stages of recruitment talent are on.",
            target: isMobile
              ? undefined
              : () => refs.refCandidateStages.current,
            placement: "top",
          },
          {
            title: "Jobs expiring soon.",
            description: "View all your jobs posts that are to expire soon.",
            target: isMobile
              ? undefined
              : () => refs.refJobsExpiringSoon.current,
            placement: "top",
          },
        ]
      case Route.MyJobPosts:
        return [
          {
            title: "View Jobs",
            description: "Switch between viewing active and closed jobs.",
            target: isMobile ? undefined : () => refs.refJobsSwitch.current,
            placement: "top",
          },
          {
            title: "Filter Job Posts",
            description: "Search by name, location or teams.",
            target: isMobile ? undefined : () => refs.refJobPostFilter.current,
            placement: "top",
          },
          {
            title: "Create New Job Post",
            description: "Click to create a new job post.",
            target: isMobile ? undefined : () => refs.refNewJobPost.current,
            placement: "left",
          },
          {
            title: "Create New Job Post with a template",
            description:
              "Click to create a new job post from pre-existing templates created by SkilledIn or you. Templates allow you to create a reusable blueprint for job posts.",
            target: isMobile
              ? undefined
              : () => refs.refNewJobPostFromTemplate.current,
            placement: "left",
          },
          {
            title: "View drafts and posted jobs in the following table",
            description:
              "View all jobs you've posted and are currently in the process of editing.",
            target: isMobile ? undefined : () => refs.refNewJobPost.current,
            placement: "top",
          },
        ]
      case Route.MyJobTemplates:
        return [
          {
            title: "Your job templates",
            description:
              "You will find here all the job templates you have used to create job posts. Click on them to view their details and use them in a job post.",
          },
        ]
      case Route.TemplatesLibrary:
        return [
          {
            title: "Templates Library",
            description:
              "Find all pre-built template libaries from SkilledIn to use in your speed up the creation of job posts.",
          },
        ]
      case Route.TalentConnected:
        return [
          {
            title: "Connected talents",
            description:
              "Find all talent that you have connected with and recruit them for job positions at your company.",
          },
        ]
      case Route.TalentDatabase:
        return [
          {
            title: "Talents Database",
            description:
              "Search, find and connect with talents to invite them job positions at your company.",
          },
        ]
      case Route.CompanySettings:
        return [
          {
            title: "Company branding",
            description:
              "Basic information which is displayed about your company.",
            target: isMobile
              ? undefined
              : () => refs.refCompanyBranding.current,
            placement: "top",
          },
          {
            title: "Company information",
            description:
              "Important descriptive information about your company.",
            target: isMobile ? undefined : () => refs.refCompanyInfo.current,
            placement: "top",
          },
          {
            title: "Social media links",
            description:
              "Share links that people can use to get in touch with you.",
            target: isMobile ? undefined : () => refs.refCompanyLinks.current,
            placement: "left",
          },
        ]
      case Route.TeamManagement:
        return [
          {
            title: "Manage your team",
            description:
              "Here you can view, add, edit and remove team members.",
          },
          {
            title: "Create new user",
            description: "Invite new users to join your application.",
            target: isMobile ? undefined : () => refs.refNewUser.current,
            placement: "left",
          },
          {
            title: "Edit a user",
            description:
              "Update an existing user's role, name, and other values.",
            target: isMobile ? undefined : () => refs.refEditUser.current,
            placement: "left",
          },
          {
            title: "Delete a user",
            description:
              "Delete existing users (disabled if only existing user is the admin)",
            target: isMobile ? undefined : () => refs.refDeleteUser.current,
            placement: "left",
          },
        ]
      case Route.PlanAndBilling:
        return [
          {
            title: "Payment Options",
            description: "Update your billing settings on this page.",
          },
        ]
    }
  }

  const startTour = (route: Route) => {
    setSteps(handleRoute(route))
    setRoute(route)
    setOpen(true)
  }
  const stopTour = () => {
    setOpen(false)
    setSteps([])
    setRoute(null)
  }

  return (
    <TourContext.Provider
      value={{
        refs,
        startTour,
        stopTour,
        open,
        route,
      }}
    >
      {route && steps && steps?.length > 0 && (
        <Tour
          open={open}
          // disabledInteraction
          onClose={stopTour}
          steps={steps}
          animated
          scrollIntoViewOptions={{ behavior: "smooth" }}
          arrow
          mask={{
            style: { width: "100%", overflow: "hidden", height: "100%" },
          }}
        />
      )}
      {children}
    </TourContext.Provider>
  )
}
export default TourProvider
