export function openInNewTab(url: string) {
  window.open(url, "_blank")
}

export const truncateText = (text: string = "", maxLength: number): string => {
  if (!text) return ""
  if (text?.length > maxLength) {
    return text.slice(0, maxLength) + "..."
  }
  return text
}

export function isEqual(value: any, other: any): boolean {
  // Check if both values are identical references
  if (value === other) {
    return true
  }
  // Check if both values are objects
  if (typeof value === "object" && typeof other === "object") {
    // Get the keys of the objects
    const valueKeys = Object.keys(value)
    const otherKeys = Object.keys(other)
    // Check if the number of keys is the same
    if (valueKeys.length !== otherKeys.length) {
      return false
    }
    // Iterate through the keys and compare the values
    for (let key of valueKeys) {
      // Recursively compare nested objects and arrays
      if (!isEqual(value[key], other[key])) {
        return false
      }
    }
    return true
  }
  // Compare other types of values
  return value === other
}
