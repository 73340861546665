import { CheckOutlined, PlusOutlined } from "@ant-design/icons"

export const QuestionCheckList = ({
  question,
  isSelected,
  onClick,
}: {
  question: any
  isSelected: boolean
  onClick: () => void
}) => {
  const classes = isSelected
    ? "rounded-xl border border-backdrop bg-secondaryYellow px-[14px] py-[5px] transition duration-300 ease-in-out transform hover:scale-110 cursor-pointer"
    : "rounded-xl border border-backdrop bg-lightGrey px-[14px] py-[5px] transition duration-300 ease-in-out transform hover:scale-110 cursor-pointer"

  const Icon = isSelected ? CheckOutlined : PlusOutlined

  return (
    <div key={question.id} onClick={onClick} className={classes}>
      <Icon className="text-base" /> {question?.name}
    </div>
  )
}
