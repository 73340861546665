import React from "react"
import { SubscriptionSelect } from "../../../common/components"

const PriceList: React.FC = () => {
  return (
    <>
      <div className="bg-white py-16 text-center ">
        <h1 className="m-0 text-[64px] font-semibold">Transparent Pricing</h1>
        <p className="text-2xl mt-10">
          Save big on building your own pool of Green Talent <br />
          ready to take on new sustainability challenges
        </p>
      </div>
      <div className="bg-secondaryBlue pt-4 pb-16 w-full flex justify-center">
        <div className=" w-[70%]">
          <div className=" w-full">
            <SubscriptionSelect />
          </div>
        </div>
      </div>
      <div className="bg-white py-32 text-center">
        <h2 className=" ">
          Take your <span className="text-primary">green skills</span> hiring to
          the next level
        </h2>
        <p className="leading-6 mt-6">
          We are here to support your hiring every step on the way. If you{" "}
          <br /> have requests for new Green Skills, Corporate upskilling
          programs or <br /> ATS customization,{" "}
          <a
            href="mailto:info@skilledin.io"
            className=" cursor-pointer text-secondary !no-underline hover:text-primary"
          >
            get in touch
          </a>
        </p>
      </div>
    </>
  )
}

export default PriceList
