import React, { useState } from "react"
import { CloseOutlined } from "@ant-design/icons"
import {
  Choice,
  type Question,
} from "../../../../../common/hooks/useReferences"
import { Select, Switch } from "antd"

interface Props {
  question: Question
  removeQuestion: () => void
  updateQuestion: (
    id: string,
    choice: Question["choices"][0] | Question["choices"],
    index: number,
  ) => void
  country: string | undefined
  index: number
}
/**
 * @note This component turns into a multi-select dropdown when the question has more than 2 choices. This entails a few things:
 * 1. The `selectedChoice` state is now a `Choice | undefined | Choice[]` type. This is because the state can now hold an array of choices.
 * 2. The `Select` component now has a `mode` prop that is set to "multiple" when the question has more than 2 choices.
 * 3. The `onSelect` prop of the `Select` component now has a conditional that checks if the question has more than 2 choices. If it does, it appends the selected choice to the `selectedChoice` array. Otherwise, it sets the `selectedChoice` to the selected choice.
 * 4. The `updateQuestion` function now has a conditional that checks if the `selectedChoice` is an array. If it is, it updates the question with the array of choices. Otherwise, it updates the question with the selected choice.
 */
export const SelectedQuestion: React.FC<Props> = ({
  question,
  removeQuestion,
  updateQuestion,
  country,
  index,
}) => {
  const isMultiple = question?.choices?.length > 2
  const [isDisqualify, setIsDisqualify] = useState(
    question?.choices?.some((choice) => choice?.disqualify) ?? false,
  )
  const [selectedChoice, setSelectedChoice] = useState<
    Choice | undefined | Choice[]
  >(
    isMultiple
      ? question?.choices?.filter((choice) => choice.disqualify) ?? []
      : question?.choices?.find((choice) => choice?.disqualify) ??
          question?.choices[0],
  )

  React.useEffect(() => {
    if (!isDisqualify) {
      updateQuestion(
        question?.id,
        question?.choices.map((choice) => ({ ...choice, disqualify: false })),
        index,
      )
    } else {
      if (Array.isArray(selectedChoice)) {
        updateQuestion(
          question?.id,
          question?.choices?.map((choice) => ({
            ...choice,
            disqualify: selectedChoice?.some(
              (choise2) => choise2?.name === choice?.name,
            ),
          })),
          index,
        )
      } else {
        if (selectedChoice?.name) {
          updateQuestion(
            question?.id,
            {
              ...selectedChoice,
              disqualify: isDisqualify,
            },
            index,
          )
        }
      }
    }
  }, [isDisqualify, selectedChoice])
  return (
    <div className="basic-card bg-custom-white">
      <div className="w-full flex justify-between items-center">
        <div className="golden-subtitle tracking-[3.4px]">{question?.name}</div>
        <CloseOutlined className="ml-auto" onClick={() => removeQuestion()} />
      </div>
      <div className="">
        <h4 className="font-bold text-[18px] first-letter:capitalize">
          {question?.question?.replace(
            "$country_name$",
            " " + country ?? " N/A",
          )}
        </h4>
        <div className="grid grid-cols-12 mt-[18px]">
          <div
            className={
              "col-span-6 flex items-center justify-start gap-x-[18px]"
            }
          >
            <Switch
              checked={isDisqualify}
              onChange={(checked) => setIsDisqualify(checked)}
            />
            <div className="font-normal leading-[20px]">
              Disqualify candidates that select an answer:{" "}
            </div>
          </div>
          {isDisqualify &&
            (isMultiple ? (
              <Select
                className="col-span-6"
                placeholder="Select answers"
                mode={"multiple"}
                defaultActiveFirstOption
                style={{ width: "100%" }}
                options={
                  question?.choices?.map((choice) => ({
                    label: choice.name,
                    value: choice.name,
                  })) ?? []
                }
                maxTagCount={question?.choices?.length - 1}
                value={
                  Array.isArray(selectedChoice)
                    ? selectedChoice?.map((choice) => choice.name)
                    : undefined
                }
                onSelect={
                  Array.isArray(selectedChoice)
                    ? (value) => {
                        setSelectedChoice([
                          ...selectedChoice,
                          question?.choices.find(
                            (c) => c.name === value,
                          ) as Choice,
                        ])
                      }
                    : undefined
                }
                onDeselect={
                  Array.isArray(selectedChoice)
                    ? (value) => {
                        setSelectedChoice(
                          selectedChoice.filter((c) => c.name !== value),
                        )
                      }
                    : undefined
                }
              />
            ) : (
              <Select
                className="col-span-6"
                placeholder="Select an answer"
                style={{ width: "100%" }}
                defaultActiveFirstOption
                options={
                  question?.choices?.map((choice) => ({
                    label: choice.name,
                    value: choice.name,
                  })) ?? []
                }
                value={
                  !Array.isArray(selectedChoice)
                    ? selectedChoice?.name
                    : undefined
                }
                onSelect={(value) => {
                  setSelectedChoice({
                    name: value,
                    disqualify: isDisqualify,
                  })
                }}
              />
            ))}
        </div>
      </div>
    </div>
  )
}
