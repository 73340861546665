import { Pie } from "@ant-design/plots"
import {
  type DataTypeChart,
  type ResponseDashboardAPIType,
} from "../types/responseDashboard"
import React, { useMemo } from "react"

interface CandidateChartProps {
  dashboardData: ResponseDashboardAPIType
}

function convertData(response: ResponseDashboardAPIType): DataTypeChart[] {
  const data: DataTypeChart[] = Object.entries(response.candidate_by_stage).map(
    ([type, value]) => ({
      type,
      value,
    }),
  )

  return data
}

// function renderStatistic(containerWidth: number, text: any, style: any) {
//   const { width: textWidth, height: textHeight } = measureTextWidth(text, style)
//   const R = containerWidth / 2 // r^2 = (w / 2)^2 + (h - offsetY)^2

//   let scale = 1

//   if (containerWidth < textWidth) {
//     scale = Math.min(
//       Math.sqrt(
//         Math.abs(
//           Math.pow(R, 2) /
//             (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)),
//         ),
//       ),
//       1,
//     )
//   }

//   const textStyleStr = `width:${containerWidth}px;`
//   return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
//     scale < 1 ? 1 : "inherit"
//   };">${text}</div>`
// }

const config = {
  height: 230,
  appendPadding: 10,
  angleField: "value",
  colorField: "type",
  radius: 0.7,
  innerRadius: 0.64,
  meta: {
    value: {
      formatter: (v: any) => `${v}`,
    },
  },
  label: {
    type: "inner",
    offset: "-50%",
    style: {
      textAlign: "center",
    },
    autoRotate: false,
    content: "{value}",
  },
  // statistic: {
  //   title: {
  //     offsetY: -4,
  //     customHtml: (container: any, view: any, datum: any) => {
  //       const { width, height } = container.getBoundingClientRect()
  //       const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2))
  //       const text = datum ? datum.type : "Total"
  //       return renderStatistic(d, text, {
  //         fontSize: 24,
  //         textAlign: "center",
  //       })
  //     },
  //   },
  //   content: {
  //     offsetY: 4,
  //     style: {
  //       fontSize: "26px",
  //     },
  //     customHtml: (container: any, view: any, datum: any, data: any) => {
  //       const { width } = container.getBoundingClientRect()
  //       const text = datum
  //         ? `${datum.value}`
  //         : `${data.reduce((r: any, d: any) => r + d.value, 0)}`
  //       return renderStatistic(width, text, {
  //         fontSize: 32,
  //       })
  //     },
  //   },
  // },

  // interactions: [
  //   {
  //     type: "element-selected",
  //   },
  //   {
  //     type: "element-active",
  //   },
  //   {
  //     type: "pie-statistic-active",
  //   },
  // ],
  interactions: [
    {
      type: "element-selected",
    },
    {
      type: "element-active",
    },
  ],
}

export const CandidateChart: React.FC<CandidateChartProps> = React.memo(
  ({ dashboardData }) => {
    const dataArray = useMemo(
      () => (dashboardData ? convertData(dashboardData) : []),
      [dashboardData],
    )

    return <Pie data={dataArray} {...config} />
  },
)
