import React from "react"
import { Button, Input } from "antd"
import { Controller, useForm } from "react-hook-form"
import { Customer } from "../../hooks/biliingPlan/type"

interface Props {
  data?: Customer
  update: {
    handleUpdate: (val: any) => Promise<void>
    isLoadingUpdateCustomer: boolean
  }
}

export const BillingInformation: React.FC<Props> = ({ data, update }) => {
  const {
    handleSubmit,
    control,
    // reset,
    // setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      email: data?.email,
      name: data?.name,
    },
  })

  return (
    <div className="rounded-xl shadow-md bg-white p-5 w-full">
      <div className="">
        <h3>Billing Information</h3>
      </div>
      <div>
        <div className="flex gap-4 items-center py-1">
          <label htmlFor="name" className="w-16">
            Name
          </label>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                id="name"
                className="my-1 font-default rounded"
                placeholder="john doe"
              />
            )}
          />
        </div>
        <div className="flex gap-4 items-center py-1">
          <label htmlFor="email" className="w-16">
            Email
          </label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                id="email"
                className="my-1 font-default rounded"
                placeholder="johndoe@example.com"
              />
            )}
          />
        </div>
      </div>
      <div className="text-end mt-3">
        <Button
          type="primary"
          disabled={!isDirty}
          loading={update.isLoadingUpdateCustomer}
          onClick={handleSubmit(update.handleUpdate)}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
