import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
} from "react-query"
import { useAxiosWithMsal } from "../../../common/hooks"
import { message } from "antd"
import { AxiosError } from "../../../common/errors"
import { AxiosInstance } from "axios"
import { ApiResponse } from "../../../common/types/ApiResponse"
import { Item } from "../../../common/hooks/useReferences"

export type UserStatusType = "pending" | "active" | "All"
export type UserRoleType = "Admin" | "Recruiter"

export type NewUser = {
  country: Item
  // display_name: string
  email: string
  first_name: string
  last_name: string
  role: UserRoleType
}

export type GetUser = {
  id: string
  tenant_id: string
  display_name: string
  first_name: string
  last_name: string
  email: string
  role: UserRoleType
  country: string
  status: UserStatusType
  avatar: string
}

interface GetUserApiResponse extends ApiResponse {
  data: { data: GetUser[] }
}

interface GetUserListProps {
  search?: string | null
  userStatusSelected: UserStatusType
}

export const useGetUserList = (
  queryParams: GetUserListProps,
): UseQueryResult<GetUserApiResponse, AxiosError> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getUserList = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<GetUserApiResponse>("/users", {
        params: {
          search: queryParams.search,
          status:
            queryParams?.userStatusSelected === "All"
              ? null
              : queryParams?.userStatusSelected,
        },
      })
    } catch (error) {
      message.error("An error occurred while fetching users.")
    }
  }
  return useQuery("user-list", getUserList, {
    onError: () => {
      message.error("An error occurred while fetching users.")
    },
  })
}

export const useAddNewUser = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const addUser = async (data: NewUser) => {
    message.open({
      key: "add-user",
      type: "loading",
      duration: 0,
      content: "adding new user...",
    })
    const finalData = { ...data, country: data.country.name }
    try {
      const api = await getAxiosInstance()
      return await api.post("/users/invite", finalData)
    } catch (error) {
      throw error
    }
  }

  return useMutation(addUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["user-list"])
      message.open({
        key: "add-user",
        type: "success",
        content: "user has successfully been invited.",
      })
    },
    onError: (err: any) => {
      message.open({
        key: "add-user",
        type: "error",
        content: err?.response?.data?.data || "something wrong",
      })
    },
  })
}

export const useEditUserByAdmin = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const editUser = async ({ data, id }: { data: NewUser; id: string }) => {
    try {
      const finalData = { ...data, country: data.country.name }
      const api = await getAxiosInstance()
      return await api.patch(`users/${id}/edit`, finalData)
    } catch (error) {
      throw error
    }
  }

  return useMutation(editUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["user-list"])
      message.success("User has successfully been edited.")
    },
    onError: () => {
      message.error("An error occurred while editing the user")
    },
  })
}

export const useDeleteUserByAdmin = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const deleteUser = async (id: string) => {
    try {
      const api = await getAxiosInstance()
      return await api.delete(`users/${id}/delete`)
    } catch (error) {
      message.error("An error occurred while deleting the user")
    }
  }
  return useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["user-list"])
      message.success("User has successfully been deleted.")
    },
    onError: () => {
      message.error("An error occurred while deleting the user")
    },
  })
}

export const useGetUserByAdmin = (id: number) => {
  const getAxiosInstance = useAxiosWithMsal()

  const getUserById = async () => {
    try {
      const api = await getAxiosInstance()
      return await api.get(`users/${id}`)
    } catch (error) {
      message.error("An error occurred while fetching the user.")
    }
  }
  return useQuery(["user", id], getUserById, {
    onError: () => {
      message.error("An error occurred while fetching the user.")
    },
  })
}
