import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { NewUser } from "./useAdministrateUsers"
import { useAddNewUser } from "./useAdministrateUsers"
import { Item } from "../../../common/hooks/useReferences"

const schema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  // display_name: yup.string().required("Display Name is required."),
  email: yup.string().email("Invalid Email").required("Email is required"),
  role: yup.string().oneOf(["Admin", "Recruiter"]).required("Role is required"),
  country: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .required("Country is required"),
})

type NewUserForm = {
  country: Item
} & Omit<NewUser, "country">

export const useNewUserDrawer = (onClose: () => void) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const addSkillMutate = useAddNewUser()

  const onSaveSubmit = async (data: NewUserForm) => {
    addSkillMutate.mutate({
      ...data,
      country: data.country,
    })
    reset()
    onClose()
  }

  return {
    handleSubmit,
    control,
    errors,
    onSaveSubmit,
    addSkillMutate,
  }
}
