import React from "react"
import { CheckCircleOutlined, RightOutlined } from "@ant-design/icons"

interface Props {
  currentStepIndex: number
  steps: string[]
  className?: string
}

export const NumberedSteps: React.FC<Props> = ({
  currentStepIndex,
  steps,
  className,
}) => {
  return (
    <div className={`${className}`}>
      <div className="flex items-center w-full gap-x-6">
        {steps.map((step, index) => {
          const isCurrentStep = index === currentStepIndex
          const isPreviousStep = index < currentStepIndex
          const isNextStep = index > currentStepIndex
          return (
            <React.Fragment key={index}>
              <div
                className={`border-b-2 flex items-center  gap-x-2 relative ${
                  isCurrentStep || isPreviousStep
                    ? "text-backdrop border-backdrop"
                    : "text-lightGrey border-lightGrey"
                } ${isCurrentStep ? "border-backdrop" : "border-lightGrey"} ${
                  index === steps.length - 1 ? "justify-start" : ""
                }`}
              >
                <div
                  className="flex gap-x-2 items-center"
                  style={{
                    borderBottom: isCurrentStep ? "2px solid #36505E" : "none",
                    paddingBottom: "10px",
                    // paddingLeft: isCurrentStep ? "80px" : "0px",
                  }}
                >
                  {isCurrentStep || isNextStep ? (
                    <div
                      style={{
                        border: isCurrentStep ? "none" : "1px solid #E5E5E5",
                      }}
                      className={`border-2 h-4 w-4 flex items-center justify-center rounded-full text-base p-2 ${
                        isCurrentStep
                          ? "text-white bg-backdrop border-backdrop"
                          : ""
                      }`}
                    >
                      {index + 1}
                    </div>
                  ) : (
                    <CheckCircleOutlined className="text-backdrop text-[32px]" />
                  )}
                  {step}
                </div>
              </div>
              {index !== steps.length - 1 && (
                <RightOutlined
                  style={{ paddingBottom: "5px" }}
                  className="text-lightGrey text-[32px] "
                />
              )}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
