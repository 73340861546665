import React from "react"
import { Button, Image } from "antd"
import Lottie, { LottieProps } from "react-lottie-player"
import * as animationData from "../../../assets/json/background.json"
import {
  Affordable,
  JobPost,
  PriceListImg2,
  SearchTalent,
} from "../../../assets/images"
import { useNavigate } from "react-router-dom"
import MeetGreenCV from "../components/landing-page/MeetGreenCV"
import AccelerateJobCreation from "../components/landing-page/AccelerateJobCreation"
import QualityHiring from "../components/landing-page/QualityHiring"
import TalentPipeline from "../components/landing-page/TalentPipeline"
import Perks from "../components/landing-page/Perks"

const LandingPage: React.FC = () => {
  const navigate = useNavigate()
  const defaultOptionsBg: LottieProps = {
    loop: true,
    play: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <div className="relative z-10 ">
      <div className="absolute -z-10  h-screen w-full">
        <Lottie {...defaultOptionsBg} className="h-full" />
      </div>
      <div className="h-screen flex flex-col pt-32  w-full text-center ">
        <div className="text-center space-y-11 mx-6 md:mx-32 lg:mx-[25%]">
          <h1 className="m-0 text-3xl md:text-[64px] font-semibold leading-tight z-20">
            Skills-based hiring for Green Economy
          </h1>
          <p className="text-lg md:text-[1.5rem]">
            Discover exceptional talent that's passionate about sustainability.
            Hire skilled professionals who are ready to make a meaningful impact
            in your organization.
          </p>
          <Button
            type="primary"
            size="large"
            onClick={() => navigate("/register")}
            className="rounded "
          >
            Get Started
          </Button>
        </div>
      </div>

      <div className="bg-white h-screen  flex flex-col items-center justify-center z-30">
        <div className="text-center mx-6 md:mx-44 w-10/12 md:w-1/2  ">
          <p className="text-primary tracking-[2px] text-xs m-0 mb-3">
            YOUR ONE-STOP GREEN ECONOMY PLATFORM
          </p>
          <h2 className="m-0 leading-tight my-12 text-2xl md:text-[40px] font-semibold">
            Addressing Green Hiring challenges head-on
          </h2>
          <p className="text-base text-[20px]">
            Navigating the skill gap in the green sector can be overwhelming,
            costly and time-consuming. Skilledin Green is your one-stop platform
            if you are on the lookout for:
          </p>

          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4 mt-10">
            <div className="px-2 sm:px-4 lg:px-6">
              <Image preview={false} src={SearchTalent} width={50} />
              <p className="text-[14px] mt-2 sm:mt-3 lg:mt-4 xl:mt-4 w-full">
                Curated Green Talent
              </p>
            </div>
            <div className="px-2 sm:px-4 lg:px-6">
              <Image preview={false} src={Affordable} width={50} />
              <p className="text-[14px] mt-2 sm:mt-3 lg:mt-4 xl:mt-4 w-full">
                Affordable Hiring
              </p>
            </div>
            <div className="px-2 sm:px-4 lg:px-6">
              <Image preview={false} src={PriceListImg2} width={50} />
              <p className="text-[14px] mt-2 sm:mt-3 lg:mt-4 xl:mt-4 w-full">
                Focus on Sustainability
              </p>
            </div>
            <div className="px-2 sm:px-4 lg:px-6">
              <Image preview={false} src={JobPost} width={50} />
              <p className="text-[14px] mt-2 sm:mt-3 lg:mt-4 xl:mt-4 w-full">
                Skills-first Approach
              </p>
            </div>
          </div>
        </div>
      </div>

      <MeetGreenCV />
      <AccelerateJobCreation />
      <QualityHiring />
      <TalentPipeline />
      <section className="text-center py-[70px] sm:px-20 px-3 space-y-[40px] bg-dirtyBlue">
        <h2 className="md:text-4xl text-3xl font-semibold">
          Removing Hiring Bias
        </h2>
        <p className="md:px-60 text-xl leading-7">
          CVs are anonymised when you browse Talent Database and review job
          applicants. Profile picture, full name, and contact of the Talent are
          only unmasked after you shortlist them in ATS or they accept your
          Connect request in Talent Database{" "}
        </p>
      </section>
      <Perks />
    </div>
  )
}

export default LandingPage
