import { useAxiosWithMsal } from "../../../../common/hooks"
import { useQuery } from "react-query"
import { AxiosInstance } from "axios"
import { Invoice } from "./type"
import { ApiResponse } from "../../../../common/types/ApiResponseV2"
import { message } from "antd"

interface ResponseInvoice {
  data: Invoice[]
  has_more: false
  total_count: number
}

export const useInvoices = () => {
  const getAxiosInstance = useAxiosWithMsal()

  const { data, isLoading } = useQuery(
    "invoices",
    async () => {
      try {
        const api: AxiosInstance = await getAxiosInstance()
        return await api.get<ApiResponse<ResponseInvoice>>("/billings/invoices")
      } catch (error) {
        throw error
      }
    },
    {
      retry: 0,
      onError: (error: any) => {
        message.error(error?.response?.data?.message)
      },
    },
  )
  return { data: data?.data?.data, isLoading }
}
