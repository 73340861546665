import React from "react"
import { InfiniteAutoComplete } from "../../../../../common/components"
import {
  CoreSkill,
  Item,
  useCriticalSkillsInfinity,
  useProficiencies,
} from "../../../../../common/hooks/useReferences"
import { Select, Table } from "antd"
import { useFieldArray, useFormContext } from "react-hook-form"
import { CreateJobSchema } from "../types"
import { DeleteOutlined } from "@ant-design/icons"

export const CriticalCoreSkills = () => {
  const { control } = useFormContext<CreateJobSchema>()

  const {
    fields: coreSkillsFields,
    append: appendCoreSkill,
    remove: removeCoreSkill,
    update: updateCoreSkill,
  } = useFieldArray({
    control,
    name: "critical_skills",
    keyName: "id_",
  })

  const coreSkillProficencies = useProficiencies("skill")

  const handleSelectCoreSkills = (
    value: string,
    option: Item | undefined | null,
  ) => {
    if (option && option.id) {
      // ensure that 'option' and 'option.id' are defined
      const isAlreadySelected = coreSkillsFields.some((item) => {
        return item.id === option.id
      })
      if (!isAlreadySelected) {
        appendCoreSkill({
          ...option,
          proficiency: coreSkillProficencies?.data?.data?.data[0] ?? null,
        })
      }
    }
  }
  const handleSelectProficiencyCoreSkill = (
    proficiency: Item,
    id: string,
    index: number,
  ) => {
    console.log("Critical Skills Proficiency: ", proficiency)
    const coreSkillItem = coreSkillsFields[index]
    updateCoreSkill(index, {
      ...coreSkillItem,
      proficiency: { ...proficiency },
    })
  }

  const coreSkillTableCols = [
    {
      title: "Skill Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Proficiency",
      dataIndex: "proficiency",
      key: "proficiency",
      render: (proficiency: Item, record: CoreSkill, index: number) => {
        return (
          <Select
            className="w-full"
            placeholder="Select a Core Skill Proficiency"
            options={coreSkillProficencies?.data?.data?.data}
            fieldNames={{ value: "id", label: "name" }}
            loading={coreSkillProficencies?.isLoading}
            value={proficiency?.id}
            onSelect={(_: any, proficiency: Item) =>
              handleSelectProficiencyCoreSkill(proficiency, record?.id, index)
            }
          />
        )
      },
    },
    {
      title: "",
      key: "action",
      render: (_: any, record: Item, index: number) => (
        <>
          <DeleteOutlined onClick={() => removeCoreSkill(index)} />
        </>
      ),
      width: 100,
    },
  ]
  return (
    <div className="basic-card bg-custom-white shadow-custom-lg">
      <h3 className="font-semibold text-lg ">Critical Core Skills</h3>
      {/* {errors?.critical_skills?.message && (
        <div className="text-danger  -mt-3">
          {errors?.critical_skills?.message}
        </div>
      )} */}
      <InfiniteAutoComplete
        useDataHook={useCriticalSkillsInfinity}
        onSelect={handleSelectCoreSkills}
        className="w-full"
        placeholder="Search Critical Core Skills"
      />
      <div className="">
        <Table
          columns={coreSkillTableCols}
          className="mt-6"
          pagination={false}
          //  @ts-ignore
          dataSource={coreSkillsFields}
        />
      </div>
    </div>
  )
}
