import React from "react"
import { Card } from "antd"
import { Talent } from "../type/database"
import { RadarSoftSkill } from "./RadarSoftSkill"

interface Props {
  data?: Talent
}

export const CardSoftSkill: React.FC<Props> = ({ data }) => {
  return (
    <Card className="shadow-md bg-[#FAFAFA] rounded-xl p-5 h-full">
      <h2 className=" capitalize">Critical Core Skills</h2>

      <RadarSoftSkill data={data?.soft_skill} />
    </Card>
  )
}
