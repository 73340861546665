import { FloatButton, Layout } from "antd"
import React from "react"
import { CustomFooter, CustomHeader } from "../layout"
import { SignInToAccessModal } from "./SignInToAccessModal"
import { useCurrencies } from "../hooks/useCurrencies"
import { CurrenciesContext } from "../../context/user"

const PageLayoutUnAuth = ({ children }: { children: React.ReactNode }) => {
  const currencies = useCurrencies()
  return (
    <CurrenciesContext.Provider value={currencies?.data?.data?.data}>
      <Layout>
        <CustomHeader />
        <Layout className="h-screen overflow-hidden">
          <Layout className="h-full relative !bg-[#F0F2F5]">
            <Layout.Content
              className="h-full overflow-auto flex flex-col justify-between !bg-[#F0F2F5]"
              style={{
                margin: 0,
                marginTop: "65px",
              }}
            >
              {children}
              <div className="">
                <CustomFooter />
              </div>
              <SignInToAccessModal />
            </Layout.Content>
          </Layout>
          <FloatButton.BackTop />
        </Layout>
      </Layout>
    </CurrenciesContext.Provider>
  )
}

export default PageLayoutUnAuth
