import React, { useContext } from "react"
import { CandidateChart } from "../CandidateChart"
import { ResponseDashboardAPIType } from "../../types/responseDashboard"
import { EmptyData } from "../../../../common/components/EmptyData"
import {
  ITourContext,
  TourContext,
} from "../../../../common/components/tour/TourProvider"

interface Props {
  dashboardData?: ResponseDashboardAPIType
}

export const CandidateByStage: React.FC<Props> = ({ dashboardData }) => {
  const {
    refs: { refCandidateStages },
  } = useContext(TourContext) as ITourContext
  return (
    <div
      ref={refCandidateStages}
      className="  rounded-md shadow-custom p-5 bg-custom-white relative h-[252px]"
    >
      <h3 className="">Candidates by stage</h3>
      {dashboardData ? (
        <div className=" absolute -left-10 top-7">
          <CandidateChart dashboardData={dashboardData} />
        </div>
      ) : (
        <div className=" absolute left-5 top-20">
          <EmptyData />
        </div>
      )}
    </div>
  )
}
