import React from "react"
import { EnvironmentOutlined } from "@ant-design/icons"
import { Job } from "../../hooks/jobdetail/types"
import { formatNumber } from "../../../../helper/formatNumber"

interface Props {
  data: Job
}

export const InfoJob: React.FC<Props> = ({ data }) => {
  return (
    <div className=" p-5 rounded-xl shadow-md bg-[#fafafa] w-full mb-5">
      <div className="flex gap-1 text-xs my-1">
        <EnvironmentOutlined />
        <div>{data?.location?.name || "unknown"}</div>
        <div>{data?.remote ? "(remote)" : "(on-site)"}</div>
      </div>
      <div className="my-1">
        <strong>Team/Function: </strong>
        {data?.team || "unknown"}
      </div>
      <div className="my-1">
        <strong>Gross/Salary: </strong>
        {`${data?.salary_currency?.code || "sgd"} ${formatNumber(
          data?.salary_from || 0,
        )} - ${data?.salary_currency?.code || "sgd"} ${formatNumber(
          data?.salary_up_to || 0,
        )} (${data?.salary_frequency?.name || "unknown"})`}
      </div>
      <div className="my-1">
        <strong>Type: </strong>
        {data?.job_type.name || "unknown"}
      </div>
    </div>
  )
}
