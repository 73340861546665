import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
} from "react-query"
import { AxiosInstance } from "axios"
import { useAxiosWithMsal } from "../../../common/hooks"
import { AxiosError } from "../../../common/errors"
import { message } from "antd"
import {
  // ApiResponse,
  ApiResponsePaginated,
} from "../../../common/types/ApiResponse"
import { Item } from "../../../common/hooks/useReferences"

export type ApplicantStatus = "CANDIDATES" | "DISQUALIFIED"

type GetApplicantsQueryParams = {
  job_id: string | undefined
  status: ApplicantStatus | undefined | null
  fit?: number | undefined | null
  stages?: string[]
  locations?: string[]
  limit?: number
  page?: number
}

export interface Candidate {
  id: string
  user_id: string
  avatar: string
  full_name: string
  first_name: string
  last_name: string
  username: string
  fit_percentage: number
  location: string
  stage: Item
  created_at: string
}

export const useGetJobApplicantsById = (
  queryParams: GetApplicantsQueryParams,
): UseQueryResult<ApiResponsePaginated<Candidate>, AxiosError> => {
  const getAxiosInstance = useAxiosWithMsal()
  const getApplicants = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      const processedParam = Object.fromEntries(
        Object.entries(queryParams).map(([key, value]) => [
          key,
          Array.isArray(value) ? value.join(",") : value,
        ]),
      )
      return await api.get<Candidate>("/applicants", {
        params: {
          ...processedParam,
        },
      })
    } catch (error) {
      message.error(
        "An error occurred while fetching applicants for the job post. Please refresh the page or try again. ",
      )
    }
  }
  return useQuery(["applicants", queryParams], getApplicants, {
    onError: (error: AxiosError) => {
      message.error(
        "An error occurred while  fetching applicants for the job post. Please refresh the page or try again. " +
          error.message,
      )
    },
  })
}

export const useUpdateApplicantData = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const updateApplicant = async ({
    data,
    id,
  }: {
    data: { stage_id: string }
    id: string
  }) => {
    try {
      message.open({
        key: "update",
        type: "loading",
        content: "Loading...",
        duration: 0,
      })
      const api = await getAxiosInstance()
      const res = await api.patch(`/applicants/${id}`, data)
      queryClient.invalidateQueries(["applicant-detail"]).then(() => {
        message.open({
          key: "update",
          type: "success",
          content: "Successfully edited stage",
        })
      })

      return res
    } catch (error) {
      message.error(
        "An error occurred while updating the applicant. Please try again.",
      )
    }
  }
  return useMutation(updateApplicant, {
    onSuccess: () => {
      queryClient.invalidateQueries(["applicants"])
    },
    onError: (error: AxiosError) => {
      message.error(
        "An error occurred while updating stage for applicant: " +
          error.message +
          "Please try again.",
      )
    },
  })
}
