import { Breadcrumb } from "antd"
import React from "react"
import { Link } from "react-router-dom"
interface BreadcrumbItem {
  path?: string
  title?: string | React.ReactNode
}
export interface BreadcrumbsProps {
  items?: BreadcrumbItem[]
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items = [] }) => {
  return (
    <Breadcrumb
      separator=">"
      className="font-default"
      items={items?.map((itm) => ({
        // href: itm.path,
        title: itm.path ? <Link to={itm.path}>{itm.title}</Link> : itm.title,
        // path: itm.path,
      }))}
    ></Breadcrumb>
  )
}
