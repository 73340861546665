/* eslint-disable */

import React from "react"

interface BasicContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  children: React.ReactNode | React.ReactElement
  type?: "small" | "default" | "large"
}

export const containerStyles = {
  small: "basic-card-small",
  default: "basic-card",
  large: "",
}

export const BasicContainer = React.forwardRef<
  HTMLDivElement,
  BasicContainerProps
>(({ className = "", children, type = "default", ...rest }, ref) => {
  return (
    <div
      ref={ref}
      className={`rounded-[10px] shadow-custom bg-white-custom ${containerStyles[type]} ${className}`}
      {...rest}
    >
      {children}
    </div>
  )
})
