import React from "react"
import { Astro, Space, Rocket } from "../../assets/images"

export const NotFound = () => {
  return (
    <div className="w-full h-screen bg-[#525150] relative">
      <div className="flex justify-center pt-20 pb-10">
        <img src={Space} alt="planet" className=" h-32 " />
      </div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
        <div className="text-5xl text-white mb-1">404</div>
        <div className="text-white tracking-wide text-lg">
          404 It looks like this page got lost in space
        </div>
      </div>
      <img
        src={Astro}
        alt="astro"
        className=" h-56 absolute top-[65%] left-[20%] transform -translate-x-1/4 -translate-y-1/2 "
      />
      <img
        src={Rocket}
        alt="rocket"
        className=" h-56 absolute top-[50%] right-[15%] transform -translate-x-1/2 -translate-y-1/2 "
      />
    </div>
  )
}
