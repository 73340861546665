import React from "react"
import { SolutionOutlined, CalendarOutlined } from "@ant-design/icons"
import otherInstitution from "../../../../assets/images/other_institution.png"
import dayjs from "dayjs"
import { EmptyData } from "../../../../common/components/EmptyData"

interface Props {
  data?: Array<{
    id: string
    institution: {
      name: string
      id: string
      image: string
    }
    degree: string
    field_of_study: string
    online: boolean
    start_at: string
    end_at: string
  }>
}

export const Educations: React.FC<Props> = ({ data }) => {
  return (
    <div>
      {data ? (
        data.map((item, i) => (
          <div
            className=" mb-5 relative shadow-md bg-[#FAFAFA] rounded-xl p-5"
            key={i}
          >
            <div className="flex gap-5 ">
              <img
                src={item.institution.image || otherInstitution}
                alt="company"
                className="w-20 h-20 p-2 rounded-full shadow object-contain"
              />
              <div>
                <h3 className=" capitalize">{item.institution.name}</h3>
                <div className="flex gap-4 items-center my-1">
                  <div className="">
                    <SolutionOutlined />
                    <span className=" text-sm ml-2 capitalize">{`${item.degree}, ${item.field_of_study}`}</span>
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="">
                    <CalendarOutlined />
                    <span className=" text-sm ml-2">{`${dayjs(
                      item.start_at,
                    ).format("MMM YYYY")} - ${
                      item.end_at
                        ? dayjs(item.end_at).format("MMM YYYY")
                        : "Present"
                    }`}</span>
                  </div>
                  <small className=" text-slate-400">
                    {item.online ? "online" : "offline"}
                  </small>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="shadow-md bg-[#FAFAFA] rounded-xl mb-5 relative p-5">
          <EmptyData />
        </div>
      )}
    </div>
  )
}
