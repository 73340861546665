import React, { useState } from "react"
import { Input, Button, Spin, Popconfirm } from "antd"
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons"
import { useForm, Controller } from "react-hook-form"

interface InputWithEditAndDeleteProps {
  onDelete: () => void
  onSave: (name: string) => void
  value: string
  isLoading: boolean
}

export const InputWithEditAndDelete: React.FC<InputWithEditAndDeleteProps> = ({
  onDelete,
  onSave,
  value,
  isLoading,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const { handleSubmit, setValue, formState, control } = useForm({
    defaultValues: { name: value },
  })
  const { errors } = formState

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleDelete = () => {
    setValue("name", "")
    setIsEditing(false)
    onDelete()
  }

  const handleSave = handleSubmit((data) => {
    setIsEditing(false)
    onSave(data.name)
  })
  return (
    <form
      onSubmit={handleSave}
      className="flex flex-grow items-center relative"
    >
      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            {...field}
            readOnly={!isEditing}
            className="flex-1 h-6"
            prefix={isLoading && <LoadingOutlined />}
            suffix={
              !isEditing ? (
                <>
                  <EditOutlined onClick={handleEdit} className="!text-sm" />
                  <Popconfirm
                    title="Are you sure to delete this stage?"
                    align={{ dynamicInset: true }}
                    onConfirm={handleDelete}
                    okText="Yes"
                    okButtonProps={{ className: "bg-primary text-white" }}
                    cancelText="No"
                  >
                    {isLoading ? (
                      <Spin />
                    ) : (
                      <DeleteOutlined className="!text-sm" />
                    )}
                  </Popconfirm>
                </>
              ) : (
                <>
                  <Button
                    htmlType="submit"
                    type="text"
                    className="!m-0 hover:!bg-transparent group !p-0"
                    icon={
                      <CheckOutlined className="!text-sm group-hover:scale-105 duration-300 transition-transform" />
                    }
                  />
                  <CloseOutlined
                    onClick={() => setIsEditing(false)}
                    className="!text-sm hover:scale-105 duration-300 transition-transform"
                  />
                </>
              )
            }
          />
        )}
      />

      {errors.name && <span className="text-red-500 ml-2">Required</span>}
    </form>
  )
}
