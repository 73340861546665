import React, { useContext } from "react"
import talent from "../../../../assets/images/talent.svg"
import { useNavigate } from "react-router-dom"
import { useTalentDatabases } from "../../../talent-finder/hooks/useTalentDatabases"
import { useConnectAvailable } from "../../../talent-finder/hooks/useConnectAvailable"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import {
  ITourContext,
  TourContext,
} from "../../../../common/components/tour/TourProvider"

export const TalentConnect = () => {
  const navigate = useNavigate()
  const talentDatabase = useTalentDatabases()
  const connectAvailable = useConnectAvailable()
  const {
    refs: { refTalentConnect },
  } = useContext(TourContext) as ITourContext

  return (
    <div
      ref={refTalentConnect}
      className=" flex gap-5 rounded-md shadow-custom p-5 bg-custom-white cursor-pointer"
      onClick={() => navigate("/talent/connected")}
    >
      <Spin
        spinning={talentDatabase?.isLoading || connectAvailable?.isLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <div className="flex gap-5">
          <img src={talent} alt="Job Post" className=" h-20 w-20" />
          <div className="">
            <h3 className="m-0 p-0">My Talent Database</h3>
            <div className="my-1">{`${
              talentDatabase?.total || 0
            } Connected Talents`}</div>
            <div>{`${connectAvailable?.data || 0} Connect Credits`}</div>
          </div>
        </div>
      </Spin>
    </div>
  )
}
