import React, { useState, useEffect, useRef } from "react"
import {
  Button,
  Drawer,
  message,
  Result,
  Select,
  Skeleton,
  Tabs,
  TabsProps,
} from "antd"
import {
  EnvironmentOutlined,
  MailOutlined,
  LinkedinOutlined,
  LinkOutlined,
  TwitterOutlined,
} from "@ant-design/icons"
import { TalentInfo } from "./detail/TalentInfo"
import { DetailApplicant } from "../../inteface/detailApplicant"
import { ScreeningQuestions } from "./detail/Screening"
import { UseQueryResult } from "react-query"
import { GetStagesApiResponse } from "../../hooks/useManageJobStages"
import { AxiosError } from "../../../../common/errors"
import { ensureHttps } from "../../../../helper/ensureHtpps"

interface CvDrawerProps {
  candidate: DetailApplicant
  stagesQuery: UseQueryResult<GetStagesApiResponse, AxiosError>
  getDetail: (id: any) => void
}

export const CvDrawer: React.FC<CvDrawerProps> = ({
  candidate,
  stagesQuery,
  getDetail,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("")
  const selectRef = useRef<HTMLSelectElement>(null)

  // const getColorForStatus = (statusId: string): string => {
  //   const status = stagesQuery.data?.data.data.find(
  //     (item) => item.id === statusId,
  //   )?.name
  //   switch (status) {
  //     case "hired":
  //       return "#D4F5C5"
  //     case "new":
  //       return "#b9babd"
  //     case "disqualified":
  //       return "#EF7574"
  //     default:
  //       return "#FFEFA0"
  //   }
  // }

  const getTextWidth = (text: string) => {
    const div = document.createElement("button")
    div.textContent = text
    document.body.appendChild(div)
    const width = div.getBoundingClientRect().width
    document.body.removeChild(div)
    return width + (1 / 5) * width
  }

  const updateSelectWidth = () => {
    if (selectRef.current) {
      const selectedOptionLabel =
        selectRef.current.options[selectRef.current.selectedIndex].text
      const width = getTextWidth(selectedOptionLabel)

      selectRef.current.style.width = `${width}px`
    }
  }

  useEffect(() => {
    if (selectedOption !== "") {
      updateSelectWidth()
    }
  }, [
    selectedOption,
    candidate?.detail?.user,
    candidate?.state?.ishandleDisqualify,
  ])

  useEffect(() => {
    if (candidate.detail.user) {
      setSelectedOption(candidate.detail.user?.stage.id)
    }
  }, [candidate?.detail?.user])

  const handleChange = (data: any) => {
    const status = stagesQuery.data?.data.data.find((item) => item.id === data)
      ?.name
    if (status === "disqualified") {
      candidate.state.changeStage(candidate.detail.user?.id || "", data, () => {
        candidate.state.setIsHandleDisqualify(true)
        getDetail(candidate.detail.user?.id || "")
      })
    } else {
      candidate.state.changeStage(candidate.detail.user?.id || "", data, () => {
        candidate.state.setIsHandleDisqualify(false)
        getDetail(candidate.detail.user?.id || "")
      })
    }
  }
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Green CV",
      children: (
        <TalentInfo
          candidate={candidate?.detail?.user}
          profile={candidate.detail.user?.profile_detail}
          location={candidate.detail.user?.location}
          stage={candidate.detail.user?.stage}
        />
      ),
    },
    {
      key: "2",
      label: "Screening Questions",
      children: (
        <ScreeningQuestions
          data={candidate.detail.user?.screening_questions_detail}
        />
      ),
    },
    {
      key: "3",
      label: "Notes",
      children: <div>Notes</div>,
      disabled: true,
    },
  ]
  return (
    <Drawer
      open={candidate.state.open}
      width={1000}
      onClose={candidate.state.closeAction}
      closable={false}
      className=" bg-slate-600 cv"
      bodyStyle={{ backgroundColor: "#E2EBFA" }}
    >
      {candidate.detail.loading ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : candidate.detail.user?.is_error ? (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
        />
      ) : (
        <div>
          <div className="p-5 bg-[#36505E] my-3 text-white">
            <div className="p-5 bg-[#36505E] my-3 text-white">
              <div className="grid grid-cols-8 gap-3 relative">
                <img
                  src={candidate.detail.user?.avatar}
                  alt="profile"
                  className="h-[90px] w-[90px] rounded-full object-contain"
                  style={{ border: "1px solid #D4F5C5" }}
                />

                <div
                  className={`${
                    candidate.detail.user?.profile_detail?.career_aspiration
                      ? " col-span-4"
                      : "col-span-7"
                  }`}
                >
                  <div>
                    <div className="font-bold text-lg mb-3">
                      <div className="font-bold text-lg">
                        {candidate.detail.user?.stage?.name === "new" ||
                        candidate.detail.user?.stage.name === "disqualified"
                          ? candidate.detail.user?.username
                          : candidate.detail.user?.full_name}
                      </div>
                    </div>
                    {candidate.detail.user?.location && (
                      <div className="mb-3">
                        <EnvironmentOutlined className="mr-2" />
                        <span>{candidate.detail.user?.location}</span>
                      </div>
                    )}
                  </div>
                  {candidate.detail.user?.profile_detail?.profile_headline && (
                    <div className=" font-light mt-3">
                      {candidate.detail.user?.profile_detail?.profile_headline}
                    </div>
                  )}
                  <div className="mt-3 flex gap-5">
                    {candidate.detail.user?.profile_detail?.email &&
                      candidate.detail.user?.profile_detail?.email !== "-" && (
                        <MailOutlined
                          className=" hover:text-primary"
                          onClick={() => {
                            window.open(
                              `mailto:${candidate.detail.user?.profile_detail?.email}`,
                            )
                          }}
                        />
                      )}
                    {candidate.detail.user?.profile_detail?.linkedin &&
                      candidate.detail.user?.profile_detail?.linkedin !==
                        "-" && (
                        <a
                          href={ensureHttps(
                            candidate.detail.user?.profile_detail?.linkedin,
                          )}
                          target={
                            candidate.detail.user?.profile_detail?.linkedin &&
                            "_blank"
                          }
                          rel="noreferrer"
                          className=" text-white"
                          onClick={(e) => {
                            if (
                              !candidate.detail.user?.profile_detail?.linkedin
                            ) {
                              e.preventDefault()
                              message.error("LinkedIn profile not available")
                            }
                          }}
                        >
                          <LinkedinOutlined className=" hover:text-primary" />
                        </a>
                      )}
                    {candidate.detail.user?.profile_detail?.twitter &&
                      candidate.detail.user?.profile_detail?.twitter !==
                        "-" && (
                        <a
                          href={ensureHttps(
                            candidate.detail.user?.profile_detail?.twitter,
                          )}
                          target={
                            candidate.detail.user?.profile_detail?.twitter &&
                            "_blank"
                          }
                          rel="noreferrer"
                          className="text-white"
                          onClick={(e) => {
                            if (
                              !candidate.detail.user?.profile_detail?.twitter
                            ) {
                              e.preventDefault()
                              message.error("Twitter profile not available")
                            }
                          }}
                        >
                          <TwitterOutlined className="hover:text-primary" />
                        </a>
                      )}
                    {candidate.detail.user?.profile_detail?.link &&
                      candidate.detail.user?.profile_detail?.link !== "-" && (
                        <a
                          href={ensureHttps(
                            candidate.detail.user?.profile_detail?.link,
                          )}
                          target={
                            candidate.detail.user?.profile_detail?.link &&
                            "_blank"
                          }
                          rel="noreferrer"
                          className="text-white"
                          onClick={(e) => {
                            if (!candidate.detail.user?.profile_detail?.link) {
                              e.preventDefault()
                              message.error("Link not available")
                            }
                          }}
                        >
                          <LinkOutlined className="hover:text-primary" />
                        </a>
                      )}
                  </div>
                </div>
                {candidate.detail.user?.profile_detail?.career_aspiration && (
                  <div className=" col-span-3 bg-custom-white rounded-lg text-backdrop p-5">
                    <h3 className="">Green Jobs I am interested in</h3>
                    <ol className="m-0 px-4">
                      {candidate.detail.user?.profile_detail?.career_aspiration?.map(
                        (item, i) => (
                          <li key={i} className="my-2">
                            {item.name}
                          </li>
                        ),
                      )}
                    </ol>
                  </div>
                )}
              </div>
              {candidate.detail.user?.profile_detail?.about_me ? (
                <div
                  className=" font-light mt-3 tracking-normal leading-normal"
                  dangerouslySetInnerHTML={{
                    __html: candidate.detail.user?.profile_detail?.about_me,
                  }}
                ></div>
              ) : (
                <div className=" font-light mt-3">
                  It seems this person is not skilled in writing "About Me"
                  section'
                </div>
              )}
              <div className="mt-3 flex gap-5">
                <div className="select-green">
                  <Select
                    // className="w-32"
                    defaultValue={candidate.detail.user?.stage?.id}
                    onChange={handleChange}
                    options={
                      candidate.detail.user?.stage.name === "disqualified" ||
                      candidate.state.ishandleDisqualify
                        ? stagesQuery?.data?.data?.data?.map((v) => ({
                            label: capitalizeFirstLetter(v?.name ?? ""),
                            value: v.id,
                          })) || []
                        : stagesQuery?.data?.data?.data
                            ?.filter((item) => item.name !== "disqualified")
                            .map((v) => ({
                              label: capitalizeFirstLetter(v?.name ?? ""),
                              value: v.id,
                            })) || []
                    }
                    showSearch={false}
                  />
                </div>

                {/* {
                  <select
                    ref={selectRef}
                    onChange={handleChange}
                    value={selectedOption}
                    style={{
                      border: "0.5px solid #36505E",
                      fontFamily: "Sen",
                      borderRadius: "4px",
                      width: "40px",
                      color: "#36505E",
                      backgroundColor: getColorForStatus(selectedOption),
                    }}

                    // disabled={
                    //   candidate.detail.user?.stage.name === "new" ||
                    //   candidate.detail.user?.stage.name === "disqualified"
                    // }
                  >
                    {candidate.detail.user?.stage.name === "disqualified" ||
                    candidate.state.ishandleDisqualify
                      ? stagesQuery?.data?.data?.data?.map((v, i) => (
                          <option key={i} value={v.id}>
                            {v.name}
                          </option>
                        ))
                      : stagesQuery?.data?.data?.data
                          ?.filter((item) => item.name !== "disqualified")
                          .map((v, i) => (
                            <option key={i} value={v.id}>
                              {v.name}
                            </option>
                          ))}
                  </select>
                } */}
                {!candidate.state.ishandleDisqualify && (
                  <Button
                    className="capitalize"
                    danger
                    disabled={candidate.state.ishandleDisqualify}
                    onClick={() => {
                      candidate.state.changeStage(
                        candidate.detail.user?.id || "",
                        stagesQuery.data?.data.data.find(
                          (item) => item.name === "disqualified",
                        )?.id || "",
                        () => candidate.state.setIsHandleDisqualify(true),
                      )
                      setSelectedOption(
                        stagesQuery.data?.data.data.find(
                          (item) => item.name === "disqualified",
                        )?.id || "",
                      )
                    }}
                  >
                    disqualify
                  </Button>
                )}
              </div>
            </div>
          </div>
          <Tabs defaultActiveKey="1" items={items} />
        </div>
      )}
    </Drawer>
  )
}

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
