import { useEffect, useState } from "react"
import { useAxiosWithMsal } from "../../../common/hooks"
import { message } from "antd"
import { useMutation } from "react-query"
import { type ApiResponseV2 } from "../../../common/types/ApiResponse"
import { type TalentDatabase } from "../components/type/database"

export const useCV = () => {
  const getAxiosInstance = useAxiosWithMsal()

  const [current, setCurrent] = useState<TalentDatabase | null>(null)
  const [connected, setConnected] = useState<any>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const getDataTalent = async (talentId: string) => {
    try {
      const api = await getAxiosInstance()
      return await api.get<ApiResponseV2>("/talent-finders/cv/" + talentId)
    } catch (error) {
      throw error
    }
  }
  const { mutate, isLoading, data } = useMutation({
    mutationFn: async (val: string) => {
      return getDataTalent(val)
    },
    mutationKey: "fav-talent",
  })

  const handleGet = (val: TalentDatabase) => {
    setCurrent(val)
    mutate(val.id, {
      onError: (err: any) => {
        message.error(err?.response?.data?.data)
      },
    })
  }

  const handleSelect = (val: TalentDatabase, connect: any) => {
    setIsOpen(true)
    setConnected(connect)
    setCurrent(val)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (current) {
      handleGet(current)
    }
    //
  }, [current])

  return {
    data: data?.data.data,
    handleSelect,
    isLoading,
    handleClose,
    isOpen,
    setConnected,
    connected,
    current,
  }
}
