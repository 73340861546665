import { message } from "antd"
import { UseQueryResult, useQuery } from "react-query"
import { AxiosError } from "axios"
import { type ApiResponse } from "../types/ApiResponse"
import { Currency } from "../../context/user/currencies.context"
import { axiosInstance } from "../../config/axios"

interface CurrencyApiResponse extends ApiResponse {
  data: { data: Currency[] | null }
}

export const useCurrencies = (): UseQueryResult<
  CurrencyApiResponse,
  AxiosError
> => {
  const getCurrencies = async () => {
    try {
      return await axiosInstance.get("/plans/currencies")
    } catch (error: any) {
      message.error(error?.response?.data?.data)
    }
  }

  return useQuery("get-currencies", getCurrencies)
}
