import { Controller } from "react-hook-form"
import {
  // CustomInputDropdownNew,
  InfiniteAutoComplete,
  NumberedSteps,
} from "../../common/components"
import {
  PlusOutlined,
  LoadingOutlined,
  LinkOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  FacebookOutlined,
  InstagramOutlined,
  GithubOutlined,
} from "@ant-design/icons"
import { Button, Input, Select, Upload } from "antd"
import { useCompanyProfileEdit } from "../settings/hooks/useCompanyProfileEdit"
import React from "react"
import {
  useCountriesInfinity,
  useListCompanySectorsInfinity,
} from "../../common/hooks"
import { LayoutForStep4 } from "./LayoutForStep4"

interface RegistrationStepTwoProps {}

const uploadButton = (
  <div className="cursor-pointer z-50">
    {false ? <LoadingOutlined /> : <PlusOutlined />}
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
)

export const RegistrationStepTwo: React.FunctionComponent<
  RegistrationStepTwoProps
> = () => {
  const {
    imageUrl,
    handleSubmit,
    control,
    errors,
    handleUpload,
    onSubmit,
    handleChange,
    beforeUpload,
    companySizes,
  } = useCompanyProfileEdit("create")
  return (
    <LayoutForStep4
      topBarChildren={
        <div className="px-20">
          <NumberedSteps
            currentStepIndex={2}
            steps={[
              "Select Plan",
              "Details",
              "Company Settings",
              "Payment Details",
            ]}
            className="mt-[40px]"
          />
        </div>
      }
    >
      <div className="px-20">
        <form className=" w-[85%] my-20" onSubmit={handleSubmit(onSubmit)}>
          <div className="basic-card bg-custom-white mt-[80px]">
            <h3>Company Information</h3>
            <div className="">
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Company Name"
                    className="mt-1"
                  />
                )}
              />
              {errors.name && (
                <span className="text-red-500 text-sm">
                  Company Name is required
                </span>
              )}
            </div>
            <div className="mt-4">
              <Controller
                name="headquarter"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <InfiniteAutoComplete
                    {...field}
                    className="!w-full"
                    placeholder="Search Country"
                    useDataHook={useCountriesInfinity}
                    // @ts-ignore
                    selectedValue={field.value}
                    onSelect={(value, option) => {
                      field.onChange(option)
                    }}
                  />
                )}
              />
              {errors.headquarter && (
                <span className="text-red-500 text-sm">
                  Company HQ Country is required
                </span>
              )}
            </div>
            <div className=" mt-4">
              <Controller
                name="sector"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <InfiniteAutoComplete
                    {...field}
                    className="!w-full"
                    placeholder="Search Sector"
                    useDataHook={useListCompanySectorsInfinity}
                    // @ts-ignore
                    selectedValue={field.value}
                    onSelect={(value, option) => {
                      field.onChange(option)
                    }}
                  />
                )}
              />
              {errors.sector && (
                <span className="text-red-500 text-sm">Sector is required</span>
              )}
            </div>

            <div className="mt-4">
              <Controller
                name="size"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    defaultValue={null}
                    loading={companySizes.isLoading}
                    className="w-full mt-1"
                    fieldNames={{ label: "name", value: "id" }}
                    options={
                      Array.isArray(companySizes?.data?.data?.data)
                        ? companySizes?.data?.data?.data
                        : []
                    }
                    placeholder="Company Size"
                  ></Select>
                )}
              />
              {errors.size && (
                <span className="text-red-500 text-sm">
                  Company Size is required (1-100)
                </span>
              )}
            </div>
          </div>

          <div className="basic-card bg-custom-white mt-[22px]">
            <h3>Branding</h3>
            <div className="flex gap-x-8 items-baseline">
              <div className="w-[102px] h-full">
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="h-full cursor-pointer z-50"
                  showUploadList={false}
                  customRequest={handleUpload}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      className="z-50"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </div>
              <div className="flex-grow">
                <div className="text-backdrop">Company Description</div>
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input.TextArea
                      rows={4}
                      {...field}
                      placeholder="Write a short company description, to build a stronger Employer Branding"
                      className="mt-1 w-full"
                    />
                  )}
                />
                {errors.description && (
                  <span className="text-red-500 text-sm">
                    Company Description is required
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="basic-card bg-custom-white mt-[22px]">
            <h3>Social Media (optional)</h3>
            <div className="col-span-4 flex flex-col justify-end">
              <Controller
                name="website_url"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    prefix={<LinkOutlined className="mr-2" />}
                    className="mt-5 font-default rounded-none"
                    placeholder="company website"
                  />
                )}
              />
              <Controller
                name="linkedin_url"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    prefix={<LinkedinOutlined className="mr-2" />}
                    className="mt-5 font-default rounded-none"
                    placeholder="LinkedIn"
                  />
                )}
              />
              <Controller
                name="twitter_url"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    prefix={<TwitterOutlined className="mr-2" />}
                    className="mt-5 font-default rounded-none"
                    placeholder="Twitter"
                  />
                )}
              />
              <Controller
                name="facebook_url"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    status={errors.facebook_url ? "error" : ""}
                    prefix={<FacebookOutlined className="mr-2" />}
                    className="mt-5 font-default rounded-none"
                    placeholder="Facebook"
                  />
                )}
              />
              <Controller
                name="instagram_url"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    status={errors.instagram_url ? "error" : ""}
                    prefix={<InstagramOutlined className="mr-2" />}
                    className="mt-5 font-default rounded-none"
                    placeholder="Instagram"
                  />
                )}
              />
              <Controller
                name="github_url"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    status={errors.github_url ? "error" : ""}
                    prefix={<GithubOutlined className="mr-2" />}
                    className="mt-5 font-default rounded-none"
                    placeholder="GitHub"
                  />
                )}
              />
            </div>
          </div>
          <div className="mt-14 mb-4 flex w-full justify-end">
            <Button
              onClick={handleSubmit(onSubmit)}
              className="bg-primary text-white hover:text-white/100 w-full"
            >
              Save and Next
            </Button>
          </div>
        </form>
      </div>
    </LayoutForStep4>
  )
}
