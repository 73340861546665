import React from "react"
import { PageContent, PageHeader } from "../../../common/layout"
import { HomeOutlined } from "@ant-design/icons"
import { CompanyProfileEdit } from "../components"

const Content = () => {
  return (
    <div className="px-5 py-6 bg-white ">
      <CompanyProfileEdit />
    </div>
  )
}

export function CompanyProfile() {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "Settings",
    },
    {
      title: "Company Profile",
    },
  ]

  return (
    <PageContent
      header={
        <PageHeader
          title={"Company Profile"}
          breadcrumbItems={items}
        ></PageHeader>
      }
      content={<Content />}
    />
  )
}
