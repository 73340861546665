import React from "react"
import { Header, Filter, Content, CvDrawer } from "../../components/candidates"
import { useParams, useNavigate } from "react-router"
import {
  useGetJobApplicantsById,
  ApplicantStatus,
} from "../../hooks/useCandidates"
import { GetStageType, useGetStages } from "../../hooks/useManageJobStages"
import { CloseJobModal } from "../../components/candidates"
import { ManageStagesDrawer } from "../../components"
import { useDetailTalent } from "../../hooks/useDetailTalent"

type QueryParams = {
  fit: number | null
  stages: string[]
  locations: string[]
}

type PaginationParams = {
  page: number
  limit: number
}

const initialQueryParams = { fit: null, stages: [], locations: [] }

const Candidates = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const candidate = useDetailTalent()
  const stagesQuery = useGetStages()

  if (id === undefined) navigate("/not-found")

  const [status, setStatus] = React.useState<ApplicantStatus>("CANDIDATES")

  const [isOpenJobCloseModal, setIsOpenJobCloseModal] = React.useState(false)

  const [openManageStagesDrawer, setOpenManageStagesDrawer] =
    React.useState(false)

  const [queryParams, setQueryParams] =
    React.useState<QueryParams>(initialQueryParams)

  const [paginationParams, setPaginationParams] =
    React.useState<PaginationParams>({ page: 1, limit: 10 })

  const initializeQueryParams = () => setQueryParams(initialQueryParams)

  const handleSliderChange = (value: number) => {
    setQueryParams((prevParams) => ({ ...prevParams, fit: value }))
  }

  const handleCheckboxChange = (e: any, stage: GetStageType) => {
    setQueryParams((prevQueryParams) => {
      if (e.target.checked) {
        return {
          ...prevQueryParams,
          stages: [...prevQueryParams.stages, stage.name],
        }
      } else {
        return {
          ...prevQueryParams,
          stages: prevQueryParams.stages.filter((id) => id !== stage.name),
        }
      }
    })
  }

  const handleLocationSelect = (
    id: string,
    location: { value: string; label: string },
  ) => {
    setQueryParams((prevQueryParams) => {
      if (prevQueryParams.locations.includes(location.label)) {
        return prevQueryParams
      } else {
        return {
          ...prevQueryParams,
          locations: [...prevQueryParams.locations, location.label],
        }
      }
    })
  }

  const handleLocationRemove = (name: string) => {
    setQueryParams((prevQueryParams) => {
      return {
        ...prevQueryParams,
        locations: prevQueryParams.locations.filter((value) => {
          return value !== name
        }),
      }
    })
  }

  const getApplicantsQuery = useGetJobApplicantsById({
    job_id: id,
    status,
    ...queryParams,
    ...paginationParams,
  })

  return (
    <div>
      <Header id={id} />
      <div className="md:flex gap-5 px-10 py-5 w-full">
        <Filter
          queryParams={queryParams}
          initializeQueryParams={initializeQueryParams}
          handleSliderChange={handleSliderChange}
          handleCheckboxChange={handleCheckboxChange}
          handleLocationSelect={handleLocationSelect}
          handleLocationRemove={handleLocationRemove}
          status={status}
          setOpenManageStagesDrawer={setOpenManageStagesDrawer}
          openJobCloseModal={() => setIsOpenJobCloseModal(true)}
        />
        <Content
          initializeQueryParams={initializeQueryParams}
          handleStatusChange={(status: ApplicantStatus) => setStatus(status)}
          isLoading={getApplicantsQuery.isLoading}
          data={getApplicantsQuery.data?.data?.data}
          setPaginationParams={setPaginationParams}
          handleOpen={candidate.state.openAction}
        />
      </div>
      {id && (
        <CloseJobModal
          id={id}
          closable={false}
          open={isOpenJobCloseModal}
          close={() => setIsOpenJobCloseModal(false)}
        />
      )}
      <ManageStagesDrawer
        open={openManageStagesDrawer}
        onClose={() => setOpenManageStagesDrawer(false)}
      />
      <CvDrawer
        candidate={candidate}
        stagesQuery={stagesQuery}
        getDetail={candidate.getDetail}
      />
    </div>
  )
}

export default Candidates
