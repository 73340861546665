export function debounce(
  func: (...args: any[]) => void,
  delay: number,
): (...args: any[]) => void {
  let timeoutId: ReturnType<typeof setTimeout>

  return function (...args: any[]) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(() => {
      func.apply(null, args)
    }, delay)
  }
}
