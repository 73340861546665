import { Button, Skeleton, Tag } from "antd"
import React from "react"
import { DeleteOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import { NewJobPostModal } from "../../job-posts/components"

interface JTDetailRightContentProps {
  templateDetailResponse: any
}

const JTDetailRightContent: React.FC<JTDetailRightContentProps> = ({
  templateDetailResponse,
}) => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [modalType, setModalType] = React.useState<"new" | "template">(
    "template",
  )
  return (
    <div className="bg-[#FAFAFA] shadow-md rounded-xl py-7 px-5">
      <div className="flex items-center justify-between">
        <p>
          Saved{" "}
          {dayjs(templateDetailResponse?.data?.updated_at).format(
            "DD MMM YY",
          ) ?? "Unknown"}
        </p>
        {templateDetailResponse?.isLoading ? (
          <Skeleton.Button
            active={true}
            size={"default"}
            shape={"round"}
            block={false}
          />
        ) : (
          <Tag
            color="volcano"
            className="!text-backdrop !border-backdrop z-20 hover:cursor-pointer"
            onClick={(e: any) => {
              templateDetailResponse?.handleUnSaveJob(
                e,
                templateDetailResponse?.data?.id,
              )
              setTimeout(() => {
                navigate("/jobs/my-job-templates")
              }, 1000)
            }}
          >
            <DeleteOutlined />
          </Tag>
        )}
      </div>

      {templateDetailResponse?.isLoading ? (
        <Skeleton.Button active block />
      ) : (
        <div>
          <Button
            className="mt-5"
            type="primary"
            onClick={() => {
              setModalType("template")
              setIsModalOpen(!isModalOpen)
            }}
            block
          >
            Use templates
          </Button>
        </div>
      )}

      {templateDetailResponse?.data && (
        <NewJobPostModal
          open={isModalOpen}
          type={modalType}
          onCancel={() => setIsModalOpen(false)}
          from="template-page"
          templateData={templateDetailResponse?.data}
        />
      )}
    </div>
  )
}

export default JTDetailRightContent
