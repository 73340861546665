import React from "react"
import { usePlans } from "../../modules/payments/hooks"
import { Toggle } from "./Toggle"
import { PlanCard, PlanCardLoading } from "../../modules/payments/components"
import { useAuth } from "../../modules/auth/hooks/useAuth"
import { useMsal } from "@azure/msal-react"

interface Product {
  id: string
  stripe_product_id: string
  name: string
  description: string
  image: string
  features: string[]
  prices: Price[]
}

interface Price {
  currency: string
  price: number
  recurring_interval: "year" | "month"
  recurring_interval_count: number
  recurring_usage_type: string
  stripe_price_id: string
  type: string
}

export const SubscriptionSelect = () => {
  const plansQuery = usePlans()
  const [billing, setBilling] = React.useState("Monthly Billing")
  const { handleRegisterRedirect } = useAuth()
  const { inProgress } = useMsal()

  return (
    <div>
      <div className="flex justify-center mt-4">
        <Toggle
          options={["Monthly Billing", "Annual Billing"]}
          defaultSelected={billing}
          onToggle={setBilling}
        />
      </div>
      <div className="flex gap-x-5 mt-4 justify-center">
        {plansQuery.isLoading || plansQuery.isFetching
          ? ["", ""].map((_, index) => (
              <PlanCardLoading key={index} features={["", "", ""]} />
            ))
          : plansQuery?.data?.data?.data?.map((plan: Product) => {
              const recurringToFind =
                billing === "Monthly Billing" ? "month" : "year"
              const selectedPrice = plan?.prices?.find(
                (price) => price?.recurring_interval === recurringToFind,
              )
              const priceMonth = plan?.prices?.find(
                (price) => price?.recurring_interval === "month",
              )

              if (selectedPrice === undefined) return null
              return (
                <PlanCard
                  key={plan?.id}
                  stripe_price_id={selectedPrice?.stripe_price_id}
                  stripe_product_id={plan.stripe_product_id}
                  price={
                    (recurringToFind === "month"
                      ? selectedPrice?.price
                      : selectedPrice?.price / 12) ?? 0
                  }
                  currency={selectedPrice?.currency}
                  footer="30 days free trial"
                  features={plan?.features}
                  name={plan?.name}
                  buttonTitle="Start for Free"
                  buttonProps={{
                    loading: inProgress !== "none",
                    disabled: plansQuery.isLoading,
                  }}
                  image={plan?.image}
                  originalPrice={
                    recurringToFind === "year" ? priceMonth?.price : undefined
                  }
                  onActionClick={() => {
                    localStorage.setItem(
                      "selectedPlan",
                      JSON.stringify({
                        stripe_price_id: selectedPrice?.stripe_price_id,
                        currency: selectedPrice?.currency,
                        ...plan,
                      }),
                    )
                    handleRegisterRedirect()
                  }}
                />
              )
            })}
      </div>
    </div>
  )
}
