import { Modal } from "antd"
import axios from "axios"
import { msalInstance } from "../msal-instance"

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API + "/api/v1",
  // baseURL: "http://localhost:3001" + "/api/v1",
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      // Open the modal and return a Promise that resolves when the modal is closed
      return new Promise(() => {
        Modal.error({
          title: "Your session has ended",
          content: "Please re-login",
          centered: true,
          onOk: async () => {
            await msalInstance.logoutRedirect()
          },
        })
      })
    }
    return Promise.reject(error)
  },
)
