import { useQuery } from "react-query"
import { axiosInstance } from "../../../config/axios"

export const usePlans = () => {
  const getPlans = async () => {
    return axiosInstance.get(
      `/plans?currency_code=${localStorage.getItem("currency_code") || "sgd"}`,
    )
  }

  return useQuery("plans", getPlans)
}
