import React from "react"
import SquareRate from "../../../../common/components/SquareRate"
import { EmptyData } from "../../../../common/components/EmptyData"

interface Props {
  data?: {
    id: string
    name: string
    proficiency: {
      id: string
      name: string
    }
  }[]
}

export const Languages: React.FC<Props> = ({ data }) => {
  return (
    <div className="p-5 rounded-xl shadow-md bg-[#FAFAFA] mb-5">
      <h3 className="">Languages</h3>
      <div>
        {data && data.length > 0 ? (
          data.map((item, i) => (
            <div key={i} className="flex gap-5 items-center">
              <SquareRate value={translateProficiency(item.proficiency.name)} />
              <div>{item.name}</div>
            </div>
          ))
        ) : (
          <EmptyData />
        )}
      </div>
    </div>
  )
}

const translateProficiency = (proficiency: string): number => {
  switch (proficiency) {
    case "Elementary Proficiency":
      return 1
    case "Limited Working Proficiency":
      return 2
    case "Professional Working Proficiency":
      return 3
    case "Full Professional Proficiency":
      return 4
    case "Native or Bilingual Proficiency":
      return 5
    default:
      return 0
  }
}
