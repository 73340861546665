export const tagOrder = ["new candidates", "pipeline", "decision"]

export function removeKeysFromObject(
  obj: Record<string, any>,
  keysToCheck: string[],
  keysToRemove: string[],
): Record<string, any> | null {
  if (obj === null || obj === undefined) return null
  return Object.keys(obj).reduce<Record<string, any>>((newObj, key) => {
    if (keysToCheck.includes(key)) {
      const value = obj[key]
      if (
        typeof value === "undefined" ||
        value === null ||
        value === undefined
      ) {
        delete newObj[key]
      } else if (typeof value === "object" && !Array.isArray(value)) {
        newObj[key] = removeKeysFromObject(value, keysToRemove, keysToRemove)
      } else {
        newObj[key] = value
      }
    } else {
      newObj[key] = obj[key]
    }
    return newObj
  }, {})
}
