import React from "react"
import { PlanDetail, Plan as PlanType } from "../../hooks/biliingPlan/type"
import dayjs from "dayjs"
import { Button, Divider, Modal } from "antd"
import { CheckCircleTwoTone } from "@ant-design/icons"
import month from "../../../../assets/images/month.svg"
import { Toggle } from "../../../../common/components"
import { formatNumber } from "../../../../helper/formatNumber"

interface Props {
  data?: PlanType
  hasDefaultPayment: boolean
  plans: {
    data: PlanDetail[] | undefined
    isLoading: boolean
  }
  cancel: {
    handleCancel: () => Promise<void>
    isLoading: boolean
  }
  update: {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    handleUpdate: (val: any) => Promise<void>
    isLoading: boolean
  }
}

export const Plan: React.FC<Props> = ({
  data,
  hasDefaultPayment,
  plans,
  cancel,
  update,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [billing, setBilling] = React.useState("Monthly Billing")
  const [isOpenCancel, setIsOpenCancel] = React.useState(false)

  return (
    <div
      className={`shadow-md ${
        (data?.subscription_status === "active" ||
          data?.subscription_status === "trialing") &&
        data?.subscription_detail?.cancel_at === 0
          ? "bg-secondaryYellow"
          : "bg-backdrop text-white"
      } rounded-xl p-5 h-full`}
    >
      <h3>Current Plan</h3>
      {(data?.subscription_status === "active" ||
        data?.subscription_status === "trialing") &&
      data?.subscription_detail?.cancel_at === 0 &&
      data?.upcoming_invoice ? (
        <div>
          <div className="flex gap-5">
            <img
              src={data?.plan?.image}
              alt=""
              className="w-16 h-16 object-contain"
            />
            <div>
              <div className=" font-semibold first-letter:capitalize">{`${data
                ?.plan?.name} (${
                data?.plan?.prices?.recurring_interval === "month"
                  ? "monthly"
                  : "annual"
              })`}</div>
              <div className="mt-3">
                <span className=" uppercase">
                  {data?.plan?.prices?.currency}
                </span>
                <span>{` ${formatNumber(data?.plan?.prices?.price)} / ${data
                  ?.plan?.prices?.recurring_interval}`}</span>
              </div>
            </div>
          </div>
          {data?.subscription_detail?.default_payment_method && (
            <div className=" text-darkGrey text-xs mt-3 capitalize">{`Payment Method ${data?.subscription_detail?.default_payment_method?.card.brand} ****${data.subscription_detail.default_payment_method?.card.last4} `}</div>
          )}
          <div className=" text-darkGrey text-xs mt-3">
            <span>Next payment</span>{" "}
            <span className="uppercase">{data?.plan?.prices?.currency}</span>{" "}
            <span>
              {formatNumber(data?.upcoming_invoice?.total / 100)} on{" "}
              {dayjs
                .unix(data?.upcoming_invoice?.created)
                .format("MMM DD, YYYY")}
            </span>
          </div>
          <div className=" flex justify-between gap-3 mt-5">
            <Button
              className="w-full"
              onClick={() => setIsOpenCancel(true)}
              disabled={cancel.isLoading || update.isLoading}
            >
              Cancel Plan
            </Button>
            <Button
              className="w-full"
              type="primary"
              disabled={cancel.isLoading || update.isLoading}
              onClick={() => {
                update.setOpen(true)
              }}
            >
              Update Plan
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex gap-5 items-center">
            <img src={month} alt="" className="w-16 h-16 object-contain" />
            <div className=" font-semibold">You have no active plan</div>
          </div>
          <div className=" text-darkGrey text-xs mt-4">
            {`${
              data?.subscription_status === "canceled"
                ? "Select plan to access premium features"
                : "Access premium features until " +
                  dayjs
                    .unix(
                      data?.metadata_subscription?.cancelat ||
                        data?.end_date ||
                        0,
                    )
                    .format("MMM DD, YYYY")
            }`}
          </div>
          <div className=" flex justify-between gap-3 mt-4">
            <Button
              className="w-full"
              type="primary"
              disabled={cancel.isLoading || update.isLoading}
              onClick={() => {
                !hasDefaultPayment ? setIsOpen(true) : update.setOpen(true)
              }}
            >
              Select Plan
            </Button>
          </div>
        </div>
      )}
      <Modal
        title="Opss.."
        centered
        closeIcon={null}
        open={isOpen}
        onCancel={() => {
          setIsOpen(false)
        }}
        width={370}
        onOk={() => {
          setIsOpen(false)
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setIsOpen(false)
            }}
          >
            Ok
          </Button>,
        ]}
      >
        <div>Please set your default payment method first</div>
      </Modal>
      <Modal
        title="Are you sure you want to cancel?"
        centered
        closeIcon={null}
        open={isOpenCancel}
        width={450}
        onCancel={() => {
          setIsOpenCancel(false)
        }}
        footer={
          <div className="grid grid-cols-2 gap-5">
            <Button
              className="w-full"
              onClick={() =>
                cancel.handleCancel().then(() => setIsOpenCancel(false))
              }
            >
              I want to cancel{" "}
            </Button>
            <Button
              className="w-full"
              type="primary"
              onClick={() => setIsOpenCancel(false)}
            >
              I want to keep my plan
            </Button>
          </div>
        }
      >
        <div className="my-3">You will lose access to all premium features</div>
      </Modal>
      <Modal
        className="modal-billing"
        centered
        closeIcon={null}
        open={update.open}
        onCancel={() => {
          update.setOpen(false)
        }}
        width={700}
        footer={null}
      >
        <div className="">
          <div className="flex items-center text-center justify-center mb-10">
            <Toggle
              options={["Monthly Billing", "Annual Billing"]}
              defaultSelected={billing}
              onToggle={setBilling}
              from="landing"
            />
          </div>

          <div className="flex gap-10">
            {plans.data?.map((plan, index) => {
              const recurringToFind =
                billing === "Monthly Billing" ? "month" : "year"
              const selectedPrice = plan?.prices?.find(
                (price) => price?.recurring_interval === recurringToFind,
              )
              return (
                <div
                  key={index}
                  className="bg-white shadow-md rounded-xl p-4 w-full relative"
                >
                  <div className=" flex flex-col h-full">
                    <div className="mb-5">
                      <div className="text-center text-xl">
                        <p className="m-0 font-semibold uppercase">
                          {plan?.name}
                        </p>
                        <img
                          src={plan?.image}
                          alt="price-list-image"
                          className=" h-20 w-20 object-cover"
                        />
                        <div className="flex items-center gap-2 justify-center">
                          <p className="text-base font-semibold m-0">
                            {selectedPrice?.currency}{" "}
                            <span className="text-xl font-semibold">
                              {formatNumber(selectedPrice?.price || 0)}
                            </span>
                          </p>
                          <p className="text-gray-400 m-0">
                            {selectedPrice?.recurring_interval === "month"
                              ? "/mo"
                              : "/year"}
                          </p>
                        </div>
                        <Divider className="border-backdrop !mt-1" />
                      </div>

                      <div className="px-5 space-y-3 mt-4">
                        {plan?.features?.map((item: any, index: number) => {
                          return (
                            <div
                              key={index}
                              className="flex items-center gap-4 text-sm"
                            >
                              <CheckCircleTwoTone twoToneColor="E2EBFA" />
                              {item}
                            </div>
                          )
                        })}

                        {index === 1 && (
                          <p className=" text-xs">*Per user monthly</p>
                        )}
                      </div>
                    </div>
                    <div className=" h-full flex items-end">
                      <Button
                        block
                        type="primary"
                        className="w-full"
                        disabled={
                          (data?.subscription_status === "active" ||
                            data?.subscription_status === "trialing") &&
                          data.subscription_detail.cancel_at === 0 &&
                          selectedPrice?.stripe_price_id ===
                            data?.stripe_price_id
                        }
                        onClick={() =>
                          update.handleUpdate({
                            stripe_price_id: selectedPrice?.stripe_price_id,
                            stripe_product_id: plan?.stripe_product_id,
                            currency: selectedPrice?.currency,
                          })
                        }
                      >
                        Select this plan
                      </Button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Modal>
    </div>
  )
}
