import { useInfiniteQuery } from "react-query"
import { message } from "antd"
import { useAxiosWithMsal } from "./useAxiosWithMsal"
import { ApiResponsePaginatedInfinite } from "../types/ApiResponse"
import { Item } from "./useReferences"
import { useCallback } from "react"

interface CompanySectorsInfinity {
  pageParam: number
  search: string
}

export const useListCompanySectorsInfinity = (searchParam: string) => {
  const handleFetchError = (error: any) => {
    message.error(
      `An error occurred while fetching company-sectors: ${error?.data}`,
    )
  }
  const getAxiosInstance = useAxiosWithMsal()

  const fetchListCompnaySectorsInfinity = useCallback(
    async ({ pageParam = 1, search }: CompanySectorsInfinity) => {
      const api = await getAxiosInstance()
      return api.get<ApiResponsePaginatedInfinite<Item>>(
        "/references/company-sectors",
        {
          params: {
            page: pageParam,
            limit: 10,
            name: search,
          },
        },
      )
    },
    [getAxiosInstance],
  )
  return useInfiniteQuery(
    ["company-sectors-infinity", searchParam],
    ({ pageParam }) =>
      fetchListCompnaySectorsInfinity({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    },
  )
}
