import React, { useEffect } from "react"
import { Button, Typography, message, Spin } from "antd"
import { PageHeader } from "../../../../common/layout"
import { HomeOutlined, LoadingOutlined } from "@ant-design/icons"
import { type QueryClient } from "react-query"
import { useGetSingleJobPost, useUpdateDraft } from "../../hooks/useJobPosts"
import { useParams, useNavigate } from "react-router-dom"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import schema from "./formSchema"
import {
  JobDescriptionTab,
  SkillsTab,
  ScreeningQuestionsTab,
  TabsJobPost,
} from "./components"
import { CreateJobSchema } from "./types"
import { removeKeysFromObject } from "../../utils"

type SingleJobPostResponse = any

export const loader = async (
  id: string,
  queryClient: QueryClient,
): Promise<SingleJobPostResponse> => {
  const data = await queryClient.fetchQuery(["job-post", id], () =>
    useGetSingleJobPost(id),
  )
  return data
}

type TabKey = "1" | "2" | "3"

export function CreateJob() {
  const { id } = useParams()
  const navigate = useNavigate()
  if (id === undefined) navigate("/not-found")

  const [singleJobPostQuery] = useGetSingleJobPost(id as unknown as string)
  const updateJobMutation = useUpdateDraft()

  const [activeKey, setActiveKey] = React.useState<TabKey>("1")

  const methods = useForm<CreateJobSchema>({
    // @ts-ignore
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      title: "",
      job_type: null,
      location: null,
      remote: false,
      salary_currency: null,
      salary_from: null,
      salary_up_to: null,
      salary_frequency: null,
      description: "",
      team: "",
      green_skills: null,
      technical_skills: null,
      critical_skills: null,
      languages: null,
      screening_questions: null,
    },
  })

  const singleJobPOstQueryData = singleJobPostQuery?.data?.data?.data

  useEffect(() => {
    if (singleJobPOstQueryData) {
      methods.reset({
        title: singleJobPOstQueryData?.name ?? "",
        job_type: singleJobPOstQueryData?.job_type ?? null,
        location: singleJobPOstQueryData?.location ?? null,
        remote: singleJobPOstQueryData?.remote ?? false,
        team: singleJobPOstQueryData?.team ?? "",
        salary_currency: singleJobPOstQueryData?.salary_currency ?? null,
        salary_from: singleJobPOstQueryData?.salary_from ?? null,
        salary_up_to: singleJobPOstQueryData?.salary_up_to ?? null,
        salary_frequency: singleJobPOstQueryData?.salary_frequency ?? null,
        description: singleJobPOstQueryData?.description ?? "",
        green_skills: singleJobPOstQueryData?.green_skills ?? null,
        technical_skills: singleJobPOstQueryData?.technical_skills ?? null,
        critical_skills: singleJobPOstQueryData?.critical_skills ?? null,
        languages: singleJobPOstQueryData?.languages ?? null,
        screening_questions:
          singleJobPOstQueryData?.screening_questions ?? null,
      })
    }
  }, [methods.reset, singleJobPOstQueryData])

  const totalTabs = 3
  const handleNext = () => {
    const nextKey = (parseInt(activeKey) % totalTabs) + 1
    setActiveKey(nextKey.toString() as TabKey)
    if (activeKey === "1") {
      methods.trigger(["description"])
    } else if (activeKey === "2") {
      methods.trigger(["green_skills", "technical_skills", "critical_skills"])
    } else if (activeKey === "3") {
      methods.trigger(["screening_questions"])
    }
  }

  const onSaveAndExitClick = async () => {
    const removedUnnecessaryKeys = removeKeysFromObject(
      methods.getValues(),
      ["green_skills", "technical_skills", "critical_skills"],
      ["id_"],
    )
    await updateJobMutation.mutateAsync({
      data: removedUnnecessaryKeys,
      id: id as unknown as string,
    })
    navigate("/jobs/my-job-posts")
  }

  const handleReviewAndPublish = async () => {
    const result = await methods.trigger()
    const data = methods.getValues()
    if (result) {
      const removedUnnecessaryKeys = removeKeysFromObject(
        { ...data },
        ["green_skills", "technical_skills", "critical_skills"],
        ["id_"],
      )
      await updateJobMutation.mutateAsync({
        data: removedUnnecessaryKeys,
        id: id as unknown as string,
      })
      if (singleJobPOstQueryData?.status === "DRAFT") {
        navigate(`/jobs/detail/${id}/draft`)
      } else {
        navigate(`/jobs/detail/${id}/preview`)
      }
    } else {
      console.log("Error whilst validating form:", methods?.formState?.errors)
      message.error(`You have errors in your form, please fix them: 
      ${Object.entries(methods?.formState?.errors)
        .map(([key, value]) => `${key}: ${value.message}`)
        .join(", ")}
      `)
    }
  }

  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "My Job Posts",
      path: "/jobs/my-job-posts",
    },
    {
      title: singleJobPostQuery.isLoading ? (
        <LoadingOutlined />
      ) : (
        singleJobPOstQueryData?.name
      ),
    },
  ]
  return (
    <>
      <PageHeader
        title={
          <div className="flex justify-between w-full items-center">
            <Typography.Title>
              {singleJobPostQuery.isLoading ? (
                <LoadingOutlined />
              ) : (
                singleJobPOstQueryData?.name
              )}
            </Typography.Title>
          </div>
        }
        breadcrumbItems={items}
      />
      <div className={"min-h-[calc(100vh-20rem)] relative"}>
        {singleJobPostQuery.isLoading ? (
          <div className="absolute left-[50%] top-1/2 ">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : (
          <FormProvider {...methods}>
            <form className="p-[22px] flex flex-col gap-[22px]">
              <TabsJobPost activeKey={activeKey} setActiveKey={setActiveKey} />
              <div className="basic-card bg-custom-white h-full">
                <div className="flex w-full justify-end gap-x-2 mb-6">
                  {singleJobPOstQueryData?.status === "DRAFT" && (
                    <Button
                      onClick={onSaveAndExitClick}
                      loading={updateJobMutation.isLoading}
                    >
                      Save and Exit
                    </Button>
                  )}
                  {activeKey !== "3" ? (
                    <Button
                      onClick={handleNext}
                      className="bg-primary text-white"
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      onClick={handleReviewAndPublish}
                      loading={updateJobMutation.isLoading}
                      className="bg-primary text-white"
                    >
                      {singleJobPOstQueryData?.status === "DRAFT"
                        ? "Review & Publish"
                        : "Update & Publish"}
                    </Button>
                  )}
                </div>
                <div
                  className={`transition-all ease-in-out duration-500 transform ${
                    activeKey === "1" ? "opacity-100" : "opacity-0 h-0"
                  }`}
                >
                  <JobDescriptionTab isLoading={singleJobPostQuery.isLoading} />
                </div>

                <div
                  className={`transition-all ease-in-out duration-500 transform ${
                    activeKey === "2"
                      ? "opacity-100 block"
                      : "opacity-0 h-0 hidden"
                  }`}
                >
                  <SkillsTab isLoading={singleJobPostQuery.isLoading} />
                </div>

                <div
                  className={`transition-all ease-in-out duration-500 transform ${
                    activeKey === "3"
                      ? "opacity-100 block"
                      : "opacity-0 h-0 hidden"
                  }`}
                >
                  <ScreeningQuestionsTab
                    isLoading={singleJobPostQuery.isLoading}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        )}
      </div>
    </>
  )
}
