import React from "react"
import { Select } from "antd"

interface SelectedStageProps {
  isLoading: boolean
  data: any
  defaultStage: any
  handleChangeStage: (val: string) => void
}

export const SelectedStage: React.FC<SelectedStageProps> = ({
  isLoading,
  data,
  defaultStage,
  handleChangeStage,
}) => {
  return (
    <form className="p-5 bg-secondaryYellow mb-5">
      <p className="mb-2 mt-0 mx-0 p-0 capitalize font-semibold">
        auto assign status
      </p>
      <p>
        Select ATS status for Talents who accepted your invitation to apply for
        a posted job
      </p>

      <Select
        loading={isLoading}
        options={data?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }))}
        value={defaultStage}
        onChange={(value) => handleChangeStage(value)}
        placeholder="select"
        className="w-full mt-3"
      />
    </form>
  )
}
