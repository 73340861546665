import React from "react"
import { Button, Checkbox, Select, Skeleton, Slider, Tag } from "antd"
import { CloseCircleOutlined } from "@ant-design/icons"
import { useGetStages } from "../../hooks/useManageJobStages"
import { useApplicantLocations } from "../../../../common/hooks/useReferences"
import { GetStageType } from "../../hooks/useManageJobStages"
import { ApplicantStatus } from "../../hooks/useCandidates"
import { tagOrder } from "../../utils"
import { useParams } from "react-router"
import { useGetSingleJobPost } from "../../hooks/useJobPosts"
import { formatNumber } from "../../../../helper/formatNumber"

interface FilterProps {
  queryParams: {
    fit: number | null
    stages: string[]
    locations: string[]
  }
  initializeQueryParams: () => void
  handleSliderChange: (value: number) => void
  handleCheckboxChange: (e: any, stage: GetStageType) => void
  handleLocationSelect: (
    id: string,
    location: { value: string; label: string },
  ) => void
  handleLocationRemove: (name: string) => void
  status: ApplicantStatus
  setOpenManageStagesDrawer: React.Dispatch<React.SetStateAction<boolean>>
  openJobCloseModal: () => void
}

export const Filter = ({
  queryParams,
  handleSliderChange,
  handleCheckboxChange,
  handleLocationSelect,
  handleLocationRemove,
  initializeQueryParams,
  status,
  setOpenManageStagesDrawer,
  openJobCloseModal,
}: FilterProps) => {
  const { id } = useParams()
  const stagesQuery = useGetStages()
  const stagesData = stagesQuery?.data?.data?.data
  const locationsQuery = useApplicantLocations(id)
  const locationsData = locationsQuery?.data?.data?.data
  const [singleJobPostQuery] = useGetSingleJobPost(id as unknown as string)
  const queryData = singleJobPostQuery.data?.data?.data

  // bg-white w-80  h-full min-h-min top-5 sticky px-5 py-3

  return (
    <div className="">
      <div className="basic-card bg-custom-white flex justify-start flex-col gap-2">
        <div className="">
          {queryData?.location?.name}{" "}
          {queryData?.remote ? "(Remote)" : "(on-site)"}
        </div>
        <div className="">
          <b>Team/Function:</b> {queryData?.team}
        </div>
        <div className="">
          <b>Salary:</b> {queryData?.salary_currency?.name}{" "}
          {formatNumber(queryData?.salary_from || 0)} -{" "}
          {formatNumber(queryData?.salary_up_to || 0)} (
          {queryData?.salary_frequency?.name})
        </div>
        <div className="">
          <b>Type:</b> {queryData?.job_type?.name}
        </div>
        <Button
          className=" self-center w-full mt-4"
          onClick={openJobCloseModal}
          disabled={queryData?.status === "CLOSE"}
          type="primary"
        >
          Close job
        </Button>
      </div>
      <div className="basic-card bg-custom-white mt-5 w-80  px-5 py-3 ">
        <div className=" flex justify-between items-center mb-5">
          <div className=" text-lg font-semibold">Filter</div>
          <div
            onClick={initializeQueryParams}
            className=" text-xs flex items-center p-0 m-0 cursor-pointer hover:scale-105 transition-transform duration-100"
          >
            <CloseCircleOutlined className="mr-1.5" />
            Clear all
          </div>
        </div>
        <div>
          <div className="flex justify-between">
            <div>Candidate fit:</div>
            <div>{queryParams.fit ?? 0}% - 100%</div>
          </div>
        </div>
        <div className="flex justify-around items-center gap-1">
          <div>0%</div>
          <Slider
            className="w-3/4"
            onAfterChange={handleSliderChange}
            trackStyle={{
              backgroundColor: "#D9D9D9",
              border: "1px solid black",
            }}
            railStyle={{
              backgroundColor: "#D4F5C5",
              border: "1px solid black",
            }}
          />
          <div>100%</div>
        </div>
        <div>
          {status === "CANDIDATES" && (
            <>
              <div className=" font-semibold my-2">Stage</div>
              {stagesQuery.isLoading ? (
                <Skeleton active />
              ) : (
                <div className="flex flex-col mb-3">
                  {stagesData
                    ?.sort(
                      (a, b) =>
                        tagOrder.indexOf(a.tag) - tagOrder.indexOf(b.tag),
                    )
                    ?.map((stage) => {
                      if (
                        stage?.tag === "decision" &&
                        stage?.name === "disqualified"
                      )
                        return null
                      return (
                        <div key={stage.id}>
                          <Checkbox
                            className="my-1 capitalize w-full max-w-max"
                            onChange={(e) => handleCheckboxChange(e, stage)}
                            checked={queryParams.stages.includes(stage.name)} // Check if stage.id is found in queryParams.stages
                          >
                            {stage.name}
                          </Checkbox>
                        </div>
                      )
                    })}
                </div>
              )}
            </>
          )}
          <div
            onClick={() => setOpenManageStagesDrawer(true)}
            className=" text-secondary hover:text-backdrop cursor-pointer transition-colors duration-500 "
          >
            Manage stages
          </div>
        </div>
        <div className="mt-5 bg-grey-select select-text-backdrop">
          <div className="font-semibold mb-3">Location</div>
          <Select
            // showSearch
            onSelect={handleLocationSelect}
            autoClearSearchValue
            placeholder={"Select Location"}
            options={locationsData?.map((location) => ({
              label: location,
              value: location,
            }))}
            className="w-full mb-3"
          />
          <div className="mb-5 flex flex-wrap gap-2">
            {queryParams.locations?.map((v, i) => (
              <Tag
                key={i}
                closable
                onClose={() => handleLocationRemove(v)}
                className="capitalize"
              >
                {v}
              </Tag>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
