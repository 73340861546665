import { LpQualityHiring } from "../../../../assets/images"

const QualityHiring = () => {
  return (
    <section className="min-h-screen py-[70px] md:px-20 px-3 space-y-[40px] bg-dirtyBlue">
      <div className="text-center">
        <p className="uppercase text-center tracking-widest text-xs leading text-primary">
          INTUITIVE ATS
        </p>
        <h2 className="md:text-4xl text-3xl font-semibold mt-2">
          Quality hiring, faster
        </h2>
        <p className="text-xl md:px-36 mt-4">
          Simple but powerful ATS allowing to focus on candidate skills profile
          and their fit
        </p>
      </div>
      <div className="text-center">
        <img
          src={LpQualityHiring}
          className="object-contain h-auto w-full lg:h-[535px]"
        />
      </div>
    </section>
  )
}

export default QualityHiring
