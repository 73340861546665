import React, { useEffect, useState } from "react"
import {
  EnvironmentOutlined,
  MailOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  LinkOutlined,
  LoadingOutlined,
  //   BankOutlined,
  //   BookOutlined,
} from "@ant-design/icons"
// import { ReactComponent as PinMap } from "../../../../assets/images/pin_map.svg"
// import Moon from "../../../../assets/images/month.svg"
import { Button, Input, Popover, Result, Skeleton, Spin } from "antd"
import { Talent } from "../type/database"
import { SkillCompetencies } from "./SkillCompetencies"
import { WorkExperience } from "./WorkExperience"
import { Educations } from "./Educations"
import { AxiosResponse } from "axios"
import { EmptyData } from "../../../../common/components/EmptyData"
import { ensureHttps } from "../../../../helper/ensureHtpps"
// import { FiveCircleRate } from "../../../../common/components"
// import SquareRate from "../../../../common/components/SquareRate"
// import { WordCloud } from "@ant-design/plots"
// import dayjs from "dayjs"

interface CVProps {
  id: string
  nick_name: string
  firstName: string
  lastName: string
  connected: any
  links: any
  profilePicture: string
  data: Talent
  roadmaps: any
  isLoading: boolean
  isLoadingReq: boolean
  handleReq: (val: string) => void
  currentReq: string
  setConnected: React.Dispatch<any>
  jobUsed: {
    data: any
    handleGet: (val: string) => void
    isLoading: boolean
  }
  jobList: {
    dataList: any[]
    isLoading: boolean
    handleSearch: (...args: any[]) => void
    params: any
    lastCardElementRef: (node: Element | null) => void
  }
  handleInvite: (val: any) => Promise<AxiosResponse<any, any> | undefined>
  isLoadingInvite: boolean
}

// interface WordCloudProps {
//   data: []
//   wordField: string
//   weightField: string
//   colorField: string
//   wordStyle: {
//     fontFamily: string
//     fontSize: [number, number]
//     rotation: number
//   }
// }

export const CV: React.FC<CVProps> = ({
  // firstName,
  // lastName,
  connected,
  data,
  // roadmaps,
  isLoading,
  isLoadingReq,
  handleReq,
  currentReq,
  setConnected,
  id,
  jobUsed,
  jobList,
  handleInvite,
  isLoadingInvite,
  // nick_name,
}) => {
  const menu = [
    {
      key: 1,
      label: "Skills & Competencies",
      active: true,
    },
    {
      key: 2,
      label: "Work Experience",
      active: false,
    },
    {
      key: 3,
      label: "Education",
      active: false,
    },
  ]
  const [stateMenu, setStateMenu] = useState(menu)

  useEffect(() => {
    setStateMenu(menu)
  }, [data])

  return isLoading ? (
    <Skeleton active paragraph={{ rows: 19 }} title={false} />
  ) : data ? (
    <div className="">
      <div className="p-5 bg-[#36505E] my-3 text-white">
        <div className="grid grid-cols-8 gap-3 relative">
          <img
            src={data?.profile_picture || data?.avatar}
            alt="profile"
            className="h-[90px] w-[90px] rounded-full object-contain"
            style={{ border: "1px solid #D4F5C5" }}
          />

          <div
            className={`${
              data?.career_aspiration ? " col-span-4" : "col-span-7"
            }`}
          >
            <div>
              <div className="font-bold text-lg mb-3">
                {data?.first_name && data?.last_name ? (
                  <div className="capitalize">{`${data?.first_name} ${data?.last_name}`}</div>
                ) : (
                  <div>@{data?.nick_name}</div>
                )}
              </div>
              {data?.country && (
                <div className="mb-3">
                  <EnvironmentOutlined className="mr-2" />
                  <span>{data?.country}</span>
                </div>
              )}
            </div>
            {data?.profile_headline && (
              <div className=" font-light mt-3">{data?.profile_headline}</div>
            )}
            <div className="mt-3 flex gap-5">
              {data?.email && (
                <MailOutlined
                  className=" hover:text-primary"
                  onClick={() => {
                    window.open(`mailto:${data?.email}`)
                  }}
                />
              )}
              {data?.linkedin && (
                <a
                  href={ensureHttps(data?.linkedin)}
                  target={data?.linkedin && "_blank"}
                  rel="noreferrer"
                  className=" text-white"
                >
                  <LinkedinOutlined className=" hover:text-primary" />
                </a>
              )}
              {data?.twitter && (
                <a
                  href={ensureHttps(data?.twitter)}
                  target={data?.twitter && "_blank"}
                  rel="noreferrer"
                  className=" text-white"
                >
                  <TwitterOutlined className=" hover:text-primary" />
                </a>
              )}

              {data?.link && (
                <a
                  href={ensureHttps(data?.link)}
                  target={data?.link && "_blank"}
                  rel="noreferrer"
                  className=" text-white"
                >
                  <LinkOutlined className=" hover:text-primary" />
                </a>
              )}
            </div>
          </div>
          {data?.career_aspiration && (
            <div className=" col-span-3 bg-custom-white rounded-lg text-backdrop p-5">
              <h3 className="">Green Jobs I am interested in</h3>
              <ol className="m-0 px-4">
                {data?.career_aspiration?.map((item, i) => (
                  <li key={i} className="my-2">
                    {item.name}
                  </li>
                ))}
              </ol>
            </div>
          )}
        </div>
        {data?.about_me ? (
          <div
            className=" font-light mt-3 tracking-normal leading-normal"
            dangerouslySetInnerHTML={{ __html: data?.about_me }}
          ></div>
        ) : (
          <div className=" font-light mt-3">
            It seems this person is not skilled in writing "About Me" section'
          </div>
        )}
        <div className="mt-3">
          {connected?.status !== "connected" ? (
            <Button
              loading={isLoadingReq && currentReq === data?.id}
              disabled={
                connected?.disabled || (isLoadingReq && currentReq === data?.id)
              }
              className=" capitalize disabled:text-white disabled:bg-slate-400"
              type="primary"
              onClick={() => {
                handleReq(id)
                setConnected({ disabled: true, status: "pending" })
              }}
            >
              {isLoadingReq && currentReq === id
                ? "Loading"
                : connected?.status}
            </Button>
          ) : (
            <Popover
              trigger={"click"}
              placement="rightTop"
              arrow={false}
              content={
                <div
                  className="w-56 "
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <div className=" capitalize">invite talent apply</div>
                  <Input.Search
                    placeholder="search"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      jobList.handleSearch(e.target.value)
                    }}
                    defaultValue={jobList.params?.name}
                    className="w-full my-2"
                  />
                  <div className="min-h-[35px] h-full max-h-32 overflow-auto">
                    <Spin
                      spinning={
                        jobUsed.isLoading ||
                        jobList.isLoading ||
                        isLoadingInvite
                      }
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    >
                      {jobList.dataList && jobList.dataList.length > 0 ? (
                        jobList.dataList?.map((item: any, index: number) => (
                          <div
                            ref={
                              index === jobList.dataList?.length + 1
                                ? jobList.lastCardElementRef
                                : undefined
                            }
                            className={`my-1 py-1 px-2 hover:bg-slate-200 ${
                              jobUsed.data?.find((val: any) => val === item?.id)
                                ? "cursor-not-allowed text-slate-500"
                                : "cursor-pointer"
                            } `}
                            key={index}
                          >
                            <div
                              className="truncate"
                              onClick={() => {
                                if (
                                  !jobUsed.data?.find(
                                    (val: any) => val === item?.id,
                                  )
                                ) {
                                  handleInvite({
                                    id: item?.id,
                                    name: item?.name,
                                    talent_id: data?.id,
                                  }).then(() => jobUsed.handleGet(data?.id))
                                }
                              }}
                            >
                              {item?.name}
                            </div>
                            {jobUsed.data?.find(
                              (val: any) => val === item?.id,
                            ) && (
                              <div className="ml-3 text-xs">
                                already applied or invited
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <EmptyData />
                      )}
                    </Spin>
                  </div>
                </div>
              }
            >
              <Button
                type="primary"
                onClick={(
                  e:
                    | React.MouseEvent<HTMLAnchorElement, MouseEvent>
                    | React.MouseEvent<HTMLButtonElement, MouseEvent>,
                ) => {
                  jobUsed.handleGet(data?.id)
                  e.stopPropagation()
                }}
              >
                Invite to Apply
              </Button>
            </Popover>
          )}
        </div>
      </div>
      <div>
        {/* switch */}
        <div className="mt-5 flex gap-5 mb-5">
          {stateMenu.map((item) => (
            <div
              className={` cursor-pointer px-2 py-1 rounded-lg ${
                item.active ? "bg-secondaryYellow" : "bg-white"
              }`}
              style={{ border: "1px solid gray" }}
              key={item.key}
              onClick={() =>
                setStateMenu((prev) =>
                  prev.map((val) => {
                    if (val.key === item.key) {
                      return { ...val, active: true }
                    } else {
                      return { ...val, active: false }
                    }
                  }),
                )
              }
            >
              {item.label}
            </div>
          ))}
        </div>
        <div>
          {getComponentActive(
            stateMenu.find((item) => item.active)?.key || 1,
            data,
          )}
        </div>
      </div>
    </div>
  ) : (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, the user didn't axis anymore"
    />
  )
}

const getComponentActive = (key: number, data: Talent): React.ReactNode => {
  switch (key) {
    case 1:
      return <SkillCompetencies data={data} />
    case 2:
      return <WorkExperience data={data?.job_histories} />
    case 3:
      return <Educations data={data?.educations} />
    default:
      return <SkillCompetencies data={data} />
  }
}
