import React from "react"
import { Route, Routes } from "react-router-dom"
import { NotFound } from "../../common/components"
import DetailJobTemplate from "./pages/DetailJobTemplate"

const ListJobTemplates = React.lazy(() =>
  import("./pages/ListJobTemplates").then((module) => ({
    default: module.default,
  })),
)

interface IndexProps {}

const Index: React.FC<IndexProps> = ({}) => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<ListJobTemplates />} />
        <Route path="/detail/:id" element={<DetailJobTemplate />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  )
}

export default Index
