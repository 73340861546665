import { Navigate, Routes, Route } from "react-router-dom"
import React, { Suspense } from "react"
import { useMsal } from "@azure/msal-react"
import {
  Login,
  Register,
  RegistrationStepTwo,
  ResetPassword,
} from "../modules/auth"
import { NotFound } from "../common/components"
import { Dashboard } from "../modules/dashboard/pages"
import { PageLayout } from "../common/layout"
import { CompanyProfile, TeamManagement } from "../modules/settings/pages"
import { OnboardingRoutes } from "./components/OnboardingRoutes"
import { Index as TalentFinder } from "../modules/talent-finder"
import { ProtectRoutes } from "./components/ProtectRoutes"
import {
  SingleJobPage,
  CreateJob,
  loader as createJobLoader,
} from "../modules/job-posts/pages"
import { MyJobPosts } from "../modules/job-posts/pages"
import JobRoute, { JobRouteWithSidebar } from "../modules/job-posts"
import TemplatesLibrary from "../modules/templates-library"
import JobTemplates from "../modules/job-templates"

import { useQueryClient } from "react-query"
import LandingPage from "../modules/home/pages/LandingPage"
import PriceList from "../modules/payments/pages/PriceList"
import { PlanBilling } from "../modules/settings/pages/PlanBilling"
// import DetailTemplateLibrary from "../modules/templates-library/pages/DetailTemplateLibrary"
import { PayForSubscription } from "../modules/payments/components"
import PrivacyPolicy from "../modules/privacyPolicy/pages/PrivacyPolicy"
import TermOfUse from "../modules/privacyPolicy/pages/TermOfUse"
import RewardsAndPerks from "../modules/perks/pages/RewardsAndPerks"
import GreenTalent from "../modules/home/pages/GreenTalent"
import PageLayoutUnAuth from "../common/components/PageLayoutUnAuth"
// import DetailJobTemplate from "../modules/job-templates/pages/DetailJobTemplate"

interface PreventRoutesProps {
  children: React.ReactElement
}

const Index = () => {
  const instance = useMsal()
  const activeAccount = instance?.accounts[0]

  const PreventRoutes: React.FC<PreventRoutesProps> = ({ children }) => {
    return activeAccount ? <Navigate to="/home/dashboard" /> : children
  }

  const queryClient = useQueryClient()
  return (
    <Suspense>
      <Routes>
        <Route
          path="/login"
          element={
            <PreventRoutes>
              <Login />
            </PreventRoutes>
          }
        />
        <Route
          path="/register"
          element={
            <PreventRoutes>
              <Register />
            </PreventRoutes>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PreventRoutes>
              <ResetPassword />
            </PreventRoutes>
          }
        />

        <Route
          path="/"
          element={
            <PreventRoutes>
              <PageLayoutUnAuth>
                <LandingPage />
              </PageLayoutUnAuth>
            </PreventRoutes>
          }
        />

        <Route
          path="/price"
          element={
            <PreventRoutes>
              <PageLayoutUnAuth>
                <PriceList />
              </PageLayoutUnAuth>
            </PreventRoutes>
          }
        />
        <Route
          path="/green-talent"
          element={
            <PreventRoutes>
              <PageLayoutUnAuth>
                <GreenTalent />
              </PageLayoutUnAuth>
            </PreventRoutes>
          }
        />
        {/* Onboarding Routes */}
        <Route
          path="/tenant-detail-form"
          element={
            <OnboardingRoutes>
              <RegistrationStepTwo />
            </OnboardingRoutes>
          }
        />
        <Route
          path="/tenant-plans"
          element={
            <OnboardingRoutes>
              <PayForSubscription />
            </OnboardingRoutes>
          }
        />
        <Route
          path="/home/dashboard"
          element={
            <ProtectRoutes>
              <PageLayout>
                <Dashboard />
              </PageLayout>
            </ProtectRoutes>
          }
        />
        {/* Settings Routes */}
        <Route
          path="/settings/company"
          element={
            <ProtectRoutes>
              <PageLayout>
                <CompanyProfile />
              </PageLayout>
            </ProtectRoutes>
          }
        />
        <Route
          path="/settings/team-management"
          element={
            <ProtectRoutes>
              <PageLayout>
                <TeamManagement />
              </PageLayout>
            </ProtectRoutes>
          }
        />
        <Route
          path="/settings/plan-and-billing"
          element={
            <ProtectRoutes>
              <PageLayout>
                <PlanBilling />
              </PageLayout>
            </ProtectRoutes>
          }
        />
        {/* Jobs Route */}
        <Route
          path="/jobs/my-job-posts/create/:id"
          element={
            <ProtectRoutes>
              <PageLayout>
                <CreateJob />
              </PageLayout>
            </ProtectRoutes>
          }
          loader={async ({ params }: any) => {
            if (params.id) {
              const data = await createJobLoader(params.id, queryClient)
              return data
            }
          }}
        />
        <Route
          path="/jobs/my-job-posts/publish/:id"
          element={
            <ProtectRoutes>
              <PageLayout>
                <SingleJobPage />
              </PageLayout>
            </ProtectRoutes>
          }
          loader={async ({ params }: any) => {
            if (params.id) {
              const data = await createJobLoader(params.id, queryClient)
              return data
            }
          }}
        />
        <Route
          path="/jobs/my-job-posts"
          element={
            <ProtectRoutes>
              <PageLayout>
                <MyJobPosts />
              </PageLayout>
            </ProtectRoutes>
          }
        />

        <Route
          path="/jobs/*"
          element={
            <ProtectRoutes>
              <PageLayout isSidebar={false}>
                <JobRoute />
              </PageLayout>
            </ProtectRoutes>
          }
        />

        <Route
          path="/jobs/detail/*"
          element={
            <ProtectRoutes>
              <PageLayout isSidebar={true}>
                <JobRouteWithSidebar />
              </PageLayout>
            </ProtectRoutes>
          }
        />

        <Route
          path="/jobs/templates-library/*"
          element={
            <ProtectRoutes>
              <PageLayout isSidebar={true}>
                <TemplatesLibrary />
              </PageLayout>
            </ProtectRoutes>
          }
        />

        <Route
          path="/jobs/my-job-templates/*"
          element={
            <ProtectRoutes>
              <PageLayout isSidebar={true}>
                <JobTemplates />
              </PageLayout>
            </ProtectRoutes>
          }
        />

        {/* talent finder router */}
        <Route
          path="/talent/*"
          element={
            <ProtectRoutes>
              <PageLayout>
                <TalentFinder />
              </PageLayout>
            </ProtectRoutes>
          }
        />

        <Route path="/rewards-and-perks" element={<RewardsAndPerks />} />

        <Route path="/privacy-policy/*" element={<PrivacyPolicy />} />
        <Route path="/term-of-use/*" element={<TermOfUse />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  )
}

export default Index
