import { TabCompLayout } from "./TabCompLayout"
import { JobDetailsChecklist } from "./JobDetailsChecklist"
import { GreenSkill } from "./GreenSkill"
import { TechnicalSkills } from "./TechnicalSkills"
import { CriticalCoreSkills } from "./CriticalCoreSkills"
import { Languages } from "./Languages"

export function SkillsTab({}: { isLoading?: boolean }) {
  return (
    <TabCompLayout
      className=" flex flex-col gap-4"
      rightSideClassName="flex flex-col gap-4"
      rightSidebarChildren={<JobDetailsChecklist />}
    >
      <GreenSkill />
      <TechnicalSkills />
      <CriticalCoreSkills />
      <Languages />
    </TabCompLayout>
  )
}
