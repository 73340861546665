import { useAxiosWithMsal } from "../../../../common/hooks"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { AxiosInstance } from "axios"
import { ApiResponse } from "../../../../common/types/ApiResponseV2"
import { message } from "antd"
import { useParams } from "react-router-dom"
import { Job } from "./types"
import { useUnSaveTemplateLibrary } from "../../../templates-library/apis/rest"

export const useDetail = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const { id } = useParams()
  const queryClient = useQueryClient()
  const unSaveTemplateLibraryMutation = useUnSaveTemplateLibrary()

  const { mutateAsync: saveAsTemplate, isLoading: isLoadingSaveAsTemplate } =
    useMutation({
      mutationFn: async (id: any) => {
        try {
          const api: AxiosInstance = await getAxiosInstance()
          return await api.post(`/jobs/${id}/save-as-template`)
        } catch (error) {
          throw error
        }
      },
      mutationKey: "update-customer",
    })

  const handleSave = async (val: any) => {
    message.open({
      key: "save-template",
      content: "saving...",
      type: "loading",
    })
    try {
      const response = await saveAsTemplate(val)
      if (response) {
        queryClient.invalidateQueries(["jobdetail"]).then(() => {
          message.open({
            key: "save-template",
            content: "successfully save",
            type: "success",
          })
        })
      }
    } catch (error: any) {
      message.open({
        key: "save-template",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }

  const handleUnSaveJob = async (e: any, id: string) => {
    e.stopPropagation()
    try {
      const res = await unSaveTemplateLibraryMutation.mutateAsync({
        job_id: id,
      })
      if (res) {
        queryClient.invalidateQueries(["jobdetail"])
      }
    } catch (error: any) {
      console.error("An error occurred:", error?.response?.data?.data)
    }
  }

  const { data, isLoading } = useQuery(
    "jobdetail",
    async () => {
      try {
        const api: AxiosInstance = await getAxiosInstance()
        return await api.get<ApiResponse<Job>>("/jobs/" + id)
      } catch (error) {
        throw error
      }
    },
    {
      retry: 0,
      onError: (error: any) => {
        message.error(error?.response?.data?.message)
      },
    },
  )
  return {
    data: data?.data?.data,
    isLoading: isLoading,
    update: {
      save: handleSave,
      isLoading:
        isLoadingSaveAsTemplate || unSaveTemplateLibraryMutation.isLoading,
      unsave: handleUnSaveJob,
    },
  }
}
