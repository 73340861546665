import React from "react"
import { CardGreenSkill } from "./CardGreenSkill"
import { Talent } from "../type/database"
import { CardCertification } from "./CardCertification"
import { CardSoftSkill } from "./CardSoftSkill"
import { CardOtherSkill } from "./CardOtherSkill"
import { CardLanguage } from "./CardLanguage"
import { Applicant } from "../../../job-posts/inteface/detailApplicant"

interface Props {
  data?: Talent
  isAnonymised?: boolean
  candidate?: Applicant | undefined
  showCert?: boolean
}

export const SkillCompetencies: React.FC<Props> = ({
  data,
  isAnonymised,
  candidate,
  showCert = true,
}) => {
  return (
    <div>
      <div>
        <CardGreenSkill
          data={data}
          isAnonymised={isAnonymised}
          candidate={candidate}
        />
      </div>
      <div className="grid grid-cols-12 mt-5 gap-5">
        <div className="col-span-full md:col-span-5 flex flex-col gap-5">
          <div className="h-full w-full">
            <CardOtherSkill data={data} />
          </div>
          <div>
            <CardLanguage data={data} />
          </div>
        </div>
        <div className="col-span-full md:col-span-7 h-full">
          <CardSoftSkill data={data} />
        </div>
      </div>
      {showCert && (
        <div className="mt-5">
          <CardCertification data={data} />
        </div>
      )}
    </div>
  )
}
