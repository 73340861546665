import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { GetUser, NewUser } from "./useAdministrateUsers"
import { useEditUserByAdmin } from "./useAdministrateUsers"
import { Item } from "../../../common/hooks/useReferences"

const schema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  display_name: yup.string().required("Display Name is required."),
  email: yup.string().email("Invalid Email").required("Email is required"),
  role: yup.string().oneOf(["Admin", "Recruiter"]).required("Role is required"),
  country: yup.mixed<Item>().required("Role is required"),
})

export const useEditUserDrawer = ({
  onClose,
  user,
}: {
  onClose: () => void
  user: GetUser
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: user?.first_name ?? "",
      last_name: user?.last_name ?? "",
      display_name: user?.display_name ?? "",
      email: user?.email ?? "",
      role: user?.role ?? "",
      country: {
        name: user.country,
        id: user.country,
      },
    },
    resolver: yupResolver(schema),
  })

  const editUser = useEditUserByAdmin()

  const onSaveSubmit = async (data: NewUser) => {
    editUser.mutate({ data, id: user?.id })
    onClose()
  }

  return {
    handleSubmit,
    control,
    errors,
    onSaveSubmit,
    editUser,
  }
}
