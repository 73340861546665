import React from "react"
import { EmptyData } from "../../../../common/components/EmptyData"
import { Talent } from "../type/database"
import { WordHardkill } from "./WordHardSkill"

interface Props {
  data?: Talent
}

export const CardOtherSkill: React.FC<Props> = ({ data }) => {
  return (
    <div className="shadow-md h-full rounded-xl bg-[#FAFAFA]">
      <div className="p-5">
        <h2 className="capitalize mb-5">Other Technical Skills</h2>
        {data?.hard_skill && data?.hard_skill?.length > 0 ? (
          <div className="mt-5  max-h-56 flex justify-center items-center">
            <WordHardkill data={data?.hard_skill} />
          </div>
        ) : (
          <EmptyData />
        )}
      </div>
    </div>
  )
}
