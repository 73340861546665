import React, { useContext } from "react"
import jobPost from "../../../../assets/images/job_posts.svg"
import { useNavigate } from "react-router-dom"
import { useGetJobList } from "../../../job-posts/hooks/useJobPosts"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import {
  ITourContext,
  TourContext,
} from "../../../../common/components/tour/TourProvider"

export const JobActive = () => {
  const jobListQuery = useGetJobList({
    location: null,
    name: null,
    status: "JUST_PUBLISH",
    team: null,
    by_recruiter: 1,
    page: 1,
    limit: 10,
  })

  const {
    refs: { refActiveJobs },
  } = useContext(TourContext) as ITourContext

  const navigate = useNavigate()
  return (
    <div
      ref={refActiveJobs}
      className="  rounded-md shadow-custom p-5 bg-custom-white cursor-pointer"
      onClick={() => navigate("/jobs/my-job-posts")}
    >
      <Spin
        spinning={jobListQuery?.isLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <div className="flex gap-5">
          <img src={jobPost} alt="Job Post" className=" h-20 w-20" />
          <div className="">
            <h3>My Jobs</h3>
            <div>
              {`${
                jobListQuery.data?.data?.data?.total_data || 0
              } Active Job Posts`}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}
