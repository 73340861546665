import React, { useContext } from "react"
import {
  NotificationOutlined,
  QuestionCircleOutlined,
  PlayCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons"
import { FloatButton } from "antd"

import { ITourContext, Route, TourContext } from "./tour/TourProvider"
import { useLocation } from "react-router-dom"
import { openInNewTab } from "../../utils/util_funcs"
import { AuthenticatedTemplate } from "@azure/msal-react"

const FloatingButtons = () => {
  const { startTour } = useContext(TourContext) as ITourContext
  const location = useLocation()
  return (
    <FloatButton.Group
      trigger="hover"
      type="primary"
      style={{ right: 24 }}
      icon={<QuestionCircleOutlined />}
    >
      <FloatButton
        tooltip="Documentation (Coming Soon)"
        icon={<InfoCircleOutlined />}
      />
      <FloatButton
        icon={<NotificationOutlined />}
        tooltip="Feature Requests and Bug Reports"
        type="primary"
        // style={{
        //   right: 50,
        // }}
        onClick={() => openInNewTab("https://skilledingreen.featurebase.app/")}
      />
      <AuthenticatedTemplate>
        <FloatButton
          tooltip="Take the tour again."
          onClick={() => startTour(location.pathname as Route)}
          icon={<PlayCircleOutlined />}
        />
      </AuthenticatedTemplate>
    </FloatButton.Group>
  )
}

export default FloatingButtons
