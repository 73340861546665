import React, { useContext } from "react"
import { Table, Avatar, Tag, Popconfirm, Spin } from "antd"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import {
  GetUser,
  useGetUserList,
  UserStatusType,
  useDeleteUserByAdmin,
} from "../hooks/useAdministrateUsers"
import avatarDefault from "../../../assets/images/avatar_1.png"
import {
  ITourContext,
  TourContext,
} from "../../../common/components/tour/TourProvider"

export const TeamManagementTable: React.FC<{
  search: string | null
  userStatusSelected: UserStatusType
  handleEditDrawer: (user: GetUser, onlyOneAdmin: boolean) => void
}> = ({ search, userStatusSelected, handleEditDrawer }) => {
  const deleteUserMutation = useDeleteUserByAdmin()

  const {
    refs: { refEditUser, refDeleteUser },
  } = useContext(TourContext) as ITourContext

  const userListQuery = useGetUserList({ search, userStatusSelected })
  const userList = userListQuery?.data?.data?.data
  const onlyOneAdmin =
    userList?.filter((user) => user.role === "Admin").length === 1

  const columns = [
    {
      title: "User",
      dataIndex: "avatar",
      key: "avatar",
      render: (avatar: string) => (
        <Avatar size={41} src={avatar || avatarDefault} />
      ),
    },
    {
      title: "",
      dataIndex: "display_name",
      key: "display_name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (country: string) => (country === "" ? "N/A" : country),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: UserStatusType) => (
        <Tag
          className={`capitalize ${
            status === "active" ? "bg-primary-bg text-backdrop" : ""
          }`}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_: any, record: GetUser) => (
        <>
          <EditOutlined
            ref={refEditUser}
            className="text-[18px] cursor-pointer hover:scale-105"
            onClick={() => handleEditDrawer(record, onlyOneAdmin)}
          />
          {onlyOneAdmin && record.role === "Admin" ? (
            <DeleteOutlined
              ref={refDeleteUser}
              className="text-[18px] ml-8 cursor-pointer hover:scale-105 opacity-50"
            />
          ) : (
            <Popconfirm
              title="Are you sure to delete this user?"
              onConfirm={() => deleteUserMutation.mutateAsync(record.id)}
              okText="Yes"
              okButtonProps={{ className: "bg-primary text-white" }}
              cancelText="No"
            >
              {deleteUserMutation.isLoading ? (
                <Spin />
              ) : (
                <DeleteOutlined className="text-[18px] ml-8 cursor-pointer hover:scale-105" />
              )}
            </Popconfirm>
          )}
        </>
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      loading={userListQuery?.isLoading}
      dataSource={userListQuery?.data?.data?.data?.map((item) => ({
        ...item,
        key: item?.id,
      }))}
      className="custom-yellow-header"
      pagination={false}
    />
  )
}
