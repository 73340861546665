import { useQuery } from "react-query"
import { ApiResponseV2 } from "../../../common/types/ApiResponse"
import { useAxiosWithMsal } from "../../../common/hooks"
import { AxiosInstance } from "axios"
import { AxiosError } from "../../../common/errors"
import { message } from "antd"
import { axiosInstance } from "../../../config/axios"

export type Perk = {
  app: string
  company_logo: string
  company_name: string
  created_at: string
  expire_at: string | null
  id: string
  offer_desc: string
  offer_link: string
  offer_title: string
  updated_at: string | null
}

export const usePerks = () => {
  const getAxiosInstance = useAxiosWithMsal()

  const fetchPerksList = async () => {
    const instance: AxiosInstance = await getAxiosInstance()
    return instance.get("/perks")
  }

  return useQuery("perks", fetchPerksList, {
    onError: (error: AxiosError) => {
      message.error("Error fetching perks")
      console.error("Error fetching perks", error)
    },
  })
}

const fetchPerksList = async () => {
  return axiosInstance.get("/perks?app=recruiter")
}

export const usePerksUnAuth = () => {
  return useQuery("perks", fetchPerksList, {
    onError: (error: AxiosError) => {
      message.error("Error fetching perks")
      console.error("Error fetching perks", error)
    },
  })
}

export const usePerkDetail = (id: string) => {
  const getAxiosInstance = useAxiosWithMsal()

  const fetchPerkDetail = async (id: string): Promise<ApiResponseV2<Perk>> => {
    const instance: AxiosInstance = await getAxiosInstance()
    return instance.get(`/perks/${id}`)
  }

  return useQuery(["perks", id], () => fetchPerkDetail(id))
}
