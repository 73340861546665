import React from "react"
import { EmptyData } from "../../../../common/components/EmptyData"
import { Talent } from "../type/database"
import SquareRate from "../../../../common/components/SquareRate"

interface Props {
  data?: Talent
}
export const CardLanguage: React.FC<Props> = ({ data }) => {
  return (
    <div className="shadow-md rounded-xl bg-[#FAFAFA] p-5">
      <h2 className="capitalize mb-5">Languanges</h2>
      {data?.languages && data?.languages?.length > 0 ? (
        <div>
          {data?.languages?.map((item, index) => (
            <div key={index} className="my-1">
              <div className=" flex gap-3 items-center mt-2">
                <SquareRate value={item.proficiency.level} />
                <div className="-mt-1">{item.language.name}</div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <EmptyData />
      )}
    </div>
  )
}
