import React from "react"
import { Avatar, Skeleton } from "antd"
import avatarDefault from "../../assets/images/avatar_1.png"
import { EnvironmentOutlined } from "@ant-design/icons"
import { truncateText } from "../../utils/util_funcs"

export interface UserCardProps {
  name?: string
  location?: string
  avatarUrl?: string
  className?: string
  isLoading?: boolean
}

export const UserCard: React.FC<UserCardProps> = ({
  name,
  location,
  avatarUrl,
  className,
  isLoading,
}) => {
  if (isLoading) return <UserCardLoading className={className} />
  const maxNameLength = 20
  const maxOccupationLength = 20

  return (
    <div className={`flex gap-x-4 items-center ${className ?? ""}`}>
      <div>
        <Avatar className=" w-12 h-12" src={avatarUrl || avatarDefault} />
      </div>
      <div className="flex flex-col gap-x-4">
        <div className="text-backdrop text-sm capitalize ">
          {truncateText(name, maxNameLength)}
        </div>
        <div className=" flex gap-1">
          <EnvironmentOutlined className="text-[#BFBFBF] text-xs" />
          <div className="text-[#BFBFBF] text-xs ">
            {truncateText(location, maxOccupationLength)}
          </div>
        </div>
      </div>
    </div>
  )
}

export const UserCardLoading: React.FC<{
  className?: string
}> = ({ className }) => {
  return (
    <div className={`flex gap-x-4 items-center ${className ?? ""}`}>
      <div>
        <Skeleton.Avatar active={true} size="default" shape="circle" />
      </div>
      <div className="flex flex-col gap-x-4">
        <Skeleton.Input active={true} size="small" style={{ width: 100 }} />
        <Skeleton.Input active={true} size="small" style={{ width: 100 }} />
      </div>
    </div>
  )
}
