import React from "react"
import { Moon } from "../../../../assets/images"
import { UpOutlined, DownOutlined, FileTextOutlined } from "@ant-design/icons"
import { Tag } from "antd"
import { Talent } from "../type/database"
import { FiveCircleRate } from "../../../../common/components"
import { Applicant } from "../../../job-posts/inteface/detailApplicant"

interface Props {
  data?: Talent
  isAnonymised?: boolean
  candidate: Applicant | undefined
}

export const CardGreenSkill: React.FC<Props> = ({
  data,
  isAnonymised,
  candidate,
}) => {
  const [active, setActive] = React.useState<boolean>(false)
  return (
    <div className="shadow-md rounded-xl bg-[#FAFAFA] p-5">
      <h2 className="capitalize">green skills</h2>
      <div className=" first-letter:capitalize my-5">skilled in</div>
      {data?.green_skill_proficient_in?.find(
        (item) => item.total_completed_level > 0,
      ) ? (
        data?.green_skill_proficient_in
          ?.filter((item) => item.total_completed_level > 0)
          .map((a, i) => (
            <div
              key={i}
              className="flex md:gap-5 gap-2 md:items-center items-center md:flex-row flex-col rounded px-3 py-1 my-2 bg-[#F0F2F5] md:h-14"
              style={{ border: "1px solid #D0DDE5" }}
            >
              <FiveCircleRate
                count={5}
                defaultValue={a.total_completed_level}
                circleSize={16}
                disabled
              />
              <div className="md:text-left w-full text-center">{a?.name}</div>
              {!isAnonymised && (
                <div className=" w-48 text-white">
                  {a.is_request_verified && a.is_signature_exist ? (
                    <div
                      // href={`${process.env.REACT_APP_TALENT_SPA_URL}learnings/preview/certificate/${a.id}/${candidate?.user_id}/${candidate?.full_name}`}
                      className=" py-1 flex cursor-pointer gap-2 justify-center w-full rounded-full bg-secondary text-white hover:text-white"
                      style={{ border: 0 }}
                    >
                      <FileTextOutlined /> Verification
                    </div>
                  ) : (
                    a.is_signature_exist && (
                      <a
                        href={`${process.env.REACT_APP_TALENT_SPA_URL}learnings/preview/certificate/${a.id}/${candidate?.user_id}/${candidate?.full_name}`}
                        className=" py-1 flex cursor-pointer gap-2 justify-center w-full rounded-full bg-backdrop text-white hover:text-white"
                      >
                        <FileTextOutlined /> Cerificate
                      </a>
                    )
                  )}
                </div>
              )}
            </div>
          ))
      ) : (
        <div className="">
          <div className="flex gap-3 items-center">
            <img src={Moon} alt="cookies" />
            <div>Learning in progress</div>
          </div>
        </div>
      )}
      {data?.green_skill_interest_in && (
        <div className="mt-5">
          <div className="flex justify-between items-center">
            <div className="mb-2">Interested in learning</div>
            {active ? (
              <div
                className="flex gap-2 items-center cursor-pointer text-secondary"
                onClick={() => setActive(!active)}
              >
                <UpOutlined className="w-3 h-3" />
                <small className="">hide details</small>
              </div>
            ) : (
              <div
                className="flex gap-2 items-center cursor-pointer text-secondary"
                onClick={() => setActive(!active)}
              >
                <DownOutlined className="w-3 h-3" />
                <small className="">show details</small>
              </div>
            )}
          </div>
          {active && (
            <div className="flex flex-wrap mt-3">
              {data?.green_skill_interest_in?.map((item, index) => (
                <Tag
                  key={index}
                  color="gold"
                  className="mb-2 overflow-hidden max-w-[235px] sm:w-auto"
                >
                  <div className="truncate">{item.name}</div>
                </Tag>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
