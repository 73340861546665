import React from "react"
import dayjs from "dayjs"
import { Dropdown, Modal, Tag, message } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { PaymentMethod as PaymentMethodType } from "../../hooks/biliingPlan/type"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { AxiosResponse } from "axios"

interface Props {
  data?: PaymentMethodType[]
  defaultPayment?: string
  add: {
    handleAdd: (
      val: any,
      callbackSuccess: () => void,
      callbackError: (error: any) => void,
    ) => Promise<AxiosResponse<any, any> | undefined>
  }
  remove: {
    handleRemove: (val: any) => Promise<AxiosResponse<any, any> | undefined>
    isLoading: boolean
  }
  makeDefault: {
    handleMakeDefault: (
      val: any,
    ) => Promise<AxiosResponse<any, any> | undefined>
    isLoading: boolean
  }
}

export const PaymentMethod: React.FC<Props> = ({
  data,
  add,
  remove,
  defaultPayment,
  makeDefault,
}) => {
  const elements = useElements()
  const stripe = useStripe()

  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpenDefault, setIsOpenDefault] = React.useState(false)
  const [dataDefault, setDataDefault] = React.useState("")
  const [isLoadingAdd, setIsLoadingAdd] = React.useState(false)

  const addAction = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    message.open({ key: "add", content: "Please wait...", type: "loading" })
    setIsLoadingAdd(true)

    const result = await stripe.createPaymentMethod({
      //`Elements` instance that was used to create the Payment Element
      elements,
    })
    if (result.error) {
      setIsLoadingAdd(false)
      message.open({
        key: "add",
        content: result.error.message,
        type: "error",
      })
      // Show error to your customer (for example, payment details incomplete)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      add.handleAdd(
        result.paymentMethod?.id,
        function () {
          setIsOpen(false)
          elements.getElement("card")?.clear()
          setIsLoadingAdd(false)
          message.open({ key: "add", content: "success", type: "success" })
        },
        function (error: any) {
          setIsOpen(false)
          elements.getElement("card")?.clear()
          setIsLoadingAdd(false)
          message.open({ key: "add", content: error, type: "error" })
        },
      )
    }
  }

  return (
    <div className="rounded-xl shadow-md bg-white p-5 w-full">
      <div className="">
        <h3>Payment Methods</h3>
        <div className="mt-2">
          {data && data.length > 0
            ? data?.map((item, index) => {
                return (
                  <div key={index} className="flex justify-between  mb-2">
                    <div className="flex gap-5">
                      <div className="w-20 truncate capitalize">
                        {item.card?.brand}
                      </div>
                      <div className="">{"*****" + item.card?.last4}</div>
                      <div className="w-10">
                        {item.id === defaultPayment && (
                          <Tag color="blue">Default</Tag>
                        )}
                      </div>
                    </div>
                    <div className="flex gap-5">
                      <div>
                        {"Exp " +
                          dayjs(
                            `01-${item.card.exp_month}-${item.card.exp_year}`,
                          ).format("MMM YYYY")}
                      </div>
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: "3",
                              label: (
                                <div
                                  className=""
                                  onClick={() => {
                                    if (item.id !== defaultPayment)
                                      setIsOpenDefault(true)
                                    setDataDefault(item.id)
                                  }}
                                >
                                  Set as default
                                </div>
                              ),
                              disabled: item.id === defaultPayment,
                            },
                            {
                              key: "1",
                              label: (
                                <div
                                  className={`${
                                    data.length > 1 ? "" : "text-danger"
                                  }`}
                                  onClick={() => {
                                    if (data.length > 1) {
                                      remove.handleRemove(item.id)
                                    }
                                  }}
                                >
                                  Delete
                                </div>
                              ),
                              disabled: data.length <= 1,
                            },
                          ],
                          selectable: true,
                        }}
                        arrow={{ pointAtCenter: true }}
                        placement="bottomRight"
                      >
                        <strong className="-mt-1 cursor-pointer">...</strong>
                      </Dropdown>
                    </div>
                  </div>
                )
              })
            : "No payment method"}
        </div>
      </div>
      <div
        className=" mt-5 cursor-pointer w-fit"
        onClick={() => setIsOpen(true)}
      >
        {" "}
        <PlusOutlined /> Add payment method
      </div>
      <Modal
        title="Add payment method"
        centered
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={addAction}
        okButtonProps={{ disabled: isLoadingAdd }}
      >
        <div
          className="p-2 rounded-md"
          style={{ border: "0.5px solid #d9d9d9" }}
        >
          <CardElement options={{ hidePostalCode: true }} />
        </div>
      </Modal>
      <Modal
        title="Are you sure?"
        centered
        closeIcon={null}
        open={isOpenDefault && !!dataDefault}
        onCancel={() => {
          setIsOpenDefault(false)
          setDataDefault("")
        }}
        onOk={() => {
          makeDefault.handleMakeDefault(dataDefault).then(() => {
            setDataDefault("")
          })
          setIsOpenDefault(false)
        }}
        // okButtonProps={{ disabled: isLoadingAdd }}
      >
        <div>
          Make as default means the subscription will charge in this card.
        </div>
      </Modal>
    </div>
  )
}
