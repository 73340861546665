import React from "react"
import { Talent } from "../type/database"
import otherCompany from "../../../../assets/images/other_company.png"
import { LinkOutlined, CalendarOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import { EmptyData } from "../../../../common/components/EmptyData"
import { Link } from "react-router-dom"

interface Props {
  data?: Talent
}

export const CardCertification: React.FC<Props> = ({ data }) => {
  return (
    <div className="shadow-md rounded-xl bg-[#FAFAFA] p-5">
      <h2 className="capitalize mb-5">Certifications</h2>
      {data?.educations ? (
        data?.certification?.map((item, i) => (
          <div key={i} className="flex gap-5 mb-5 last:mb-0">
            <img
              src={item.organisation.image || otherCompany}
              alt="company"
              className="w-24 h-24 p-2 rounded-full shadow object-contain"
            />
            <div>
              <h3 className="capitalize">{item.organisation.name}</h3>
              <div className=" my-1">
                {item.credentials_url && (
                  <Link
                    to={item.credentials_url}
                    target="_blank"
                    className="mr-3 text-secondary cursor-pointer"
                  >
                    <LinkOutlined className="" />
                  </Link>
                )}
                <span className=" capitalize">{item.certification_name}</span>
              </div>
              <div className="flex gap-4 items-center">
                <CalendarOutlined />
                <div>
                  <span>Expiration</span>
                  <span className="ml-2 text-darkGrey">{`${
                    item.has_expiry_date
                      ? dayjs(item.end_at).format("MMM YYYY")
                      : "this certificate does not expire"
                  }`}</span>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <EmptyData />
      )}
    </div>
  )
}
