import React from "react"
import { Route, Routes } from "react-router-dom"
import { NotFound } from "../../common/components"
import { Connected } from "./pages/Connected"
import { Database } from "./pages/Database"

export const Index = () => {
  return (
    <>
      <Routes>
        <Route path="/connected" element={<Connected />} />
        <Route path="/database" element={<Database />} />

        {/* note! each route must be contain this route error to handle error after parent route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}
