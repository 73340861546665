import React from "react"
import { EnvironmentOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { Job } from "../../hooks/jobdetail/types"
import { useNavigate } from "react-router-dom"
import { usePublishJob } from "../../hooks/useJobPosts"
import { useQueryClient } from "react-query"
import { formatNumber } from "../../../../helper/formatNumber"

interface Props {
  data: Job
}

export const InfoJobDraft: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate()
  const publishJobMutation = usePublishJob()
  const queryClient = useQueryClient()
  return (
    <div className=" p-5 rounded-xl shadow-md bg-secondaryYellow w-full mb-5">
      <div className="flex gap-1 text-xs my-1">
        <EnvironmentOutlined />
        <div>{data?.location?.name || "unknown"}</div>
        <div>{data?.remote ? "(remote)" : "(on-site)"}</div>
      </div>
      <div className="my-1">
        <strong>Team/Function: </strong>
        {data?.team || "unknown"}
      </div>
      <div className="my-1">
        <strong>Gross/Salary: </strong>
        {`${data?.salary_currency?.code || "sgd"} ${formatNumber(
          data?.salary_from || 0,
        )} - ${data?.salary_currency?.code || "sgd"} ${formatNumber(
          data?.salary_up_to || 0,
        )} (${data?.salary_frequency?.name || "unknown"})`}
      </div>
      <div className="my-1">
        <strong>Type: </strong>
        {data?.job_type.name || "unknown"}
      </div>
      <div>
        <div className=" flex gap-3 mt-5">
          <Button
            className="w-full"
            onClick={() => navigate(`/jobs/my-job-posts/create/${data.id}`)}
          >
            Back to Edit
          </Button>
          <Button
            onClick={async () => {
              publishJobMutation.mutate(data.id, {
                onSuccess: async () => {
                  await queryClient.invalidateQueries(["jobdetail"])
                  navigate(`/jobs/detail/${data.id}/preview`)
                },
              })
            }}
            loading={publishJobMutation.isLoading}
            disabled={publishJobMutation.isLoading}
            className="w-full"
            type="primary"
          >
            Publish
          </Button>
        </div>
      </div>
    </div>
  )
}
