import React from "react"
import { NumberedSteps, SubscriptionSelect } from "../../common/components"
import { LayoutForStep4 } from "./LayoutForStep4"

export const Register: React.FC = () => {
  return (
    <LayoutForStep4
      topBarChildren={
        <div className="px-20">
          <NumberedSteps
            currentStepIndex={0}
            steps={[
              "Select Plan",
              "Details",
              "Company Settings",
              "Payment Details",
            ]}
            className="mt-[40px]"
          />
        </div>
      }
    >
      <div className="px-20">
        <div className=" w-[85%] my-20 basic-card bg-custom-white">
          <SubscriptionSelect />
        </div>
      </div>
    </LayoutForStep4>
  )
}
