import { useCallback } from "react"
import { useAxiosWithMsal } from "../../../common/hooks"
import { ApiResponsePaginatedInfinite } from "../../../common/types/ApiResponse"
import { Item } from "../../../common/hooks/useInfiniteLanguages"
import { message } from "antd"
import { useInfiniteQuery } from "react-query"

export const useMyJobTemplatesInfinite = (searchParam: string) => {
  const handleFetchError = (error: any) => {
    message.error(
      `An error occurred while fetching your templates: ${error?.data}`,
    )
  }
  const getAxiosInstance = useAxiosWithMsal()

  const fetchCountriesInfinity = useCallback(
    async ({
      pageParam = 1,
      search,
    }: {
      pageParam: number
      search: string
    }) => {
      const api = await getAxiosInstance()
      return api.get<ApiResponsePaginatedInfinite<Item>>("/jobs/my-templates", {
        params: {
          page: pageParam,
          limit: 10,
          name: search,
        },
      })
    },
    [getAxiosInstance],
  )

  return useInfiniteQuery(
    ["my-templates-infinity"],
    ({ pageParam }) =>
      fetchCountriesInfinity({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage: any) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    },
  )
}
