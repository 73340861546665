import { useAxiosWithMsal } from "../../../../common/hooks"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { AxiosInstance } from "axios"
import { ApiResponse } from "../../../../common/types/ApiResponseV2"
import { Customer } from "./type"
import { message } from "antd"

export const useCustomer = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(
    "customer",
    async () => {
      try {
        const api: AxiosInstance = await getAxiosInstance()
        return await api.get<ApiResponse<Customer>>("/billings/customer")
      } catch (error) {
        throw error
      }
    },
    {
      onError: (error: any) => {
        message.error(error?.response?.data?.message)
      },
    },
  )

  const { mutateAsync: updateCustomer, isLoading: isLoadingUpdateCustomer } =
    useMutation({
      mutationFn: async (val: any) => {
        try {
          const api: AxiosInstance = await getAxiosInstance()
          return await api.patch("/billings/customer", val)
        } catch (error) {
          throw error
        }
      },
      mutationKey: "update-customer",
    })

  const handleUpdate = async (val: any) => {
    message.open({
      key: "update-customer",
      content: "updating...",
      type: "loading",
    })
    try {
      const response = await updateCustomer({
        name: val.name,
        email: val.email,
        // address: { country: val.country },
      })
      if (response) {
        queryClient.invalidateQueries(["billing-customer"]).then(() => {
          message.open({
            key: "update-customer",
            content: "billing updated",
            type: "success",
          })
        })
      }
    } catch (error: any) {
      message.open({
        key: "update-customer",
        content: error?.response?.data?.data || "Internal server error",
        type: "error",
      })
    }
  }
  return {
    data: data?.data?.data,
    isLoading,
    updateCustomer: { handleUpdate, isLoadingUpdateCustomer },
  }
}
