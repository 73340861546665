import React from "react"
import { Search } from "../commons/Search"
import { SwitchFilter } from "../commons/Switch"
import { useLocation } from "react-router-dom"

interface FilterTopProps {
  search: string
  handleSearch: (value: string) => void
  favorite: number
  handleFavorite: (value: number) => void
}

const FilterTop: React.FC<FilterTopProps> = ({
  handleSearch,
  search,
  favorite,
  handleFavorite,
}) => {
  const { pathname } = useLocation()
  return (
    <div className="flex justify-between items-center p-5 rounded-xl shadow-md bg-[#FAFAFA]">
      <div
        className={`${
          pathname !== "/talent/connected" ? "w-[calc(100%-9rem)]" : "w-full"
        }`}
      >
        <Search
          className=" "
          handleSearch={handleSearch}
          search={search}
          placeholder={
            pathname !== "/talent/connected"
              ? "search by nickname"
              : "search by name"
          }
        />
      </div>
      {pathname !== "/talent/connected" && (
        <SwitchFilter checked={favorite} handleChecked={handleFavorite} />
      )}
    </div>
  )
}

export default FilterTop
