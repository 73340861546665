import React from "react"
import { Skeleton, Tabs } from "antd"
import type { TabsProps } from "antd"
import { CardList } from "./CardList"
import { ApplicantStatus, Candidate } from "../../hooks/useCandidates"
import { ApiResponsePaginated } from "../../../../common/types/ApiResponse"

interface ContentProps {
  initializeQueryParams: () => void
  handleStatusChange: (status: ApplicantStatus) => void
  isLoading: boolean
  data: ApiResponsePaginated<Candidate>["data"]["data"] | undefined
  setPaginationParams: React.Dispatch<
    React.SetStateAction<{
      page: number
      limit: number
    }>
  >
  handleOpen: (id: string) => void
}

export const Content = ({
  initializeQueryParams,
  handleStatusChange,
  isLoading,
  setPaginationParams,
  data,
  handleOpen,
}: ContentProps) => {
  const handleNext = () => {
    if (
      data?.pagination?.limit &&
      data?.total_data &&
      data?.pagination?.limit < data?.total_data
    ) {
      setPaginationParams((prev) => ({ ...prev, limit: prev.limit + 10 }))
    }
  }

  const onChange = (key: string) => {
    initializeQueryParams()
    setPaginationParams({ limit: 10, page: 1 })
    handleStatusChange(key as ApplicantStatus)
  }

  const items: TabsProps["items"] = [
    {
      key: "CANDIDATES",
      label: "Candidates",
      children: isLoading ? (
        <div className=" mt-5">
          <Skeleton active />
          <Skeleton active />
        </div>
      ) : (
        <>
          <div className="mt-4">{data?.total_data ?? 0} applicants</div>
          <CardList
            data={data?.list}
            handleNext={handleNext}
            handleOpen={handleOpen}
            isDisqualified={false}
          />
        </>
      ),
    },
    {
      key: "DISQUALIFIED",
      label: "Disqualified",
      children: isLoading ? (
        <div className=" mt-5">
          <Skeleton active />
          <Skeleton active />
        </div>
      ) : (
        <div className="py-3">
          <CardList
            isDisqualified={true}
            data={data?.list}
            handleNext={handleNext}
            handleOpen={handleOpen}
          />
        </div>
      ),
    },
  ]
  return (
    <div className=" bg-white w-full px-5 py-3 h-full min-h-min">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  )
}
