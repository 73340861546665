import { AutoComplete, Empty, Spin } from "antd"
import React, { useState } from "react"
import { type UseInfiniteQueryResult } from "react-query"
import { type AxiosResponse } from "axios"
import { type ApiResponsePaginatedInfinite } from "../../types/ApiResponse"
import { type Item } from "../../hooks/useReferences"

interface InfiniteAutocompleteProps {
  useDataHook: (
    searchParam: string,
  ) => UseInfiniteQueryResult<
    AxiosResponse<ApiResponsePaginatedInfinite<Item>, any>,
    any
  >
  onSelect?: (value: string, option: Item | undefined | null) => void
  selectedValue?: Item | string
  emptyDescription?: string
  className?: string
  placeholder?: string
  style?: React.CSSProperties
  notFoundContent?: React.ReactNode | ((search: string) => React.ReactNode)
  fieldNames?: { label: string; value: string }
  onClearValue?: null | undefined
  disabled?: boolean
  // autoCompleteProps?: AutoCompleteProps
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
}

export const InfiniteAutoComplete: React.FC<InfiniteAutocompleteProps> = ({
  useDataHook,
  onSelect,
  selectedValue,
  emptyDescription,
  className,
  placeholder,
  style,
  notFoundContent,
  fieldNames,
  onClearValue = undefined,
  disabled,
  getPopupContainer,
}) => {
  const [searchParam, setSearchParam] = useState<string>("")
  const [debouncedSearchParam, setDebouncedSearchParam] = useState(searchParam)
  const [options, setOptions] = useState<Item[] | undefined>([])
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useDataHook(debouncedSearchParam)

  // Handle scroll event
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget
    if (scrollHeight - scrollTop === clientHeight) {
      if (hasNextPage) {
        fetchNextPage()
      }
    }
  }

  // hanldes debounce for search
  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchParam(searchParam)
    }, 300)

    return () => {
      clearTimeout(handler)
    }
  }, [searchParam])

  // Update options when data changes
  React.useEffect(() => {
    if (data?.pages) {
      setOptions(
        () => data?.pages?.flatMap((page) => page.data.data.list ?? []),
      )
    }
  }, [data?.pages])

  // Update searchParam when selectedValue changes
  React.useEffect(() => {
    if (typeof selectedValue === "string") {
      setSearchParam(selectedValue ?? "")
    } else {
      setSearchParam(selectedValue?.name ?? "")
    }
  }, [selectedValue])

  // Determine notFoundContent
  let notFoundContentElement
  if (isFetchingNextPage || isLoading) {
    notFoundContentElement = (
      <div className="flex items-center justify-center p-6">
        <Spin />
      </div>
    )
  } else if (typeof notFoundContent === "function") {
    notFoundContentElement = notFoundContent(searchParam)
  } else {
    notFoundContentElement = notFoundContent ?? (
      <Empty description={emptyDescription ?? "Sorry, we've got nothing"} />
    )
  }

  return (
    <AutoComplete
      disabled={disabled}
      getPopupContainer={getPopupContainer}
      fieldNames={fieldNames ?? { label: "name", value: "id" }}
      style={style}
      className={className ?? ""}
      placeholder={placeholder ?? "Search"}
      options={options ?? []}
      onPopupScroll={handleScroll}
      onSearch={(value) => {
        setSearchParam(value)
      }}
      onClear={() => {
        onSelect && onSelect("", onClearValue)
        setSearchParam("")
      }}
      allowClear
      onSelect={onSelect}
      defaultValue={
        typeof selectedValue === "string"
          ? selectedValue
          : selectedValue?.name || searchParam
      }
      value={
        typeof selectedValue === "string"
          ? selectedValue
          : selectedValue?.name || searchParam
      }
      notFoundContent={notFoundContentElement}
    />
  )
}
