import { useAxiosWithMsal } from "./useAxiosWithMsal"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { message } from "antd"
import { useMsal } from "@azure/msal-react"
import { useContext, useEffect, useState } from "react"
import { passwordChangeRequest } from "../../authConfig"
import { UserContext } from "../../context/user"

export const useUser = () => {
  const getAxiosInstance = useAxiosWithMsal()
  // const { instance } = useMsal();
  // const account = instance.getActiveAccount();
  // const id = (account?.idTokenClaims?.sub)

  const getUser = async () => {
    try {
      const api = await getAxiosInstance()
      return await api.get("/users/profile")
    } catch (error: any) {
      message.error(error?.response?.data?.data)
    }
  }

  return {
    user: useQuery("get-user", getUser),
  }
}

export interface BodyUser {
  first_name: string
  last_name: string
}
export interface BodyUserPassword {
  old_password: string
  new_password: string
}

export const useActionUser = () => {
  const user = useContext(UserContext)
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()
  const [isOpenFormPassword, setIsOpenFormPassword] = useState<boolean>(false)
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined)
  const [imageFile, setImageFile] = useState<File | undefined>(undefined)
  const { instance } = useMsal()
  const handlePassword = async () => {
    try {
      await instance.loginPopup(passwordChangeRequest)
    } catch (error: any) {
      console.log("Password change error", error)
    }
  }

  const handleImageChange = (files: FileList | null) => {
    if (files) {
      const file = files[0]
      const imageUrl = URL.createObjectURL(file)
      setImageUrl(imageUrl)
      setImageFile(file)
    }
  }

  useEffect(() => {
    if (user?.avatar) {
      setImageUrl(`${user?.avatar}?${Date.now()}`)
    }
  }, [user])

  const updateUser = async (val: {}) => {
    try {
      const api = await getAxiosInstance()
      return await api.patch("/users/profile", val)
    } catch (error: any) {
      return error
    }
  }
  const updateUserPassword = async (val: {}) => {
    try {
      const api = await getAxiosInstance()
      return await api.patch("/users/profile/password", val)
    } catch (error: any) {
      return error
    }
  }
  const updateImg = async (val: FormData) => {
    try {
      const api = await getAxiosInstance()
      return await api.post("/users/profile/avatar", val, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    } catch (error: any) {
      return error
    }
  }
  const { mutate: mutateUser } = useMutation({
    mutationFn: (val: BodyUser) => {
      return updateUser(val)
    },
    mutationKey: "update-user",
  })
  const { mutate: mutateUserPassword } = useMutation({
    mutationFn: (val: BodyUserPassword) => {
      return updateUserPassword(val)
    },
    mutationKey: "update-user",
  })
  const { mutate: mutateImage, isLoading } = useMutation({
    mutationFn: (val: FormData) => {
      return updateImg(val)
    },
    mutationKey: "update-image",
  })

  const handleUpdate = (val: BodyUser) => {
    if (imageFile) {
      const formData = new FormData()
      formData.append("file", imageFile)
      mutateImage(formData, {
        onError: (err: any) => {
          message.error(err?.response?.data?.data)
        },
      })
    }
    mutateUser(val, {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-user"])
        message.success("Update profile success")
      },
      onError: (err: any) => {
        message.error(err?.response?.data?.data)
      },
    })
  }
  const handleUpdatePassword = (val: BodyUserPassword) => {
    mutateUserPassword(val, {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-user"])
        message.success("Update password success")
      },
      onError: (err: any) => {
        message.error(err?.response?.data?.data?.new_password)
        return err
      },
    })
  }

  const showModalPassword = () => {
    setIsOpenFormPassword(true)
  }

  const hideModalPassword = () => {
    setIsOpenFormPassword(false)
  }

  const handleChangePassword = async (val: any) => {
    const data = {
      old_password: val.old_password,
      new_password: val.new_password,
    }

    setIsConfirmLoading(true)
    if (val.new_password !== val.confirm_new_password) {
      message.error("Password does not match")

      setTimeout(() => {
        setIsConfirmLoading(false)
      }, 2000)
    } else {
      const response = await handleUpdatePassword(data)
      console.log("maru", response)
      setTimeout(() => {
        setIsConfirmLoading(false)
        hideModalPassword()
      }, 2000)
    }
  }

  return {
    isOpen,
    setIsOpen,
    handlePassword,
    user,
    imageUrl,
    handleImageChange,
    update: {
      handle: handleUpdate,
      loading: isLoading,
    },
    isOpenFormPassword,
    handleChangePassword,
    isConfirmLoading,
    showModalPassword,
    hideModalPassword,
  }
}
