import React, { useContext, useMemo } from "react"
import { Tag, Table, Badge, Dropdown, Button, Popconfirm } from "antd"
import {
  Status,
  useGetJobList,
  ActiveJob,
  useDeleteJobPost,
  useSaveJobPostAsTemplate,
} from "../hooks/useJobPosts"
import { CustomPagination } from "../../../common/components"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import { EllipsisOutlined } from "@ant-design/icons"
import { CloseJobModal } from "./candidates"
import {
  ITourContext,
  TourContext,
} from "../../../common/components/tour/TourProvider"
interface JobPostsTableProps {
  locations: string[] | null | string
  name: string
  status: Status
  teams: string[] | null
}

export function JobPostsTable({
  locations,
  name,
  status = "ACTIVE",
  teams,
}: JobPostsTableProps) {
  const [selectedPage, setSelectedPage] = React.useState(1)
  const [itemsPerPage, setItemsPerPage] = React.useState(20)
  const navigate = useNavigate()
  const deleteJobPost = useDeleteJobPost()
  const saveJobPostAsTemplate = useSaveJobPostAsTemplate()

  const {
    refs: { refJobsTable },
  } = useContext(TourContext) as ITourContext

  const jobListQuery = useGetJobList({
    location: locations,
    name,
    status,
    team: teams,
    by_recruiter: 1,
    page: selectedPage,
    limit: itemsPerPage,
  })
  const [jobCloseModal, setJobCloseModal] = React.useState({
    isOpen: false,
    id: null,
  })

  const columns = useMemo(
    () => [
      {
        title: "Job Title",
        dataIndex: "name",
        key: "name",
        render: (_: any, record: ActiveJob) => (
          <>
            {record?.status === "DRAFT" ? (
              <>
                <Badge className="text-backdrop rounded-full bg-lightGrey px-2 border lowercase text-sm border-backdrop  mr-2">
                  {record?.status}
                </Badge>
                <span
                  onClick={() => {
                    navigate(`/jobs/my-job-posts/create/${record?.id}`)
                  }}
                  className="hover:text-secondary cursor-pointer "
                >
                  {record?.name}
                </span>
              </>
            ) : (
              <div
                className="text-secondary cursor-pointer font-semibold"
                onClick={() =>
                  navigate("/jobs/detail/" + record?.id + "/preview")
                }
              >
                {record?.name}
              </div>
            )}
          </>
        ),
      },
      status === "CLOSE"
        ? {
            title: "Closed On",
            dataIndex: "closed_at",
            key: "closed_at",
            render: (closed_at: string) =>
              closed_at ? dayjs(closed_at).format("ddd DD MMMM YYYY") : "N/A",
          }
        : {
            title: "Expires On",
            dataIndex: "expired_at",
            key: "expired_at",
            render: (expired_at: string) =>
              expired_at ? dayjs(expired_at).format("ddd DD MMMM YYYY") : "N/A",
            // expired_at === null ? "N/A" : expired_at?.toDateString(),
          },
      {
        title: "Function/Team",
        dataIndex: "team",
        key: "team",
        render: (func: string) => (
          <Tag className={`capitalize ${""}`}>
            {func === "-" ? "N/A" : func}
          </Tag>
        ),
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        render: (location: string) => (location === "-" ? "N/A" : location),
      },
      {
        title: "Applicants",
        dataIndex: "total_candidates",
        key: "total_candidates",
        render: (total_candidates: string | number) => total_candidates,
      },

      {
        title: "",
        key: "action",
        render: (_: any, record: any) => (
          <>
            <Dropdown
              trigger={["click"]}
              menu={{
                items:
                  record?.status === "DRAFT"
                    ? [
                        {
                          key: "edit",
                          label: "Edit",
                          onClick: () =>
                            navigate(`/jobs/my-job-posts/create/${record?.id}`),
                        },
                        {
                          key: "delete",
                          label: (
                            <Popconfirm
                              title="Are you sure you want to delete this draft?"
                              onConfirm={() => deleteJobPost.mutate(record?.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              Delete
                            </Popconfirm>
                          ),

                          // onClick: () => deleteJobPost.mutate(record?.id),
                        },
                      ]
                    : record?.status === "CLOSED"
                      ? [
                          {
                            key: "view",
                            label: "View",
                            onClick: () =>
                              navigate(
                                `/jobs/my-job-posts/publish/${record?.id}`,
                              ),
                          },
                          {
                            key: "save-as-template",
                            label: record.is_template
                              ? "Saved as Template"
                              : "Save as Template",
                            disabled: record.is_template,
                            onClick: () => {
                              saveJobPostAsTemplate.mutate(record?.id)
                            },
                          },
                          {
                            key: "delete",
                            label: (
                              <Popconfirm
                                title="Are you sure you want to delete this closed job post?"
                                onConfirm={() =>
                                  deleteJobPost.mutate(record?.id)
                                }
                                okText="Yes"
                                cancelText="No"
                              >
                                Delete
                              </Popconfirm>
                            ),
                            // onClick: () => deleteJobPost.mutate(record?.id),
                          },
                        ]
                      : [
                          {
                            key: "view",
                            label: "View",
                            onClick: () =>
                              navigate(`/jobs/detail/${record?.id}/preview`),
                          },
                          {
                            key: "applicants",
                            label: "Applicants",
                            onClick: () =>
                              navigate(`/jobs/candidates/${record?.id}`),
                          },
                          {
                            key: "save",
                            label: "Save as Template",
                            onClick: () => {
                              saveJobPostAsTemplate.mutate(record?.id)
                            },
                          },
                          {
                            key: "close",
                            label: "Close",
                            onClick: () => {
                              setJobCloseModal({
                                isOpen: true,
                                id: record?.id,
                              })
                            },
                          },
                        ],
              }}
            >
              <Button className="text-backdrop border-lightGrey rounded-md">
                <EllipsisOutlined className="text-lg" />
              </Button>
            </Dropdown>
          </>
        ),
        width: 100,
      },
    ],
    [
      itemsPerPage,
      selectedPage,
      locations,
      jobListQuery?.data?.data?.data?.list,
      status,
    ],
  )

  return (
    <>
      <div className="mt-4 mb-12">
        {jobListQuery?.data?.data?.data?.total_data ?? 0} job
        {jobListQuery?.data?.data?.data?.total_data &&
        jobListQuery?.data?.data?.data?.total_data > 1
          ? "s"
          : ""}{" "}
        found
      </div>
      <div ref={refJobsTable} className="basic-card bg-custom-white !p-0">
        <Table
          columns={columns}
          loading={jobListQuery.isLoading}
          dataSource={jobListQuery?.data?.data?.data?.list}
          className="transparent-table"
          pagination={false}
        />
        {jobListQuery?.data?.data?.data &&
          jobListQuery?.data?.data?.data?.total_data > itemsPerPage && (
            <CustomPagination
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              total_data={jobListQuery?.data?.data?.data?.total_data}
              // pageSize={itemsPerPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
            />
          )}
      </div>
      {jobCloseModal.id !== null && (
        <CloseJobModal
          id={jobCloseModal.id}
          closable={false}
          open={jobCloseModal.isOpen}
          close={() =>
            setJobCloseModal({
              isOpen: false,
              id: null,
            })
          }
        />
      )}
    </>
  )
}
