import React from "react"
import InputWithSaveButton from "./InputWithSaveButton"

interface AddNewWithInputProps {
  isLoading: boolean
  createStage: (stage: any) => Promise<void>
}

export function AddNewWithInput({
  isLoading,
  createStage,
}: AddNewWithInputProps) {
  const [showAddNew, setShowAddNew] = React.useState(true)

  return showAddNew ? (
    <div
      className="text-secondary text-xs font-semibold my-5 cursor-pointer "
      onClick={() => setShowAddNew(false)}
    >
      + Add New
    </div>
  ) : (
    <InputWithSaveButton
      isLoading={isLoading}
      onSave={async (stage) => {
        await createStage(stage)
        setShowAddNew(true)
      }}
      showInput={!showAddNew}
      onClose={() => setShowAddNew(true)}
    />
  )
}
