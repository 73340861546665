import { useAxiosWithMsal } from "../../../common/hooks"
import { type AxiosInstance } from "axios"
import { type ApiResponseV2 } from "../../../common/types/ApiResponse"
import { message } from "antd"
import { useQuery } from "react-query"
import { type AxiosError } from "../../../common/errors"

export const useRawFavConnected = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const getTalentFinderList = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<ApiResponseV2>("/talent-finders/fav-connected")
    } catch (error) {
      message.error(
        "An error occurred while fetching raw favorite and connected",
      )
    }
  }
  const { data, isLoading } = useQuery(
    ["raw-fav-connected-list"],
    getTalentFinderList,
    {
      onError: (error: AxiosError) => {
        message.error(
          "An error occurred while raw favorite and connected: " +
            error.message,
        )
      },
    },
  )
  return {
    data: data?.data?.data,
    isLoading,
  }
}
