import { Button, message } from "antd"
import React, { useCallback, useEffect, useRef, useState } from "react"
import Lottie, { LottieProps } from "react-lottie-player"
import { useNavigate } from "react-router-dom"
import * as animationData from "../../../assets/json/background.json"
import { ApiResponsePaginatedV2 } from "../../../common/types/ApiResponse"
import { AxiosError } from "../../../common/errors"
import { useQuery } from "react-query"
import { axiosInstance } from "../../../config/axios"
import GreenTalentProfile, {
  GreenTalentSkeleton,
  TalentProfile,
} from "../components/GreenTalentProfile"

const defaultOptionsBg: LottieProps = {
  loop: true,
  play: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const GreenTalent = () => {
  const navigate = useNavigate()

  const [params, setParams] = useState({ page: 1, limit: 9 })
  const [dataList, setDataList] = useState<TalentProfile[]>([])
  const [total, setTotal] = useState<number>(0)

  const observer = useRef<IntersectionObserver | null>(null)
  const lastCardElementRef = useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          dataList.length &&
          dataList?.length < total &&
          !isLoading
        ) {
          setParams((prev: any) => ({ ...prev, page: prev.page + 1 }))
        }
      })
      if (node) observer.current.observe(node)
    },
    [dataList?.length],
  )

  const getTalentFinderList = async () => {
    try {
      return await axiosInstance.get<ApiResponsePaginatedV2<TalentProfile>>(
        "talent-finders/database/no-auth",
        {
          params,
        },
      )
    } catch (error) {
      message.error("An error occurred while fetching  talent finder list")
    }
  }
  const { data, isLoading } = useQuery(
    ["talentfinder-list"],
    getTalentFinderList,
    {
      retry: 3,
      onError: (error: AxiosError) => {
        message.error(
          "An error occurred while fetching talent finder list: " +
            error.message,
        )
      },
    },
  )

  useEffect(() => {
    if (!isLoading) {
      if (params.page === 1) {
        setDataList(data?.data.data.list ?? [])
        setTotal(data?.data.data.total_data ?? 0)
      } else {
        setDataList((prev) => [...prev, ...(data?.data.data.list ?? [])])
        setTotal(data?.data.data.total_data ?? 0)
      }
    }
  }, [data?.data.data.list])

  return (
    <div className="relative z-10 ">
      <div className="fixed top-0 -z-10  h-screen w-full">
        <Lottie {...defaultOptionsBg} className="h-full" />
      </div>
      <div className="h-screen flex flex-col pt-32  w-full text-center ">
        <div className="text-center space-y-11 mx-6 md:mx-32 lg:mx-[25%]">
          <h1 className="m-0 text-3xl md:text-[64px] font-semibold leading-tight z-20">
            Access Green Talent, Globally
          </h1>
          <p className="text-lg md:text-[1.5rem]">
            Data is anonymised. Invite candidates to connect or shortlist them
            when they apply to your job post
          </p>
          <Button
            type="primary"
            size="large"
            onClick={() => navigate("/register")}
            className="rounded "
          >
            Get Started
          </Button>
        </div>
      </div>
      <section className="px-20 py-8 -mt-[30vh] z-10">
        {isLoading ? (
          <div className="mx-auto max-w-[1100px] 2xl:max-w-[1300px] place-content-center grid grid-cols-3  gap-4 ">
            <GreenTalentSkeleton />
            <GreenTalentSkeleton />
            <GreenTalentSkeleton />
          </div>
        ) : (
          <div className="mx-auto max-w-[1100px] 2xl:max-w-[1300px] place-content-center grid grid-cols-3  gap-4 ">
            {data?.data?.data?.list?.map((talent, index) => (
              <GreenTalentProfile
                key={talent?.id}
                talent={talent}
                observ={
                  dataList?.length === index + 1
                    ? lastCardElementRef
                    : undefined
                }
              />
            ))}
          </div>
        )}
      </section>
    </div>
  )
}

export default GreenTalent
