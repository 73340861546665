import React from "react"
import { FiveCircleRate } from "../../../common/components"

export interface TLSkillProficiencyProps {
  item: any
}

const TLSkillProficiency: React.FC<TLSkillProficiencyProps> = ({ item }) => {
  return (
    <div
      className="flex items-center gap-4 !border-2 !border-secondaryBlue bg-[#F0F2F5] rounded-xl px-4 py-3"
      style={{ border: "2px solid #D0DDE5" }}
    >
      <FiveCircleRate defaultValue={item?.level} circleSize={16} />
      <p className="m-0 p-0 leading-6">{item?.name}</p>
    </div>
  )
}

export default TLSkillProficiency
