import React from "react"
import TLDetailResponsible from "./TLDetailResponsible"
import TLGreenSkills from "./TLGreenSkills"
import TLTechnicalSkills from "./TLTechnicalSkills"
import TLSoftSkills from "./TLSoftSkills"

interface TLDetailContentProps {
  templateDetailResponse: any
}

const TLDetailContent: React.FC<TLDetailContentProps> = ({
  templateDetailResponse,
}) => {
  return (
    <div className="bg-white rounded-xl p-5 space-y-8">
      <TLDetailResponsible templateDetailResponse={templateDetailResponse} />
      <TLGreenSkills greenSkills={templateDetailResponse} />
      <TLTechnicalSkills otherSkills={templateDetailResponse} />
      <TLSoftSkills softSkills={templateDetailResponse} />
    </div>
  )
}

export default TLDetailContent
