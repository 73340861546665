import React from "react"
import { Button, Tag } from "antd"
import { HeartOutlined, HeartFilled } from "@ant-design/icons"
import dayjs from "dayjs"
import { CloseJobModal } from "../candidates"
import { useNavigate } from "react-router-dom"

interface Props {
  update: {
    save: (id: string) => Promise<void>
    isLoading: boolean
    unsave: (e: any, id: string) => Promise<void>
  }
  id: string
  publisedAt?: string
  isTemplate?: boolean
  isClosed?: boolean
}

export const ViewApplicant: React.FC<Props> = ({
  id,
  publisedAt = "2020-01-01T07:46:02.921Z",
  isTemplate,
  isClosed,
  update,
}) => {
  const navigate = useNavigate()
  const [isOpenJobCloseModal, setIsOpenJobCloseModal] = React.useState(false)
  return (
    <div className="p-5 rounded-xl shadow-md bg-[#FAFAFA] mb-5 w-full">
      <div className=" flex items-center justify-between">
        <div className="tracking-widest uppercase">
          {dayjs(publisedAt).format("DD MMM YY")}
        </div>
        <Tag
          onClick={(e) => {
            if (update.isLoading) return
            if (isTemplate) {
              update.unsave(e, id)
            } else {
              update.save(id)
            }
          }}
          bordered={false}
          color={isTemplate ? undefined : "volcano"}
          className={`${
            isTemplate ? "bg-primary-bg" : "text-backdrop"
          } max-w-full overflow-clip group hover:scale-105 duration-200 transition-all cursor-pointer m-0`}
          icon={
            isTemplate ? (
              <HeartFilled key={"favorite"} className="!text-primary" />
            ) : (
              <HeartOutlined className="hover:text-primary" key="favorite" />
            )
          }
        >
          {isTemplate ? "Saved as template" : "Save as template"}
        </Tag>
      </div>
      <Button
        type="primary"
        className="w-full mt-5"
        onClick={() => navigate("/jobs/candidates/" + id)}
      >
        View Applicants
      </Button>
      {!isClosed && (
        <div className="flex gap-3 mt-5">
          <Button
            className="w-full "
            onClick={() => navigate("/jobs/my-job-posts/create/" + id)}
          >
            Edit
          </Button>
          <Button
            className="w-full "
            disabled={isClosed}
            onClick={() => setIsOpenJobCloseModal(true)}
          >
            Close Job
          </Button>
        </div>
      )}
      <CloseJobModal
        id={id}
        closable={false}
        open={isOpenJobCloseModal}
        close={() => setIsOpenJobCloseModal(false)}
      />
    </div>
  )
}
