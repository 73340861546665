import React from "react"
import { LpAccelerateJobs } from "../../../../assets/images"
import { Button } from "antd"

const AccelerateJobCreation = () => {
  return (
    <section className="min-h-screen py-[70px] md:px-20 px-3 space-y-[40px]">
      <div className="text-center">
        <p className="uppercase text-center tracking-widest text-xs leading text-primary">
          JOB ROLES TEMPLATE LIBRARY
        </p>
        <h2 className="md:text-4xl text-3xl font-semibold mt-2">
          Accelerate job creation with 500+ templates
        </h2>
        <p className="text-xl md:px-36 mt-4">
          Save time by using our job role templates with pre-populated
          responsibilities and skill profiles across various sectors
        </p>
      </div>
      <div className="text-center">
        <img
          src={LpAccelerateJobs}
          className="object-contain h-auto w-full md:h-[230px]"
        />
      </div>
      <div className="w-full flex justify-center">
        <Button className="bg-primary text-white ">Get Started Now</Button>
      </div>
    </section>
  )
}

export default AccelerateJobCreation
