import { useState, useEffect } from "react"

const useTailwindScreenSizes = () => {
  const [screenSize, setScreenSize] = useState("")
  const [width, setWidth] = useState(0)

  useEffect(() => {
    const checkScreenSize = () => {
      const width = window.innerWidth
      if (width < 640) {
        setScreenSize("sm") // Tailwind's 'sm'
      } else if (width >= 640 && width < 768) {
        setScreenSize("md") // Tailwind's 'md'
      } else if (width >= 768 && width < 1024) {
        setScreenSize("lg") // Tailwind's 'lg'
      } else if (width >= 1024 && width < 1280) {
        setScreenSize("xl") // Tailwind's 'xl'
      } else {
        setScreenSize("2xl") // Tailwind's '2xl'
      }
      setWidth(width)
    }

    // Check screen size on initial render
    checkScreenSize()

    // Add event listener for window resize
    window.addEventListener("resize", checkScreenSize)

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkScreenSize)
  }, [])

  return { screenSize, width }
}

export default useTailwindScreenSizes
