import { useNavigate } from "react-router-dom"
import { useAxiosWithMsal } from "../../../common/hooks"
import { useMutation, useQueryClient } from "react-query"
import { message } from "antd"

export const useJobClosed = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const repostJob = async (id: string) => {
    try {
      message.loading("loading...")
      const api = await getAxiosInstance()
      const res = await api.patch(`/jobs/${id}/reuse`)
      message.destroy()
      return res
    } catch (error: any) {
      return error
    }
  }
  const { mutate: actionRepostJob, isLoading: loadingRepostJob } = useMutation({
    mutationFn: (id: string) => {
      return repostJob(id)
    },
    mutationKey: "repost-job",
  })
  const handleRepostJob = (id: any) => {
    actionRepostJob(id, {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["job-list"])
        navigate("/jobs/my-job-posts/create/" + res.data.data.id)
      },
      onError: (err: any) => {
        message.error(err?.response?.data?.data)
      },
    })
  }

  const deleteJob = async (id: string) => {
    try {
      message.loading("loading...")
      const api = await getAxiosInstance()
      const res = await api.delete(`/jobs/${id}`)
      message.destroy()
      return res
    } catch (error: any) {
      return error
    }
  }
  const { mutate: actionDeleteJob, isLoading: loadingDeleteJob } = useMutation({
    mutationFn: (id: string) => {
      return deleteJob(id)
    },
    mutationKey: "repost-job",
  })
  const handleDeleteJob = (id: any) => {
    actionDeleteJob(id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["job-list"])
      },
      onError: (err: any) => {
        message.error(err?.response?.data?.data)
      },
    })
  }

  return {
    repost: {
      action: handleRepostJob,
      loading: loadingRepostJob,
    },
    purge: {
      action: handleDeleteJob,
      loading: loadingDeleteJob,
    },
  }
}
