import { Radar } from "@ant-design/plots"
import React, { useMemo } from "react"

interface ChartProps {
  data: any // Replace 'any' with the actual type of your data
  legend?: boolean
}

export const RadarSoftSkill: React.FC<ChartProps> = ({ data }) => {
  const config = useMemo(() => {
    return {
      data: data || [],
      xField: "name",
      yField: "level",
      shapeField: "smooth",
      xAxis: {
        tickLine: null,
      },
      yAxis: {
        label: false,
        grid: false,
      },
      meta: {
        level: {
          min: 0,
          max: 5,
        },
      },
      radius: 0.6,
    }
  }, [data])

  // @ts-ignore
  return <Radar {...config} />
}
