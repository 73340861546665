import React from "react"
import { RadarSoftSkill } from "./Charts"
import { Skeleton, Space } from "antd"
import { DotChartOutlined } from "@ant-design/icons"

interface TLSoftSkillsProps {
  softSkills: any
}

const TLSoftSkills: React.FC<TLSoftSkillsProps> = ({ softSkills }) => {
  return (
    <div className="px-6 py-2 bg-[#FAFAFA] shadow-custom rounded-xl">
      <h3>Soft Skills</h3>

      <div className="w-full  text-center pt-12">
        {softSkills?.isLoading ? (
          <Space size={90} className=" inline-block  mx-auto my-auto h-72">
            <Skeleton.Node
              active={true}
              className=" !w-[17rem] !h-[15rem]"
              fullSize={true}
            >
              <DotChartOutlined style={{ fontSize: 160, color: "#bfbfbf" }} />
            </Skeleton.Node>{" "}
          </Space>
        ) : (
          <div className=" inline-block  mx-auto my-auto h-72 -mt-10">
            <RadarSoftSkill data={softSkills?.data?.critical_skills} />
          </div>
        )}
      </div>
    </div>
  )
}

export default TLSoftSkills
