import { Link } from "react-router-dom"
import {
  SettingOutlined,
  HomeOutlined,
  BankOutlined,
  SketchOutlined,
} from "@ant-design/icons"
import type { MenuProps } from "antd"
import React from "react"
import { matchPath } from "react-router-dom"
// import { msalInstance } from "../../.."

type MenuItem = Required<MenuProps>["items"][number]
export interface SidebarAuthenticatedMenuItem {
  label?: string
  key?: string
  icon?: React.ComponentType<any>
  children?: SidebarAuthenticatedMenuItem[]
  is_link?: boolean
  type?: string
}

export const skillQueryParams = new URLSearchParams({
  search: "",
  price: "low-to-high",
  availability: "start-today",
  sort_rank: "",
  viewType: "cards",
  page: "1",
  limit: "20",
})

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: string,
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

export const returnSidebarAuthenticatedMenuItems = (
  currentUrl: string,
  returnExtra: boolean = true,
): { menuItems: MenuItem[]; activeLink: string | undefined } => {
  // check if user is an expert, return menu items accordingly
  const menuArray = authMenuItems.filter((item) => item.key !== "expert")

  const activeLink = getActiveLink(menuArray, currentUrl)

  const returnArr = menuArray.map((menuItem) => {
    if (menuItem?.type === "divider") {
      return menuItem
    }
    if (menuItem?.key && menuItem?.icon) {
      return getItem(
        menuItem.label,
        menuItem.key,
        React.createElement(menuItem.icon, {
          className: "text-backdrop",
        }),
        menuItem?.children?.map((itemChild) =>
          getItem(
            <Link className={"text-md text-backdrop"} to={itemChild.link}>
              {itemChild.label}
            </Link>,
            itemChild.key,
          ),
        ),
      )
    }
    if (returnExtra) {
      return getItem(
        <Link className={"text-md  text-backdrop"} to={menuItem.key ?? ""}>
          {menuItem.label}
        </Link>,
        menuItem.key ?? "",
      )
    }
  })
  // adding extra item for logout
  // returnArr.push({
  //   key: "logout",
  //   label: "Logout",
  //   onClick: () => msalInstance.logoutRedirect(),
  // })
  // @ts-ignore
  return { menuItems: returnArr, activeLink }
}

export function getActiveLink(
  authMenuItems: SidebarAuthenticatedMenuItem[],
  currentUrl: string,
) {
  let activeLink
  for (let item of authMenuItems) {
    if (item?.children) {
      const temp = item?.children?.find((childItem) =>
        matchPath({ path: childItem?.key ?? "" }, currentUrl),
      )
      if (temp) {
        activeLink = temp?.key
        break
      }
    } else {
      if (matchPath({ path: item?.key ?? "" }, currentUrl)) {
        activeLink = item?.key
        break
      }
    }
  }
  return activeLink
}

export const returnSidebarUnauthMenuItems = (
  currentUrl: string,
): { menuItems: MenuItem[]; activeLink: string | undefined } => {
  // const activeLink = getActiveLink(menuArray, currentUrl)
  const returnArr = unauthMenuItems.map((menuItem) => {
    return getItem(
      menuItem.label,
      menuItem.key,
      React.createElement(menuItem.icon, {
        className: "text-backdrop",
      }),
      menuItem?.children?.map((itemChild: any) =>
        getItem(
          <Link className={"text-md text-backdrop"} to={itemChild.link}>
            {itemChild.label}
          </Link>,
          itemChild.key,
        ),
      ),
    )
  })
  const activeLink = getActiveLink(unauthMenuItems, currentUrl)

  return {
    menuItems: returnArr,
    activeLink: activeLink,
  }
}

const unauthMenuItems: any[] = []

const authMenuItems = [
  {
    label: "Home",
    key: "home",
    icon: HomeOutlined,
    children: [
      {
        label: "Dashboard",
        link: "/home/dashboard",
        key: "/home/dashboard",
        is_link: true,
      },
    ],
  },
  {
    label: "Jobs",
    key: "jobs",
    icon: BankOutlined,
    children: [
      {
        label: "My Job Posts",
        link: "/jobs/my-job-posts",
        key: "/jobs/my-job-posts/*",
        is_link: true,
      },
      {
        label: "My Job Templates",
        link: "/jobs/my-job-templates",
        key: "/jobs/my-job-templates/*",
        is_link: true,
      },
      {
        label: "Templates Library",
        link: "/jobs/templates-library",
        key: "/jobs/templates-library/*",
        is_link: true,
      },
    ],
  },
  {
    label: "Talent Finder",
    key: "talent_finder",
    icon: SketchOutlined,
    children: [
      {
        label: "Connected Talents",
        link: "/talent/connected",
        key: "/talent/*",
        is_link: true,
      },
      {
        label: "Talent Database",
        link: "/talent/database",
        key: "/talent/database/*",
        is_link: true,
      },
    ],
  },
  {
    label: "Settings",
    key: "settings",
    icon: SettingOutlined,
    children: [
      {
        label: "Company",
        link: "/settings/company",
        key: "/settings/company/*",
        is_link: true,
      },
      {
        label: "Team Management",
        link: "/settings/team-management",
        key: "/settings/team-management/*",
        is_link: true,
      },
      {
        label: "Plan & Billing",
        link: "/settings/plan-and-billing",
        key: "/settings/plan-and-billing/*",
        is_link: true,
      },
    ],
  },
  { type: "divider" },
]
