import { LpTalentPipeline } from "../../../../assets/images"

const TalentPipeline = () => {
  return (
    <section className="min-h-screen py-[70px] md:px-20 px-3 space-y-[40px]">
      <div className="text-center">
        <p className="uppercase text-center tracking-widest text-xs leading text-primary">
          GREEN TALENT DATABASE
        </p>
        <h2 className="md:text-4xl text-3xl font-semibold mt-2">
          Create Talent Pipeline for your future hiring needs
        </h2>
        <p className="text-xl md:px-36 mt-4">
          Unlimited search of Green Talent by location, career aspiration and
          Green Skills. Connect with up to 10* talents every month to invite
          them apply for your jobs later
        </p>
      </div>
      <div className="text-center">
        <img
          src={LpTalentPipeline}
          className="object-contain h-auto w-full lg:h-[535px]"
        />
      </div>
    </section>
  )
}

export default TalentPipeline
