import React from "react"
import { Input, Button } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { debounce } from "../../utils/debounce"
import { InputProps } from "antd/lib/input"
interface CustomSearchProps extends InputProps {
  setSearch: (value: string | null) => void
}
export function CustomSearch({ setSearch, ...inputProps }: CustomSearchProps) {
  const handleSkillSearch = debounce((value: string | null) => {
    setSearch(
      value === null || value?.trim() === "" || value === undefined
        ? null
        : value,
    )
  }, 1000)
  return (
    <Input.Search
      {...inputProps}
      enterButton={
        <Button type="primary" style={{ backgroundColor: "#009ACE" }}>
          <SearchOutlined /> Search
        </Button>
      }
      size="middle"
      onSearch={handleSkillSearch}
    />
  )
}
