import { useCallback, useEffect, useRef, useState } from "react"
import { useAxiosWithMsal } from "../../../common/hooks"
import { type AxiosInstance } from "axios"
import {
  type ApiResponsePaginatedV2,
  type ApiResponseV2,
} from "../../../common/types/ApiResponse"
import { message } from "antd"
import { useMutation, useQuery } from "react-query"
import { type AxiosError } from "../../../common/errors"
import { debounce } from "../../../utils/debounce"

export const useJobList = () => {
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 20,
    name: "",
    status: "JUST_PUBLISH",
    by_recruiter: 1,
  })

  const [dataList, setDataList] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)

  const getAxiosInstance = useAxiosWithMsal()
  const getJobList = async () => {
    try {
      const api: AxiosInstance = await getAxiosInstance()
      return await api.get<ApiResponsePaginatedV2<any>>("/jobs", {
        params,
      })
    } catch (error) {
      message.error("An error occurred while fetching  job list")
    }
  }
  const { data, isLoading } = useQuery(["jobtalent-list", params], getJobList, {
    onError: (error: AxiosError) => {
      message.error(
        "An error occurred while fetching job list: " + error.message,
      )
    },
  })

  const handleSearch = debounce((value: string | null) => {
    setParams((prev: any) => ({ ...prev, name: value, page: 1 }))
  }, 1000)

  const observer = useRef<IntersectionObserver | null>(null)
  const lastCardElementRef = useCallback(
    (node: Element | null) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          dataList.length &&
          dataList?.length < total &&
          !isLoading
        ) {
          setParams((prev: any) => ({ ...prev, page: prev.page + 1 }))
        }
      })
      if (node) observer.current.observe(node)
    },
    //
    [dataList?.length],
  )

  useEffect(() => {
    if (!isLoading) {
      if (params.page === 1) {
        setDataList(data?.data.data.list ?? [])
        setTotal(data?.data.data.total_data ?? 0)
      } else {
        setDataList((prev) => [...prev, ...(data?.data.data.list ?? [])])
        setTotal(data?.data.data.total_data ?? 0)
      }
    }
    //
  }, [data?.data.data.list])

  return {
    dataList,
    isLoading,
    handleSearch,
    params,
    lastCardElementRef,
  }
}

export const useJobUsed = () => {
  const getAxiosInstance = useAxiosWithMsal()
  const getJobUsed = async (talentId: string) => {
    try {
      const api = await getAxiosInstance()
      return await api.get<ApiResponseV2>("/jobs/talent/applied-invited", {
        params: {
          talent_id: talentId,
        },
      })
    } catch (error) {
      message.error("An error occurred while get job used")
    }
  }
  const { mutate, isLoading, data } = useMutation({
    mutationFn: async (val: string) => {
      return getJobUsed(val)
    },
    mutationKey: "job-used-talent",
  })

  const handleGet = (val: string) => {
    mutate(val, {
      onError: (err: any) => {
        message.error(err?.response?.data?.data)
      },
    })
  }

  return {
    data: data?.data?.data,
    handleGet,
    isLoading,
  }
}
