import React from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { useTalentDatabases } from "../hooks/useTalentDatabases"
import { useCV } from "../hooks/useCV"
import { useConnect } from "../hooks/useConnect"
import FilterTop from "./database/FilterTop"
import { Drawer, Spin } from "antd"
import { ListTalents } from "./database/List"
import { FilterRight } from "./database/FilterRight"
import { CV } from "./commons/CV"
import { SelectedStage } from "./matches/SelectedStage"
import useInviteApplicant from "../hooks/useInviteApplicant"
import { useJobList, useJobUsed } from "../hooks/useJobList"
import { useLocation } from "react-router-dom"

export const TalentFinders = () => {
  const {
    dataList: data,
    handleSearch,
    handleFavorite,
    params,
    isLoading,
    handleChangeFilter,
    lastCardElementRef,
    isConnectedPage,
    total,
  } = useTalentDatabases()

  const {
    isOpen,
    handleSelect,
    handleClose,
    current,
    connected,
    setConnected,
    data: dataCV,
    isLoading: isLoadingCV,
  } = useCV()

  const {
    isLoading: isLoadingRequest,
    handleRequest,
    current: currentRequest,
  } = useConnect()

  const {
    dataStages,
    isLoadingStages,
    valueStage,
    handleChangeStage,
    handleInvite,
    isLoading: isLoadingInvite,
  } = useInviteApplicant()

  const jobUsed = useJobUsed()

  const jobList = useJobList()

  const { pathname } = useLocation()

  return (
    <div className=" grid grid-cols-12 gap-5">
      <div className=" col-span-8">
        <FilterTop
          handleSearch={handleSearch}
          search={
            pathname === "/talent/connected"
              ? params?.search_name
              : params?.search
          }
          favorite={params?.favorite}
          handleFavorite={handleFavorite}
        />
        <div className="talent-finders">
          <Spin
            spinning={isLoading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          >
            <ListTalents
              list={data}
              observ={lastCardElementRef}
              handleSelect={handleSelect}
              isLoadingRequest={isLoadingRequest}
              handleRequest={handleRequest}
              currentRequest={currentRequest}
              isConnectedPage={isConnectedPage}
              handleInvite={handleInvite}
              isLoadingInvite={isLoadingInvite}
              total={total}
              jobUsed={jobUsed}
              jobList={jobList}
            />
          </Spin>
        </div>
      </div>
      <div className="col-span-4 h-full ">
        <div className=" sticky top-0 w-full">
          {isConnectedPage && (
            <SelectedStage
              isLoading={isLoadingStages}
              data={dataStages}
              defaultStage={valueStage}
              handleChangeStage={handleChangeStage}
            />
          )}
          <FilterRight
            handleChangeFilter={handleChangeFilter}
            paramGS={params?.green_skills_id}
            paramCareer={params?.career_goal}
            paramLocation={params?.location_id}
          />
        </div>
      </div>
      <Drawer
        open={isOpen}
        onClose={handleClose}
        width={1000}
        placement="right"
        closable={false}
        className=" bg-slate-600 relative"
        bodyStyle={{ backgroundColor: "#E2EBFA" }}
      >
        <CV
          isLoading={isLoadingCV}
          connected={connected}
          setConnected={setConnected}
          id={current?.id ?? ""}
          nick_name={current?.nick_name ?? ""}
          lastName={current?.last_name ?? ""}
          firstName={current?.first_name ?? ""}
          profilePicture={current?.profile_picture ?? ""}
          links={current?.links ?? null}
          data={dataCV}
          roadmaps={current?.roadmap}
          isLoadingReq={isLoadingRequest}
          handleReq={handleRequest}
          currentReq={currentRequest}
          jobUsed={jobUsed}
          jobList={jobList}
          handleInvite={handleInvite}
          isLoadingInvite={isLoadingInvite}
        />
      </Drawer>
    </div>
  )
}
