import { Avatar1, Avatar2, Avatar3, Avatar4, Avatar5 } from "./images"

export const avatarImgs = [Avatar1, Avatar2, Avatar3, Avatar4, Avatar5]

export function assignAvatar(userName) {
  // Step 1: Assign each image a unique name or number
  if (userName === null || userName === undefined) return avatarImgs[3]

  // Step 2: Convert the user's name into a numerical value
  let nameValue = 0
  for (let i = 0; i < userName?.length; i++) {
    nameValue += userName.charCodeAt(i)
  }

  // Step 3: Find the remainder when divided by the number of images
  const imageIndex = nameValue % avatarImgs?.length

  // Step 4: Use the remainder to select the corresponding image
  const assignedImage = avatarImgs[imageIndex]

  return assignedImage
}
