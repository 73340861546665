import React, { useContext, useEffect } from "react"
import { PageContent, PageHeader } from "../../../common/layout"
import { HomeOutlined, SearchOutlined, CloseOutlined } from "@ant-design/icons"
import { AutoComplete, Button, Input } from "antd"
import {
  JobPostsTable,
  ManageStagesDrawer,
  NewJobPostModal,
} from "../components"
import { type Status, useGetJobPostLocations } from "../hooks/useJobPosts"
import { debounce } from "../../../utils/debounce"
import { useSearchParams, useLocation } from "react-router-dom"
import {
  type Item,
  useTeamFunctions,
} from "../../../common/hooks/useReferences"
import { TenantContext } from "../../../context/user"
import {
  ITourContext,
  TourContext,
} from "../../../common/components/tour/TourProvider"

const Content = () => {
  const loc = useLocation()
  const propsData = loc.state

  const tenant = useContext(TenantContext)

  const teamsQuery = useTeamFunctions(tenant?.id)
  const [openManageStagesDrawer, setOpenManageStagesDrawer] =
    React.useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(
    propsData?.propValue ? propsData?.propValue : false,
  )
  const jobPostLocationsQuery = useGetJobPostLocations()

  const [name, setName] = React.useState<string>("")
  const [status, setStatus] = React.useState<Status>("ACTIVE")
  const [locations, setLocation] = React.useState<Item[] | null>(null)
  const [teams, setTeams] = React.useState<null | string[]>(null)
  const [params] = useSearchParams()
  const [modalType, setModalType] = React.useState<"new" | "template">("new")

  useEffect(() => {
    if (params.get("status")) {
      const a: any = params.get("status") ?? "ACTIVE"
      setStatus(a)
    }
  }, [params])

  const handleJobSearch = debounce((value: string) => {
    setName(value)
  }, 1000)

  const handleTabChange = (key: string) => {
    if (key === "ACTIVE" || key === "CLOSE") {
      setStatus(key)
    }
  }

  const handleRemoveLocation = (id: string) => {
    const newLocations = locations?.filter((item) => item.id !== id)
    setLocation(newLocations ?? null)
  }

  const handleRemoveTeam = (id: string) => {
    const newTeams = teams?.filter((item) => item !== id)
    setTeams(newTeams ?? null)
  }

  const {
    refs: {
      refJobsSwitch,
      refJobPostFilter,
      refNewJobPost,
      refNewJobPostFromTemplate,
    },
  } = useContext(TourContext) as ITourContext

  return (
    <div className="px-5 py-6">
      <div className="grid grid-cols-12 gap-x-6 mt-4">
        <div className=" col-span-9">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-4">
              <div ref={refJobsSwitch} className="flex items-center gap-x-2">
                <div
                  style={{
                    border: "1px solid",
                  }}
                  className={`border border-backdrop rounded-full text-backdrop py-[3px] px-[7px] text-xs cursor-pointer ${
                    status === "ACTIVE" ? "bg-primary-bg" : ""
                  }`}
                  onClick={() => handleTabChange("ACTIVE")}
                >
                  Active
                </div>
                <div
                  style={{
                    border: "1px solid",
                  }}
                  className={`border border-backdrop rounded-full text-backdrop py-[3px] px-[7px] text-xs cursor-pointer ${
                    status === "CLOSE" ? "bg-primary-bg" : ""
                  }`}
                  onClick={() => handleTabChange("CLOSE")}
                >
                  Closed
                </div>
              </div>
            </div>
          </div>
          <div
            ref={refJobPostFilter}
            className="basic-card bg-custom-white mt-5"
          >
            <div className="mt-8 flex gap-x-10">
              <div
                className="flex md:w-1/3"
                style={{ borderBottom: "1px solid #D0DDE5" }}
              >
                <SearchOutlined />
                <Input
                  bordered={false}
                  placeholder="search by job role name"
                  onChange={(e) => handleJobSearch(e.target.value)}
                />
              </div>
              <div className="bg-grey-select select-text-backdrop flex-grow">
                <AutoComplete
                  allowClear
                  placeholder="All Locations"
                  className="w-full"
                  fieldNames={{ label: "name", value: "id" }}
                  value={null}
                  onSelect={(value, option: Item) => {
                    if (
                      option !== undefined &&
                      option !== null &&
                      !locations?.includes(option)
                    ) {
                      setLocation((prev) => [...(prev ?? []), option])
                    }
                  }}
                  options={jobPostLocationsQuery.data?.data?.data ?? []}
                ></AutoComplete>
              </div>
              <div className="bg-grey-select select-text-backdrop  flex-grow">
                <AutoComplete
                  allowClear
                  placeholder="All Teams"
                  className="w-full"
                  value={null}
                  onSelect={(value) => {
                    if (
                      value !== undefined &&
                      value !== null &&
                      !teams?.includes(value)
                    ) {
                      setTeams((prev) => [...(prev ?? []), value])
                    }
                    return
                  }}
                  options={
                    teamsQuery.data?.data?.data?.map((value: string) => ({
                      label: value,
                      value: value,
                    })) ?? []
                  }
                ></AutoComplete>
              </div>
            </div>
            <div className="mt-5 flex items-center gap-6">
              {((locations && locations?.length > 0) ||
                (teams && teams?.length > 0)) && (
                <div className="flex items items-center gap-6">
                  <p className="font-semibold">Location:</p>
                  {locations?.map((item) => (
                    <div
                      key={item.id}
                      className="basic-tag-white flex items-center justify-start gap-x-[10px]"
                    >
                      {item.name}
                      <CloseOutlined
                        onClick={() => handleRemoveLocation(item.id)}
                      />
                    </div>
                  ))}
                  <p className="font-semibold">Team:</p>
                  {teams?.map((item) => (
                    <div
                      key={item}
                      className="basic-tag-white flex items-center justify-start gap-x-[10px]"
                    >
                      {item}
                      <CloseOutlined onClick={() => handleRemoveTeam(item)} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="basic-card flex flex-col items-center justify-center gap-4 bg-secondaryYellow col-span-3">
          <Button
            className="border-primary w-full"
            ref={refNewJobPost}
            onClick={() => {
              setModalType("new")
              setIsModalOpen(true)
            }}
          >
            New Job Post
          </Button>
          <Button
            ref={refNewJobPostFromTemplate}
            className="bg-primary text-white w-full"
            onClick={() => {
              setModalType("template")
              setIsModalOpen(true)
            }}
          >
            New from Template
          </Button>
        </div>

        <div className="col-span-full">
          <JobPostsTable
            name={name}
            locations={locations?.map((item) => item.name) ?? null}
            status={status}
            teams={teams}
          />
        </div>
      </div>
      <ManageStagesDrawer
        open={openManageStagesDrawer}
        onClose={() => setOpenManageStagesDrawer(false)}
      />
      <NewJobPostModal
        open={isModalOpen}
        type={modalType}
        onCancel={() => setIsModalOpen(false)}
      />
    </div>
  )
}

export function MyJobPosts(): React.ReactElement {
  const items = [
    {
      path: "/",
      title: <HomeOutlined className="text-gray-400" />,
    },
    {
      title: "My Job Posts",
    },
  ]
  return (
    <PageContent
      header={<PageHeader title={"My Job Posts"} breadcrumbItems={items} />}
      content={<Content />}
    />
  )
}
