import { CheckCircleFilled } from "@ant-design/icons"
import React from "react"
export interface ListProps {
  items: string[] | React.ReactNode[]
}

export const PlanCardList: React.FC<ListProps> = ({ items }) => {
  return (
    <ul className="list-none p-0">
      {items.map((item, index) => (
        <li key={index} className="flex items-center mb-1">
          <CheckCircleFilled
            style={{ width: 18, height: 18 }}
            className="mr-2"
          />
          <span className="p-2">{item}</span>
        </li>
      ))}
    </ul>
  )
}
