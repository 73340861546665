import React from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { CardTalent } from "./CardTalent"
import { type TalentDatabase } from "../type/database"
import { useRawFavConnected } from "../../hooks/useRawFavConnected"
import { useFavorite } from "../../hooks/useFavorite"
import { type AxiosResponse } from "axios"
import { useLocation } from "react-router-dom"
import { useConnectAvailable } from "../../hooks/useConnectAvailable"
import cookie from "../../../../assets/images/month.svg"

interface ListTalentsProps {
  list: TalentDatabase[] | undefined
  observ?: (node: Element | null) => void
  handleSelect: (val: TalentDatabase, connect: boolean) => void
  isLoadingRequest: boolean
  handleRequest: (val: string) => void
  currentRequest: string
  isConnectedPage: boolean
  handleInvite: (val: any) => Promise<AxiosResponse<any, any> | undefined>
  isLoadingInvite: boolean
  total: number
  jobUsed: {
    data: any
    handleGet: (val: string) => void
    isLoading: boolean
  }
  jobList: {
    dataList: any[]
    isLoading: boolean
    handleSearch: (...args: any[]) => void
    params: any
    lastCardElementRef: (node: Element | null) => void
  }
}

export const ListTalents: React.FC<ListTalentsProps> = ({
  list,
  observ,
  handleSelect,
  isLoadingRequest,
  handleRequest,
  currentRequest,
  isConnectedPage,
  handleInvite,
  isLoadingInvite,
  total,
  jobUsed,
  jobList,
}) => {
  const { pathname } = useLocation()
  const { isLoading, handleUpdate, current } = useFavorite()
  const { data: raw, isLoading: isLoadingRaw } = useRawFavConnected()

  const { data: dataConnectAvail, isLoading: isLoadingAvail } =
    useConnectAvailable()

  return list?.length ?? 0 ? (
    <div>
      {pathname === "/talent/database" ? (
        <div className="mt-5">
          <strong>
            {isLoadingAvail ? <LoadingOutlined /> : dataConnectAvail || 0}{" "}
            Connect
          </strong>{" "}
          Requests available
        </div>
      ) : (
        <div className="mt-5">
          <strong>{total || 0}</strong> Talents shared their contact information
          with you
        </div>
      )}

      {list?.map((item, index) => (
        <CardTalent
          key={index}
          data={item}
          raw={raw}
          handleSelect={handleSelect}
          isLoading={isLoadingRaw}
          isLoadingUpdate={isLoading}
          handleUpdate={handleUpdate}
          currentUpdate={current}
          isLoadingRequest={isLoadingRequest}
          handleRequest={handleRequest}
          currentRequest={currentRequest}
          observ={list.length === index + 1 ? observ : undefined}
          isConnectedPage={isConnectedPage}
          dataJob={jobList.dataList}
          observJob={jobList.lastCardElementRef}
          handleGet={jobUsed.handleGet}
          paramsJob={jobList.params}
          handleSearchJob={jobList.handleSearch}
          dataJobUsed={jobUsed.data}
          isLoadingJobUsed={jobUsed.isLoading}
          handleInvite={handleInvite}
          isLoadingInvite={isLoadingInvite}
        />
      ))}
    </div>
  ) : (
    <div className="mt-5 h-60 shadow-md rounded-xl bg-custom-white flex justify-center items-center flex-col gap-5">
      <div>No new Talents to show</div>
      <img className="w-20 h-20 object-cover" src={cookie} alt="no data" />
    </div>
  )
}
